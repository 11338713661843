import { ledgerService } from '@/main';
import Ledger from '@/models/Finance/Ledger';
import to from 'await-to-js';
import { Component } from 'vue-property-decorator';
import BaseModal from './BaseModal';

@Component
export default class CreateLedgerAccountModal extends BaseModal {
    private ledger: Ledger = new Ledger();

    public setLedger(ledger: Ledger) {
        this.ledger = ledger;
    }

    public async createOrUpdateLedgerAccount() {
        this.showPending('CREATE_OR_UPDATE_LEDGER_PENDING');
        let err = null;
        if (this.ledger.ledgerId) {
            [err] = await to(ledgerService.updateLedgers(this.ledger));
        } else {
            [err] = await to(ledgerService.postLedgers(this.ledger));
        }

        if (err) {
            return this.clearAndShowError('CREATE_OR_UPDATE_LEDGER_FAILED', null);
        }

        this.$emit('reload-ledgers');
        this.clearAndShowSuccess('CREATE_OR_UPDATE_LEDGER_SUCCESS');
        this.hide();
    }

    public onHide() {
        this.ledger = new Ledger();
    }
}
