import Digit from '../Validator/Digit';
import IValidation from '../Validator/IValidation';
import LowerCase from '../Validator/LowerCase';
import MinLength from '../Validator/MinLength';
import NonAlpha from '../Validator/NonAlpha';
import UpperCase from '../Validator/UpperCase';

export default class Policies {
    public static Default: IValidation[] =  [Policies.MinLength(), Policies.NonAlpha()];
    public static Password: IValidation[] =  [
        Policies.MinLength(),
        Policies.NonAlpha(),
        Policies.LowerCase(),
        Policies.UpperCase(),
        Policies.Digit(),
    ];

    public static MinLength(length: number = 8): IValidation {
        return new MinLength(length);
    }

    public static NonAlpha(): IValidation {
        return new NonAlpha();
    }

    public static LowerCase(): IValidation {
        return new LowerCase();
    }

    public static UpperCase(): IValidation {
        return new UpperCase();
    }

    public static Digit(): IValidation {
        return new Digit();
    }
}
