export default class AttributionPercentages {
    public groupAttributionsPercentage: number = null;
    public groupRemittancePercentage: number = null;
    public teamAttributionsPercentage: number = null;
    public groupId: number = null;

    /**
     * Constructor with initial values
     */
    constructor(teamAttributionsPercentage?: number, groupRemittancePercentage?: number, groupAttributionsPercentage?: number) {
        if (groupAttributionsPercentage) {
            this.groupAttributionsPercentage = groupAttributionsPercentage;
        }
        if (teamAttributionsPercentage) {
            this.teamAttributionsPercentage = teamAttributionsPercentage;
        }
        if (groupRemittancePercentage) {
            this.groupRemittancePercentage = groupRemittancePercentage;
        }
    }

    public hasValues() {
        return this.groupAttributionsPercentage !== null || this.groupRemittancePercentage !== null || this.teamAttributionsPercentage !== null;
    }
}
