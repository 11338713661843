import { BaseService } from './baseService';
import { AxiosHelper } from '../helpers/axiosHelper';
import { UserRoles } from '../models/UserRoles';
import Vue from 'vue';

export default class UserRoleService extends BaseService {
    private endpoint = `${Vue.$env().identityEndpoint}api/roles/`;
    private addDeleteEndpoint = `${this.endpoint}user/:externalIdentifier/role/:role`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public getUsersInRole(role: UserRoles) {
        return this.get(`${this.endpoint}:role/users`, [['role', role]]);
    }

    public makeAddDeleteData(externalIdentifier: string, role: UserRoles): ReadonlyArray<[string, any]> {
        return [
            ['externalIdentifier', externalIdentifier],
            ['role', role as string],
        ];
    }

    public setUserRole(externalIdentifier: string, role: UserRoles) {
        return this.post(this.addDeleteEndpoint, null, this.makeAddDeleteData(externalIdentifier, role));
    }

    public deleteUserRole(externalIdentifier: string, role: UserRoles) {
        return this.delete(this.addDeleteEndpoint, this.makeAddDeleteData(externalIdentifier, role));
    }
}
