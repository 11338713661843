import Attribution from '../Attribution';
import { PrivacyLevel } from '../PrivacyLevel';

export default class Template {
    public financeGroupTemplateId: number = 0;

    public teamId: number = 0;
    public name: string = '';
    public description: string = '';
    public applyMissingAttributionsOnTransfer: boolean = true;
    public autoDistributeIncomeToSavings: boolean = true;
    public autoDistributeExpensesToSavings: boolean = true;
    public autoCompensateGroupMembers: boolean = true;
    public privacyLevel: PrivacyLevel = PrivacyLevel.Unknown;
    public attributions: Attribution[] = [];

    constructor(obj?: Partial<Template>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
