import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { groupHelper, memberHelper, teamHelper, ecosystemHelper } from '@/main';
import PageRender from '@/models/PageRender';
import Group from '@/models/Group';
import { Person } from '@/models/Person';
import { periodModule } from '@/store/modules/period';

@Component
export default class GroupsComponent extends PageRender {
    public groups: Group[] = null;
    public members: Person[] = [];
    public isLoading: boolean = true;

    public groupsColumns: any[] = [
        { cell: this.renderRouterLink, title: 'NAME', width: 300 },
        { field: 'purpose', title: 'GROUP_PURPOSE' },
        { cell: this.renderMembers, title: 'GROUP_MEMBERS', width: 125 },
        { cell: this.renderGroupLead, title: 'GROUP_LEAD', width: 300 },
    ];

    public async created() {
        const self = this;

        this.groups = await groupHelper.getGroups();
        this.members = await memberHelper.getMembers(true);

        self.isLoading = false;
    }

    public renderGroupLead(h, _, row): any {
        return this.renderMember(h, row.dataItem.groupLead);
    }

    public renderRouterLink(h, _, row) {
        const routeUrl = this.$router.resolve({
            name: 'team-directory-group',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                groupKey: row.dataItem.groupKey,
                groupId: row.dataItem.groupId,
                teamId: teamHelper.currentTeam.id.toString(),
                teamKey: teamHelper.currentTeam.key,
                tab: 'workspace',
                period: periodModule.selectedPeriod,
            },
        }).href;

        const props = {
            text: row.dataItem.name,
            url: routeUrl,
            entity: row.dataItem,
        };

        return h(Vue.component('grid-router-link'), { props });
    }

    public fullname(member: any): string {
        return `${member.firstName} ${member.insertion ? member.insertion : ''} ${member.lastName}`;
    }

    public renderFullname(h, _, row) {
        const member = row.dataItem;
        const name = `${member.firstName} ${member.insertion ? member.insertion : ''} ${member.lastName}`;
        return h('td', [name]);
    }
}
