import PageRender from '@/models/PageRender';
import { Svg } from '@svgdotjs/svg.js';
import { SVG } from '@svgdotjs/svg.js';
import { Component } from 'vue-property-decorator';
import '@svgdotjs/svg.draggable.js';
import moment from 'moment';
import svgPanZoom from 'svg-pan-zoom';
import { insightService, mapService, teamService } from '@/main';
import Team from '@/models/Team';
import IMapSetting from '@/models/Map/IMapSettings';
import GraphData from '@/models/Graph/GraphData';
import { translateModule } from '@/store/modules/translate';
import { periodModule } from '@/store/modules/period';

@Component
export default class InsightsComponent extends PageRender {
    public teams: Team[] = [];
    public canvas: Svg = null;
    public panZoomInstance: SvgPanZoom.Instance = null;
    public point: SvgPanZoom.Point = null;
    public selectedView: any = null;
    public selectedLenses: any[] = [];
    public views: any[] = [];
    public lenses: any[] = [];
    public graphData: GraphData = new GraphData();
    public lensesData: any[] = [];
    public chartData: any[] = [];
    public showVisuals: boolean = false;

    public lines = [
        { text: 'Results', value: 'results' },
        { text: 'Balance', value: 'balance' },
        { text: 'Safety Zone', value: 'safetyZone' },
        { text: 'External Revenue', value: 'externalRevenue' },
        { text: 'Expenses', value: 'expenses' },
    ];

    public selectedLines: any[] = [];
    public chartsLib = null;
    public chartsLibBar = null;
    public multiplePeriodsSelected: boolean = false;
    public target: string = '';
    public content: string = translateModule.value('HOVER_INSIGHTS');
    public edgesToShowContentOfDestination: any[] = [];
    public edgesToShowContentOfOrigin: any[] = [];
    public availableTeamsList = [];
    public paymentTerm: any = { value: 0, name: 'Zero days' };

    private mapSettings: IMapSetting[] = [];

    public get hasFinanceLenseSelected() {
        return this.selectedLenses.find((x) => x.slug === 'ecosystem-finance');
    }

    public get hasCO2LenseSelected() {
        return this.selectedLenses.find((x) => x.slug === 'co2');
    }

    public get hasFearlessLenseSelected() {
        return this.selectedLenses.find((x) => x.slug === 'fearless');
    }

    public get paymentTerms() {
        return [
            { value: 0, name: 'Zero days' },
            { value: 14, name: '14 Days' },
            { value: 30, name: '30 Days' },
            { value: 60, name: '60 Days' },
            { value: 90, name: '90 Days' },
        ];
    }

    public get availableTeams() {
        if (!this.lensesData) {
            return [{ teamId: null, expanded: false, visuals: false }];
        }

        return this.availableTeamsList;
    }

    public chartOptionsBar() {
        if (!this.chartsLibBar) {
            return null;
        }

        return this.chartsLibBar.charts.Bar.convertOptions({
            chart: {
                title: 'Ecosystem Team Finances',
                subtitle: 'Result, balance, etc per period',
            },
            height: 800,
        });
    }

    public createBarChart(el, google) {
        this.chartsLibBar = google;
        return new this.chartsLibBar.charts.Bar(el);
    }

    public createLineChart(el, google) {
        this.chartsLib = google;
        return new this.chartsLib.charts.Line(el);
    }

    public async mounted() {
        await this.loadPersonalViews();
        await this.loadLenses();
        this.mapSettings = await mapService.getSettings();
        this.teams = await teamService.getTeams();

        this.isLoading = false;
    }

    public async reloadInsights(dates) {
        this.isLoading = true;

        this.multiplePeriodsSelected = dates.fromFilterMonth !== dates.toFilterMonth;

        await this.loadLens(dates);

        if (this.lensesData.length === 0) {
            return;
        }

        if (this.panZoomInstance) {
            this.panZoomInstance.destroy();
            delete this.panZoomInstance;

            this.canvas.clear();
        }
        this.initCanvas();
        this.baseStructure();
        this.plotTeams();
        this.plotRevenue();

        this.$nextTick(() => {
            this.panZoomInstance = svgPanZoom('#svg-container', {
                panEnabled: true,
                controlIconsEnabled: true,
            });

            this.panZoomInstance.zoom(1.2);
        });

        this.isLoading = false;
    }

    public showRelatedElements(id) {
        this.content = '';

        this.edgesToShowContentOfDestination = [];
        this.edgesToShowContentOfOrigin = [];

        this.graphData.edgePaths.forEach((x) => {
            x.stroke({ color: 'rgba(0,0,0,0)' });
        });

        this.graphData.edges
            .filter((x) => x.origin === id || x.destination === id)
            .forEach((edge) => {
                const path = this.graphData.edgePaths.find((x) => x.id() === `edge-${edge.origin}-${edge.destination}`);
                if (path) {
                    if (edge.destination === id) {
                        path.stroke({ color: `rgba(0,128,0,0.3)` });
                    } else {
                        path.stroke({ color: `rgba(255,0,0,0.3)` });
                    }
                }

                let name = this.getOriginName(id);
                if (!name) {
                    name = this.getDestinationName(id);
                }

                this.content = name;

                if (edge.destination === id) {
                    this.edgesToShowContentOfDestination.push(edge);
                } else {
                    this.edgesToShowContentOfOrigin.push(edge);
                }
            });
    }

    public showAllEdges() {
        this.graphData.edgePaths.forEach((x) => {
            x.stroke({ color: 'rgba(0,0,0,0.3)' });
        });
    }

    public plotTeams() {
        this.graphData.edges = [];
        this.graphData.edgePaths = [];

        for (let j = 0; j < this.lensesData.length; j++) {
            const lense = this.lensesData[j];

            if (lense && lense.graph) {
                for (let i = 0; i < lense.graph.nodes.length; i++) {
                    const vertex = lense.graph.nodes[i];
                    const teamSettings = this.mapSettings.find((x) => x.team === vertex.id);

                    vertex.x = teamSettings.x;
                    vertex.y = teamSettings.y;
                    vertex.radius = teamSettings.radius;

                    const group = this.canvas.group();

                    const vertexCircle = this.canvas.circle(vertex.radius).fill('#0f0');
                    vertexCircle.attr({ cx: vertex.x, cy: vertex.y, opacity: '0.2', name: vertex.name, id: vertex.id, class: 'cursor-pointer' });

                    const text = this.canvas.text(vertex.name);
                    const textBbox = text.bbox();
                    text.move(vertex.x - textBbox.width / 2, vertex.y - textBbox.height - vertex.radius / 2);

                    group.add(vertexCircle);
                    group.add(text);

                    group.on('mouseover', () => {
                        this.showRelatedElements(vertexCircle.id());
                    });
                    group.on('mouseout', () => {
                        this.showAllEdges();
                    });

                    this.graphData.vertices[vertex.id] = vertex;
                    this.graphData.vertexPaths[vertex.id] = group;
                }

                for (let i = 0; i < lense.graph.edges.length; i++) {
                    const edge = lense.graph.edges[i];
                    this.drawEdge(edge);
                }
            }
        }
    }

    public drawEdge(edge) {
        const v1 = this.graphData.vertices[edge.origin];
        const v2 = this.graphData.vertices[edge.destination];

        if (!v1 || !v2) {
            return;
        }

        const startx = v1.x;
        const starty = v1.y - v1.radius / 3;

        const endx = v2.x;
        // tslint:disable: restrict-plus-operands
        const endy = v2.y + v2.radius / 3;

        const mpx = (startx + endx) * 0.5;
        const mpy = (starty + endy) * 0.5;

        // angle of perpendicular to line:
        const theta = Math.atan2(endy - starty, endx - startx) - Math.PI / 2;

        // distance of control point from mid-point of line:
        const offset = 30; // random(20, 50);

        // location of control point:
        const c1x = mpx + offset * Math.cos(theta);
        const c1y = mpy + offset * Math.sin(theta);

        const path = this.canvas.path(`M${startx} ${starty} Q${c1x} ${c1y} ${endx} ${endy}`);
        path.stroke({ color: 'rgba(0,0,0,0.3)', width: 5, linecap: 'round' })
            .attr({ fill: 'transparent' })
            .attr('id', `edge-${edge.origin}-${edge.destination}`);

        this.graphData.edges.push(edge);
        this.graphData.edgePaths.push(path);
    }

    public plotRevenue() {
        this.lensesData.forEach((lense) => {
            if (lense && lense.totals) {
                lense.totals.teamTotals.forEach((team) => {
                    const externalRevenue = this.getTableValue(team.teamId, 'externalRevenue');
                    if (externalRevenue) {
                        this.drawEdge({ destination: team.teamId, origin: 'ext_revenue', total: externalRevenue });
                    }

                    const memberCosts = this.getTableValue(team.teamId, 'memberExpenses');
                    const memberCostsExtra = this.getTableValue(team.teamId, 'memberNonRecurringExpenses');
                    if (memberCosts || memberCostsExtra) {
                        this.drawEdge({ origin: team.teamId, destination: 'member_costs', total: memberCosts + memberCostsExtra });
                    }

                    const groupCosts = this.getTableValue(team.teamId, 'groupExpenses');
                    const groupCostsExtra = this.getTableValue(team.teamId, 'groupNonRecurringExpenses');
                    if (groupCosts || groupCostsExtra) {
                        this.drawEdge({ origin: team.teamId, destination: 'group_costs', total: groupCosts + groupCostsExtra });
                    }
                });
            }
        });
    }

    public async loadPersonalViews() {
        if (this.views.length === 0) {
            this.views = await insightService.getViews(true);
        }
    }

    public async loadLenses() {
        this.lenses = await insightService.getLenses();

        this.lenses.push({ slug: 'co2', name: 'CO2 footprint' });
        this.lenses.push({ slug: 'fearless', name: 'Fearless PSI (demo)' });
    }

    public async loadLens(dates) {
        if (this.selectedLenses.length > 0) {
            this.lensesData = [];

            for (let i = 0; i < this.selectedLenses.length; i++) {
                if (this.selectedLenses[i].slug === 'co2' || this.selectedLenses[i].slug === 'fearless') {
                    continue;
                }

                const lense = await insightService.getLens(
                    this.selectedLenses[i].slug,
                    this.selectedView.insightViewId,
                    dates,
                    this.selectedLenses[i].slug === 'ecosystem-cash' ? this.paymentTerm.value : null,
                );
                this.lensesData.push(lense.data);

                if (i === 0) {
                    this.availableTeamsList = [];
                    lense.data.totals.teamTotals.forEach((x) => {
                        this.availableTeamsList.push({
                            teamId: x.teamId,
                            expanded: false,
                            visuals: false,
                        });
                    });

                    if (
                        !this.availableTeamsList.length &&
                        lense.data.table.results &&
                        lense.data.table.results.items &&
                        lense.data.table.results.items.length > 0
                    ) {
                        lense.data.table.results.items[0].item.teamResults.forEach((x) => {
                            this.availableTeamsList.push({
                                teamId: x.teamId,
                                expanded: false,
                                visuals: false,
                            });
                        });
                    }
                }
            }
        }
    }

    public initCanvas() {
        if (!this.canvas) {
            this.canvas = SVG()
                .addTo('#graph')
                .size(this.calculateGraphWidth(), this.calculateGraphHeight())
                .attr('id', 'svg-container')
                .addClass('svg-container');
        } else {
            this.canvas.clear();
        }
    }

    public baseStructure() {
        const petriCircle = this.canvas.circle(2000);
        petriCircle.attr({ cx: 0, cy: 0, 'fill-opacity': 0, stroke: '#32e875', 'stroke-width': 5 });

        const group = this.canvas.group();

        const elipse = this.canvas
            .ellipse(400, 200)
            .fill('#f06')
            .attr({ opacity: '0.1', id: 'ext_revenue', class: 'cursor-pointer' })
            .move(-1400, -100);
        const externalRevenueText = this.canvas.text('External revenue').attr({ 'font-size': 30 });
        const externalRevenueTextBox = externalRevenueText.bbox();
        externalRevenueText.move(
            (elipse.x() as number) + (elipse.width() as number) / 2 - externalRevenueTextBox.width / 2,
            (elipse.y() as number) + (elipse.height() as number) / 2 - externalRevenueTextBox.height / 2,
        );

        group.add(externalRevenueText);
        group.add(elipse);

        group.on('mouseover', () => {
            this.showRelatedElements(elipse.id());
        });
        group.on('mouseout', () => {
            this.showAllEdges();
        });

        const group2 = this.canvas.group();
        const elipse2 = this.canvas
            .ellipse(400, 200)
            .fill('#f06')
            .attr({ opacity: '0.1', id: 'member_costs', class: 'cursor-pointer' })
            .move(750, -800);
        const memberCostsText = this.canvas.text('Member costs').attr({ 'font-size': 30 });
        const memberCostsTextBox = memberCostsText.bbox();
        memberCostsText.move(
            (elipse2.x() as number) + (elipse2.width() as number) / 2 - memberCostsTextBox.width / 2,
            (elipse2.y() as number) + (elipse2.height() as number) / 2 - memberCostsTextBox.height / 2,
        );

        group2.add(memberCostsText);
        group2.add(elipse2);

        group2.on('mouseover', () => {
            this.showRelatedElements(elipse2.id());
        });
        group2.on('mouseout', () => {
            this.showAllEdges();
        });

        const group3 = this.canvas.group();
        const elipse3 = this.canvas
            .ellipse(400, 200)
            .fill('#f06')
            .attr({ opacity: '0.1', id: 'group_costs', class: 'cursor-pointer' })
            .move(750, 600);
        const groupCostsText = this.canvas.text('Group costs').attr({ 'font-size': 30 });
        const groupCostsTextBox = groupCostsText.bbox();
        groupCostsText.move(
            (elipse3.x() as number) + (elipse3.width() as number) / 2 - groupCostsTextBox.width / 2,
            (elipse3.y() as number) + (elipse3.height() as number) / 2 - groupCostsTextBox.height / 2,
        );

        group3.add(groupCostsText);
        group3.add(elipse3);

        group3.on('mouseover', () => {
            this.showRelatedElements(elipse3.id());
        });
        group3.on('mouseout', () => {
            this.showAllEdges();
        });

        // tslint:disable: no-string-literal
        this.graphData.vertexPaths['ext_revenue'] = elipse;
        this.graphData.vertices['ext_revenue'] = {
            id: 'ext_revenue',
            x: (elipse.x() as number) + 400,
            y: (elipse.y() as number) + 100,
            name: 'External revenue',
            radius: 0,
        };

        this.graphData.vertexPaths['member_costs'] = elipse2;
        this.graphData.vertices['member_costs'] = {
            id: 'member_costs',
            x: elipse2.x(),
            y: (elipse2.y() as number) + 100,
            name: 'Member costs',
            radius: 0,
        };
        this.graphData.vertexPaths['group_costs'] = elipse3;
        this.graphData.vertices['group_costs'] = {
            id: 'group_costs',
            x: elipse3.x(),
            y: (elipse3.y() as number) + 100,
            name: 'Group costs',
            radius: 0,
        };
    }

    public getComponentName(slug) {
        return `${slug}-totals`;
    }

    public getLenseData(slug, teamId?: number) {
        let tableData:any;

        if (slug === 'ecosystem-finance') {
            const lenseData = this.lensesData.find((x) => x.slug === slug);
            if (!teamId) {
                if (lenseData) {
                    tableData = lenseData.totals;
                }

                return tableData;
            }

            if (lenseData) {
                tableData = lenseData.totals.teamTotals.find((y) => y.teamId === teamId);
            }

            return tableData;
        }

        const lens = this.lensesData.find((x) => x.slug === slug);
        if (lens && lens.table && lens.table.results && lens.table.results.items && lens.table.results.items.length > 0) {
            if (!teamId) {
                tableData = lens.table.results.items[0].item;
                tableData.startBalance = lens.table.results.items[lens.table.results.items.length - 1].item.startBalance;
            } else {
                tableData = lens.table.results.items[0].item.teamResults.find((y) => y.teamId === teamId);
                tableData.startBalance = lens.table.results.items[lens.table.results.items.length - 1].item.startBalance;
            }
        }

        return tableData;
    }

    public getTableData(slug, teamId) {
        let tableData: any;
        const lens = this.lensesData.find((x) => x.slug === slug);
        if (lens && lens.table && lens.table.results && lens.table.results.items && lens.table.results.items.length > 0) {
            if (!teamId) {
                tableData = lens.table.results.items[0].item;
                tableData.startBalance = lens.table.results.items[lens.table.results.items.length - 1].item.startBalance;
            } else {
                tableData = lens.table.results.items[0].item.teamResults.find((y) => y.teamId === teamId);
                tableData.startBalance = lens.table.results.items[lens.table.results.items.length - 1].item.startBalance;
            }
        }

        return tableData;
    }

    public getOriginName(origin) {
        if (origin === 'ext_revenue') {
            return 'External revenue';
        }

        if (origin === 'group_costs') {
            return 'Group costs';
        }

        if (origin === 'member_costs') {
            return 'Member costs';
        }

        return this.graphData.vertices.find((x) => x && x.id === origin).name;
    }

    public getDestinationName(destination) {
        if (destination === 'group_costs') {
            return 'Group costs';
        }

        if (destination === 'member_costs') {
            return 'Member costs';
        }

        if (destination === 'ext_revenue') {
            return 'External revenue';
        }

        return this.graphData.vertices.find((x) => x && x.id === destination).name;
    }

    public getTableValue(teamId: number, property: string) {
        const ecosystemFinanceLenseData = this.lensesData.find((x) => x.slug === 'ecosystem-finance');
        let total = 0;
        if (ecosystemFinanceLenseData) {
            const table = ecosystemFinanceLenseData.table;
            table.results.items.forEach((item) => {
                const teamResult = item.item.teamResults.find((x) => x.teamId === teamId);

                total += teamResult[property];
            });
        }

        return total;
    }

    public getTeamRevenueParts() {
        const data = [];
        const lines = ['Period'];

        const lensTable = this.lensesData.find((x) => x.slug === 'ecosystem-finance').table;

        for (let i = lensTable.results.items.length - 1; i >= 0; i--) {
            const element = lensTable.results.items[i];
            const periodData = [];
            const period = periodModule.allPeriods.find((x) => x.periodId === element.periodId);
            periodData.push(moment(period.startDate, 'YYYY-MM-DD').format('YYYY-MM'));

            const ecologyTotalRevenue = element.item.externalRevenue;

            for (let j = 0; j < element.item.teamResults.length; j++) {
                const teamRevenue = element.item.teamResults[j].externalRevenue;
                const value = (teamRevenue / ecologyTotalRevenue) * 100;

                if (i === 0) {
                    const team = this.teams.find((x) => x.teamId === element.item.teamResults[j].teamId);
                    lines.push(team ? team.name : element.item.teamResults[j].teamId);
                }

                if (typeof value !== 'undefined' || !isNaN(value)) {
                    periodData.push(value);
                } else {
                    periodData.push(null);
                }
            }

            data.push(periodData);
        }

        data.unshift(lines);

        return data;
    }

    public getChartDataForLens(slug, teamId, props) {
        const data = [];
        const lines = ['Period'];

        lines.push(...props);

        if (slug === 'ecosystem-finance') {
            const lensTable = this.lensesData.find((x) => x.slug === slug).table;

            for (let i = lensTable.results.items.length - 1; i >= 0; i--) {
                const element = lensTable.results.items[i];
                const periodData = [];
                const period = periodModule.allPeriods.find((x) => x.periodId === element.periodId);

                periodData.push(moment(period.startDate, 'YYYY-MM-DD').format('YYYY-MM'));

                for (let j = 0; j < props.length; j++) {
                    const prop = props[j];
                    let value = 0;
                    if (!teamId) {
                        value = element.item[prop];
                    } else {
                        value = element.item.teamResults.find((x) => x.teamId === teamId)[prop];
                    }
                    if (typeof value !== 'undefined') {
                        periodData.push(value);
                    } else {
                        periodData.push(null);
                    }
                }

                data.push(periodData);
            }

            data.unshift(lines);
        }

        return data;
    }

    public getTeamName(teamId: number) {
        const team = this.teams.find((x) => x.teamId === teamId);
        return team ? team.name : 'N/A';
    }

    public getTableDataForLens(lens) {
        const list = [];

        if (lens.slug === 'ecosystem-finance') {
            const flattendData = [];

            lens.table.results.items.forEach((x) => {
                x.item.periodId = x.periodId;

                x.item.teamResults.forEach((y) => {
                    y.periodId = x.periodId;
                    flattendData.push(y);
                });
            });

            list.push(...flattendData);
        }

        return list;
    }

    private calculateGraphHeight() {
        const height = window.innerHeight - 190;
        return height;
    }

    private calculateGraphWidth() {
        return '100%';
    }
}
