import { AxiosHelper } from '../helpers/axiosHelper';
import { AccountType } from '../models/AccountType';
import { BaseService } from './baseService';
import Vue from 'vue';
import to from 'await-to-js';
import AccountFunds from '@/models/Finance/Api/AccountFunds';
import Range from '@/models/Finance/Api/Range';
import { FinanceAccount } from '@/models/Interfaces';

export class FinanceAccountService extends BaseService {
    private endpoint = `${Vue.$env().financeEndpoint}accounts`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public getAccounts(type: AccountType, team: number, period?: string, group?: number, personId?: number) {
        if (!period) {
            return this.get(`${this.endpoint}/:type?teamId=:team&groupId=:?group&personId=:?person`, [
                ['type', type],
                ['team', team],
                ['group', group],
                ['person', personId],
            ]);
        }

        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.get(
            `${this.endpoint}/:type?teamId=:team&groupId=:?group&personId=:?person`,
            [['type', type], ['team', team], ['group', group], ['person', personId]],
            config,
        );
    }

    public async getTeamAccounts(type: AccountType, team: number, period: string): Promise<FinanceAccount[]> {
        const [err, response] = await to(this.get(`${this.endpoint}/:type?teamId=:team`, [['type', type], ['team', team], ['period', period]]));
        if (err) {
            this.showFailedResponse('Failed to load accounts');
            return [];
        }

        return response.data;
    }

    public async getBalance(accountId: number, period: string, team: number, type: AccountType, fromPeriod?: string): Promise<Range<AccountFunds>> {
        const config = {
            headers: {
                'x-startPeriod': fromPeriod ? fromPeriod : period,
                'x-endPeriod': period,
            },
        };

        const [err, response] = await to(
            this.get(
                `${this.endpoint}/:type/:accountId/balance?teamId=:team`,
                [['accountId', accountId], ['period', period], ['team', team], ['type', type]],
                config,
            ),
        );

        if (err) {
            this.clearAndShowWarning(`Failed to load balance for account: ${accountId}`);
            return new Range<AccountFunds>();
        }

        return new Range<AccountFunds>(response.data);
    }

    public async getAccount(team: number, personId: number, period: string) {
        const accounts = (await this.getAccounts(AccountType.Person, team, period, null, personId)).data;

        return accounts.find((item) => {
            return item.relatedEntityId === personId;
        });
    }

    public async getGroupAccount(team: number, groupId: number, type: AccountType, period: string): Promise<FinanceAccount> {
        const accounts = (await this.getAccounts(type, team, period)).data;
        return accounts.find((item) => {
            return item.relatedEntityId === groupId;
        });
    }
}
