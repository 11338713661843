import { Membership, FinanceAccount } from './Interfaces';
import { AccountType } from './AccountType';

export class Person {
    public personId: number = 0;
    public externalIdentifier: string = null;
    public firstName: string = '';
    public lastName: string = '';
    public insertion: string = '';
    public name: string = '';
    public groupMemberships: Membership[] = [];
    public teamMemberships: Membership[] = [];
    public ecosystemMemberships: Membership[] = [];
    public currentTeam: string = '';
    public energyDedication: number = 0;
    public deleted: boolean = false;
    public primaryEcosystem: number = null;
    public primaryTeam: number = null;

    public account: FinanceAccount = {
        accountId: 0,
        accountType: AccountType.Person,
        name: '',
        relatedEntityId: 0,
    };
    public emailAddress: string = '';
    public phoneNumber: string = '';

    constructor(person?: any) {
        (Object as any).assign(this, person);

        if (person && person.memberId) {
            this.personId = person.memberId;
        }
    }

    public showMember() {
        return true;
    }

    public get fullName() {
        return this.getFullName();
    }

    public getFullName(): string {
        let parts = [this.firstName, this.insertion, this.lastName];

        parts = parts.filter((value) => {
            return !!value;
        });

        const name = parts.join(' ');

        return parts.length === 0
            ? this.name
            : name;
    }

    public inGroup(groupId) {
        if (this.groupMemberships.length === 0) {
            return false;
        }

        for (let i = 0; i < this.groupMemberships.length; i++) {
            if (this.groupMemberships[i].id === groupId) {
                return true;
            }
        }

        return false;
    }
}
