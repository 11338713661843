import { relationService } from '@/main';
import Relation, { IntellectualPropertyLimitation, RelationSettings, TaxRate } from '@/models/Finance/Relation';
import { ledgerModule } from '@/store/modules/ledger';
import to from 'await-to-js';
import { Component, Prop } from 'vue-property-decorator';
import BaseModal from './BaseModal';

@Component
export default class RelationSettingsModal extends BaseModal {
    @Prop() public entityName: string;
    @Prop() public from: string;
    @Prop() public to: string;
    @Prop({ default: true }) public relationEditMode: boolean;
    @Prop({ default: () => [] }) public intellectualPropertyLimitations: IntellectualPropertyLimitation[];
    @Prop({ default: () => [] }) public taxRates: TaxRate[];

    public settings: RelationSettings = new RelationSettings();

    public salesLedger: any = null;
    public purchaseLedger: any = null;
    public relation: Relation = null;
    public taxRate: any = null;

    public async created() {
        await ledgerModule.fetchLedgersIfNeeded();
    }

    public setData(relation: Relation, settings?: RelationSettings) {
        if (settings) {
            this.settings = new RelationSettings(settings);
        } else {
            this.settings = new RelationSettings();
        }

        this.relation = relation;
    }

    public getOptionListLedgers() {
        return ledgerModule.ledgers.map((ledger) => {
            return { value: ledger.ledgerId, text: ledger.name };
        });
    }

    public getOptionListIPL() {
        return this.intellectualPropertyLimitations.map((ledger) => {
            return { value: ledger.intellectualPropertyLimitationId, text: ledger.name };
        });
    }

    public getOptionListTaxRates() {
        return this.taxRates.map((taxRate) => {
            return { value: taxRate.taxRateId, text: taxRate.description };
        });
    }

    public async updateSettings() {
        this.showPending('SAVING_RELATION_SETTINGS_PENDING');
        const [err] = await to(relationService.createOrUpdateSettings(this.relation.relationId, this.settings));
        if (err) {
            this.clearAndShowError('SAVING_RELATION_SETTINGS_FAILED', err);
        }

        this.hide();
        this.clearAndShowSuccess('SAVING_RELATION_SETTINGS_SUCCESS');
    }
}
