import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import Notification from './models/Notification';
import { INotificationsService } from './models/Interface/INotifcationService';

@Component
export default class TransferSentNotificationsComponent extends Vue {
    @Prop({ default: () => null }) public notification: Notification;
    @Prop() public notificationService: INotificationsService;

    public handled() {
        return this.notification.handled;
    }

    public isSent() {
        return this.notification.notificationTemplate === 'transfer-request-sent';
    }

    public async approve() {
        this.$root.$emit('notification-approve', this.notification);
        this.notification.handled = true;
    }

    public async deny() {
        this.$root.$emit('notification-deny', this.notification);
        this.notification.handled = true;
    }

    public deleteNotification() {
        this.$emit('delete-notification', this.notification);
    }

    public async openLink() {
        this.notificationService.markAsHandled(this.notification);
        this.notification.handled = true;

        window.open(this.notification.deepLink, '_blank');
    }
}
