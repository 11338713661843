import Component from 'vue-class-component';
import { loginHelper, teamHelper, eventBus, ecosystemHelper, $router } from '@/main';
import { Route } from 'vue-router';
import PageRender from '@/models/PageRender';
import { Person } from '@/models/Person';
import { translateModule } from '@/store/modules/translate';
import { Membership } from '@/models/Interfaces';
import EcosystemSwitcherModalComponent from '../modals/ecosystem-switcher-modal';
import { periodModule } from '@/store/modules/period';

@Component
export default class TopBarTeamSwitch extends PageRender {
    public key: number = 0;
    public profile: Person = null;

    public $refs!: {
        ecosystemSwitcherModal: EcosystemSwitcherModalComponent;
    };

    public mounted() {
        this.profile = loginHelper.getUser();
        eventBus.$on('onPeriodChanged', this.updateTeamSwith);
    }

    public updateTeamSwith() {
        const profile = loginHelper.getUser();
        this.profile.teamMemberships = profile.teamMemberships;
        this.profile.ecosystemMemberships = profile.ecosystemMemberships;
        this.key++;
    }

    public get currentUser(): string {
        this.profile = loginHelper.getUser();
        if (this.profile) {
            let parts = [this.profile.firstName, this.profile.insertion, this.profile.lastName];
            parts = parts.filter((value) => !!value);
            return parts.join(' ');
        }

        return '';
    }

    public get currentTeam() {
        this.profile = loginHelper.getUser();
        if (this.profile && this.profile.teamMemberships) {
            return this.profile.teamMemberships.find((membership: Membership) => {
                return membership.id === teamHelper.currentTeamId;
            });
        }

        return {} as Membership;
    }

    public async switchTo(team: Membership): Promise<Route> {
        teamHelper.setTeam(team);
        await periodModule.init();
        const route = this.$route;
        const params = Object.assign({}, route.params);
        params.teamId = teamHelper.currentTeam.id.toString();
        params.teamKey = teamHelper.currentTeam.key;
        if (typeof route.params.groupKey !== 'undefined') {
            const sub =
                this.$route.fullPath.indexOf('finance') > -1
                    ? 'finances'
                    : this.$route.fullPath.indexOf('insights') > -1
                    ? 'insights'
                    : 'directory';
            const tab =
                this.$route.fullPath.indexOf('finance') > -1
                    ? 'group-overview'
                    : this.$route.fullPath.indexOf('insights') > -1
                    ? 'group-results'
                    : 'groups';
            params.tab = tab;
            return this.$router.push({
                name: `team-${sub}`,
                params,
            });
        }
        return this.$router.push({
            name: route.name,
            params,
        });
    }

    public openEcosystemSwitcherModal() {
        return this.$refs.ecosystemSwitcherModal.show();
    }
    public toggleEditMode() {
        translateModule.toggleEditMode();
    }

    public get editModeActive() {
        return translateModule.inEditMode;
    }

    public get languages() {
        return translateModule.languages;
    }

    public get profileParams() {
        return {
            ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
            ecosystemKey: ecosystemHelper.currentEcosystem.key,
            id: loginHelper.getUser().personId.toString(),
            team: teamHelper.teamParam,
        };
    }

    public get activeLanguage() {
        return translateModule.activeLanguage;
    }

    public async setLanguage(lang: string) {
        await translateModule.setLanguage(lang);
    }

    public async logOut() {
        await loginHelper.logOut();
        await $router.push({
            name: 'login',
            params: {
                lang: translateModule.activeLanguage,
            },
        });
    }
}
