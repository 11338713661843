import { AxiosHelper } from '../helpers/axiosHelper';
import { BaseService } from './baseService';
import Vue from 'vue';
import to from 'await-to-js';
import Notification from '@/components/notification/models/Notification';

export class NotificationsService extends BaseService {
    private endpoint = `${Vue.$env().notificationsEndpoint}`;
    private application = 'bright';

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getNotifications() {
        const [err, response] = await to(this.get<any[]>(`${this.endpoint}notifications?application=${this.application}`));

        if (err) {
            return [];
        }

        return response.data.map((x) => new Notification(x));
    }

    public async markAsHandled(notification: Notification) {
        return this.post(`${this.endpoint}notifications/${notification.id}/handled?application=${this.application}`);
    }

    public async deleteNotification(notification: Notification) {
        return this.delete(`${this.endpoint}notifications/${notification.id}?application=${this.application}`);
    }
}
