import Group from '../models/Group';
import { AxiosHelper } from '../helpers/axiosHelper';
import { BaseService } from './baseService';
import Transfer from '../models/Transfer';
import { AxiosPromise } from 'axios';
import Vue from 'vue';
import { loginService } from '@/main';
import { Member } from '@/models/Member';
import { Person } from '@/models/Person';
import EnergyDedication from '@/models/EnergyDedication';
import to from 'await-to-js';
import { periodModule } from '@/store/modules/period';

export class MeService extends BaseService {
    private endpoint = `${Vue.$env().directoryEndpoint}`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper, true);
    }

    public async profile(ecosystemId?: number): Promise<Person> {
        const config = { headers: {} };
        config.headers['x-period'] = periodModule.selectedPeriod;

        if (ecosystemId) {
            config.headers['x-ecosystem'] = ecosystemId;
        }

        if (ecosystemId) {
            const [profileErr, profileResponse] = await to(this.get(`${this.endpoint}me/profile`, null, config));
            if (profileErr) {
                this.clearAndShowError('Failed to load profile', profileErr);
                await loginService.logout();
                await this.$router.push({ name: 'login' });
                return new Person();
            }

            return new Person(profileResponse.data);
        }

        const [err, response] = await to(this.get(`${this.endpoint}me/profile`, null, config));
        if (err) {
            this.clearAndShowError('Failed to load profile', err);
            return new Person();
        }

        return new Person(response.data);
    }

    public updatePreferences(primaryEcosystem: number, primaryTeam: number): AxiosPromise {
        const period = periodModule.selectedPeriod;
        return this.put(`${this.endpoint}me/preferences?period=:period`, { primaryEcosystem, primaryTeam }, [
            ['period', period],
        ]);
    }

    public disableAccount(emailAddress: string) {
        return this.put(`${this.endpoint}me/disable?emailAddress=:emailAddress`, null, [['emailAddress', emailAddress]]);
    }

    public getGroups(team: number, period: string) {
        const config = {
            headers: {
                'x-period': period,
            },
        };
        return this.get<Group[]>(`${this.endpoint}me/groups?teamId=:team`, [['team', team]], config);
    }

    public getMembers(teamId: number, period: string) {
        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.get<Member[]>(`${this.endpoint}me/team-members?teamId=:teamId`, [['teamId', teamId]], config);
    }

    public getEnergyDedications(teamId: number, period: string) {
        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.get<EnergyDedication[]>(`${this.endpoint}me/energy-dedication?teamId=:teamId`, [['teamId', teamId]], config);
    }

    public getTransfers(teamId: number, memberId: number, period: string) {
        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.get<Transfer[]>(
            `${this.endpoint}transfers?teamId=:teamId&personId=:?personId`,
            [['teamId', teamId], ['personId', memberId], ['period', period]],
            config,
        );
    }
}
