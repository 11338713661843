import { Person } from './Person';
import FinanceMe from './FinanceMe';
import FinanceGroup from './FinanceGroup';
import FinanceBase from './FinanceBase';
import { AccountType } from './AccountType';
import { teamHelper } from '@/main';
import { Member } from './Member';

export default class FinanceTeam extends FinanceBase {
    public members: any = [];
    public groups: any = [];
    public memberOverview: any = [];
    public groupOverview: any = [];
    public energyDedications: any;

    constructor(invoices, expenses, members, groups, energyDedications) {
        super(invoices, expenses);

        this.invoices = this.filterEmptyInvoices(invoices);
        this.expenses = this.filterEmptyInvoices(expenses);
        this.memberOverview = [];
        this.energyDedications = energyDedications;

        this._prepareMembers(members);
        this._prepareGroups(groups);
    }

    public _prepareMembers(members) {
        this.members = [];
        members.forEach((memberData) => {
            const member = new Member(memberData);
            const memberInvoices = this.filterEntityDistributions(this.invoices, member.memberId, AccountType.Person);
            const memberExpenses = this.filterEntityDistributions(this.expenses, member.memberId, AccountType.Person);

            const memberFinance = new FinanceMe(member.memberId, memberInvoices, [], memberExpenses, [], memberData.balance, null, null);
            memberFinance.memberName = member.getFullName();

            this.members.push(member);
            this.memberOverview.push(memberFinance);
        });
    }

    public _prepareGroups(groups) {
        this.groups = groups;
        groups.forEach((group: any) => {
            const groupInvoices = this.invoices.filter((invoice) => {
                return invoice.invoice.group === group.groupId;
            });

            const groupExpenses = this.expenses.filter((invoice) => {
                return invoice.invoice.group === group.groupId;
            });

            const finance = new FinanceGroup(teamHelper.getTeamId(), group, groupInvoices, groupExpenses, [], [], [], group.balance);
            finance.group = group;
            this.groupOverview.push(finance);
        });
    }

    public _getGroupMembersOfGroup(groupId) {
        this.members.filter((member: Person) => {
            const exists = member.groupMemberships.find((group) => {
                return group.id === groupId;
            });

            if (exists) {
                return member;
            }
        });
    }
}
