import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class InsightsEcosystemFinanceTotalsComponent extends Vue {
    @Prop({ default: () => null }) public data: any;

    public getCO2Balance() {
        return Vue.filter('number-format')(this.data && this.data.co2balance ? this.data.co2balance : 113292);
    }

    public getCO2Mutation() {
        return Vue.filter('number-format')(this.data && this.data.co2mutation ? this.data.co2mutation : 10482);
    }

    public getCO2Compensation() {
        return Vue.filter('number-format')(this.data && this.data.co2compensation ? this.data.co2compensation : 5000);
    }

    public getCO2Expenses() {
        return Vue.filter('number-format')(this.data && this.data.co2OnExpenses ? this.data.co2OnExpenses : 5482);
    }

    public getCO2Transfered() {
        return Vue.filter('number-format')(this.data && this.data.co2Transfered ? this.data.co2Transfered : 4248);
    }
}
