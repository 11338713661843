import GridHelper from '@/helpers/GridHelper';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Totals from '../models/Totals';

@Component
export default class FinanceTotals extends Vue {
    @Prop() public totals: Totals[];
    @Prop({ type: Boolean }) public expanded: boolean;
    public showDetails = false;
    private expandedColumns = [
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.startBalance), title: 'START_BALANCE' },
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.balance), title: 'BALANCE', field: 'balance' },
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.result), title: 'RESULT', field: 'result' },
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.expenses), title: 'EXPENSES_RECURRING' },
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.nonRecurringExpenses), title: 'EXTRA_NON_RECURRING' },
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.transfers), title: 'TRANSFERS' },
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.openInvoiceAmount), title: 'OPEN_INVOICES' },
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.openExpenseAmount), title: 'OPEN_EXPENSES' },
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.groupOpenInvoiceAmount), title: 'OPEN_INVOICES_GROUP' },
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.groupOpenExpenseAmount), title: 'OPEN_EXPENSES_GROUP' },
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.externalRevenue), title: 'EXTERNAL_REVENUE' },
        { cell: this.renderDropDeadRatio, title: 'DROP_DEAD_RATIO' },
    ];
    private defaultColumsn = [
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.startBalance), title: 'START_BALANCE' },
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.balance), title: 'BALANCE', field: 'balance' },
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.result), title: 'RESULT', field: 'result' },
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.expenses), title: 'EXPENSES_RECURRING' },
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.extras), title: 'EXTRA_NON_RECURRING' },
        { cell: (h, _, row) => GridHelper.renderNumber(h, row.dataItem.externalRevenue), title: 'EXTERNAL_REVENUE' },
        { cell: this.renderDropDeadRatio, title: 'DROP_DEAD_RATIO' },
    ];

    public get columns() {
        if (this.expanded) {
            return this.expandedColumns;
        }
        return this.defaultColumsn;
    }

    public get aggregates() {
        return this.totals.reduce((totals: Totals, value: Totals) => {
            return {
                startBalance: totals.startBalance,
                balance: value.balance,
                result: totals.result + value.result,
                expenses: totals.expenses + value.expenses,
                extras: totals.extras + value.extras,
                openInvoiceAmount: totals.openInvoiceAmount + value.openInvoiceAmount,
                openExpenseAmount: totals.openExpenseAmount + value.openExpenseAmount,
                groupOpenInvoiceAmount: totals.groupOpenInvoiceAmount + value.groupOpenInvoiceAmount,
                groupOpenExpenseAmount: totals.groupOpenExpenseAmount + value.groupOpenExpenseAmount,
                externalRevenue: totals.externalRevenue + value.externalRevenue,
            };
        });
    }

    private renderDropDeadRatio(h, _, row) {
        if (row.dataItem.safetyZone) {
            const props = { value: row.dataItem.safetyZone };
            return h(Vue.component('number-formatter'), { props });
        } else {
            return h('td', ['N/A']);
        }
    }
}
