import { Component } from 'vue-property-decorator';
import PageRender from '../../../models/PageRender';
import { $router, teamHelper,  roleHelper } from '@/main';
import jQuery from 'jquery';
import TeamSettings from '@/models/TeamSettings';
import { settingsModule } from '@/store/modules/settings';

@Component
export default class TeamFinanceComponent extends PageRender {
    public tab: string = null;
    public tabs: string[] = [
        'group-overview', 'member-overview', 'invoices', 'expenses', 'period', 'transfers', 'settings',
    ];
    public currentTabComponent: string = '';
    public teamFinanceSettings: TeamSettings = new TeamSettings();

    public async created() {
        await this.loadTeamFinanceSettings();

        this.isLoading = false;
    }

    public async mounted() {
        await this.checkPeriodParam();
        await this.changeTab(this.$route.params.tab);
    }

    public async changeTab(tab: string): Promise<void> {
        if (this.tabs.indexOf(tab) === -1) {
            tab = this.tabs[0];
        }

        if (tab !== this.$route.params.tab) {
            const params = this.$route.params;
            params.tab = tab;
            await $router.push({ name: 'team-finances', params });
        } else {
            this.doActiveClass(tab);
            this.currentTabComponent = 'team-finances-' + tab;
        }
    }

    public async loadTeamFinanceSettings() {
        const currentTeamId = teamHelper.currentTeam.id;
        await settingsModule.fetchTeamSettingsIfNeeded(currentTeamId);
        this.teamFinanceSettings = settingsModule.findTeamFinanceSettings(currentTeamId);
    }

    public doActiveClass(tab: string) {
        this.$nextTick(() => {
            jQuery('.' + tab).addClass('active');
        });
    }

    get hideExpenses() {
        return !roleHelper.checkAccess(this.teamFinanceSettings.allExpensesPrivacyLevel);
    }

    get hideMemberResults() {
        return !roleHelper.checkAccess(this.teamFinanceSettings.memberResultsPrivacyLevel);
    }
}
