import { FinanceAccount } from './Interfaces';
import { AccountType } from './AccountType';
import { TransferType } from './TransferType';
import { InvoiceType } from './InvoiceType';
import Team from './Team';

export default class Transfer {
    public transferId: number;
    public amount: number = 0;
    public internalAmount: number = 0;
    public reason: string = '';
    public transferType: TransferType;
    public group: number = null;
    public invoice: number = null;
    public invoiceType: InvoiceType;
    public senderTeam: Team = { teamId: null, teamKey: '', name: '' } as Team;
    public receiverTeam: Team = { teamId: null, teamKey: '', name: '' } as Team;
    public period: any;
    public includeMissingAttributions: boolean = false;
    public initiator: number = 0;
    public receiver: FinanceAccount = null;
    public receiverId: number = null;
    public sender: FinanceAccount = {
        accountId: 0,
        accountType: AccountType.Uknonwn,
        name: '',
        relatedEntityId: 0,
    };
    public senderId: number = 0;
    public includeServiceAttributions: any;
    public isExpense: boolean = false;
    public recurring: boolean = null;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
