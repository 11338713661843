import { Component } from 'vue-property-decorator';
import to from 'await-to-js';
import { mapService, $router, groupService, teamService, ecosystemHelper } from '@/main';
import GraphData from '@/models/Graph/GraphData';
import IMapSetting from '@/models/Map/IMapSettings';
import Group from '@/models/Group';
import Team from '@/models/Team';
import { Person } from '@/models/Person';
import BaseEcosystemDirectory from './BaseEcosystemDirectory';
import { periodModule } from '@/store/modules/period';

@Component
export default class EcosystemMapComponent extends BaseEcosystemDirectory {
    public graph = new GraphData();
    public teamId: number = null;
    public team: Team = new Team();
    public groupKey: string = '';
    public groupId: number = null;
    public group: Group = new Group();

    private mapSettings: IMapSetting[] = [];

    public created() {
        this.teamId = parseInt(this.$route.params.teamId);
        this.groupKey = this.$route.params.group;
        this.groupId = parseInt(this.$route.params.groupId);
    }

    public async mounted() {
        if (typeof this.groupKey !== 'undefined') {
            const [err, response] = await to(groupService.getGroup(this.groupId, periodModule.selectedPeriod));
            if (err) {
                this.showFailedResponse('Failed to load group', err);
            }

            this.group = response.data;
        }

        this.teams = await teamService.getTeams();
        if (typeof this.teamId !== 'undefined') {
            this.team = this.teams.find((x) => x.teamId === this.teamId);
            this.mapSettings = await mapService.getSettings(this.teamId);
        } else {
            this.mapSettings = await mapService.getSettings();
        }

        if (this.group && this.group.groupId > 0) {
            await this.handleGroupScope();
        } else if (this.team && this.team.teamId > 0) {
            await this.handleTeamScope();
        } else {
            await this.handleEcosystemScope();
        }

        this.isLoading = false;
    }

    public async handleGroupScope() {
        for (const [index, member] of this.group.members.entries()) {
            let settings = null; // this.mapSettings.find((x) => x.member === member.groupId);

            if (!settings) {
                const rows = Math.ceil(Math.sqrt(this.group.members.length));
                const x = 0 + (index % rows) * 100;
                const y = 0 + Math.floor(index / rows) * 100;

                settings = { x, y, radius: 40, team: null, group: this.group.groupId, member: member.memberId };
            }

            this.graph.vertices.push({
                id: member.memberId,
                name: new Person(member).getFullName(),
                x: settings.x,
                y: settings.y,
                radius: settings.radius,
                // onclick: () => this.goToGroup(this.team.key, group.groupKey),
            });
        }
    }

    public async handleTeamScope() {
        for (const [index, group] of this.team.groups.entries()) {
            let settings = this.mapSettings.find((x) => x.group === group.groupId);
            if (!settings) {
                settings = { x: 20 * index, y: 20, radius: 20, team: null, group: group.groupId };
            }

            this.graph.vertices.push({
                id: group.groupId,
                name: group.name,
                x: settings.x,
                y: settings.y,
                radius: settings.radius,
                onclick: () => this.goToGroup(this.team, group),
            });
        }
    }

    public async handleEcosystemScope() {
        for (const team of this.teams) {
            const settings = this.mapSettings.find((x) => x.team === team.teamId);
            this.graph.vertices.push({
                id: team.teamId,
                key: team.teamKey,
                name: team.name,
                x: settings.x,
                y: settings.y,
                radius: settings.radius,
                onclick: () => this.goToTeam(team),
            });
        }
    }

    public async goUp() {
        if (this.group && this.group.groupId > 0) {
            return this.goToTeam(this.team);
        }

        await $router.push({
            name: 'ecosystem-directory',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                period: periodModule.selectedPeriod,
                tab: 'map',
            },
        });
    }

    public async goToTeam(team: Team) {
        await $router.push({
            name: 'ecosystem-directory',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                period: periodModule.selectedPeriod,
                tab: 'map',
                teamId: team.teamId.toString(),
                teamKey: team.teamKey,
            },
        });
    }

    public async goToGroup(team: Team, group: Group) {
        await $router.push({
            name: 'ecosystem-directory',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                period: periodModule.selectedPeriod,
                tab: 'map',
                teamId: team.teamId.toString(),
                teamKey: team.teamKey,
                groupId: group.groupId.toString(),
                groupKey: group.groupKey,
            },
        });
    }

    public getEcosystemName() {
        return ecosystemHelper.currentEcosystem.name;
    }
}
