import { teamService } from '@/main';
import { Person } from '../models/Person';

export class MemberHelper {
    public members: Person[] = [];
    public interval: any;

    public async getMembers(force: boolean = false) {
        const self = this;
        self.members = [];

        if (self.members && self.members.length > 0 && !force) {
            return self.members;
        }

        self.members = await teamService.getMembers();

        return self.members;
    }
}
