<template>
    <transition name="fade-in-from-right">
        <div v-if="visible"
             class="prd-notification-toast"
             @mouseover="cancelHide"
             @mouseleave="hide">
            <item v-if="notification"
                  :notification="notification" />
            <span v-else>Notification property is empty...</span>
        </div>
    </transition>
</template>
<script>
/* tslint:disable */
import Item from './item.vue';

export default {
    components: {
        Item,
    },
    data() {
        return {
            visible: false,
        };
    },
    props: {
        notification: { default: () => ({}) },
    },
    methods: {
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
    },
};
</script>