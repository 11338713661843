import { G, Svg } from '@svgdotjs/svg.js';
import MapNode from '../models/MapNode';

export default class SvgRender {
    public id = '';
    public type = '';
    public canvas: Svg = null;
    public container: G = null;
    public allNodes: MapNode[] = [];
    public empty: boolean = true;
    protected minScale = 0;
    private callbacks: { [key: string]: CallableFunction } = {};
    private zoomBy: number = 1;

    public render() {
        return;
    }

    public redraw() {
        this.render();
    }

    public clear() {
        this.canvas.clear();
        this.empty = true;
    }

    public fit() {
        const smallest = Math.min(+this.canvas.width(), +this.canvas.height());
        const ratio = smallest / (+this.container.bbox().width);
        const scale = (ratio >= this.minScale ? ratio : this.minScale) * this.zoomBy;
        this.container.transform({ scale });
    }

    public setSize(width: number, height: number) {
        this.canvas.size(width, height);
    }

    public on(name: string, callback) {
        this.callbacks[name] = callback;
    }

    public zoom(zoom: number) {
        this.zoomBy = zoom;
    }

    public setId(id: string) {
        this.id = id;
    }

    protected fire(name: string, params: any) {
        if (this.callbacks[name]) {
            this.callbacks[name](params);
        }
    }
}
