import { Component, Vue, Watch } from 'vue-property-decorator';
import { InvoiceType } from '../../../../models/InvoiceType';
import { periodFilterHelper, roleHelper } from '@/main';
import BaseFinanceTeamInvoicePage from './BaseFinanceTeamInvoicePage';
import DistributedInvoice from '@/models/DistributedInvoice';
import { teamsModule } from '@/store/modules/teams';

@Component
export default class TeamFinanceInvoices extends BaseFinanceTeamInvoicePage {
    public type: InvoiceType = InvoiceType.Income;

    @Watch('onlyNotYetDistributed')
    public onFailureFilterChange() {
        this.filterInvoices();
    }

    @Watch('textSearch')
    public onTextFilterChange(val, oldVal) {
        if ((val && val.length > 2) || (oldVal && oldVal.length > val.length)) {
            this.filterInvoices();
        }
    }

    public async created() {
        this.importedInvoiceColumns.push({ field: 'group', title: 'GROUP' });

        await this.init(this.type);

        this.isLoaded = true;
    }

    public rowClick(e) {
        if (periodFilterHelper.isCurrentPeriodClosed()) {
            return;
        }

        const invoice = this.invoices.find((i) => i.invoice.invoiceId === this.editID);
        this.invoices.forEach((expense) => (expense[this.selectedField] = false));

        if (invoice) {
            Vue.set(invoice, 'inEdit', false);
        }

        this.editID = e.dataItem.invoice.invoiceId;
        Vue.set(e.dataItem, 'inEdit', true);
    }

    public onHeaderSelectionChange(event) {
        const checked = event.event.target.checked;
        Vue.set(this, 'invoices', this.invoices.map((item) => ({ ...item, selected: checked })));
    }

    public onSelectionChange(event) {
        Vue.set(event.dataItem, 'inEdit', false);
        Vue.set(event.dataItem, this.selectedField, !event.dataItem[this.selectedField]);
    }

    public pageChangeHandler(event) {
        this.skip = event.page.skip;
        this.take = event.page.take;
    }
    public renderNonEqualLEgalEntities(_h, tr, _row, item) {
        const invoice = new DistributedInvoice(item.dataItem);

        if (invoice.invoice && teamsModule.current && (roleHelper.isFinanceEmployee() || roleHelper.isSiteAdmin())) {
            // tslint:disable-next-line: no-string-literal
            tr.data['class'] += ` ${
                invoice.invoice && invoice.invoice.legalEntityReferenceOrigin !== teamsModule.current.legalEntityReference
                    ? 'legal-entity-difference'
                    : ''
            }`;
        }
        return tr;
    }
}
