import { Member } from '../Member';

export default class Ambition {
    public member: Member;
    public internalRevenueAmbition: any = 0;
    public actualInternalRevenue: number = 0;
    public actualExternalRevenue: number = 0;
    public cost: number = 0;
    public actualCompensations: number = 0;
    public guidancePercentage: number = 0;

    constructor(obj?: Partial<Ambition>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    get externalRevenueGoal(): number {
        return this.cost;
    }

    get actualMargin(): number {
        return this.actualInternalRevenue - this.actualCompensations;
    }

    get actualFee(): number {
        return (this.actualCompensations * this.guidancePercentage) / 100;
    }

    get actualResult(): number {
        return this.actualFee + this.actualMargin;
    }

    get expectedMargin(): number {
        return this.internalRevenueAmbition - this.cost;
    }

    get expectedFee(): number {
        return (this.cost * this.guidancePercentage) / 100;
    }

    get expectedResult(): number {
        return this.expectedFee + this.expectedMargin;
    }
}
