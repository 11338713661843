import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { teamHelper, financeService, eventBus } from '@/main';
import to from 'await-to-js';
import { mixin as clickaway } from 'vue-clickaway';
import Group from '@/models/Group';
import DistributedInvoice from '@/models/DistributedInvoice';
import { InvoiceType } from '@/models/InvoiceType';
import { AccountType } from '@/models/AccountType';

@Component({
    mixins: [clickaway],
})
export default class DistributionTreeAmount extends Vue {
    @Prop({ default: () => new DistributedInvoice() }) public invoice: DistributedInvoice;
    @Prop({ default: () => new Group() }) public group: Group;

    public show: boolean = false;
    public hovering: boolean = false;
    public loadingTree: boolean = true;
    public update: number = 0;
    public notAvailable: boolean = false;

    public marginX: number = 20;
    public nodeTextMargin: number = 20;
    public radius: number = 8;
    public strokeWidth: number = 6;
    public leafTextMargin: number = 20;

    public timer: any = null;

    public collision: any = {
        horizontal: 'fit',
        vertical: 'flip',
    };

    public get _group(){
        return this.group;
    }

    public treeData: any = {
        name: this._group && this._group.groupId !== 0 ? this._group.name : teamHelper.currentTeamId,
        children: [],
    };

    public created() {
        eventBus.$on('close-others', this.close);
    }

    public async populateNode(node) {
        node.children = [];

        const groupId = node.groupId;
        let invoice = null;
        if (node.account.accountType === AccountType.GroupIncome) {
            const [err, response] = await to(financeService.getInvoice(this.invoice.invoice.invoiceId, groupId));
            if (err) {
                return;
            }
            invoice = response.data;
        } else if (
            node.account.accountType === AccountType.GroupSavings ||
            (node.account.accountType === AccountType.Person && this.invoice.invoice.invoiceType !== InvoiceType.Expenses)
        ) {
            const [err, response] = await to(
                financeService.getInvoiceDetails(
                    this.invoice.invoice.invoiceId,
                    this.group && this.group.groupId !== 0 ? this.group.groupId : groupId,
                    node.account.accountId,
                ),
            );
            if (err) {
                return;
            }
            invoice = response.data;
        }

        if (!invoice) {
            return;
        }

        for (const distribution of invoice.distributions) {
            if (distribution.amount !== 0 && distribution.account && distribution.account.accountId) {
                node.children.push({
                    name: `${distribution.amount} - ${distribution.account.name}`,
                    children: [],
                    groupId,
                    account: distribution.account,
                });
            }
        }

        if (node.account.accountType === AccountType.GroupSavings || node.account.accountType === AccountType.Person) {
            return;
        }

        for (const child of node.children) {
            await this.populateNode(child);
        }
    }

    public close() {
        this.show = false;
    }

    public resetTimer() {
        clearTimeout(this.timer);
    }

    public delayHover() {
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            // tslint:disable-next-line: no-floating-promises
            this.showPopup();
        }, 333);
    }

    public async showPopup() {
        this.loadingTree = true;
        eventBus.$emit('close-others');
        this.show = true;
        this.treeData.children = [];
        this.notAvailable = false;

        let invoice = null;

        if (this.group && this.group.groupId > 0) {
            invoice = this.invoice;
        } else {
            const [err, response] = await to(financeService.getInvoice(this.invoice.invoice.invoiceId, this.invoice.invoice.group));
            if (err) {
                return;
            }

            invoice = response.data;
        }

        for (const distribution of invoice.distributions) {
            if (distribution.amount !== 0 && distribution.account && distribution.account.accountId) {
                this.treeData.children.push({
                    name: `${distribution.amount} - ${distribution.account.name}`,
                    children: [],
                    groupId: distribution.account.relatedEntityId,
                    account: distribution.account,
                });
            }
        }

        const promises = [];
        for (const node of this.treeData.children) {
            promises.push(this.populateNode(node));
        }

        await Promise.all(promises);

        this.loadingTree = false;
        this.update++;
    }

    public anchorAlign() {
        return {
            horizontal: 'left',
            vertical: 'top',
        };
    }

    public popupAlign() {
        return {
            horizontal: 'left',
            vertical: 'top',
        };
    }

    public getHeight() {
        let totalGrandChildrend = 0;
        for (const node of this.treeData.children) {
            totalGrandChildrend += node.children.length;
        }

        const test = {
            // tslint:disable-next-line: object-literal-key-quotes
            height: `${100 + 40 * this.treeData.children.length + totalGrandChildrend * 40}px`,
            'min-width': `${this.getDepth(this.treeData) * 150 + 100}px`,
            // width: this.getDepth(this.treeData) * 200,
        };

        return test;
    }

    public getDepth(obj) {
        let depth = 0;
        if (obj.children) {
            obj.children.forEach((d) => {
                const tmpDepth = this.getDepth(d);
                if (tmpDepth > depth) {
                    depth = tmpDepth;
                }
            });
        }
        return 1 + depth;
    }

    public getInvoiceAmount() {
        return Vue.filter('number-format')(this.invoice.invoice.amount);
    }
}
