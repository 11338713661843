export default class Period {
    public periodId?: number;
    public startDate: string = '';
    public endDate: string = '';
    public closed: boolean;
    public current: boolean;
    public status: string = '';

    constructor(obj?: Partial<Period>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
