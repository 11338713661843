import { VuexModule, Module, Mutation, Action, RegisterOptions } from 'vuex-class-modules';
import store from '../index';
import { Profile, User } from 'oidc-client';
import { loginService, meService } from '@/main';
import { UserRoles } from '@/models/UserRoles';
import { Person } from '@/models/Person';
import { Mutex } from 'async-mutex';
@Module
class ProfileModule extends VuexModule {
    private _user: User = null;
    private _brightProfile: Person = null;
    private _mutex = new Mutex();

    constructor(options: RegisterOptions) {
        super(options);
    }

    public get brightProfile(): Person {
        return this._brightProfile;
    }

    public get access_token(): string {
        return this._user.access_token;
    }

    public get profile(): Profile {
        if(!this._user){
            return null;
        }
        return this._user.profile;
    }

    public get user(): User {
        return this._user;
    }

    public get isLoggedIn() {
        return this._user != null && !this._user.expired;
    }

    public get profileLoaded() {
        return this._user != null && !this._user.expired && this._brightProfile != null;
    }

    public get isSiteAdmin() {
        return this.isInRole(UserRoles.SiteAdmin);
    }

    public isInRole(role: string): boolean {
        const roles = this.getRoles();
        return typeof roles !== 'undefined' && roles.indexOf(role) > -1;
    }

    public getRoles(): string[] {
        if (!this.isLoggedIn) {
            return [];
        }

        if (this._user && this._user.profile && typeof this._user.profile.role === 'undefined') {
            return ['BE_Member'];
        }

        return this._user.profile.role;
    }

    @Action
    public async reloadProfile() {
        await this.loadBrightProfileIfNeeded();
    }

    @Action
    public async init() {
        const isLoggedIn = await loginService.isLoggedIn();
        if (isLoggedIn) {
            await this.fetchUserIfNeeded();
            await this.loadBrightProfileIfNeeded();
        }

        const hasExpiredToken = await loginService.accessTokenExpired();

        if (hasExpiredToken) {
            await loginService.refreshToken();

            const success = await loginService.isLoggedIn();
            if (success) {
                await this.fetchUserIfNeeded();
                await this.loadBrightProfileIfNeeded();
            }
        }
    }

    @Action
    public async fetchUserIfNeeded() {
        if (this._user === null || !this._user.expired) {
            return await this.fetchUser();
        }
    }

    @Action
    public async fetchUser() {
        const user = await loginService.getUser();

        this.setUser(user);
    }

    @Action
    public async loadBrightProfileIfNeeded(force: boolean = false) {
        // tslint:disable-next-line: no-floating-promises
        this._mutex.acquire().then(async () => {
            if (force || this._brightProfile === null) {
                const profile = await meService.profile();
                this.setBrightProfile(profile);

                this._mutex.release();
            }
        });
    }

    @Action
    public async login() {
        await loginService.login();
    }

    @Action
    public async logout() {
        await loginService.logout();
    }

    @Action
    public setUser(user: User) {
        this.SET_USER(user);
    }

    @Action
    public setBrightProfile(profile: Person) {
        this.SET_BRIGHT_PROFILE(profile);
    }

    @Mutation
    private SET_BRIGHT_PROFILE(profile) {
        this._brightProfile = profile;
    }

    @Mutation
    private SET_USER(user) {
        this._user = user;
    }
}

export const profileModule = new ProfileModule({ store, name: 'profile' });
