import { Circle, Container, Element, G } from '@svgdotjs/svg.js';
import MapCellConfig from '../models/MapCellConfig';
import ISvgElement from './ISvgElement';
import SvgLabel from './SvgLabel';

export default class SvgCircleCell implements ISvgElement {
    private container: Container;
    private cellGroup: G;
    private circle: Circle;

    constructor(container: Container) {
        this.container = container;
    }

    public draw(parent: Circle, angle: number, config: MapCellConfig): Element {
        const parentRadius = parent.bbox().width / 2;
        const x = +parent.x() + parentRadius + (Math.cos(angle) * parentRadius) - config.radius;
        const y = +parent.y() + parentRadius + (Math.sin(angle) * parentRadius) - config.radius;
        const group = this.container.group();
        const circle = group.circle(config.radius * 2);
        circle.fill(config.fill);
        circle.stroke(config.stroke);
        circle.id(`cell-${config.id}`);
        group.move(x, y);

        this.cellGroup = group;
        this.circle = circle;
        return circle;
    }

    public label(content: string): Element {
        if (!this.circle || !this.cellGroup) {
            throw new Error('The cell must be drawn before the label');
        }

        const l = new SvgLabel(this.cellGroup).draw(content);
        const textX = (this.circle.bbox().width - l.bbox().width) / 2;
        l.x(+this.circle.x() + textX);
        l.y(+this.circle.y() - l.bbox().height - 4);

        return l;
    }

    public getGroup() {
        return this.cellGroup;
    }
}
