import { Component } from 'vue-property-decorator';
import PageRender from '@/models/PageRender';
import Team from '@/models/Team';
import to from 'await-to-js';
import { memberHelper, teamService } from '@/main';
import { teamsModule } from '@/store/modules/teams';
import { profileModule } from '@/store/modules/profile';
import { Person } from '@/models/Person';
import { BModal } from 'bootstrap-vue';

@Component
export default class SettingsComponent extends PageRender {
    public failedSavingSettings: boolean = false;
    public failedInvites: string[] = [];
    public isLoading: boolean = true;
    public team: Team = new Team();
    public teamMembers: Person[] = [];
    public isUpdatingTeam: boolean = false;
    public selectedTeamLead: Person = null;

    public $refs!: {
        transferLeaderShipModal: BModal;
    };

    public async created() {
        const self = this;
        this.team = await this.getTeam();
        this.teamMembers = await memberHelper.getMembers();
        self.isLoading = false;
    }

    public getTeamLeadName() {
        if (!this.team.teamLead) {
            return 'N/A';
        }

        return [this.team.teamLead.firstName, this.team.teamLead.insertion, this.team.teamLead.lastName].filter((x) => x && x.length > 0).join(' ');
    }

    public async transferLeaderShip() {
        this.showPending('TRANSFER_TEAM_LEAD_PENDING');
        const [err] = await to(teamService.transferTeamLeaderShip(this.selectedTeamLead));
        if (err) {
            this.clearAndShowError('TRANSFER_TEAM_LEAD_FAILED', null);
            return;
        }

        this.showSuccess('TRANSFER_TEAM_LEAD_SUCCESS');
        this.$refs.transferLeaderShipModal.hide();
    }

    public openTransferLeaderShipModal() {
        this.$refs.transferLeaderShipModal.show();
    }

    public async updateTeam() {
        this.isUpdatingTeam = true;
        const [err] = await to(teamService.updateTeam(this.team));
        if (err) {
            this.clearAndShowError('UPDATE_TEAM_PROFILE_FAILED', null);
        } else {
            this.clearAndShowSuccess('UPDATE_TEAM_PROFILE_SUCCESS');
        }
        await teamsModule.fetchTeams();
        await profileModule.loadBrightProfileIfNeeded(true);
        this.isUpdatingTeam = false;
    }

    private async getTeam() {
        const teamId = parseInt(this.$route.params.teamId);
        const period = this.$route.params.period;
        const [err, response] = await to(teamService.getTeam(teamId, period));
        return err ? new Team() : response.data;
    }
}
