var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('prd-toolbar',[_c('generic-search',{ref:"searchBox",attrs:{"data-items":_vm.allTransactions,"properties":[
                            'matchId',
                            'amount',
                            'destinationLegalEntityReference',
                            'legalEntityReference',
                            'destinationGroup',
                            'name',
                            'destinationTeam',
                            'description',
                            'originLegalEntityReference',
                            'originGroup',
                            'originTeamId' ]},on:{"finished-search":_vm.updateGridData}}),_c('b-form-checkbox',{staticClass:"ml-2",on:{"change":_vm.filterRelations},model:{value:(_vm.onlyWithoutSettings),callback:function ($$v) {_vm.onlyWithoutSettings=$$v},expression:"onlyWithoutSettings"}},[_vm._v(" Show transactions without settings ")])],1),(_vm.isLoading)?_c('prd-loader',[_c('spinner')],1):_c('prd-grid-wrapper',{key:_vm.refreshGrid,attrs:{"data-items":_vm.transactions,"columns":_vm.columns,"row-height":35,"pageable":true,"orderBy":{ field: 'dateCreated', dir: 'desc' }}}),(!_vm.isLoading)?_c('relation-settings-modal',{ref:"relationModal",attrs:{"relation-edit-mode":false,"intellectual-property-limitations":_vm.intellectualPropertyLimitations,"tax-rates":_vm.taxRates}}):_vm._e(),_c('b-modal',{ref:"invoiceInfoModal",attrs:{"size":"sm"}},[_c('div',{attrs:{"slot":"modal-title"},slot:"modal-title"},[_vm._v(" Invoice details ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.getContent())}}),_c('b-button',{attrs:{"slot":"modal-footer","variant":"secondary","size":"sm"},on:{"click":function($event){return _vm.$refs.invoiceInfoModal.hide()}},slot:"modal-footer"},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }