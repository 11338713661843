import moment from 'moment';

export default class Notification {
    public id: string = '';
    public handled: boolean = false;
    public deepLink: string = null;

    public application: string;
    public notificationTemplate: string = '';
    public notificationType: number = 0;

    public title: string = '';
    public message: string = '';
    public timestamp: string = '';

    constructor(obj?: Partial<Notification>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    public get dateString() {
        return moment(this.timestamp, 'YYYY-MM-DD hh:mm:ss').fromNow();
    }
}
