import GraphNode from './Node';
import Vertex from './Vertex';

export default class GraphData {
    public edges: any[] = [];
    public vertices: Vertex[] = [];
    public vertexPaths: any[] = [];
    public edgePaths: any[] = [];
    public nodes: GraphNode[] = [];

    constructor(init?: Partial<GraphData>) {
        Object.assign(this, init);
    }
}
