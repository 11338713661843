import Component from 'vue-class-component';
import Vue from 'vue';
import { $router, teamHelper } from '@/main';
import { periodModule } from '@/store/modules/period';

@Component
export default class NotFoundComponent extends Vue {
    public async goToRoot() {
        await $router.push({
            name: 'me-finances',
            params: {
                teamId: teamHelper.currentTeam.id.toString(),
                teamKey: teamHelper.currentTeam.key,
                tab: 'overview',
                period: periodModule.selectedPeriod,
            },
        });
    }
}
