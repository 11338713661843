import PageRender from '@/models/PageRender';
import { Component } from 'vue-property-decorator';
import { teamService, ecosystemHelper, loginHelper } from '@/main';
import to from 'await-to-js';
import { periodModule } from '@/store/modules/period';
import { profileModule } from '@/store/modules/profile';

@Component
export default class EcosystemCreateTeamComponent extends PageRender {
    public newTeam: any = {
        name: '',
        legalEntityReference: '',
        ecosystem: ecosystemHelper.currentEcosystem.name,
        teamLeadId: loginHelper.getUser().personId,
    };

    public async createTeam() {
        this.showPending('CREATE_TEAM_PENDING');

        const [err] = await to(teamService.createTeam(this.newTeam));
        if (err) {
            return this.clearAndShowError('CREATE_TEAM_FAILED', err);
        }
        this.clearAndShowSuccess('CREATE_TEAM_SUCCESS');

        await profileModule.loadBrightProfileIfNeeded(true);

        await this.$router.push({
            name: 'ecosystem-overview',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                period: periodModule.selectedPeriod,
            },
        });
    }
}
