import IValidation from './IValidation';

export default class UpperCase implements IValidation {
    public valid(value: string) {
        return !!value.match(/[A-Z]/g);
    }

    public get message() {
        return 'Must contain a uppercase character';
    }
}
