import Vue from 'vue';
import { BaseService } from './baseService';
import { AxiosHelper } from '@/helpers/axiosHelper';
import to from 'await-to-js';
import Connector from '@/models/Connectors/Connector';

export default class ConnectorService extends BaseService {
    private endpoint = `${Vue.$env().directoryEndpoint}connectors`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getConnectors(): Promise<Connector[]> {
        const [err, response] = await to(this.get(`${this.endpoint}`));
        if (err) {
            return [];
        }

        return response.data as Connector[];
    }
}
