import Component from 'vue-class-component';
import FinanceGroup from '../../../../models/FinanceGroup';
import {
    periodFilterHelper,
    loginHelper,
    teamHelper,
    roleHelper,
    groupService,
    compensationService,
    financeGroupService,
    transferService,
} from '@/main';
import FinanceTotal from '../../../../models/FinanceTotal';
import Transfer from '../../../../models/Transfer';
import Vue from 'vue';
import BaseGroupPage from './BaseGroupPage';
import { Member } from '../../../../models/Member';
import { to } from 'await-to-js';
import { FinanceAccount } from '@/models/Interfaces';
import { TransferType } from '@/models/TransferType';
import { periodModule } from '@/store/modules/period';

@Component({
    filters: {
        formatNumber(n: any) {
            if (!n || typeof n === 'undefined' || n == null) {
                return 0;
            }

            if (n > -1000 && n < 1000) {
                return n.round(2).replace('.', ',');
            }

            const parts = n.round(2).split('.');
            const chars = [];
            let i = 0;

            if (parts.length === 2) {
                chars.push(`,${parts[1]}`);
            }

            parts[0]
                .split('')
                .reverse()
                .forEach((c) => {
                    chars.push(c);
                    i++;
                    if (i > 0 && i % 3 === 0) {
                        chars.push(' ');
                    }
                });

            return chars.reverse().join('');
        },
    },
})
export default class GroupDetailOverview extends BaseGroupPage {
    public compensations: any = null;
    public groupFinance: FinanceGroup = new FinanceGroup();
    public isLoaded: boolean = false;

    public members: Member[] = null;
    public groupCompensations: any = [];

    public transferGridUpdate: number = 0;
    public transfer: Transfer = new Transfer();
    public transferModalType: TransferType = TransferType.GroupToPerson;
    public teamId: number = null;

    public receivingAccounts: FinanceAccount[] = [];

    public compensationColumns: any[] = [
        { title: 'Member', field: 'member' },
        { title: 'Compensation', field: 'compensation', cell: this.formatCompensationValue },
        { title: 'Energy dedication', field: 'energyDedication', cell: this.renderEnergyDedication },
        { title: 'Financial compensation', field: 'financialCompensation', cell: this.renderPercentageCompensation },
    ];

    public calculatedAmount: number = 0;
    public teamAttribution: number = 0;
    public groupResults: any = null;

    public editMode: boolean = false;
    public editIsLoading: boolean = false;

    get transferTitle() {
        return `Compensate member of ${this.group.name}`;
    }

    public async mounted() {
        await this.loadGroupFinanceSettings();
        this.isBuitenBeschouwingGroup = this.groupFinanceSettings && this.groupFinanceSettings.applyMissingAttributionsOnTransfer;

        const financeTotal = new FinanceTotal(this.teamId);
        try {
            this.groupFinance = await financeTotal.getGroupFinance(this.group, periodModule.selectedPeriod);
            this.groupResults = await this.getGroupResults();
        } catch (e) {
            this.groupFinance = {} as FinanceGroup;
            this.clearAndShowError('Could not get group finance', e);
        }

        if (this.isBuitenBeschouwingGroup) {
            this.$emit('activate-class');
            this.isLoaded = true;
            return;
        }

        this.members = this.group.members.map((member: any) => new Member(member));
        const membersEnergyResponse = await groupService.getEnergyDedication(
            teamHelper.currentTeamId,
            this.group.groupId,
            periodModule.selectedPeriod,
        );
        membersEnergyResponse.data.forEach((energy) => {
            const foundMember = this.group.members.find((member) => member.memberId === energy.personId);
            if (foundMember) {
                foundMember.energyDedication = energy.amount;
            }
        });

        if (this.groupFinance.compensations) {
            await this.mutateGridCompensationData(this.groupFinance.compensations);
        }

        this.$emit('activate-class');

        this.teamId = teamHelper.getTeamId();
        this.isLoaded = true;
    }

    get personTranfers() {
        return this.groupFinance.transfers.filter((x) => x.transferType === 'GroupToPerson');
    }

    get hideMemberCompensations() {
        return !roleHelper.checkAccess(this.groupFinanceSettings.privacyLevel, this.group);
    }

    public async reloadData() {
        this.isLoaded = false;

        const financeTotal = new FinanceTotal(this.teamId);
        try {
            this.groupFinance = await financeTotal.getGroupFinance(this.group, periodModule.selectedPeriod);
            this.groupResults = await this.getGroupResults();
        } catch (e) {
            this.groupFinance = {} as FinanceGroup;
            this.clearAndShowError('Could not get group finance', e);
        }

        if (this.isBuitenBeschouwingGroup) {
            this.$emit('activate-class');
            this.isLoaded = true;
            return;
        }

        if (this.groupFinance.compensations) {
            await this.mutateGridCompensationData(this.groupFinance.compensations);
        }

        this.isLoaded = true;

        if (this.$refs.recurringTransfers) {
            await this.$refs.recurringTransfers.refreshData();
        }
        this.transfer = new Transfer();
    }

    public async getGroupResults() {
        const [err, response] = await to(financeGroupService.getGroupResults(this.group.groupId, periodModule.selectedPeriod));
        if (err) {
            console.error(err);
        }

        return response.data;
    }

    public async compensateMembers() {
        this.showPending('COMPENSATE_MEMBERS_PENDING');

        const [err] = await to(financeGroupService.compenstateCosts(this.group.groupId, teamHelper.currentTeamId));
        if (err) {
            return this.clearAndShowError('COMPENSATE_MEMBERS_FAILED', err);
        }

        await this.reloadGridCompensationData();
        await this.reloadData();
        this.clearAndShowSuccess('COMPENSATE_MEMBERS_SUCCCESS');
        this.transferGridUpdate++;
    }

    public getAccount(accounts, id) {
        return accounts.find((account) => {
            return account.accountId === id;
        });
    }

    public renderMemberInitiator(h, _, row) {
        let member = row.dataItem.initiatorPerson;

        if (!member) {
            member = this.members.find((m: Member) => m.memberId === row.dataItem.initiator);
        }

        if (!member) {
            member = new Member();
            member.firstName = 'Not available';
        }

        return this.renderMember(h, member);
    }

    public calculateTransferGridHeight() {
        if (this.groupFinance.transfers.length < 25) {
            return { height: `${39 + this.groupFinance.transfers.length * 47}px` };
        }
    }

    public renderActions(h, _, row: any): any {
        let actions = [];
        const userId = loginHelper.getUser().personId;
        const initiator = row.dataItem && typeof row.dataItem.initiator !== 'undefined' ? parseInt(row.dataItem.initiator) : 0;

        if (initiator === userId || roleHelper.isSiteAdmin() || roleHelper.isFinanceEmployee()) {
            actions = [
                { title: 'Edit', function: this.editCompensation, disabled: periodFilterHelper.isCurrentPeriodClosed() },
                { title: 'Delete', function: this.removeCompensation, disabled: periodFilterHelper.isCurrentPeriodClosed() },
            ];
        }

        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    public renderPercentageCompensation(h, _, row) {
        let additionalPercentages = 0;

        const attribition = this.teamAttributions.find((x) => x.targetGroup === this.group.groupId);
        if (attribition) {
            additionalPercentages += attribition.percentage;
        }

        return h('td', [
            isNaN(row.dataItem.financialCompensation)
                ? row.dataItem.financialCompensation
                : // tslint:disable-next-line: restrict-plus-operands
                  `${(row.dataItem.financialCompensation + additionalPercentages).toFixed(2)}%`,
        ]);
    }

    public formatCompensationValue(h, _, row) {
        const props = { value: row.dataItem.compensation };
        return h(Vue.component('number-formatter'), { props });
    }

    public async editCompensation(item) {
        Vue.nextTick(async () => {
            this.$refs.newTransferModal.editMode = true;
            await this.$refs.newTransferModal.initTransfer(item);
        });
    }

    public async removeCompensation(item) {
        await transferService.deleteTransfer(item.transferId);

        const transfer = this.groupFinance.transfers.findIndex((t: Transfer) => {
            return t.transferId === item.transferId;
        });

        this.groupFinance.transfers.splice(transfer, 1);
        this.transferGridUpdate++;
        await this.reloadGridCompensationData();
    }

    private async mutateGridCompensationData(compensations) {
        this.groupCompensations = [];

        this.groupFinance.setCompensations(compensations);
        await this.groupFinance.recalculateTotals();

        compensations.forEach((compensation: any) => {
            const member = this.group.members.find((m) => m.memberId === compensation.member.personId);
            const energyDedication = member ? (member.energyDedication ? member.energyDedication : 0) : 0;
            const totalCompensations = parseFloat(compensation.compensation) + parseFloat(compensation.invoiceIncome);

            const financialCompensation = (totalCompensations / (compensation.costs * (energyDedication / 100))) * 100;
            this.groupCompensations.push({
                member: compensation.member.name,
                memberObj: compensation.member,
                compensation: totalCompensations,
                costs: compensation.costs,
                energyDedication,
                financialCompensation:
                    isNaN(totalCompensations) || totalCompensations === 0
                        ? 'Missing compensations'
                        : isFinite(financialCompensation)
                        ? financialCompensation
                        : 'Costs or energy dedication zero',
            });
        });
    }

    private async reloadGridCompensationData() {
        const [errC, responseC] = await to(
            compensationService.getCompensations(teamHelper.currentTeam.id, this.group.groupId, periodModule.selectedPeriod),
        );
        if (errC) {
            this.clearAndShowError(errC, errC);
        }
        await this.mutateGridCompensationData(responseC.data.memberCompensations);
    }
}
