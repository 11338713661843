import CreateLedgerAccountModal from '@/components/modals/create-ledger-account-modal';
import Ledger from '@/models/Finance/Ledger';
import PageRender from '@/models/PageRender';
import { ledgerModule } from '@/store/modules/ledger';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class EcosystemSettingsLedgerAccounts extends PageRender {
    public isLoading: boolean = true;
    public columns: any = [
        { title: 'NAME', field: 'name' },
        { title: 'VALUE', field: 'value' },
        { title: 'DESCRIPTION', field: 'description' },
        { title: 'ACTIONS', cell: this.renderActions, width: 100 },
    ];

    public $refs: {
        createLeaderModal: CreateLedgerAccountModal;
    };

    public async created() {
        await ledgerModule.fetchLedgersIfNeeded();

        this.isLoading = false;
    }

    public get ledgers() {
        return ledgerModule.ledgers;
    }

    public async mounted() {
        this.isLoading = false;
    }

    public async reloadLedgers() {
        await ledgerModule.fetch();
    }

    public openLedgerCreateModal() {
        this.$refs.createLeaderModal.show();
    }

    public editLedger(item?: Ledger) {
        if (item) {
            this.$refs.createLeaderModal.setLedger(item);
        }

        this.openLedgerCreateModal();
    }

    public renderActions(h, _, row: any): any {
        const actions = [{ title: 'Edit ledger', function: this.editLedger }];
        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }
}
