import { Component } from 'vue-property-decorator';
import { loginHelper, meService } from '@/main';
import { Person } from '@/models/Person';
import to from 'await-to-js';
import PageRender from '@/models/PageRender';
import { profileModule } from '@/store/modules/profile';
import Vue from 'vue';
@Component
export default class ProfileComponent extends PageRender {
    public profile: Person = null;
    public isLoading: boolean = true;

    public created() {
        this.profile = profileModule.brightProfile;
        this.isLoading = false;
    }

    public async saveProfile() {
        this.showPending('SAVE_PROFILE_PENDING');
        const [err] = await to(
            meService.updatePreferences(
                this.profile.primaryEcosystem,
                this.profile.primaryTeam,
            ),
        );
        if (err) {
            return this.clearAndShowError('SAVE_PROFILE_FAILED', err);
        }

        const reloadProfileResponse = await loginHelper.reloadProfile();
        profileModule.setBrightProfile(reloadProfileResponse);
        this.profile = new Person(reloadProfileResponse);

        return this.clearAndShowSuccess('SAVE_PROFILE_SUCCESS');
    }

    public optionListEcosystems() {
        return this.profile.ecosystemMemberships.map((ship) => {
            return { value: ship.id, text: ship.name };
        });
    }

    public optionListTeams() {
        return this.profile.teamMemberships.map((ship) => {
            return { value: ship.id, text: ship.name };
        });
    }

    public goToManangePassword() {
        window.open(`${Vue.$env().identityEndpoint}Manage/ChangePassword`, '_blank');
    }

    public goToManangeProfile() {
        window.open(`${Vue.$env().identityEndpoint}Manage`, '_blank');
    }
}
