import VueRouter from 'vue-router';

export class ErrorHelper {
    private router: VueRouter;

    constructor(router: VueRouter) {
        this.router = router;
    }

    public throwError(message: string, returnUrl?: string) {
        let url = `/error?message=${message}`;
        if (returnUrl !== undefined) {
            url = `${url}&returnUrl=${returnUrl}`;
        }

        this.router.push(url);
    }
}
