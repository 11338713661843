export const numberFormat = (n: number): string => {
    if (!n || typeof n === 'undefined' || n == null) {
        return '0';
    }

    if (n > -1000 && n < 1000) {
        return n.round(2).replace('.', ',');
    }

    const parts = n.round(2).split('.');
    const chars = [];
    let i = 0;

    if (parts.length === 2) {
        chars.push(`,${parts[1]}`);
    }

    parts[0]
        .split('')
        .reverse()
        .forEach((c) => {
            chars.push(c);
            i++;
            if (i > 0 && i % 3 === 0) {
                chars.push(' ');
            }
        });

    return chars.reverse().join('').trim();
};
