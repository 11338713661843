import Vue from 'vue';
import Component from 'vue-class-component';
import { teamService, personService } from '@/main';
import TeamJoinRequest from '../../models/TeamJoinRequest';
import { Person } from '../../models/Person';
import Team from '@/models/Team';
import { periodModule } from '@/store/modules/period';

@Component
export default class JoinRequestComponent extends Vue {
    public joinRequest: TeamJoinRequest = new TeamJoinRequest();
    public person: Person;
    public team: Team;
    private requestId: number;

    public created() {
        const self = this;
        this.requestId = parseInt(this.$route.params.id);

        teamService
            .getJoinRequest(self.requestId)
            .then((result) => {
                self.joinRequest = result.data as TeamJoinRequest;

                teamService
                    .getTeam(self.joinRequest.ForTeam, periodModule.selectedPeriod)
                    .then((tresult) => self.team = tresult.data)
                    .catch(console.log);

                personService
                    .getPerson(self.joinRequest.FromPerson, periodModule.selectedPeriod)
                    .then((presult) => self.person = presult)
                    .catch(console.log);
            })
            .catch( console.log );
    }

    public getPersonFullName(): string {
        let parts = [this.person.firstName, this.person.insertion, this.person.lastName];
        parts = parts.filter((value) => {
          return !!value;
        });

        return parts.join(' ');
    }

    public choiceAction(allow: boolean) {
        this.joinRequest.Allowed = allow;

        teamService
            .updateJoinRequest(this.requestId, this.joinRequest)
            .then(console.log)
            .catch(console.log);
    }
}
