import Group from '../models/Group';
import { AxiosHelper } from '../helpers/axiosHelper';
import { BaseService } from './baseService';
import Vue from 'vue';
import to from 'await-to-js';

export class FinanceGroupService extends BaseService {
    private financeGroupEndpoint = `${Vue.$env().financeEndpoint}` + 'groups';

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getGroups(team: number, period?: string) {
        if (!period) {
            const [errWPeriod, responseWPeriod] = await to(this.get<Group[]>(`${this.financeGroupEndpoint}?teamId=:team`, [['team', team]]));

            if (errWPeriod) {
                this.showFailedResponse(`Failed to load groups for team ${team}`, null);
            }

            return responseWPeriod.data;
        }

        const config = {
            headers: {
                'x-period': period,
            },
        };

        const [err, response] = await to(this.get<Group[]>(`${this.financeGroupEndpoint}?teamId=:team`, [['team', team]], config));
        if (err) {
            this.showFailedResponse(`Failed to load groups for team ${team}`, null);
        }

        return response.data;
    }

    public getGroup(group: number, period: string) {
        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.get<Group>(`${this.financeGroupEndpoint}/:group`, [['group', group]], config);
    }

    public getGroupResults(group: number, period: string) {
        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.get<Group>(`${this.financeGroupEndpoint}/:group/results`, [['group', group]], config);
    }

    public compenstateCosts(groupId: number, teamId: number): Promise<unknown> {
        return this.post(`${this.financeGroupEndpoint}/:group/compensate-members?teamId=:teamId`, null, [['group', groupId], ['teamId', teamId]]);
    }
}
