import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridTransferDirectionComponent extends Vue {
    @Prop({ default: () => null }) public action: any;
    @Prop({ default: () => false }) public isIncoming: any;

    public getDirectionIcon() {
        if (this.isIncoming) {
            return 'k-icon k-i-arrow-right text-danger k-icon-24 ';
        } else {
            return 'k-icon k-i-arrow-left text-success k-icon-24';
        }
    }
}
