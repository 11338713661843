import IValidation from './IValidation';

export default class LowerCase implements IValidation {
    public valid(value: string) {
        return !!value.match(/[a-z]/g);
    }

    public get message() {
        return 'Must contain a lowercase character';
    }
}
