import { Component, Prop } from 'vue-property-decorator';
import TranslateBase from './base';

@Component
export default class TranslateTextInline extends TranslateBase {
    @Prop({ default: () => '' }) public variant;

    public onClick(e) {
        this.$emit('click', e);
    }

    public onSpace(e) {
        e.preventDefault();
    }
}
