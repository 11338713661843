import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import Notification from './models/Notification';
import { INotificationsService } from './models/Interface/INotifcationService';
@Component
export default class GeneralNotificationsComponent extends Vue {
    @Prop({ default: () => null }) public notification: Notification;
    @Prop() public notificationService: INotificationsService;

    public handled() {
        return this.notification.handled;
    }

    public async openLink() {
        this.notificationService.markAsHandled(this.notification);
        this.notification.handled = true;

        window.open(this.notification.deepLink, '_blank');
    }

    public deleteNotification() {
        this.$emit('delete-notification', this.notification);
    }
}
