import { Component } from 'vue-property-decorator';
import { attributionService, teamHelper, teamService, financeGroupService, settingService, templateService } from '@/main';
import Attribution from '../../../../models/Attribution';
import { AttributionType } from '../../../../models/AttributionType';
import BaseFinancePage from './BaseFinancePage';
import to from 'await-to-js';
import Team from '@/models/Team';
import SaveAttributionsModalComponent from '@/components/modals/save-attributions-modal';
import TeamSettings from '@/models/TeamSettings';
import { settingsModule } from '@/store/modules/settings';
import { periodModule } from '@/store/modules/period';

@Component
export default class TeamFinanceSettingsPage extends BaseFinancePage {
    public teams: Team[] = [];
    public team: Team = null;
    public isLoading: boolean = true;
    public servicesAttributions: Attribution[] = [];
    public failedSavingSettings: boolean = false;
    public roleOptions: any[] = [
        { text: 'SITE_ADMIN', value: 'SiteAdmin' },
        { text: 'FINANCE_EMPLOYEE', value: 'FinanceEmployee' },
        { text: 'GROUP_LEAD', value: 'GroupLead' },
        { text: 'MEMBER', value: 'Member' },
    ];

    public teamSettings: TeamSettings = null;

    public templates: any[] = [];

    public $refs!: {
        saveAttributionsModal: SaveAttributionsModalComponent;
    };

    public async created() {
        const self = this;
        self.teams = await teamService.getTeams();

        this.team = self.teams.find((x) => x.teamId === teamHelper.currentTeam.id);

        const currentTeamId = teamHelper.currentTeam.id;
        const [err, response] = await to(
            attributionService.getAttributions(AttributionType.TeamAttribution, currentTeamId, periodModule.selectedPeriod),
        );
        if (err) {
            this.showError('Failed to load attributoins');
        }

        await settingsModule.fetchTeamSettingsIfNeeded(currentTeamId);
        this.teamSettings = settingsModule.findTeamFinanceSettings(currentTeamId);

        for (let i = 0; i < response.data.length; i++) {
            const item = response.data[i];
            const attr = new Attribution(0);
            Object.assign(attr, item);

            attr.targetTeamModel = self.teams.find((team) => {
                if (item.targetTeam) {
                    return team.teamId === item.targetTeam;
                }
                return team.teamId === teamHelper.getTeamId();
            });

            if (attr.targetTeamModel) {
                attr.targetTeamModel.groups = await financeGroupService.getGroups(attr.targetTeamModel.teamId);

                attr.targetGroupModel = attr.targetTeamModel.groups.find((group) => {
                    return group.groupId === attr.targetGroup;
                });
            }

            if (attr.targetTeamModel || attr.targetGroupModel) {
                self.servicesAttributions.push(attr);
            }
        }

        // self.servicesAttributions = attrs.filter((x) => x.targetTeamModel || x.targetGroupModel);

        await this.loadTemplates();
        this.isLoading = false;
    }

    public createContactSideAction(template) {
        this.$sideActions.push(
            'create-or-edit-template-side-action',
            {
                // options
                template: template ? template : null,
            },
            async () => {
                await this.loadTemplates();
            },
        );
    }

    public async loadTemplates() {
        this.templates = await templateService.getTemplates(teamHelper.currentTeam.id);
    }

    public async teamChange(attribution: Attribution) {
        attribution.targetGroup = null;
        attribution.groups = await financeGroupService.getGroups(attribution.targetTeamModel.teamId);
    }

    public async addGroupAttribution() {
        const attribution = new Attribution(0);

        attribution.targetTeamModel = this.teams.find((team) => {
            return team.teamId === teamHelper.getTeamId();
        });

        attribution.groups = await financeGroupService.getGroups(attribution.targetTeamModel.teamId);

        this.servicesAttributions.push(attribution);
    }

    public deleteGroupAttribution(item: Attribution) {
        this.servicesAttributions.splice(this.servicesAttributions.indexOf(item), 1);
    }

    public async getTeamGroups(team: Team) {
        return await financeGroupService.getGroups(team.teamId, periodModule.selectedPeriod);
    }

    public async savePrivacySettings() {
        const currentTeamId = teamHelper.getTeamId(teamHelper.currentTeamId);

        this.showPending('SAVE_FINANCE_SETTINGS_PENDING');
        const [err] = await to(settingService.saveTeamSettings(currentTeamId, this.teamSettings));

        if (err) {
            return this.clearAndShowError('SAVE_FINANCE_SETTINGS_FAILED', err);
        }

        this.clearAndShowSuccess('SAVE_FINANCE_SETTINGS_SUCCESS');
    }

    public async openSaveAttributionsModal() {
        let invalid = false;

        this.servicesAttributions.forEach((attr) => {
            if (
                typeof attr.targetGroupModel === 'undefined' ||
                attr.targetGroupModel === null ||
                !attr.targetGroupModel ||
                typeof attr.targetTeamModel === 'undefined' ||
                !attr.targetTeamModel ||
                attr.targetTeamModel === null ||
                !attr.percentage ||
                attr.percentage < 0 ||
                attr.percentage > 100
            ) {
                invalid = true;
            }
        });

        if (invalid) {
            return this.clearAndShowWarning('Current state of attributions not valid, please fill in all fields');
        }

        return this.$refs.saveAttributionsModal.show();
    }

    public async saveLegalEntityReference() {
        this.showPending('SAVE_TEAM_PENDING');

        const [err] = await to(
            teamService.updateTeam({ teamId: this.team.teamId, legalEntityReference: this.team.legalEntityReference, name: this.team.name }),
        );
        if (err) {
            return this.clearAndShowError('SAVE_TEAM_FAILED', err);
        }

        this.clearAndShowSuccess('SAVE_TEAM_SUCCESS');
    }
}
