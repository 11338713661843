import { FinanceInterface, Distribution } from './Interfaces';
import DistributedInvoice from './DistributedInvoice';
import { AccountType } from './AccountType';
import { functions } from '@/helpers/functions';
import Vue from 'vue';

export default class FinanceBase implements FinanceInterface {
    public totalIncomes: string;
    public totalIncomesAmount: number;
    public totalExpenses: string;
    public totalExpensesAmount: number;
    public totalExtras: string;
    public totalCosts: string;
    public totalCostAmount: number;
    public totalResult: string = 'N/A';
    public totalResultAmount: number;
    public totalBudget: string = 'N/A';
    public totalTransfers: string;
    public totalTransfersAmount: number;
    public totalExternalTransfers: string;
    public totalExternalTransferAmount: number;

    public previousBalance: string = 'N/A';
    public currentBalance: string = 'N/A';
    public currentBalanceAmount: number;
    public ratio: any;

    public invoices: DistributedInvoice[] = [];
    public expenses: DistributedInvoice[] = [];
    public extras: DistributedInvoice[] = [];

    constructor(invoices, expenses) {
        this.invoices = this._initDistributedInvoices(invoices);
        this.expenses = this._initDistributedInvoices(expenses);
    }

    public _initDistributedInvoices(invoices): DistributedInvoice[] {
        const distributedInvoices = [];
        invoices.forEach((invoice) => {
            distributedInvoices.push(new DistributedInvoice(invoice));
        });
        return distributedInvoices;
    }

    public calculateTotalDistributions(invoices, type?: AccountType) {
        let total = 0;
        invoices.forEach((invoice: DistributedInvoice) => {
            if (type) {
                const distributions = invoice.getDistributionsByType(type);

                distributions.forEach((distribution) => {
                    total += distribution.amount;
                });
            } else {
                total += invoice.amountDistributed;
            }
        });
        return total;
    }

    public calculatePersonIncome(personId: number, incomes): number {
        let income = 0;
        incomes.forEach((invoice: DistributedInvoice) => {
            const distributions = invoice.getPersonDistributions(personId);
            distributions.forEach((distribution) => {
                income += distribution.amount;
            });
        });
        return income;
    }

    public calculatePersonExpenses(personId: number, expenses): number {
        let total = 0;
        expenses
            .filter((expense: DistributedInvoice) => expense.invoice.isRecurring)
            .forEach((expense: DistributedInvoice) => {
                const distributions = functions.getPersonDistributions(personId, expense.distributions);
                distributions.forEach((distribution: Distribution) => {
                    total += distribution.amount;
                });
            });
        return total;
    }

    public calculatePersonExtras(personId: number, expenses): number {
        let total = 0;
        expenses
            .filter((expense: DistributedInvoice) => !expense.invoice.isRecurring)
            .forEach((expense: DistributedInvoice) => {
                const distributions = functions.getPersonDistributions(personId, expense.distributions);
                distributions.forEach((distribution: Distribution) => {
                    total += distribution.amount;
                });
            });
        return total;
    }

    public filterEmptyInvoices(invoices) {
        const distributedInvoices = [];
        invoices.forEach((invoice) => {
            const distributedInvoice = new DistributedInvoice(invoice);
            if (distributedInvoice.distributions.length > 0 && distributedInvoice.amountDistributed !== 0) {
                distributedInvoices.push(distributedInvoice);
            }
        });
        return distributedInvoices;
    }

    public filterEntityDistributions(invoices, entityId: number, type: AccountType) {
        return invoices.filter((invoice: DistributedInvoice) => {
            return invoice.hasDistributions(entityId, type);
        });
    }

    public setTotals(incomes, expenses, costs, result, previousBalance, currentBalanceAmount, transfers) {
        this.totalIncomes = `(${Vue.filter('number-format')(incomes)})`;
        this.totalExpenses = `(${Vue.filter('number-format')(expenses)})`;
        this.totalCosts = `(${Vue.filter('number-format')(costs)})`;
        this.totalTransfers = `(${Vue.filter('number-format')(transfers)})`;

        if (previousBalance && currentBalanceAmount) {
            this.totalResult = `(${Vue.filter('number-format')(result)})`;
            this.previousBalance = `(${Vue.filter('number-format')(previousBalance)})`;
            this.currentBalance = `(${Vue.filter('number-format')(currentBalanceAmount)})`;
            this.totalBudget = `(${Vue.filter('number-format')(currentBalanceAmount)})`;
        }
    }
}
