var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('prd-nav-pane',{attrs:{"name":"bright"}},[_c('img',{attrs:{"slot":"logo","src":"/img/bright_icon_blue.svg"},slot:"logo"}),_c('p',{attrs:{"slot":"context"},slot:"context"},[_vm._v("Bright")]),_c('prd-nav-category',{attrs:{"title":"Me","variant":"primary","open":_vm.isMe,"name":"me"}},[_c('prd-translate-text',{attrs:{"slot":"title","translate-key":"ME"},slot:"title"}),_c('prd-nav-link',{attrs:{"icon":"directory","label":".directory","to":{
                name: 'me-directory',
                params: {
                    ecosystemId: _vm.$root.ecosystemHelper.currentEcosystem.id,
                    ecosystemKey: _vm.$root.ecosystemHelper.currentEcosystem.key,
                    teamId: _vm.$root.teamHelper.currentTeam.id,
                    teamKey: _vm.$root.teamHelper.currentTeam.key,
                    tab: 'groups',
                    period: _vm.$store.state.period._selectedPeriod,
                },
            },"active":_vm.isMe && _vm.$route.meta.section === 'directory'}},[_c('prd-translate-text',{attrs:{"inline":true,"translate-key":"DIRECTORY"}})],1),_c('prd-nav-link',{attrs:{"icon":"finance","label":".finances","to":{
                name: 'me-finances',
                params: {
                    ecosystemId: _vm.$root.ecosystemHelper.currentEcosystem.id,
                    ecosystemKey: _vm.$root.ecosystemHelper.currentEcosystem.key,
                    teamId: _vm.$root.teamHelper.currentTeam.id,
                    teamKey: _vm.$root.teamHelper.currentTeam.key,
                    period: _vm.$store.state.period._selectedPeriod,
                    tab: 'overview',
                },
            },"active":_vm.isMe && _vm.$route.meta.section === 'finances'}},[_c('prd-translate-text',{attrs:{"inline":true,"translate-key":"FINANCES"}})],1)],1),_c('prd-nav-category',{attrs:{"title":"Team","variant":"warning","icon":"team","open":_vm.isTeam,"name":"team"}},[_c('prd-translate-text',{attrs:{"slot":"title","translate-key":"TEAM"},slot:"title"}),_c('prd-nav-link',{attrs:{"icon":"directory","label":".directory","to":{
                name: 'team-directory',
                params: {
                    ecosystemId: _vm.$root.ecosystemHelper.currentEcosystem.id,
                    ecosystemKey: _vm.$root.ecosystemHelper.currentEcosystem.key,
                    teamId: _vm.$root.teamHelper.currentTeam.id,
                    teamKey: _vm.$root.teamHelper.currentTeam.key,
                    tab: 'groups',
                    period: _vm.$store.state.period._selectedPeriod,
                },
            },"active":_vm.isTeam && _vm.$route.meta.section == 'directory'}},[_c('prd-translate-text',{attrs:{"inline":true,"translate-key":"DIRECTORY"}})],1),_c('prd-nav-link',{attrs:{"icon":"finance","label":".finances","to":{
                name: 'team-finances',
                params: {
                    ecosystemId: _vm.$root.ecosystemHelper.currentEcosystem.id,
                    ecosystemKey: _vm.$root.ecosystemHelper.currentEcosystem.key,
                    teamId: _vm.$root.teamHelper.currentTeam.id,
                    teamKey: _vm.$root.teamHelper.currentTeam.key,
                    tab: 'group-overview',
                    period: _vm.$store.state.period._selectedPeriod,
                },
            },"active":_vm.isTeam && _vm.$route.meta.section == 'finances'}},[_c('prd-translate-text',{attrs:{"inline":true,"translate-key":"FINANCES"}})],1),_c('prd-nav-link',{attrs:{"icon":"insights","label":".analytics","to":{
                name: 'team-insights',
                params: {
                    ecosystemId: _vm.$root.ecosystemHelper.currentEcosystem.id,
                    ecosystemKey: _vm.$root.ecosystemHelper.currentEcosystem.key,
                    teamId: _vm.$root.teamHelper.currentTeam.id,
                    teamKey: _vm.$root.teamHelper.currentTeam.key,
                    tab: 'group-results',
                    period: _vm.$store.state.period._selectedPeriod,
                },
            },"active":_vm.isTeam && _vm.$route.meta.section == 'insights'}},[_c('prd-translate-text',{attrs:{"inline":true,"translate-key":"ANALYTICS"}})],1)],1),_c('prd-nav-category',{attrs:{"title":"Ecosystem","variant":"success","icon":"ecosystem","open":_vm.isEco,"name":"ecosystem"}},[_c('prd-translate-text',{attrs:{"slot":"title","translate-key":"ECOLOGY"},slot:"title"}),_c('prd-nav-link',{attrs:{"icon":"overview","label":".overview","to":{
                name: 'ecosystem-overview',
                params: { ecosystem: _vm.$root.ecosystemHelper.ecosystemParam, period: _vm.$store.state.period._selectedPeriod },
            },"active":_vm.isEco && _vm.$route.meta.section == 'overview'}},[_c('prd-translate-text',{attrs:{"inline":true,"translate-key":"OVERVIEW"}})],1),_c('prd-nav-link',{attrs:{"icon":"directory","label":".directory","to":{
                name: 'ecosystem-directory',
                params: {
                    ecosystemId: _vm.$root.ecosystemHelper.currentEcosystem.id,
                    ecosystemKey: _vm.$root.ecosystemHelper.currentEcosystem.key,
                    period: _vm.$store.state.period._selectedPeriod,
                    tab: 'groups',
                },
            },"active":_vm.isEco && _vm.$route.meta.section == 'directory'}},[_c('prd-translate-text',{attrs:{"inline":true,"translate-key":"DIRECTORY"}})],1),(_vm.$root.roleHelper.isSiteAdmin() || _vm.$root.roleHelper.isFinanceEmployee())?_c('prd-nav-link',{attrs:{"icon":"finance","label":".finances","to":{
                name: 'ecosystem-finances',
                params: {
                    ecosystemId: _vm.$root.ecosystemHelper.currentEcosystem.id,
                    ecosystemKey: _vm.$root.ecosystemHelper.currentEcosystem.key,
                    period: _vm.$store.state.period._selectedPeriod,
                },
            },"active":_vm.isEco && _vm.$route.meta.section == 'finances'}},[_c('prd-translate-text',{attrs:{"inline":true,"translate-key":"FINANCES"}})],1):_vm._e(),(_vm.$root.roleHelper.isSiteAdmin() || _vm.$root.roleHelper.isFinanceEmployee())?_c('prd-nav-link',{attrs:{"label":".settings","icon":"settings","to":{
                name: 'ecosystem-settings',
                params: {
                    tab: 'ledger-accounts',
                    ecosystemId: _vm.$root.ecosystemHelper.currentEcosystem.id,
                    ecosystemKey: _vm.$root.ecosystemHelper.currentEcosystem.key,
                    period: _vm.$store.state.period._selectedPeriod,
                },
            },"active":_vm.isEco && _vm.$route.meta.section == 'settings'}},[_c('prd-translate-text',{attrs:{"inline":true,"translate-key":"SETTINGS"}})],1):_vm._e(),_c('prd-nav-link',{attrs:{"label":".insights","icon":"insights","to":{
                name: 'ecosystem-insights',
                params: {
                    ecosystemId: _vm.$root.ecosystemHelper.currentEcosystem.id,
                    ecosystemKey: _vm.$root.ecosystemHelper.currentEcosystem.key,
                    period: _vm.$store.state.period._selectedPeriod,
                },
            },"active":_vm.isEco && _vm.$route.meta.section == 'insights'}},[_c('prd-translate-text',{attrs:{"inline":true,"translate-key":"INSIGHTS"}})],1),_c('prd-nav-link',{attrs:{"label":".cells","icon":"share-2","to":{
                name: 'ecosystem-cells',
                params: {
                    ecosystemId: _vm.$root.ecosystemHelper.currentEcosystem.id,
                    ecosystemKey: _vm.$root.ecosystemHelper.currentEcosystem.key,
                    period: _vm.$store.state.period._selectedPeriod,
                },
            },"active":_vm.isEco && _vm.$route.meta.section == 'cells'}},[_c('prd-translate-text',{attrs:{"inline":true,"translate-key":"CELLS"}})],1),(_vm.$root.roleHelper.isSiteAdmin() || _vm.$root.roleHelper.isFinanceEmployee())?_c('prd-nav-link',{attrs:{"label":".subscriptions","icon":"rotate-cw","to":{
                name: 'ecosystem-subscription',
                params: {
                    ecosystemId: _vm.$root.ecosystemHelper.currentEcosystem.id,
                    ecosystemKey: _vm.$root.ecosystemHelper.currentEcosystem.key,
                    period: _vm.$store.state.period._selectedPeriod,
                },
            },"active":_vm.isEco && _vm.$route.meta.section == 'subscription'}},[_c('prd-translate-text',{attrs:{"inline":true,"translate-key":"SUBSCRIPTION"}})],1):_vm._e()],1),_c('prd-nav-category',{attrs:{"title":"Settings","variant":"dark","icon":"settings","open":_vm.isSettings,"name":"settings"}},[_c('prd-translate-text',{attrs:{"slot":"title","translate-key":"SETTINGS"},slot:"title"}),(_vm.$root.roleHelper.isSiteAdmin())?_c('prd-nav-link',{attrs:{"icon":"settings","label":".language","to":{ name: 'settings-translate' }}},[_c('prd-translate-text',{attrs:{"inline":true,"translate-key":"SETTINGS_LANGUAGE"}})],1):_vm._e()],1),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn btn-sm btn-default",attrs:{"type":"button"},on:{"click":_vm.showVersionModal}},[_c('span',{staticClass:"icon-info"})])]),_c('b-modal',{ref:"versionNotesModal",attrs:{"size":"lg","title":'Version ' + _vm.$env().version}},[_c('release-notes'),_c('span',{attrs:{"slot":"modal-ok"},slot:"modal-ok"},[_vm._v(" close ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }