import InsightGroupTotals from './InsightGroupTotals';
import InsightTotals from './InsightTotals';
import MapCellData from './MapCellData';
import MapEdge from './MapEdge';
import MapNode from './MapNode';

export default class MapData {
    public nodes: MapNode[] = [];
    public edges: MapEdge[] = [];
    public external: MapNode[] = [];
    public total?: MapCellData = null;
    public insightTotals?: InsightTotals;
    public groupTotals?: InsightGroupTotals[];
}
