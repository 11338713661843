import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Person } from '../../models/Person';

@Component
export default class MemberNameComponent extends Vue {
    @Prop({ default: () => new Person() }) public member: Person;
    public memberObj: Person = null;
    public isLoaded: boolean = false;

    public mounted() {
        this.memberObj = new Person(this.member);
        this.isLoaded = true;
    }
}
