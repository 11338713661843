import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { teamHelper, teamService, ecosystemHelper } from '@/main';
import BaseEcosystemDirectory from './BaseEcosystemDirectory';
import Group from '@/models/Group';
import { orderBy } from '@progress/kendo-data-query';
import { periodModule } from '@/store/modules/period';

@Component
export default class EcosystemGroupsComponent extends BaseEcosystemDirectory {
    public allGroups: Group[] = [];
    public groupsColumns: any[] = [
        { field: 'name', title: 'NAME', width: 300 },
        { cell: this.renderTeam, title: 'TEAM', width: 300 },
        { field: 'purpose', title: 'GROUP_PURPOSE' },
        { cell: this.renderMembersEcosystem, title: 'GROUP_MEMBERS', width: 125 },
        { cell: this.renderGroupLead, title: 'GROUP_LEAD', width: 300 },
    ];

    public async created() {
        const self = this;

        this.teams = await teamService.getTeams();

        for (const team of this.teams) {
            team.groups.forEach((group) => {
                group.teamId = team.teamId;
                this.groups.push(group);
            });
        }

        this.allGroups = this.groups = orderBy(this.groups, [{ field: 'name', dir: 'asc' }]);
        self.isLoading = false;
    }

    public updateGridData(dataItems) {
        this.groups = orderBy(dataItems, [{ field: 'name', dir: 'asc' }]);
        this.refreshGrid++;
    }

    public renderGroupLead(h, _, row): any {
        if (!row.dataItem.groupLead) {
            return h('td', 'Not yet set');
        }

        return this.renderMember(h, row.dataItem.groupLead);
    }

    public renderMembersEcosystem(h, _, row): any {
        const props = { teamId: row.dataItem.teamId, groupId: row.dataItem.groupId, lazy: true, memberCount: row.dataItem.memberCount };
        return h(Vue.component('members-component'), { props });
    }

    public renderRounterLink(h, _, row) {
        const routeUrl = this.$router.resolve({
            name: 'team-directory-group',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                groupKey: row.dataItem.groupKey,
                teamId: teamHelper.currentTeam.id.toString(),
                teamKey: teamHelper.currentTeam.key,
                tab: 'members',
                period: periodModule.selectedPeriod,
            },
        }).href;

        const props = {
            text: row.dataItem.name,
            url: routeUrl,
        };

        return h(Vue.component('grid-router-link'), { props });
    }

    public fullname(member: any): string {
        return `${member.firstName} ${member.insertion ? member.insertion : ''} ${member.lastName}`;
    }

    public renderFullname(h, _, row) {
        const member = row.dataItem;
        const name = `${member.firstName} ${member.insertion ? member.insertion : ''} ${member.lastName}`;
        return h('td', [name]);
    }

    public renderTeam(h, _, row) {
        return h('td', this.teams.find((x) => x.teamId === row.dataItem.teamId).name);
    }
}
