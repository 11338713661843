import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class InsightsEcosystemFinanceTotalsComponent extends Vue {
    @Prop({ default: () => null }) public data: any;
    @Prop({
        default: () => {
            return { teamId: null, expanded: false };
        },
    })
    public team: any;
    @Prop({ default: () => null }) public tableData: any;
    @Prop({ default: () => null }) public lense: any;

    public tableColumns: any[] = [
        {
            filterable: false,
            cell: this.renderStartBalance,
            title: 'START_BALANCE',
            field: 'startBalance',
            width: 160,
        },
        { filterable: false, cell: this.renderBalance, title: 'BALANCE', field: 'balance', width: 160 },
        { filterable: false, cell: this.renderResult, title: 'RESULT', width: 160, field: 'result' },
        {
            filterable: false,
            cell: this.renderExpenses,
            title: 'EXPENSES_RECURRING',
            width: 160,
            field: 'expenses',
        },
        {
            filterable: false,
            cell: this.renderExtras,
            title: 'EXTRA_NON_RECURRING',
            width: 160,
            field: 'extras',
        },
        {
            filterable: false,
            cell: this.renderExternalRevenue,
            title: 'EXTERNAL_REVENUE',
            width: 160,
            field: 'externalRevenue',
        },
        { filterable: false, cell: this.renderDropDeadRatio, title: 'DROP_DEAD_RATIO', width: 150 },
    ];

    public teamTableColumns: any[] = [
        {
            filterable: false,
            cell: this.renderStartBalance,
            title: 'START_BALANCE',
            field: 'startBalance',
            width: 160,
        },
        { filterable: false, cell: this.renderBalance, title: 'BALANCE', field: 'balance', width: 160 },
        { filterable: false, cell: this.renderResult, title: 'RESULT', width: 160, field: 'result' },
        {
            filterable: false,
            cell: this.renderExpenses,
            title: 'EXPENSES_RECURRING',
            width: 160,
            field: 'expenses',
        },
        {
            filterable: false,
            cell: this.renderExtras,
            title: 'EXTRA_NON_RECURRING',
            width: 160,
            field: 'extras',
        },
        {
            field: 'openInvoiceAmount',
            title: 'OPEN_INVOICES',
            filterable: false,
            cell: this.renderOpenInvoiceAmount,
            width: 160,
        },
        {
            field: 'openExpenseAmount',
            title: 'OPEN_EXPENSES',
            filterable: false,
            cell: this.renderOpenExpensesAmount,
            width: 160,
        },
        {
            field: 'groupOpenInvoiceAmount',
            title: 'OPEN_INVOICES_GROUP',
            filterable: false,
            cell: this.renderGroupOpenInvoiceAmount,
            width: 160,
        },
        {
            field: 'groupOpenExpenseAmount',
            title: 'OPEN_EXPENSES_GROUP',
            filterable: false,
            cell: this.renderGroupOpenExpensesAmount,
            width: 160,
        },
        {
            filterable: false,
            cell: this.renderExternalRevenue,
            title: 'EXTERNAL_REVENUE',
            width: 160,

            field: 'externalRevenue',
        },
        { filterable: false, cell: this.renderDropDeadRatio, title: 'DROP_DEAD_RATIO', width: 150 },
    ];

    public getBalance() {
        return Vue.filter('number-format')(this.data ? this.data.balance : 0);
    }

    public getStartBalance() {
        return Vue.filter('number-format')(this.data ? this.data.startBalance : 0);
    }

    public getResult() {
        return Vue.filter('number-format')(this.data ? this.data.result : 0);
    }

    public getExternalRevenue() {
        return Vue.filter('number-format')(this.data ? this.data.externalRevenue : 0);
    }

    public getExpenses() {
        return Vue.filter('number-format')(this.data ? this.data.expenses : 0);
    }

    public getNonRecurringExpenses() {
        return Vue.filter('number-format')(this.data ? this.data.nonRecurringExpenses : 0);
    }

    public getSafetyZone() {
        return Vue.filter('number-format')(this.data ? this.data.safetyZone : 0);
    }

    public renderBalance(h, _, row) {
        const props = { value: row.dataItem.balance };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderStartBalance(h, _, row) {
        const props = { value: row.dataItem.startBalance };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderExpenses(h, _, row) {
        const props = { value: row.dataItem.expenses };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderResult(h, _, row) {
        const props = { value: row.dataItem.result };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderExtras(h, _, row) {
        const props = { value: row.dataItem.extras };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderExternalRevenue(h, _, row) {
        const props = { value: row.dataItem.externalRevenue };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderOpenInvoiceAmount(h, _, row) {
        const teamOpenInvoices = row.dataItem.openInvoiceAmount as number;
        const props = { value: teamOpenInvoices };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderOpenExpensesAmount(h, _, row) {
        const teamOpenExpenses = row.dataItem.openExpenseAmount as number;
        const props = { value: teamOpenExpenses };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderGroupOpenInvoiceAmount(h, _, row) {
        const groupOpenInvoiceAmount = row.dataItem.groupOpenInvoiceAmount as number;
        const props = { value: groupOpenInvoiceAmount };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderGroupOpenExpensesAmount(h, _, row) {
        const groupOpenExpenseAmount = row.dataItem.groupOpenExpenseAmount as number;
        const props = { value: groupOpenExpenseAmount };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderDropDeadRatio(h, _, row) {
        if (row.dataItem.safetyZone) {
            const props = { value: row.dataItem.safetyZone };
            return h(Vue.component('number-formatter'), { props });
        } else {
            return h('td', ['N/A']);
        }
    }
}
