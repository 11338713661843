import { InviteResponseInterface } from './Interfaces';
import { Person } from './Person';
import Invite from './Invite';
import Team from './Team';

export default class InviteResponse implements InviteResponseInterface {
    public sender: Person = new Person();
    public receiver: Person = new Person();
    public team: Team = new Team();
    public invite: Invite = new Invite();
}
