import { AxiosHelper } from '../helpers/axiosHelper';
import { BaseService } from './baseService';
import Vue from 'vue';
import TeamSettings from '@/models/TeamSettings';

export class SettingService extends BaseService {
    private endpoint = `${Vue.$env().financeEndpoint}`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public saveTeamSettings(teamId: number, teamFinanceSettings: TeamSettings) {
        return this.post<any>(
            `${this.endpoint}team-settings?teamId=:team`,
            {
                defaultGroupPrivacyLevel: teamFinanceSettings.defaultGroupPrivacyLevel,
                allExpensesPrivacyLevel: teamFinanceSettings.allExpensesPrivacyLevel,
                memberResultsPrivacyLevel: teamFinanceSettings.memberResultsPrivacyLevel,
            },
            [['team', teamId]],
        );
    }

    public getTeamSettings(teamId: number) {
        return this.get<TeamSettings>(`${this.endpoint}team-settings?teamId=:team`, [['team', teamId]]);
    }
}
