import { Person } from '../Person';
import Team from '../Team';

export default class InsightView {
    public insightViewId: number;
    public name: string;

    public teams: Team[] = [];
    public persons: Person[] = [];
    public roles: any[] = [];
    public includeOwnTeams: boolean = false;

    constructor(init?: Partial<InsightView>) {
        Object.assign(this, init);
    }
}
