import { CellIndicatorScore } from './CellIndicatorScore';
import { CellScore } from './CellScore';

export default class MapCellData {
    public id?: number | string = null;
    public name = '';
    public subtitle = '';
    public title = '';
    public labels: string[] = [];
    public values: string[] = [];
    public indicatorScore: CellIndicatorScore = CellIndicatorScore.Unkown;
    public colorScore: CellScore = CellScore.Unknown;
}
