declare global {
  interface String {
    /**
     * Returns the string with the first character changed to uppercase.
     */
    firstCharToUpper(): string;

    replaceAt(index: number, replacement: string): string;
  }
}

String.prototype.firstCharToUpper = function f(this: string): string {
  if (!!this && !!this[0]) {
    return this[0].toUpperCase() + this.slice(1);
  }

  return '';
};

String.prototype.replaceAt = function(this: string, index, replacement) {
  return `${this.substr(0, index)}${replacement}${this.substr(index + replacement.length)}`;
};

export {};
