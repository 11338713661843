import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import to from 'await-to-js';
import { resultService, teamService, $router, roleHelper, periodService, loginHelper, ecosystemHelper, teamHelper, financeService } from '@/main';
import PageRender from '../../models/PageRender';
import jQuery from 'jquery';
import TeamResults from '@/models/Finance/Api/TeamResults';
import { orderBy } from '@progress/kendo-data-query';
import Team from '@/models/Team';
import { periodModule } from '@/store/modules/period';
import EcosystemResults from '@/models/Finance/Api/TeamResults';
import { Invoice } from '@/models/Invoice';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import moment from 'moment';
import { InvoiceType } from '@/models/InvoiceType';

@Component
export default class EcosystemResultsComponent extends PageRender {
    public ecosystemResults: EcosystemResults = null;

    public currentTabComponent: string;
    public currentTab: string = null;
    public currentTeamData: any = new TeamResults();
    public previousCurrentTeamData: any = null;
    public isLoading: boolean = true;
    public numberOfMonths: number = 1;

    public teams: Team[] = [];

    public totalBalance: string = '';
    public totalResult: string = '';
    public totalExternalRevenue: string = '';
    public totalExtras: string = '';
    public totalExpenses: string = '';
    public expensesType: boolean = false;
    public includeDistributions: boolean = false;

    public previousTotalBalance: string = '';
    public previousTotalResult: string = '';
    public previousTotalExternalRevenue: string = '';
    public previousTotalExpenses: string = '';
    public previousTotalExtras: string = '';

    public reloadingResults: boolean = true;
    public reloadGrids: number = 0;
    public loadingGrids: boolean = false;

    public selectedTeams: number[] = [];
    public selectedTeam: number = null;
    public selectedLegalEntity: string = null;

    public updateTotals: number = 0;
    public teamPeriodStatusses: any = [];

    public invoicesColumns = [
        { title: 'INVOICE_ID', field: 'invoiceId' },
        { title: 'NAME', field: 'name', width: 300 },
        { title: 'DESCRIPTION', field: 'description', width: 300 },
        { title: 'AMOUNT', field: 'amount', cell: this.formatAmount },
        { title: 'REFERENCE', field: 'reference' },
        { title: 'INVOICE_DATE', field: 'invoiceDate', cell: this.renderInvoiceDate }, // cell: this.renderInvoiceDate },
        { title: 'INVOICE_TYPE', field: 'invoiceType' },
        { title: 'IS_RECURRING', field: 'isRecurring' },
        { title: 'LEGAL_ENTITY_REF', field: 'legalEntityReferenceOrigin', width: 180 },
        { title: 'PERIOD', field: 'periodId' },
        { title: 'SOURCE', field: 'source' },
        { title: 'TEAM', field: 'team' },
    ];

    public exportInvoicesGridColumns = [
        { title: 'INVOICE_ID', field: 'invoiceId' },
        { title: 'AMOUNT', field: 'amount' },
        { title: 'NAME', field: 'name' },
        { title: 'DESCRIPTION', field: 'description' },
        { title: 'REFERENCE', field: 'reference' },
        { title: 'INVOICE_DATE', field: 'invoiceDate' },
        { title: 'INVOICE_TYPE', field: 'invoiceType' },
        { title: 'IS_RECURRING', field: 'isRecurring' },
        { title: 'LEGAL_ENTITY_REF', field: 'legalEntityReferenceOrigin' },
        { title: 'PERIOD', field: 'periodId' },
        { title: 'SOURCE', field: 'source' },
        { title: 'TEAM', field: 'team' },
    ];

    public exportDistributedInvoicesGridColumns = [
        { title: 'INVOICE_ID', field: 'invoiceId' },
        { title: 'AMOUNT', field: 'amount' },
        { title: 'NAME', field: 'name' },
        { title: 'DESCRIPTION', field: 'description' },
        { title: 'REFERENCE', field: 'reference' },
        { title: 'INVOICE_DATE', field: 'invoiceDate' },
        { title: 'INVOICE_TYPE', field: 'invoiceType' },
        { title: 'IS_RECURRING', field: 'isRecurring' },
        { title: 'LEGAL_ENTITY_REF', field: 'legalEntityReferenceOrigin' },
        { title: 'PERIOD', field: 'periodId' },
        { title: 'SOURCE', field: 'source' },
        { title: 'TEAM', field: 'team' },
        { title: 'GROUP', field: 'group' },
        { title: 'OPEN_AMOUNT', field: 'openAmount' },
    ];

    public invoices: Invoice[] = [];
    public invoicesGrid: number = 0;

    public groupsData: any = [];
    public groupsDataColumns = [
        { field: 'group.name', title: 'GROUP_NAME', cell: this.renderEcosystemGroupLink, width: 300, locked: true },
        { field: 'startBalance', format: '{0:n2}', title: 'START_BALANCE', width: 150, footerCell: this.renderGroupResultsFooterCellSum },
        { field: 'balance', format: '{0:n2}', title: 'BALANCE', width: 150, footerCell: this.renderGroupResultsFooterCellSum },
        { field: 'income', format: '{0:n2}', title: 'INCOME', width: 150, footerCell: this.renderGroupResultsFooterCellSum },
        { field: 'totalCosts', format: '{0:n2}', title: 'TOTAL_COSTS', width: 150, footerCell: this.renderGroupResultsFooterCellSum },
        { field: 'result', format: '{0:n2}', title: 'RESULT', width: 150, footerCell: this.renderGroupResultsFooterCellSum },
        { field: 'expenses', format: '{0:n2}', title: 'EXPENSES', width: 150, footerCell: this.renderGroupResultsFooterCellSum },
        { field: 'nonRecurringExpenses', format: '{0:n2}', title: 'EXTRAS', width: 150, footerCell: this.renderGroupResultsFooterCellSum },
        { field: 'openInvoiceAmount', format: '{0:n2}', title: 'OPEN_INVOICES', width: 150, footerCell: this.renderGroupResultsFooterCellSum },
        { field: 'openExpenseAmount', format: '{0:n2}', title: 'OPEN_EXPENSES', width: 150, footerCell: this.renderGroupResultsFooterCellSum },
        { field: 'externalRevenue', format: '{0:n2}', title: 'EXTERNAL_REVENUE', width: 150, footerCell: this.renderGroupResultsFooterCellSum },
        { field: 'attributions', format: '{0:n2}', title: 'ATTRIBUTIONS', width: 150, footerCell: this.renderGroupResultsFooterCellSum },
        { field: 'compensations', format: '{0:n2}', title: 'COMPENSATIONS', width: 150, footerCell: this.renderGroupResultsFooterCellSum },
        { field: 'transferredAmount', format: '{0:n2}', title: 'TRANSFERRED_AMOUNT', width: 150, footerCell: this.renderGroupResultsFooterCellSum },
    ];

    public membersData: any = [];
    public membersDataColumns = [
        { field: 'member', cell: this.renderMemberResult, title: 'MEMBER', width: 300, locked: true },
        { field: 'income', format: '{0:n2}', title: 'INCOME', width: 150, footerCell: this.renderMemberResultsFooterCellSum },
        { field: 'totalCosts', format: '{0:n2}', title: 'TOTAL_COSTS', width: 150, footerCell: this.renderMemberResultsFooterCellSum },
        { field: 'result', format: '{0:n2}', title: 'RESULT', width: 150, footerCell: this.renderMemberResultsFooterCellSum },
        { field: 'balance', format: '{0:n2}', title: 'BALANCE', width: 150, footerCell: this.renderMemberResultsFooterCellSum },
        { field: 'extras', format: '{0:n2}', title: 'EXTRAS', width: 150, footerCell: this.renderMemberResultsFooterCellSum },
        { field: 'expenses', format: '{0:n2}', title: 'EXPENSES', width: 150, footerCell: this.renderMemberResultsFooterCellSum },
    ];

    public overviewTeamColumns: any[] = [
        { field: 'teamName', title: 'TEAM', width: 300, filterable: false, cell: this.renderTeamNameWithPeriod, locked: true, className: 'bg-white' },
        {
            filterable: false,
            cell: this.renderStartBalance,
            title: 'START_BALANCE',
            field: 'startBalance',
            width: 160,
            footerCell: this.renderFooterCellSum,
        },
        { filterable: false, cell: this.renderBalance, title: 'BALANCE', field: 'balance', width: 160, footerCell: this.renderFooterCellSum },
        { filterable: false, cell: this.renderResult, title: 'RESULT', width: 160, footerCell: this.renderFooterCellSum, field: 'result' },
        {
            filterable: false,
            cell: this.renderExpenses,
            title: 'EXPENSES_RECURRING',
            width: 160,
            footerCell: this.renderFooterCellSum,
            field: 'expenses',
        },
        {
            filterable: false,
            cell: this.renderExtras,
            title: 'EXTRA_NON_RECURRING',
            width: 160,
            footerCell: this.renderFooterCellSum,
            field: 'extras',
        },
        {
            field: 'openInvoiceAmount',
            title: 'OPEN_INVOICES',
            filterable: false,
            cell: this.renderOpenInvoiceAmount,
            width: 160,
            footerCell: this.renderFooterCellSum,
        },
        {
            field: 'openExpenseAmount',
            title: 'OPEN_EXPENSES',
            filterable: false,
            cell: this.renderOpenExpensesAmount,
            width: 160,
            footerCell: this.renderFooterCellSum,
        },
        {
            field: 'groupOpenInvoiceAmount',
            title: 'OPEN_INVOICES_GROUP',
            filterable: false,
            cell: this.renderGroupOpenInvoiceAmount,
            width: 160,
            footerCell: this.renderFooterCellSum,
        },
        {
            field: 'groupOpenExpenseAmount',
            title: 'OPEN_EXPENSES_GROUP',
            filterable: false,
            cell: this.renderGroupOpenExpensesAmount,
            width: 160,
            footerCell: this.renderFooterCellSum,
        },
        {
            filterable: false,
            cell: this.renderExternalRevenue,
            title: 'EXTERNAL_REVENUE',
            width: 160,
            footerCell: this.renderFooterCellSum,
            field: 'externalRevenue',
        },
        { filterable: false, cell: this.renderDropDeadRatio, title: 'DROP_DEAD_RATIO', width: 150 },
    ];

    private currentDates: any = null;

    public async created() {
        if (!roleHelper.isSiteAdmin() && !roleHelper.isFinanceEmployee()) {
            await $router.push({ name: 'not-found' });
        }

        this.teams = await teamService.getTeams();

        this.teamPeriodStatusses = await periodService.getTeamPeriodStatusses();

        this.currentTab = 'invoices';
        this.isLoading = false;
    }

    public async recalculateResultsIncome(dates) {
        this.reloadingResults = true;
        this.currentDates = dates;

        const results = await resultService.getEcosystemResults(dates.fromFilterMonth, dates.toFilterMonth, this.selectedTeams);
        if (dates.fromFilterMonth !== dates.toFilterMonth) {
            this.numberOfMonths = 0;
        }

        this.ecosystemResults = results;
        this.reloadingResults = false;
        this.setTotals();

        this.loadGridData(this.currentTab);
    }

    public async reloadInvoices(dates) {
        this.reloadingResults = true;
        if (this.currentTab !== 'invoices') {
            return;
        }

        this.currentDates = dates;

        const results = await financeService.searchInvoices(
            dates.fromFilterMonth,
            dates.toFilterMonth,
            this.selectedTeam,
            this.selectedLegalEntity,
            this.expensesType ? InvoiceType.Expenses : InvoiceType.Income,
            this.includeDistributions,
        );

        if (this.includeDistributions && !this.invoicesColumns.find((x) => x.field === 'openAmount')) {
            this.invoicesColumns.splice(4, 0, {
                title: 'Open',
                field: 'openAmount',
                cell: this.renderOpenAmount,
            });
        } else if (!this.includeDistributions) {
            this.invoicesColumns = this.invoicesColumns.filter((x) => x.field !== 'openAmount');
        }

        this.invoices = results;
        this.reloadingResults = false;
        this.invoicesGrid++;
    }

    public async exportInvoices() {
        if (this.includeDistributions) {
            return this.exportDistributedInvoices();
        }

        saveExcel({
            data: this.invoices,
            fileName: `Team-${teamHelper.currentTeamId}-invoices-${moment().format('YYYY-MM-DD HH:mm:')}`,
            columns: this.exportInvoicesGridColumns,
        });
    }

    public exportDistributedInvoices() {
        const invoices = [];

        this.invoices.forEach((invoice) => {
            invoice.distributions.forEach((dist: any) => {
                invoices.push({
                    ...invoice,
                    group: dist.groupName,
                    openAmount: dist.openAmount,
                });
            });
        });

        saveExcel({
            data: invoices,
            fileName: `Team-${teamHelper.currentTeamId}-invoices-${moment().format('YYYY-MM-DD HH:mm:')}`,
            columns: this.exportDistributedInvoicesGridColumns,
        });
    }

    public filterOnMembership(teams: Team[]) {
        const member = loginHelper.getUser();
        const myTeams = [];

        for (const team of teams) {
            if (member.teamMemberships.find((x) => x.id === team.teamId)) {
                myTeams.push(team);
            }
        }

        return myTeams;
    }

    public setTotals() {
        this.totalBalance = Vue.filter('number-format')(this.ecosystemResults.balance);
        this.totalResult = Vue.filter('number-format')(this.ecosystemResults.result);
        this.totalExternalRevenue = Vue.filter('number-format')(this.ecosystemResults.externalRevenue);
        this.totalExpenses = Vue.filter('number-format')(this.ecosystemResults.expenses);
        this.totalExtras = Vue.filter('number-format')(this.ecosystemResults.extras);
    }

    public renderBalance(h, _, row) {
        const props = { value: row.dataItem.balance };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderStartBalance(h, _, row) {
        const props = { value: row.dataItem.startBalance };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderExpenses(h, _, row) {
        const props = { value: row.dataItem.expenses };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderResult(h, _, row) {
        const props = { value: row.dataItem.result };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderExtras(h, _, row) {
        const props = { value: row.dataItem.extras };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderExternalRevenue(h, _, row) {
        const props = { value: row.dataItem.externalRevenue };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderOpenInvoiceAmount(h, _, row) {
        const teamOpenInvoices = row.dataItem.openInvoiceAmount as number;
        const props = { value: teamOpenInvoices };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderOpenExpensesAmount(h, _, row) {
        const teamOpenExpenses = row.dataItem.openExpenseAmount as number;
        const props = { value: teamOpenExpenses };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderGroupOpenInvoiceAmount(h, _, row) {
        const groupOpenInvoiceAmount = row.dataItem.groupOpenInvoiceAmount as number;
        const props = { value: groupOpenInvoiceAmount };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderGroupOpenExpensesAmount(h, _, row) {
        const groupOpenExpenseAmount = row.dataItem.groupOpenExpenseAmount as number;
        const props = { value: groupOpenExpenseAmount };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderDropDeadRatio(h, _, row) {
        if (row.dataItem.safetyZone) {
            const props = { value: row.dataItem.safetyZone };
            return h(Vue.component('number-formatter'), { props });
        } else {
            return h('td', ['N/A']);
        }
    }

    public async getTeamPeriods(teamId: number) {
        const [err, periods] = await to(periodService.getPeriods(teamId));
        if (err) {
            this.showFailedResponse('Failed to load team periods', null);
        }

        return periods;
    }

    public loadGridData(currentTab: string) {
        if (currentTab === 'totals' || currentTab === 'invoices') {
            return;
        }

        const foundTeam = this.teams.find((x) => x.teamKey === currentTab);
        this.currentTeamData = this.ecosystemResults.teamResults.find((team) => team.teamId === foundTeam.teamId);

        if (this.currentTeamData) {
            const groupDataSorted = orderBy(this.currentTeamData.groupResults, [{ field: 'name', dir: 'asc' }]);
            this.groupsData = groupDataSorted;

            const memberDataSorted = orderBy(this.currentTeamData.memberResults, [{ field: 'name', dir: 'asc' }]);
            this.membersData = memberDataSorted;
        } else {
            this.membersData = [];
            this.groupsData = [];
        }

        this.loadingGrids = false;
    }

    public teamOptions() {
        return this.teams.map((team) => {
            return { text: team.name, value: team.teamId };
        });
    }

    public legalEntitiesOptions() {
        const teams = this.teams.filter((x) => x.legalEntityReference);

        const list = [];

        teams.forEach((team) => {
            if (!list.find((y) => y.value === team.legalEntityReference)) {
                list.push({ text: `${team.legalEntityReference}`, value: team.legalEntityReference });
            }
        });

        return list;
    }

    public getEcologyOverviewData() {
        this.ecosystemResults.teamResults.forEach((team) => {
            const apiTeam = this.teams.find((x) => x.teamId === team.teamId);
            if (apiTeam) {
                team.name = apiTeam.name;
            } else {
                team.name = `team name non-found (teamId: ${team.teamId})`;
            }
        });

        return orderBy(this.ecosystemResults.teamResults, [{ field: 'name', dir: 'asc' }]);
    }

    public getTeamBalance() {
        return Vue.filter('number-format')(this.currentTeamData ? this.currentTeamData.balance : 0);
    }

    public getTeamResult() {
        return Vue.filter('number-format')(this.currentTeamData ? this.currentTeamData.result : 0);
    }

    public getTeamOpenInvocies() {
        return Vue.filter('number-format')(this.currentTeamData ? this.currentTeamData.openInvoiceAmount : 0);
    }

    public getTeamOpenExpenses() {
        return Vue.filter('number-format')(this.currentTeamData ? this.currentTeamData.openExpenseAmount * -1 : 0);
    }

    public getTeamExpenses() {
        return Vue.filter('number-format')(this.currentTeamData ? this.currentTeamData.expenses * -1 : 0);
    }

    public getGroupOpenExpenses() {
        return Vue.filter('number-format')(this.currentTeamData ? this.currentTeamData.groupOpenExpenseAmount * -1 : 0);
    }

    public getGroupOpenInvoices() {
        return Vue.filter('number-format')(this.currentTeamData ? this.currentTeamData.groupOpenInvoiceAmount : 0);
    }

    public getTeamExtras() {
        return Vue.filter('number-format')(this.currentTeamData ? this.currentTeamData.extras * -1 : 0);
    }

    public getDropDeadRatio() {
        if (this.currentTeamData && this.currentTeamData.safetyZone) {
            return Vue.filter('number-format')(this.currentTeamData.safetyZone);
        }

        return 'N/A';
    }

    public async changeTab(tab: string) {
        if (!this.teams) {
            return;
        }

        this.doActiveClass(tab);
        this.currentTab = tab;
        this.loadingGrids = true;

        if (this.currentTab !== 'invoices') {
            if (!this.ecosystemResults) {
                await this.recalculateResultsIncome(this.currentDates);
            }
        }

        if (this.currentTab !== 'totals' && this.currentTab !== 'invoices') {
            this.$nextTick(() => {
                this.loadGridData(this.currentTab);
            });
        } else {
            this.reloadGrids++;
            this.loadingGrids = false;
        }
    }

    public doActiveClass(tab: string) {
        this.$nextTick(() => {
            jQuery('.nav-link').removeClass('active');
            jQuery('.' + tab).addClass('active');
        });
    }

    public renderMemberResult(h, _row, item: any): any {
        return h('td', [item.dataItem.name]);
    }

    public calculateGroupGridHeight() {
        return { height: `${100 + (this.currentTeamData ? this.currentTeamData.groupResults.length : 1) * 40}px` };
    }

    public calculateMemberGridHeight() {
        return { height: `${100 + (this.currentTeamData ? this.currentTeamData.memberResults.length : 1) * 40}px` };
    }

    public calculateTotalsHeight() {
        return { height: `${100 + this.ecosystemResults.teamResults.length * 40}px` };
    }

    public renderFooterCellSum(h, _, row) {
        const total = this.ecosystemResults.teamResults.reduce((acc: number, current) => acc + (current[row.field] as number), 0);
        const props = { value: total };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderMemberResultsFooterCellSum(h, _, row) {
        const total = this.membersData.reduce((acc: number, current) => acc + (current[row.field] as number), 0);
        const props = { value: total };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderGroupResultsFooterCellSum(h, _, row) {
        const total = this.groupsData.reduce((acc: number, current) => acc + (current[row.field] as number), 0);
        const props = { value: total };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderOpenAmount(h, _, row) {
        let openAmount = 0;

        openAmount = row.dataItem.distributions.reduce((a, b) => a + b.openAmount, 0);

        const props = { value: openAmount };
        return h(Vue.component('number-formatter'), { props });
    }

    public formatAmount(h, _, row) {
        const props = { value: row.dataItem.amount };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderInvoiceDate(h, _, row) {
        return h('td', [moment(row.dataItem.invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY')]);
    }

    private renderTeamNameWithPeriod(h, _, row) {
        const teamId = row.dataItem.teamId;
        const periodStatus = this.teamPeriodStatusses.find((team) => team.teamId === teamId);
        const foundTeam = this.teams.find((team) => team.teamId === teamId);

        if (!periodStatus || !foundTeam) {
            return h('td', [`Team not found (teamId: ${teamId})`]);
        }

        return h('td', [`${foundTeam.name} ${periodStatus && periodStatus.closed ? '(closed)' : '(open)'}`]);
    }

    private renderEcosystemGroupLink(h, _, row): any {
        const groupId = row.dataItem.groupId;
        const groupName = row.dataItem.name;
        const groupKey = row.dataItem.groupKey;
        let group;

        let team = this.teams.find((x) => {
            return x.groups.filter((y) => y.groupId === groupId).length > 0;
        });

        if (team) {
            group = team.groups.find((x) => x.groupId === groupId);
        } else {
            team = this.teams.find((x) => x.teamKey === this.currentTab);
        }

        teamHelper.setTeamByTeamId(team.teamId);

        const routeUrl = $router.resolve({
            name: 'team-finances-group',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                groupKey: groupKey ? groupKey : group ? group.groupKey : `not-found`,
                groupId,
                teamId: teamHelper.currentTeam.id.toString(),
                teamKey: teamHelper.currentTeam.key,
                tab: 'insights',
                period: periodModule.selectedPeriod,
            },
        }).href;

        const props = {
            text: groupName ? groupName : group ? group.name : `Group not found (id: ${groupId})`,
            url: routeUrl,
        };

        return h(Vue.component('grid-router-link'), { props });
    }
}
