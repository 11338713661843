import { attributionService, groupService, teamHelper, teamService, templateService } from '@/main';
import Attribution from '@/models/Attribution';
import { AttributionType } from '@/models/AttributionType';
import Template from '@/models/Finance/Template';
import Group from '@/models/Group';
import { PrivacyLevel } from '@/models/PrivacyLevel';
import Team from '@/models/Team';
import TeamSettings from '@/models/TeamSettings';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { periodModule } from '@/store/modules/period';
import { settingsModule } from '@/store/modules/settings';
import to from 'await-to-js';
import { Component } from 'vue-property-decorator';
import { required, minValue, maxValue, not } from 'vuelidate/lib/validators';

@Component({
    validations: {
        template: {
            name: { required },
            description: { required },
            privacyLevel: {
                not: not((model) => {
                    return model === PrivacyLevel.Unknown;
                }),
            },

            attributions: {
                $each: {
                    percentage: { required, minValue: minValue(0.01), maxValue: maxValue(100) },
                    // targetTeamModel: { required },
                    // targetGroupModel: { required },
                },
            },
        },
    },
} as any)
export default class TemplateActionComponent extends SideActionBase<any> {
    public isEdit: boolean = false;
    public isLoading: boolean = true;
    public submitted: boolean = false;

    public template: Template = new Template();

    public teams: Team[] = [];
    public groups: Group[] = [];
    public copingLoading: boolean = false;
    public importGroupAttributionGroupId?: number = null;

    public remittances: Attribution[] = [];
    public groupAttributions: Attribution[] = [];

    public teamFinanceSettings: TeamSettings = {
        defaultGroupPrivacyLevel: PrivacyLevel.Unknown,
        allExpensesPrivacyLevel: PrivacyLevel.Unknown,
        memberResultsPrivacyLevel: PrivacyLevel.Unknown,
    };

    public roleOptions: any[] = [
        { text: 'Site Admin', value: 'SiteAdmin' },
        { text: 'Finance Employee', value: 'FinanceEmployee' },
        { text: 'Group Lead', value: 'GroupLead' },
        { text: 'Member', value: 'Member' },
    ];

    public async mounted() {
        this.isEdit = this.options.template && this.options.template.financeGroupTemplateId && this.options.template.financeGroupTemplateId > 0;

        this.teams = await teamService.getTeams();

        await this.loadTeamFinanceSettings();

        this.groups = this.teams.find((x) => x.teamId === teamHelper.getTeamId()).groups;

        if (this.isEdit) {
            Object.assign(this.template, this.options.template);

            const groupAttributions = this.options.template.attributions.filter((x) => x.attributionType === AttributionType.GroupAttribution);
            const attrs = groupAttributions.map((item) => {
                const attr = new Attribution(0);
                Object.assign(attr, item);

                attr.targetTeamModel = this.teams.find((team) => {
                    if (item.targetTeam) {
                        return team.teamId === item.targetTeam;
                    }

                    return team.teamId === teamHelper.getTeamId();
                });

                return attr;
            });

            attrs.forEach(async (attribution) => {
                attribution.groups = await this.getTeamGroups(attribution.targetTeamModel);

                attribution.targetGroupModel = attribution.groups.find((group) => {
                    return group.groupId === attribution.targetGroup;
                });
            });

            this.groupAttributions = attrs;

            this.remittances = this.options.template.attributions.filter((x) => x.attributionType === AttributionType.GroupRemittance);
        }

        this.isLoading = false;
    }

    public async addGroupAttribution() {
        const attribution = new Attribution(0);
        attribution.attributionType = AttributionType.GroupAttribution;
        attribution.targetTeamModel = this.teams.find((team) => {
            return team.teamId === teamHelper.getTeamId();
        });

        attribution.groups = await this.getTeamGroups(attribution.targetTeamModel);

        this.groupAttributions.push(attribution);
    }

    public addRemittanceAttribution(): any {
        const attribution = new Attribution(0);
        attribution.attributionType = AttributionType.GroupRemittance;
        this.remittances.push(attribution);
    }

    public deleteRemittanceAttribution(item: Attribution) {
        this.remittances.splice(this.remittances.indexOf(item), 1);
    }

    public deleteGroupAttribution(item: Attribution) {
        this.groupAttributions.splice(this.groupAttributions.indexOf(item), 1);
    }

    public async teamChange(attribution: Attribution) {
        attribution.targetGroup = null;
        attribution.groups = await this.getTeamGroups(attribution.targetTeamModel);
    }

    public async getTeamGroups(team: Team) {
        const [err, response] = await to(groupService.getGroups(team.teamId, periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse(`Failed to load groups for team ${team.teamKey}`, null);
        }

        return response.data;
    }

    public async importGroupAttribution() {
        this.copingLoading = true;
        const currentTeamId = teamHelper.getTeamId(teamHelper.currentTeamId);

        await attributionService
            .getAttributions(AttributionType.GroupAttribution, currentTeamId, periodModule.selectedPeriod, this.importGroupAttributionGroupId)
            .then((result) => {
                result.data.forEach(async (attribution: Attribution) => {
                    attribution.attributionId = undefined;
                    // attribution.group = self.group.groupId;
                    const foundTeam = this.teams.find((team) => team.teamId === attribution.targetTeam);
                    attribution.targetTeamModel = foundTeam;
                    const groups = (await teamService.getGroups(foundTeam.teamId, periodModule.selectedPeriod)).data;
                    attribution.targetGroupModel = groups.find((group) => group.groupId === attribution.targetGroup);
                    attribution.groups = groups;

                    this.groupAttributions.push(attribution);
                });
            });

        this.copingLoading = false;
    }

    public async loadTeamFinanceSettings() {
        const currentTeamId = teamHelper.getTeamId(teamHelper.currentTeamId);
        await settingsModule.fetchTeamSettingsIfNeeded(currentTeamId);
        return (this.teamFinanceSettings = settingsModule.findTeamFinanceSettings(currentTeamId));
    }

    public async createTemplate(e) {
        this.submitted = true;

        this.template.attributions = [];

        this.groupAttributions.forEach((x) => {
            x.targetTeam = x.targetTeamModel.teamId;
            x.targetGroup = x.targetGroupModel.groupId;
        });

        this.template.attributions.push(...this.groupAttributions);

        this.remittances.forEach((x) => {
            x.targetGroup = null;
            x.targetTeam = null;
            x.targetAccount = null;
            x.targetTeamModel = null;
            x.targetGroupModel = null;
        });

        this.template.attributions.push(...this.remittances);

        this.template.attributions.forEach((x) => {
            x.percentage = parseFloat(x.percentage);
        });

        this.template.teamId = teamHelper.currentTeam.id;

        if ((this as any).$v.template.$invalid) {
            return;
        }

        this.showPending('SAVING_TEMPLATE');

        if (this.isEdit) {
            const [errU] = await to(templateService.updateTemplate(this.template));
            if (errU) {
                return this.clearAndShowError('ERROR_SAVING_TEMPLATE', errU);
            }
        } else {
            const [err] = await to(templateService.createTemplate(this.template));
            if (err) {
                return this.clearAndShowError('ERROR_SAVING_TEMPLATE', err);
            }
        }

        this.clearNotifications();
        this.finished(e);
    }

    public async deleteTemplate(e) {
        this.showPending('DELETING_TEMPLATE');
        const [err] = await to(templateService.deleteTemplate(this.template));
        if (err) {
            return this.clearAndShowError('ERROR_DELETING_TEMPLATE', err);
        }

        this.clearNotifications();
        this.finished(e);
    }
}
