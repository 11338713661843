declare global {
    interface Number {
        /**
         * Returns the a rounded value of a number.
         */
        round(numberOfDecimals: number): string;
    }
}

Number.prototype.round = function f(this: number, numberOfDecimals: number): string {
    // tslint:disable-next-line: restrict-plus-operands
    const formattedNumber = +(Math.round(((this + 'e+' + numberOfDecimals) as unknown) as number) + 'e-' + numberOfDecimals);

    return formattedNumber.toFixed(2);
};

export {};
