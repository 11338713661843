import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { $router, teamHelper, ecosystemHelper } from '@/main';
import { periodModule } from '@/store/modules/period';

@Component
export default class MembershipComponent extends Vue {
    @Prop({ default: () => [] }) public memberships: any;
    @Prop({ default: () => false }) public isTeam: boolean;
    @Prop({ default: () => false }) public disableOnClick: boolean;

    public valueRender = 'valueTemplate';
    public itemRender = 'itemTemplate';

    public async go(key: string, id: number) {
        let team = !key ? teamHelper.currentTeam : teamHelper.getTeamByKey(key);
        const groupKey = key;
        const groupId = id;

        if (this.isTeam) {
            team = teamHelper.getTeamByKey(key);
        }

        if (this.isTeam) {
            return $router.push({
                name: 'team-directory',
                params: {
                    ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                    ecosystemKey: ecosystemHelper.currentEcosystem.key,
                    teamId: team.id.toString(),
                    teamKey: team.key,
                    groupkey: groupKey,
                    groupId: groupId.toString(),
                    tab: 'groups',
                    period: periodModule.selectedPeriod,
                },
            });
        }

        const params = {
            teamId: teamHelper.currentTeam.id.toString(),
            teamKey: teamHelper.currentTeam.key,
            groupkey: groupKey,
            groupId: groupId.toString(),
            tab: 'members',
            period: periodModule.selectedPeriod,
        };
        await $router.push({ name: 'team-directory-group', params });
    }

    public memberShipValueTemplate(): string {
        return `<span class="px-2">${this.memberships.length} ${this.isTeam ? 'team' : 'group'}${this.memberships.length > 1 ? 's' : ''}</span>`;
    }

    public async onSelect(item) {
        if (!this.disableOnClick) {
            const membership = item.dataItem;
            await this.go(membership.key, membership.id);
        }
    }
}
