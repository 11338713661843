import { Component } from 'vue-property-decorator';
import { resultService, teamHelper } from '@/main';
import FinanceTeam from '../../../../models/FinanceTeam';
import BaseFinancePage from './BaseFinancePage';
import { periodModule } from '@/store/modules/period';

@Component
export default class TeamFinanceMemberOverview extends BaseFinancePage {
    public memberOverviewData: object[] = [];
    public teamFinance: FinanceTeam;
    public isLoaded: boolean = false;
    public memberResultColumns: any[] = [
        { cell: this.renderMemberWithIcon, title: 'NAME', field: 'firstName' },
        { cell: this.formatResultValue, title: 'RESULT' },
        { cell: this.formatExpensesValue, title: 'EXPENSES' },
        { cell: this.formatExtrasValue, title: 'EXTRAS' },
        { cell: this.formatBalanceValue, title: 'BALANCE' },
    ];

    public async mounted() {
        const period = periodModule.selectedPeriod;

        const results = await resultService.getMemberResults(teamHelper.getTeamId(), period, period);
        this.memberOverviewData = results.items[0].item.memberResults;

        this.isLoaded = true;
    }

    public renderMemberWithIcon(h, _, row) {
        return this.renderMember(h, row.dataItem.member);
    }
}
