import IValidation from './IValidation';

export default class NonAlpha implements IValidation {
    public valid(value: string) {
        return !!value.match(/[\W_]+/g);
    }

    public get message() {
        return 'Must contain a non-alphanumeric character';
    }
}
