import MapCellConfig from './MapCellConfig';

export default class MapConfig {
    public static animationTime = 200;
    public static maxNodes = 100;
    public static maxEdges = 1000;

    public static fontSize = 12;
    public static title = { size: 32, weight: 'bold' };
    public static subTitle = { size: 24 };
    public static text = { size: 20 };
    public static textMutedColor = '#495057';
    public static padding = 16;
    public static green = '#11AA6F';
    public static lightGreen = '#B4DFCC';
    public static orange = '#ffbc00';
    public static lightOrange = '#FBE8AB'
    public static red = '#ff0026';
    public static lightRed = '#F2A7B2';
    public static purple = '#7011AA';
    public static lightPurple = '#DCB4DF';
    public static blue = '#228be6';
    public static lightBlue = '#B7D4F3';

    // Main cell
    public static containerBg = '#fff';
    public static mainCellBg = 'transparent';
    public static mainCellStroke =  { color: '#f2f6f9', width: 6 };

    // Cell
    public static cell: MapCellConfig = {
        radius: 15,
        fill: '#D9EBF9',
        stroke: { color: '#1F75D5', width: 2 },
    };

    // Edges
    public static edgeStroke = { color: '#ced4da', width: 3, linecap: 'round' };
    public static edgeStrokeGreen = { color: '#11AA6F' };
    public static edgeStrokeRed = { color: '#ff0026' };

    // External cells
    public static externalCell: MapCellConfig = {
        radius: 20,
        fill: '#adb5bd',
        stroke: { color: '#495057', width: 2 },
    };
}
