import Component from 'vue-class-component';
import Vue from 'vue';
import { navigationHelper, notificationsService, transferService } from '@/main';
import { notificationModule } from '@/store/modules/notification';
import Notification from '@/models/Notification/Notification';
import { NotificationsService } from '@/services/notificationsService';

@Component
export default class BarComponent extends Vue {
    public isLoading: boolean = true;
    public notificationService = null;
    public notificationsService: NotificationsService = null;

    public $refs!: {
        notifications;
    };

    public hasUnreadNotifications() {
        return this.$refs.notifications && this.$refs.notifications.hasUnreadNotifications();
    }

    public toggleNavMenu() {
        navigationHelper.toggleNavigation();
    }

    public toggleNotification() {
        notificationModule.toggleVisibility();
    }

    public async mounted() {
        this.$root.$off('notification-approve');
        this.$root.$on('notification-approve', this.approve);
        this.$root.$off('notification-deny');
        this.$root.$on('notification-deny', this.deny);

        this.notificationService = notificationsService;
        this.$nextTick(this.$refs.notifications.fetch);
        this.isLoading = false;
    }

    public async approve(notification: Notification) {
        if (notification.notificationTemplate === 'transfer-request-sent') {
            await transferService.acceptTransferRequest(parseInt(notification.externalIdentifier));
        }
    }

    public async deny(notification: Notification) {
        if (notification.notificationTemplate === 'transfer-request-sent') {
            await transferService.rejectTransferRequest(parseInt(notification.externalIdentifier));
        }
    }
}
