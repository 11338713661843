import { Component, Vue, Watch } from 'vue-property-decorator';
import DistributedInvoice from '@/models/DistributedInvoice';
import { InvoiceType } from '@/models/InvoiceType';
import { financeService, periodFilterHelper } from '@/main';
import to from 'await-to-js';
import BaseFinanceTeamInvoicePage from './BaseFinanceTeamInvoicePage';

@Component
export default class TeamFinanceExpenses extends BaseFinanceTeamInvoicePage {
    public type: InvoiceType = InvoiceType.Expenses;

    @Watch('onlyNotYetDistributed')
    public onFailureFilterChange() {
        this.filterInvoices();
    }

    @Watch('onlyRecurring')
    public onRecurringChange() {
        this.filterInvoices();
    }

    @Watch('textSearch')
    public onTextFilterChange(val, oldVal) {
        if ((val && val.length > 2) || (oldVal && oldVal.length > val.length)) {
            this.filterInvoices();
        }
    }

    public async created() {
        this.invoiceColumns.push({
            title: 'IS_RECURRING',
            width: 125,
            field: 'invoice.isRecurring',
            locked: true,
            filterable: true,
            cell: this.renderRecurring,
            filter: 'boolean',
            headerCell: this.translate,
        });

        await this.init(this.type);

        this.isLoaded = true;
    }

    public optionListReceivers(): any[] {
        const list = this.groups.map((sender) => {
            if (sender) {
                return { value: sender.accountId, text: sender.name };
            }
        });

        list.push({ value: null, text: '-------------------------------------' });

        this.members.forEach((member) => {
            list.push({ value: member.accountId, text: member.name });
        });

        return list;
    }

    public rowClick(e) {
        if (periodFilterHelper.isCurrentPeriodClosed()) {
            return;
        }

        const invoice = this.invoices.find((item) => item.invoice.invoiceId === this.editID);
        this.invoices.forEach((expense) => (expense[this.selectedField] = false));
        if (invoice) {
            Vue.set(invoice, 'inEdit', false);
        }

        this.editID = e.dataItem.invoice.invoiceId;
        Vue.set(e.dataItem, 'inEdit', true);
    }

    public onHeaderSelectionChange(event) {
        const checked = event.event.target.checked;
        Vue.set(this, 'invoices', this.invoices.map((item) => ({ ...item, selected: checked })));
    }

    public onSelectionChange(event) {
        Vue.set(event.dataItem, 'inEdit', false);
        Vue.set(event.dataItem, this.selectedField, !event.dataItem[this.selectedField]);
    }

    public pageChangeHandler(event) {
        this.skip = event.page.skip;
        this.take = event.page.take;
    }

    public async setSelectedExpensesAsNotRecurring() {
        const self = this;
        const expenses = this.getSelectedInvoices();
        const list = [];

        expenses.forEach((expense: DistributedInvoice) => {
            if (expense) {
                list.push({ InvoiceId: expense.invoice.invoiceId, IsRecurring: expense.invoice.isRecurring = !expense.invoice.isRecurring });
            }
        });

        this.showPending('UPDATE_RECURRENCES_EXPENSES_PENDING');
        const [err] = await to(financeService.setInvoicesNotRercurring(list));
        if (err) {
            return self.clearAndShowError('UPDATE_RECURRENCES_EXPENSES_FAILED', null);
        }
        self.clearAndShowSuccess('UPDATE_RECURRENCES_EXPENSES_SUCCESS');
    }
}
