import { InvoiceType } from '@/models/InvoiceType';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import PageRender from '../../models/PageRender';

@Component
export default class GridNumericEditorComponent extends PageRender {
    @Prop({ default: () => null }) public dataItem: any;
    @Prop({ default: () => 0 }) public value: number;
    @Prop({ default: () => null }) public prop: string;
    @Prop({ default: false }) public isNegative: boolean;
    @Prop({ default: false }) public allowNegative: boolean;
    @Prop({ default: false }) public appendPercentage: boolean;

    private startValue: number = 0;
    private editingValue: number = 0;

    public mounted() {
        this.editingValue = this.startValue = this.value;
    }

    public getFormattedAmount() {
        return `${Vue.filter('number-format')(this.editingValue)}${this.appendPercentage ? '%' : ''}`;
    }

    public gottaSave(e) {
        if (e.keyCode === 13 && e.key === 'Enter') {
            if (!this.validValue()) {
                Vue.set(this, 'editingValue', this.startValue);
                return this.clearAndShowWarning('Invalid value');
            }
            this.$emit('saved', { dataItem: this.dataItem, value: this.editingValue, prop: this.prop });
        } else if (e.keyCode === 27 && e.key === 'Escape') {
            this.undoChange();
        }
    }

    public undoChange() {
        Vue.set(this, 'editingValue', this.startValue);
        Vue.set(this.dataItem, 'inEdit', false);
    }

    public checkValue() {
        if (!this.validValue()) {
            Vue.set(this, 'editingValue', this.startValue);
            return this.clearAndShowWarning('Invalid value');
        }

        this.$emit('changed', { dataItem: this.dataItem, value: this.editingValue, prop: this.prop });
    }

    public validValue() {
        if (this.dataItem.invoice && this.dataItem.invoice.invoiceType === InvoiceType.Expenses) {
            // its allowed to add positive or even negative amounts when its an expense
            return true;
        }

        return this.allowNegative || this.editingValue < 0 === this.isNegative || this.editingValue === 0;
    }
}
