export default class GroupResultItem {
    public group: any;
    public balance: number = 0;
    public result: number = 0;
    public expenses: number = 0;
    public extras: number = 0;
    public externalRevenue: number = 0;
    public startBalance: number = 0;
    public openExpenseAmount: number = 0;
    public openInvoiceAmount: number = 0;
    public name: string = '';
    public groupKey: string = '';
}
