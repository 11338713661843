import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { navigationHelper } from '@/main';
import { Person } from '../../models/Person';
import { BModal } from 'bootstrap-vue';
import ReleaseNotes from '../../components/release-notes/release-notes.vue';
import { profileModule } from '@/store/modules/profile';
import { periodModule } from '@/store/modules/period';

@Component({
    components: {
        ReleaseNotes,
    },
})
export default class NavMenuComponent extends Vue {
    public isMe: boolean = false;
    public isTeam: boolean = false;
    public isEco: boolean = false;
    public profile: Person;
    public isSettings: boolean = false;
    public show: boolean = false;

    public $refs!: {
        versionNotesModal: BModal;
    };

    @Watch('$route', { immediate: true, deep: true })
    public onRouteChanged() {
        this.setScope();
    }

    public showVersionModal() {
        this.$refs.versionNotesModal.show();
    }

    public setScope(): void {
        this.isMe = this.$route.path.indexOf('/me/') > -1 || this.$route.path === '/';
        this.isTeam = this.$route.path.indexOf('/team/') > -1;
        this.isEco = this.$route.meta.scope === 'ecosystem';
        this.isSettings = this.$route.meta.scope === 'settings';
    }

    public toggleSubMenu() {
        navigationHelper.toggleNavigation();
    }

    public showMainNav() {
        return profileModule.profileLoaded && periodModule.periods.length > 0;
    }
}
