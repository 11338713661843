import { AxiosHelper } from '../helpers/axiosHelper';
import { AttributionType } from '../models/AttributionType';
import Attribution from '../models/Attribution';
import { BaseService } from './baseService';
import Vue from 'vue';
import { PrivacyLevel } from '@/models/PrivacyLevel';
import to from 'await-to-js';

export class AttributionService extends BaseService {
    private endpoint = `${Vue.$env().financeEndpoint}`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public getAttributions(type: AttributionType, teamId: number, period: string, groupId?: number) {
        return this.get<Attribution[]>(`${this.endpoint}attributions?attributionType=:type&teamId=:teamId&groupId=:?groupId&period=:period`, [
            ['type', type],
            ['teamId', teamId],
            ['groupId', groupId],
            ['period', period],
        ]);
    }

    public saveAttributions(model: any[], type: AttributionType, teamId: number, period: string, groupId?: number) {
        let config = {};
        if (period) {
            config = {
                headers: {
                    'x-period': period,
                },
            };

            return this.post<any>(
                `${this.endpoint}attributions?attributionType=:type&teamId=:teamId&groupId=:?groupId&period=:period`,
                model,
                [['type', type], ['teamId', teamId], ['groupId', groupId], ['period', period]],
                config,
            );
        }

        return this.post<any>(
            `${this.endpoint}attributions?attributionType=:type&teamId=:teamId&groupId=:?groupId`,
            model,
            [['type', type], ['teamId', teamId], ['groupId', groupId]],
            {},
        );
    }

    public saveAttributionSetting(
        groupId: number,
        applyMissingAttributions: boolean,
        autoDistributeIncomeToSavings: boolean,
        autoDistributeExpensesToSavings: boolean,
        autoCompensateGroupMembers: boolean,
        privacyLevel: PrivacyLevel,
    ) {
        return this.post<any>(
            `${this.endpoint}settings?groupId=:group`,
            {
                applyMissingAttributionsOnTransfer: applyMissingAttributions,
                autoDistributeIncomeToSavings,
                autoDistributeExpensesToSavings,
                autoCompensateGroupMembers,
                privacyLevel,
            },
            [['group', groupId]],
        );
    }

    public getAttributionSetting(groupId: number) {
        return this.get<any>(`${this.endpoint}settings?groupId=:group`, [['group', groupId]]);
    }

    public async removeAttributionSources(teamId: number, groupId: number) {
        const model = {
            teamId,
            groupId,
        };

        const [err] = await to(this.post(`${this.endpoint}attributions/remove-attribution-sources`, model));
        if (err) {
            this.showFailedResponse('FAILED_TO_DELETE_ATTRIBUTION_SOURCES');
            return false;
        }

        return true;
    }
}
