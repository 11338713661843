import { Component } from 'vue-property-decorator';
import BaseModal from './BaseModal';
import to from 'await-to-js';
import {
    teamHelper,
    financeAccountService,
    resultService,
    attributionService,
    transferService,
    loginHelper,
    groupService,
    teamService,
} from '@/main';
import { FinanceAccount } from '@/models/Interfaces';
import { InvoiceType } from '@/models/InvoiceType';
import { AccountType } from '@/models/AccountType';
import { periodModule } from '@/store/modules/period';
import { Person } from '@/models/Person';
import AttributionPercentages from '@/models/Finance/AttributionPercentages';
import { AttributionType } from '@/models/AttributionType';
import Transfer from '@/models/Transfer';
import Team from '@/models/Team';
import moment from 'moment';
import { translateModule } from '@/store/modules/translate';

@Component
export default class MovePLModalComponent extends BaseModal {
    public memberToRemove: Person = null;
    public memberResult: any = null;
    public groupAccounts: FinanceAccount[] = [];
    public plGroup: any = null;
    public attributionPercentages: AttributionPercentages = new AttributionPercentages();
    public attributionsAreLoading: boolean = false;

    public async init(item: Person) {
        this.memberToRemove = item;
        const results = await resultService.getMemberResults(teamHelper.currentTeamId, periodModule.selectedPeriod, periodModule.selectedPeriod);

        const result = results.items[0].item.memberResults.find((x) => x.member.personId === item.personId);
        this.memberResult = result;

        const financeGroupAccounts = await this.getFinanceGroupAccounts(teamHelper.currentTeamId);
        this.groupAccounts = financeGroupAccounts;
    }

    public async transferPLAndRemoveMember() {
        this.showPending('TRANSFERRING_PENDING');

        await this.loadAttributions();
        const amount = this.calculateFromPenLTariff(this.memberResult.balance);
        const transfer = new Transfer();
        transfer.amount = amount > 0 ? amount * -1 : amount;
        transfer.senderTeam = new Team({ teamId: teamHelper.currentTeam.id });
        transfer.receiverTeam = new Team({ teamId: teamHelper.currentTeam.id });
        transfer.sender = this.groupAccounts.find((x) => x.accountId === this.plGroup);
        transfer.reason =  translateModule.value('TRANSFER_PL_CONTENT');
        transfer.group = transfer.sender.relatedEntityId;
        transfer.initiator = loginHelper.getUser().personId;
        transfer.invoiceType = InvoiceType.InternalIncome;
        transfer.period = periodModule.selectedPeriodObject.periodId;

        transfer.receiver = await financeAccountService.getAccount(
            teamHelper.currentTeamId,
            this.memberToRemove.personId,
            periodModule.selectedPeriod,
        );

        const [transferErr] = await to(transferService.doTransfer(transfer));
        if (transferErr) {
            return this.clearAndShowError('Failed to transfer P&L', transferErr);
        }

        const groups = await groupService.getGroups(teamHelper.currentTeamId, periodModule.selectedPeriod);
        const foundGroup = groups.data.find((x) => x.groupLead && x.groupLead.memberId === this.memberToRemove.personId);
        if (foundGroup) {
            return this.clearAndShowError(`The member you're trying to remove is still a group lead (${foundGroup.name})`, null);
        }
        this.clearNotifications();
        this.showPending('REMOVING_MEMBER_PENDING');
        const [err] = await to(
            teamService.removeMemberFromTeam(
                this.memberToRemove.personId,
                teamHelper.currentTeamId,
                moment(periodModule.selectedPeriodObject.startDate, 'YYYY-MM-DD')
                    .add(1, 'months')
                    .format('YYYY-MM'),
            ),
        );
        if (err) {
            return this.clearAndShowError(`REMOVING_MEMBER_FAILED`, err);
        }

        this.clearAndShowSuccess(`REMOVING_MEMBER_SUCCESS`);
        this.$emit('finished');
        this.hide();
    }

    public onHide() {
        this.memberToRemove = null;
        this.memberResult = null;
        this.groupAccounts = [];
        this.plGroup = null;

        this.attributionPercentages = new AttributionPercentages();
    }

    public calculateFromPenLTariff(amount) {
        return (
            amount /
            (1 - this.attributionPercentages.teamAttributionsPercentage / 100 / (1 - this.attributionPercentages.groupAttributionsPercentage / 100))
        );
    }

    public async getFinanceGroupAccounts(team: number) {
        const [err, response] = await to(financeAccountService.getAccounts(AccountType.GroupSavings, team, periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse('Failed to load group accounts', null);
        }

        return response.data;
    }

    public optionListGroups(): any[] {
        const list = this.groupAccounts.map((sender) => {
            return { value: sender.accountId, text: sender.name };
        });
        return list;
    }

    public async loadAttributions() {
        this.attributionsAreLoading = true;

        const groupId = this.groupAccounts.find((x) => x.accountId === this.plGroup).relatedEntityId;
        const [groupAttributions, groupRemittances, teamAttributions] = await Promise.all([
            this.getGroupAttributions(teamHelper.currentTeamId, groupId),
            this.getGroupRemittances(teamHelper.currentTeamId, groupId),
            this.getTeamAttributions(teamHelper.currentTeamId),
        ]);
        this.attributionPercentages.teamAttributionsPercentage = this.attributionPercentages.groupRemittancePercentage = this.attributionPercentages.groupAttributionsPercentage = 0;

        groupRemittances.forEach((attr) => {
            this.attributionPercentages.groupRemittancePercentage += attr.percentage;
        });

        // only do this when check box in settings is checked
        groupAttributions.forEach((attr) => {
            this.attributionPercentages.groupAttributionsPercentage += attr.percentage;
        });

        teamAttributions.forEach((attr) => {
            this.attributionPercentages.teamAttributionsPercentage += attr.percentage;
        });

        this.attributionsAreLoading = false;
    }

    public async getGroupAttributions(teamId: number, groupId: number) {
        const self = this;
        const [err, response] = await to(
            attributionService.getAttributions(AttributionType.GroupAttribution, teamId, periodModule.selectedPeriod, groupId),
        );
        if (err) {
            this.showFailedResponse('Failed to load group attributions', null);
        }

        self.attributionPercentages.groupAttributionsPercentage = 0;

        response.data.forEach((attr) => {
            self.attributionPercentages.groupAttributionsPercentage += attr.percentage;
        });

        return response.data;
    }

    public async getGroupRemittances(teamId: number, groupId: number) {
        const self = this;
        const [err, response] = await to(
            attributionService.getAttributions(AttributionType.GroupRemittance, teamId, periodModule.selectedPeriod, groupId),
        );
        if (err) {
            this.showFailedResponse('Failed to load group remittances', null);
        }

        self.attributionPercentages.groupRemittancePercentage = 0;

        response.data.forEach((attr) => {
            self.attributionPercentages.groupRemittancePercentage += attr.percentage;
        });

        return response.data;
    }

    public async getTeamAttributions(teamId: number) {
        const self = this;
        const [err, response] = await to(attributionService.getAttributions(AttributionType.TeamAttribution, teamId, periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse('Failed to load team attributions', null);
        }

        self.attributionPercentages.teamAttributionsPercentage = 0;
        response.data.forEach((attr) => {
            self.attributionPercentages.teamAttributionsPercentage += attr.percentage;
        });

        return response.data;
    }
}
