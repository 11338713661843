import { Component } from 'vue-property-decorator';
import BaseModal from './BaseModal';

@Component
export default class AreYouSureModal extends BaseModal {
    public value: string = '';
    public entityName: string = '';
    public itemToDelete: any = null;
    public propertyToCheck: string = '';

    public setScope(item: any, property: string, entityName: string) {
        this.itemToDelete = item;
        this.propertyToCheck = property;
        this.entityName = entityName;
    }

    public async deleteEntity() {
        if (!this.itemToDelete.hasOwnProperty(this.propertyToCheck)) {
            return this.showError('PROPERTY_DOENST_EXIST');
        }

        if (this.itemToDelete[this.propertyToCheck] !== this.value) {
            return this.showError('VALUE_DOESNT_MATCH');
        }

        this.$emit('delete-entity', this.itemToDelete);
    }

    public onHide() {
        this.itemToDelete = null;
        this.propertyToCheck = '';
        this.entityName = '';
    }
}
