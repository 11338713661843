import { InviteInterface } from './Interfaces';

export default class Invite implements InviteInterface {
    public id: number;
    public email: string;
    public valid: boolean;
    public fromPerson: number;
    public key: string;
    public team: number;
}
