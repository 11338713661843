import { Component, Prop } from 'vue-property-decorator';
import BaseModal from './BaseModal';
import DistributedInvoice from '@/models/DistributedInvoice';
import { InvoiceType } from '@/models/InvoiceType';
import { financeService, teamHelper, financeAccountService } from '@/main';
import moment from 'moment';
import { FinanceAccount } from '@/models/Interfaces';
import { teamsModule } from '@/store/modules/teams';
import { AccountType } from '@/models/AccountType';
import { to } from 'await-to-js';
import { periodModule } from '@/store/modules/period';
import { InvoiceSource } from '@/models/InvoiceSource';

export class InvoiceImportBase {
    public client: string = '';
    public description: string = '';
    public reference: string = '';
    public amount: string = '';
    public date: string = '';
    public group: string = '';
    public person: string = '';
    public legalEntityReference: string = '';
    public distributions: any[] = [];
}

// tslint:disable-next-line: max-classes-per-file
export class CsvColumn {
    public label: string = '';
    public placeholder: string = '';
    public property: string = '';
}

// tslint:disable-next-line: max-classes-per-file
@Component
export default class ImportParticipantComponent extends BaseModal {
    @Prop({ default: () => [] }) public groups: FinanceAccount[];
    @Prop({ default: () => [] }) public persons: FinanceAccount[];
    @Prop({ default: () => InvoiceType.Income }) public type: InvoiceType;

    public inputModel: string = '';
    public inputMode: string = 'overview';
    public isUploadingCsv: boolean = false;
    public personAccounts: FinanceAccount[] = [];

    public lineData: InvoiceImportBase[] = [];
    public mapping: any[] = [];

    private readonly delimiter: string = ';';
    private csvColumns: CsvColumn[] = [
        { label: 'Client', placeholder: 'Bright Services', property: 'client' },
        { label: 'Description', placeholder: 'Annual Licences', property: 'description' },
        { label: 'Reference', placeholder: '1234567890', property: 'reference' },
        { label: 'Amount', placeholder: '1234.56', property: 'amount' },
        { label: 'Date', placeholder: '31/12/2020', property: 'date' },
        {
            label: 'LegalEntityReference',
            placeholder: '<optional> - The name of the legal entity this invoice is referred to, when left blank current legal entity will be filled',
            property: 'legalEntityReference',
        },
    ];

    public async mounted() {
        await teamsModule.fetchTeamsIfNeeded();

        if (this.type === InvoiceType.Expenses) {
            const [err, response] = await to(
                financeAccountService.getAccounts(AccountType.Person, teamHelper.currentTeam.id, periodModule.selectedPeriod),
            );

            if (err) {
                return this.showFailedResponse('failed to load person accounts');
            }

            this.personAccounts = response.data;
        }

        this.groups.forEach((x) => {
            this.csvColumns.push({
                label: `${x.name}`,
                placeholder: `<optional> - to create on import a distribution for ${x.name}, matches on full name, only applicable on expenses`,
                property: `group_${x.relatedEntityId}`,
            });
        });

        if (this.type === InvoiceType.Expenses) {
            this.persons.forEach((x) => {
                if (this.csvColumns.findIndex((y) => y.label === x.name) === -1) {
                    this.csvColumns.push({
                        label: `${x.name}`,
                        placeholder: `<optional> - to create on import a distribution for ${x.name}, matches on full name, only applicable on expenses`,
                        property: `person_${x.relatedEntityId}`,
                    });
                }
            });
        }
    }

    public onHide() {
        this.inputMode = 'overview';
        this.lineData = [];
    }

    public importLines(): boolean {
        const removedDistributionInvoices = [];
        const self = this;
        self.isUploadingCsv = true;
        self.showPending('IMPORT_INVOICE_PENDING');

        const distributedInvoices = self.lineData.map((invoice: any) => {
            const distributedInvoice = new DistributedInvoice();
            invoice.source = InvoiceSource.Manual;
            distributedInvoice.invoice = invoice;
            distributedInvoice.invoice.invoiceDate = invoice.date;
            distributedInvoice.invoice.name = invoice.client;
            distributedInvoice.invoice.invoiceType = this.type;
            distributedInvoice.invoice.period = periodModule.selectedPeriodObject;
            distributedInvoice.invoice.legalEntityReferenceOrigin = invoice.legalEntityReference;

            distributedInvoice.distributions = invoice.distributions.map((x) => {
                const distribution = {
                    amount: x.value,
                    account: this.groups.find((g) => g.relatedEntityId === x.relatedEntityId),
                };

                if (!distribution.account) {
                    distribution.account = this.persons.find((p) => p.relatedEntityId === x.relatedEntityId);
                }

                return distribution;
            });

            const total = distributedInvoice.distributions.reduce((a: number, b) => a + parseInt(b.amount.toString()), 0);
            if (Math.abs(total) > Math.abs(distributedInvoice.invoice.amount)) {
                delete distributedInvoice.distributions;
                removedDistributionInvoices.push(distributedInvoice.invoice.reference);
            }

            return distributedInvoice;
        });

        financeService
            .importInvoice(distributedInvoices, teamHelper.getTeamId(), null)
            .then((response) => {
                if (response) {
                    self.isUploadingCsv = false;
                    self.clearAndShowSuccess('IMPORT_INVOICE_SUCCESS');

                    if (removedDistributionInvoices.length > 0) {
                        self.showWarning(
                            `Invoices with references ${removedDistributionInvoices.join(
                                ', ',
                            )} are not imported because the total distributions are greater than the total amount of the invoice`,
                        );
                    }

                    this.$emit('invoices-imported', this.type);
                    this.hide();
                }
            })
            .catch((error) => {
                self.isUploadingCsv = false;
                self.clearAndShowError('IMPORT_INVOICE_FAILED', error);
            });

        return true;
    }

    public fileUploaded(event) {
        this.loadCSV(event, new InvoiceImportBase());
    }

    public downloadExample() {
        this.exportExampleCSV(this.csvColumns);
    }

    public exportExampleCSV(columns: CsvColumn[]): void {
        const properties = [];
        const values = [];
        const lines = [];

        for (const prop of columns) {
            values.push(prop.placeholder);
            properties.push(prop.label);
        }

        lines.push(properties.join(';'));
        lines.push(values.join(';'));

        const blob = new Blob([lines.join('\n')], { type: 'text/csv' });
        const downloadLink = document.createElement('a');
        downloadLink.download = 'ExampleImport.csv';
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    public loadCSV<T>(e: any, object: T): void {
        const self = this;
        if ((window as any).FileReader) {
            const reader = new FileReader();
            reader.readAsText(e.target.files[0]);
            reader.onload = (event) => {
                const csv = (event.target as any).result;
                self.processInput(csv, self.delimiter);
            };

            reader.onerror = (evt) => {
                if ((evt.target as any).error.name === 'NotReadableError') {
                    alert('Cannot read file !');
                }
            };

            reader.onloadend = () => {
                return object;
            };
        } else {
            alert('FileReader are not supported in this browser.');
        }
    }

    public processPastedInput(): void {
        this.processInput(this.inputModel, '\t');
    }

    public processInput(input: string, delimiter: string) {
        const self = this;
        const lines = input.split('\n');
        const mapping = []; // Once to be used by a mailchimp style importer?

        const headers = lines[0].split(delimiter).map((header) => {
            return header.replace('\r', '');
        });

        for (let i = 0; i < this.csvColumns.length; i++) {
            const matchingColumn = headers.findIndex((h) => {
                return h.length > 3 && h.toLowerCase() === this.csvColumns[i].label.toLowerCase();
            });

            if (matchingColumn !== -1) {
                mapping.push({ index: matchingColumn, property: this.csvColumns[i].property });
            }
        }

        this.mapping = mapping;

        for (let li = 1; li < lines.length; li++) {
            // Skip first line
            if (lines[li] == null || lines[li] === '') {
                continue;
            }

            const columns = lines[li].replace('\r', '').replace('"', '').split(delimiter);
            const itemLine = { distributions: [] } as InvoiceImportBase;

            for (let m = 0; m < mapping.length; m++) {
                const currentMap = mapping[m];
                let value = columns[currentMap.index];

                if (currentMap.property === 'amount') {
                    const nanCheck = value;
                    // @ts-ignore
                    if (isNaN(1 * nanCheck)) {
                        value = value.replace('.', '').replace(',', '.');
                    }
                    value = parseFloat(value).toString();
                }

                if (currentMap.property === 'date') {
                    if (!value || value === '') {
                        value = moment().format('YYYY-MM-DD');
                    } else {
                        value = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
                    }
                }

                if (currentMap.property === 'legalEntityReference') {
                    if (!value || value === '') {
                        value = teamsModule.current.legalEntityReference;
                    }
                }

                if (currentMap.property.startsWith('group_') || currentMap.property.startsWith('person_')) {
                    if (!value || value === '' || value === '0') {
                        continue;
                    }

                    const nanCheck = value;
                    // @ts-ignore
                    if (isNaN(1 * nanCheck)) {
                        value = value.replace('.', '').replace(',', '.');
                    }
                    value = parseFloat(value).toString();

                    itemLine.distributions.push({
                        relatedEntityId: parseInt(currentMap.property.split('_')[1]),
                        value,
                    });

                    continue;
                }

                itemLine[currentMap.property] = value.replace('"', '');
            }

            self.lineData.push(itemLine);
        }
    }

    public cancelImport(): void {
        this.hide();
    }

    public getRelatedEntityName(relatedEntityId: number) {
        let entity = this.groups.find((x) => x.relatedEntityId === relatedEntityId);

        if (!entity) {
            entity = this.persons.find((x) => x.relatedEntityId === relatedEntityId);
        }

        return entity.name;
    }
}
