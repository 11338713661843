export default class GroupAlias {
    public teamKey: string;
    public teamId: number;
    public groupKey: string;
    public groupId: number;
    public aliases: string[] = [];

    constructor(obj?: Partial<GroupAlias>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
