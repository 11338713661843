import GroupResultItem from './GroupResultItem';
import MemberResultItem from './MemberResultItem';

export default class TeamResultItem {
    public groupResults: GroupResultItem[] = [];
    public memberResults: MemberResultItem[] = [];

    public openExpenseAmount: number = 0;
    public openInvoiceAmount: number = 0;
    public teamKey: string = '';
    public teamId: number = 0;
    public teamName: string = '';
    public dropDeadRatio: number = 0;
    public name: string;
    public expenses: number = 0;
    public extras: number = 0;

    constructor(obj?: Partial<TeamResultItem>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
