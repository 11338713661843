import Policies from '@/models/Policy/Policies';
import IValidation from '@/models/Validator/IValidation';

export default class PolicyHelper {
    private options: IValidation[] = [];

    constructor(options: IValidation[] = Policies.Default) {
        this.options = options;
    }

    public validate(password: string) {
        return this.options.filter((policy) => {
            return !policy.valid(password);
        }).length <= 0;
    }

    public get policies() {
        return this.options;
    }
}
