import { Circle, Container, G, Svg } from '@svgdotjs/svg.js';
import CellColor from '../models/CellColor';
import { CellScore } from '../models/CellScore';
import MapCell from '../models/MapCell';
import MapCellConfig from '../models/MapCellConfig';
import MapConfig from '../models/MapConfig';
import MapData from '../models/MapData';
import MapEdge from '../models/MapEdge';
import MapNode from '../models/MapNode';
import SvgCircleCell from './SvgCircleCell';
import SvgHelper from './SvgHelper';
import SvgRender from './SvgRender';

export default class SvgRenderCellSpace extends SvgRender {
    public type = 'CellSpace';
    public selectedId: number | string = 0;
    public extGroup: G;
    public edgesGroup: G;
    public cellsGroup: G;
    protected minScale = 0;
    private cells: MapCell[] = [];
    private nodes: MapNode[] = [];
    private edges: MapEdge[] = [];
    private externalNodes: MapNode[] = [];
    private rotateBy = 45;
    private margin = 100;

    constructor(canvas?: Svg) {
        super();
        this.canvas = canvas;
    }

    public setData(data: MapData) {
        if (data.nodes.length > MapConfig.maxNodes) {
            console.warn(`To much nodes, only ${MapConfig.maxNodes} are rendered`);
        }

        if (data.edges.length > MapConfig.maxEdges) {
            console.warn(`To much edges, only ${MapConfig.maxEdges} are rendered`);
        }

        this.nodes = data.nodes.slice(0, MapConfig.maxNodes);
        this.edges = data.edges.slice(0, MapConfig.maxEdges);
        this.externalNodes = data.external;
        this.allNodes = [...this.nodes, ...this.externalNodes];
    }

    public render() {
        this.empty = false;
        this.canvas.clear();
        this.canvas.click(() => this.resetHighlight());

        this.container = this.canvas.group().draggable();
        this.edgesGroup = this.container.group();
        this.extGroup = this.container.group();
        this.cellsGroup = this.container.group();
        this.cells = [];

        const mainRadius = this.calculateMainCircleRadius(this.nodes.length);
        const externalRadius = this.calculateExternalCircleRadius(mainRadius);
        const mainCircle = this.renderMain(this.container, mainRadius);
        const externalCircle = this.renderExternal(this.container, externalRadius);
        mainCircle.insertBefore(this.edgesGroup);
        externalCircle.insertBefore(this.edgesGroup);

        SvgHelper.center(mainCircle);
        SvgHelper.center(externalCircle);
        SvgHelper.center(this.container);

        this.nodes.forEach((node: MapNode, index: number) => {
            const degree = this.rotateBy + (360 / this.nodes.length) * index;
            const config = MapConfig.cell;
            const colors = CellColor.score(node.data ? node.data.colorScore : CellScore.Unknown);
            config.id = `cell-${node.id}`;
            config.fill = colors.light;
            config.stroke.color = colors.dark;
            const mapCell = this.renderCell(this.cellsGroup, mainCircle, node, degree, config);
            mapCell.group.click((e: Event) => {
                e.preventDefault();
                e.stopPropagation();
                this.resetHighlight();
                this.fire('navigate', node.id);
            });
            this.cells.push(mapCell);
        });

        this.externalNodes.forEach((node: MapNode, index: number) => {
            const degree = -45 + (360 / this.externalNodes.length) * index;
            const config = MapConfig.externalCell;
            config.id = `external-${node.id}`;
            const mapCell = this.renderCell(this.extGroup, externalCircle, node, degree, config);
            mapCell.group.click((e: Event) => {
                e.preventDefault();
                e.stopPropagation();
            });
            this.cells.push(mapCell);
        });

        this.renderEdges(this.edgesGroup, this.edges, this.cells);
    }

    public redraw() {
        this.render();
        if (this.selectedId) {
            this.highlight(this.selectedId);
        }
    }

    public rotate(degree: number) {
        this.rotateBy = degree;
    }

    public setMargin(margin: number) {
        this.margin = margin;
    }

    private renderMain(container: Container, radius: number) {
        const circle = container.circle(radius * 2);
        circle.stroke(MapConfig.mainCellStroke);
        circle.fill(MapConfig.mainCellBg);
        return circle;
    }

    private renderExternal(container: Container, radius: number) {
        const circle = container.circle(radius * 2);
        circle.fill('transparent');
        return circle;
    }

    private renderCell(group: G, mainCircle: Circle, node: MapNode, degree: number, config: MapCellConfig): MapCell {
        const angle = (degree * Math.PI) / 180;
        const cell = new SvgCircleCell(group);
        const circle = cell.draw(mainCircle, angle, config);
        const cellGroup = cell.getGroup();
        cell.label(node.title);

        let timeout = null;
        cellGroup.mouseenter(() => {
            clearTimeout(timeout);
            cellGroup.transform({ scale: 1.1 });
            this.highlight(node.id);
        });
        cellGroup.mouseleave(() => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                cellGroup.transform({ scale: 1 });
                this.resetHighlight();
            }, 50);
        });
        cellGroup.attr({ class: 'cursor-pointer' });

        return {
            id: node.id,
            degree,
            circle,
            group: cellGroup,
        };
    }

    private renderEdges(container: G, edges: MapEdge[], cells: MapCell[]) {
        if (cells.length < 1) {
            return;
        }

        container.clear();
        for (let i = 0; i < edges.length; i++) {
            const origin = this.findCell(cells, edges[i].origin);
            const destination = this.findCell(cells, edges[i].destination);
            const edge = edges[i];

            if (!origin || !destination) {
                console.warn('Destination or origin cell could not be found', edge);
            } else {
                edge.path = this.renderEdge(container, origin, destination);
            }
        }
    }

    private renderEdge(container: G, v1: MapCell, v2: MapCell) {
        const angle = Math.atan((v2.circle.cy() - v1.circle.cy()) / (v2.circle.cx() - v1.circle.cx()));
        const degree = (angle * 180) / Math.PI;
        const inverse = v1.degree > v2.degree ? 1 : 0;
        let rotateY = 0;
        if (Math.abs(v1.degree - v2.degree) < 45) {
            rotateY = inverse ? 30 : 25;
        } else {
            rotateY = inverse ? 10 : 5;
        }
        const path = container.path(
            `M ${v1.circle.cx()} ${v1.circle.cy()} A 30 ${rotateY}, ${degree}, 0 ${inverse}, ${v2.circle.cx()} ${v2.circle.cy()}`,
        );
        path.attr({ 'fill-opacity': 0 });
        path.stroke(MapConfig.edgeStroke);
        return path;
    }

    private highlight(id: number | string) {
        this.select(id);
        const cellIds = [id];

        const external_incoming = ['external_revenue'];
        const external_outgoing = ['member_cost', 'activity_expenses'];
        const external_incoming_outgoing = ['transfers_outside'];

        this.edges.forEach((edge: MapEdge) => {
            if (!edge.path) {
                return;
            }

            if (edge.destination === id || edge.origin === id) {
                cellIds.push(edge.destination);
                cellIds.push(edge.origin);
            }

            if (edge.valueColorOverride) {
                // plz don't ask, here doesn't the direction determine the color, but combination of external outgoing/incoming with a positive/negative value
                if (edge.origin === id) {
                    if (external_incoming.indexOf(edge.origin as string) > -1) {
                        edge.path.stroke(edge.value > 0 ? MapConfig.edgeStrokeGreen : MapConfig.edgeStrokeRed);
                    } else if (external_outgoing.indexOf(edge.origin as string) > -1) {
                        edge.path.stroke(edge.value < 0 ? MapConfig.edgeStrokeGreen : MapConfig.edgeStrokeRed);
                    } else if (external_incoming.indexOf(edge.destination as string) > -1) {
                        edge.path.stroke(edge.value > 0 ? MapConfig.edgeStrokeGreen : MapConfig.edgeStrokeRed);
                    } else if (external_outgoing.indexOf(edge.destination as string) > -1) {
                        edge.path.stroke(edge.value < 0 ? MapConfig.edgeStrokeGreen : MapConfig.edgeStrokeRed);
                    } else if (external_incoming_outgoing.indexOf(edge.destination as string) > -1) {
                        edge.path.stroke(edge.value < 0 ? MapConfig.edgeStrokeGreen : MapConfig.edgeStrokeRed);
                    }
                }

                if (edge.destination === id) {
                    if (external_incoming.indexOf(edge.destination as string) > -1) {
                        edge.path.stroke(edge.value < 0 ? MapConfig.edgeStrokeGreen : MapConfig.edgeStrokeRed);
                    } else if (external_outgoing.indexOf(edge.destination as string) > -1) {
                        edge.path.stroke(edge.value < 0 ? MapConfig.edgeStrokeGreen : MapConfig.edgeStrokeRed);
                    } else if (external_incoming.indexOf(edge.origin as string) > -1) {
                        edge.path.stroke(edge.value > 0 ? MapConfig.edgeStrokeGreen : MapConfig.edgeStrokeRed);
                    } else if (external_outgoing.indexOf(edge.origin as string) > -1) {
                        edge.path.stroke(edge.value < 0 ? MapConfig.edgeStrokeGreen : MapConfig.edgeStrokeRed);
                    } else if (external_incoming_outgoing.indexOf(edge.destination as string) > -1) {
                        edge.path.stroke(edge.value > 0 ? MapConfig.edgeStrokeGreen : MapConfig.edgeStrokeRed);
                    }
                }
            } else if (edge.destination === id && !edge.valueColorOverride) {
                edge.path.stroke(MapConfig.edgeStrokeGreen);
            } else if (edge.origin === id && !edge.valueColorOverride) {
                edge.path.stroke(MapConfig.edgeStrokeRed);
            } else {
                edge.path.stroke(MapConfig.edgeStroke);
                edge.path.opacity(0.2);
            }
        });

        this.cells.forEach((cell: MapCell) => {
            if (cellIds.indexOf(cell.id) > -1) {
                cell.group.opacity(1);
            } else {
                cell.group.opacity(0.2);
            }
        });
    }

    private resetHighlight() {
        this.select(null);
        this.edges.forEach((edge: MapEdge) => {
            if (edge.path) {
                edge.path.stroke(MapConfig.edgeStroke);
                edge.path.opacity(1);
            }
        });
        this.cells.forEach((cell: MapCell) => {
            cell.group.opacity(1);
        });
    }

    private select(id: number | string) {
        this.selectedId = id;
        this.fire('select', id);
    }

    private calculateMainCircleRadius(cells: number) {
        const outline = (MapConfig.cell.radius * 2 + this.margin) * cells;
        const radius = outline / (2 * Math.PI);
        return radius;
    }

    private calculateExternalCircleRadius(mainRadius: number) {
        const outerBorder = mainRadius + MapConfig.cell.radius;
        return Math.sqrt(outerBorder ** 2 + outerBorder ** 2) + this.margin;
    }

    private findCell(cells: MapCell[], id: string | number) {
        return cells.find((cell: MapCell) => cell.id === id);
    }
}
