import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridPercentageComponent extends Vue {
    @Prop({ default: 0 }) public value: number;
    @Prop({ default: () => null }) public action: any;
    @Prop({ default: true }) public showPercentage: boolean;
    @Prop({ default: null }) public text: string;
}
