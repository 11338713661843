import { Component } from 'vue-property-decorator';
import { resultService, teamHelper, roleHelper, ecosystemHelper } from '@/main';
import FinanceTeam from '../../../../models/FinanceTeam';
import BaseInsightsPage from './BaseInsightsPage';
import RangeItem from '@/models/Finance/Api/RangeItem';
import MemberResults from '@/models/Finance/Api/MemberResults';
import { periodModule } from '@/store/modules/period';
import Vue from 'vue';

@Component
export default class TeamFinanceMemberOverview extends BaseInsightsPage {
    public teamId: number = null;
    public reloadingResults: boolean = false;

    public memberOverviewData: any[] = [];
    public tempMemberOverviewData: any[] = [];
    public teamFinance: FinanceTeam;
    public isLoaded: boolean = false;
    public reload: number = 0;

    public memberResultColumns: any[] = [
        { field: 'member.name', title: 'MEMBER' },
        { field: 'balance', cell: this.formatStartBalanceValue, title: 'START_BALANCE', footerCell: this.renderFooterCellStartBalance },
        { field: 'result', cell: this.formatResultValue, title: 'RESULT', footerCell: this.renderFooterCellSum },
        { field: 'balance', cell: this.formatBalanceValue, title: 'END_BALANCE', footerCell: this.renderFooterCellSum },
        { field: 'expenses', cell: this.formatExpensesValue, title: 'EXPENSES', footerCell: this.renderFooterCellSum },
        { field: 'extras', cell: this.formatExtrasValue, title: 'EXTRAS', footerCell: this.renderFooterCellSum },
    ];

    public async mounted() {
        if (!roleHelper.isSiteAdmin() && !roleHelper.isFinanceEmployee()) {
            await this.$router.push({
                name: 'team-finances',
                params: {
                    ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                    ecosystemKey: ecosystemHelper.currentEcosystem.key,
                    teamId: teamHelper.currentTeam.id.toString(),
                    teamKey: teamHelper.currentTeam.key,
                    tab: 'group-overview',
                    period: periodModule.selectedPeriod,
                },
            });
        }

        this.teamId = teamHelper.currentTeamId;

        this.isLoaded = true;
    }

    public async recalculateResultsIncome(dates) {
        this.reloadingResults = true;
        this.clearNotifications();
        this.showPending('CALCULATING_RESULT_PENDING');

        this.memberOverviewData = [];
        this.tempMemberOverviewData = [];

        const result = await resultService.getMemberResults(teamHelper.getTeamId(), dates.fromFilterMonth, dates.toFilterMonth);
        this.calculateTotals(result.items);

        this.memberOverviewData = this.tempMemberOverviewData;
        this.clearNotifications();
        this.reloadingResults = false;
        this.reload++;
    }

    public calculateTotals(results: RangeItem<MemberResults>[]) {
        for (const item of results) {
            for (const personResult of item.item.memberResults) {
                const member = this.tempMemberOverviewData.find((x) => x.member.personId === personResult.member.personId);
                if (member) {
                    member.result += personResult.result;
                    member.extras += personResult.extras;
                    member.expenses += personResult.expenses;
                    member.balance = personResult.balance;
                } else {
                    this.tempMemberOverviewData.push(personResult);
                }
            }
        }
    }

    public renderFooterCellSum(h, _, row) {
        const total = this.memberOverviewData.reduce((acc: number, current) => acc + (current[row.field] as number), 0);
        const props = { value: total };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderFooterCellStartBalance(h, _) {
        const total = this.memberOverviewData.reduce((acc: number, current) => acc + this.getStartBalanceValue(current), 0);
        const props = { value: total };
        return h(Vue.component('number-formatter'), { props });
    }
}
