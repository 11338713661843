import Vue from 'vue';
import { Grid, GridToolbar, GridNoRecords } from '@progress/kendo-vue-grid';
import { Popup } from '@progress/kendo-vue-popup';
import { DropDownList } from '@progress/kendo-vue-dropdowns';
import DatePicker from 'vue2-datepicker';
import { tree } from 'vued3tree';

import vSelect from 'vue-regex-input';
Vue.component('vue-regex-input', vSelect);

Vue.component('date-picker', DatePicker);
Vue.component('tree-chart', tree);
Vue.component('prd-local-map', require('@/components/map/Map.vue').default);
Vue.component('svg-graph', require('@/components/graph/svg-graph.vue').default);

// Components
Vue.component('grid', Grid);
Vue.component('prd-grid-wrapper', require('@/components/grid/grid-wrapper.vue').default);
Vue.component('grid-toolbar', GridToolbar);
Vue.component('grid-norecords', GridNoRecords);

Vue.component('kendo-popup', Popup);
Vue.component('kendo-dropdownlist', DropDownList);

Vue.component('navigation-bar', require('@/components/navigation/bar.vue').default);
Vue.component('navigation-breadcrumb', require('@/components/navigation/breadcrumb.vue').default);
Vue.component('navigation-profile', require('@/components/navigation/profile.vue').default);
Vue.component('navigation-team', require('@/components/navigation/team.vue').default);
Vue.component('spinner', require('@/components/spinner/spinner.vue').default);

Vue.component('grid-percentage', require('@/components/grid/grid-percentage.vue').default);
Vue.component('grid-popover-element', require('@/components/grid/grid-popover-element.vue').default);
Vue.component('grid-router-link', require('@/components/grid/grid-router-link.vue').default);
Vue.component('grid-actions', require('@/components/grid/grid-actions.vue').default);
Vue.component('grid-numeric-editor', require('@/components/grid/grid-numeric-editor.vue').default);
Vue.component('grid-amount-left-filter', require('@/components/grid/grid-amount-left-filter.vue').default);
Vue.component('grid-html-renderer', require('@/components/grid/grid-html-renderer.vue').default);
Vue.component('grid-period-range-filter', require('@/components/grid/grid-period-range-filter.vue').default);
Vue.component('grid-transfer-direction', require('@/components/grid/grid-transfer-direction.vue').default);
Vue.component('grid-relation-settings', require('@/components/grid/grid-relation-settings.vue').default);

Vue.component('grid-list', require('@/components/grid/grid-list.vue').default);
Vue.component('grid-label-value', require('@/components/grid/grid-label-value.vue').default);

Vue.component('number-formatter', require('@/components/formatting/number-formatter.vue').default);
Vue.component('distribution-tree-amount', require('@/components/distribution/distribution-tree-amount.vue').default);

Vue.component('member-name', require('@/components/member/member-name.vue').default);
Vue.component('member-name-native', require('@/components/member/member-name-native.vue').default);
Vue.component('members-component', require('@/components/member/members.vue').default);
Vue.component('memberships-component', require('@/components/member/memberships.vue').default);
Vue.component('register', require('@/components/account/register.vue').default);
Vue.component('period-filter', require('@/components/period filter/period-filter.vue').default);

Vue.component('me-directory-groups', require('@/pages/me/directory/components/groups.vue').default);
Vue.component('me-directory-members', require('@/pages/me/directory/components/members.vue').default);

Vue.component('team-directory-groups', require('@/pages/teams/directory/components/groups.vue').default);
Vue.component('team-directory-members', require('@/pages/teams/directory/components/members.vue').default);
Vue.component('team-directory-settings', require('@/pages/teams/directory/components/settings.vue').default);

Vue.component('group-directory-members', require('@/pages/groups/details/components/group-detail-members.vue').default);
Vue.component('group-directory-workspace', require('@/pages/groups/details/components/group-detail-workspace.vue').default);
Vue.component('group-finance-settings', require('@/pages/groups/details/components/group-detail-settings.vue').default);
Vue.component('group-directory-settings', require('@/pages/groups/details/components/group-detail-settings.vue').default);
Vue.component('group-finance-income', require('@/pages/groups/details/components/group-detail-income.vue').default);
Vue.component('group-finance-overview', require('@/pages/groups/details/components/group-detail-overview.vue').default);
Vue.component('group-finance-expense', require('@/pages/groups/details/components/group-detail-expense.vue').default);
Vue.component('group-finance-insights', require('@/pages/groups/details/components/group-detail-insights.vue').default);
Vue.component('group-finance-ambitions', require('@/pages/groups/details/components/group-detail-ambitions.vue').default);
Vue.component('group-finance-transfers', require('@/pages/groups/details/components/group-detail-transfers.vue').default);

Vue.component('team-finances-settings', require('@/pages/teams/finance/components/team-finance-settings.vue').default);
Vue.component('team-finances-group-overview', require('@/pages/teams/finance/components/team-finance-group-overview.vue').default);
Vue.component('team-finances-period', require('@/pages/teams/finance/components/team-finance-period.vue').default);
Vue.component('team-finances-member-overview', require('@/pages/teams/finance/components/team-finance-member-overview.vue').default);
Vue.component('team-finances-transfers', require('@/pages/teams/finance/components/team-finance-transfers.vue').default);
Vue.component('team-finances-invoices', require('@/pages/teams/finance/components/team-finance-invoices.vue').default);
Vue.component('team-finances-expenses', require('@/pages/teams/finance/components/team-finance-expenses.vue').default);

Vue.component('team-insights-member-results', require('@/pages/teams/insights/components/team-insights-member-results.vue').default);
Vue.component('team-insights-group-results', require('@/pages/teams/insights/components/team-insights-group-results.vue').default);
Vue.component('team-insights-payment-statusses', require('@/pages/teams/insights/components/team-insights-payment-statusses.vue').default);

Vue.component('ecosystem-directory-map', require('@/pages/ecosystem/directory/components/map.vue').default);
Vue.component('ecosystem-directory-people', require('@/pages/ecosystem/directory/components/people.vue').default);
Vue.component('ecosystem-directory-members', require('@/pages/ecosystem/directory/components/members.vue').default);
Vue.component('ecosystem-directory-groups', require('@/pages/ecosystem/directory/components/groups.vue').default);
Vue.component('ecosystem-directory-teams', require('@/pages/ecosystem/directory/components/teams.vue').default);

Vue.component('ecosystem-settings-ledger-accounts', require('@/pages/ecosystem/settings/components/ledger-accounts.vue').default);
Vue.component('ecosystem-settings-cross-legal-transactions', require('@/pages/ecosystem/settings/components/cross-legal-transactions.vue').default);
Vue.component('ecosystem-settings-relations', require('@/pages/ecosystem/settings/components/relations.vue').default);
Vue.component('ecosystem-settings-insight-views', require('@/pages/ecosystem/settings/components/insight-views.vue').default);

Vue.component('roles-settings', require('@/components/roles/roles-settings.vue').default);
// Vue.component('release-notes'    , require('@/components/release-notes/release-notes.vue').default);

Vue.component('amortize-modal', require('@/components/modals/amortize-modal.vue').default);
Vue.component('move-pl-modal', require('@/components/modals/move-pl-modal.vue').default);
Vue.component('move-to-account-modal', require('@/components/modals/move-to-account-modal.vue').default);
Vue.component('save-attributions-modal', require('@/components/modals/save-attributions-modal.vue').default);
Vue.component('import-modal', require('@/components/modals/import-modal.vue').default);
Vue.component('transfer-modal', require('@/components/modals/transfer-modal.vue').default);
Vue.component('transfer-request-modal', require('@/components/modals/transfer-request-modal.vue').default);
Vue.component('energy-dedication-modal', require('@/components/modals/energy-dedication-modal.vue').default);
Vue.component('ecosystem-switcher-modal', require('@/components/modals/ecosystem-switcher-modal.vue').default);
Vue.component('legal-entity-change-modal', require('@/components/modals/change-legal-entity-reference-modal.vue').default);
Vue.component('relation-settings-modal', require('@/components/modals/relation-settings-modal.vue').default);
Vue.component('create-ledger-account-modal', require('@/components/modals/create-ledger-account-modal.vue').default);
Vue.component('are-you-sure-modal', require('@/components/modals/are-you-sure-modal.vue').default);
Vue.component('generic-search', require('@/components/generic-search/generic-search.vue').default);
Vue.component('connectors', require('@/components/connectors/connectors.vue').default);

Vue.component('recurring-transfers-grid', require('@/components/transfer/recurring-transfers-grid.vue').default);
Vue.component(
    'create-or-edit-template-side-action',
    require('@/components/side-actions/create-or-edit-template-action/create-or-edit-template-action.vue').default,
);

Vue.component('create-or-edit-view-side-action', require('@/components/side-actions/views/create-or-edit-view-action.vue').default);
Vue.component('create-ecosystem-side-action', require('@/components/side-actions/ecosystem/create-ecosystem-action.vue').default);

// Translate
import '@/components/translate/directive';
Vue.component('prd-translate-text', require('@/components/translate/text.vue').default);
Vue.component('prd-translate-text-inline', require('@/components/translate/text-inline.vue').default);
Vue.component('prd-translate-button', require('@/components/translate/button.vue').default);

// notifications
Vue.component('bright-notifications', require('@/components/notification/notifications.vue').default);

// Insights
Vue.component('ecosystem-finance-totals', require('@/components/insights/ecosystem-finance-totals.vue').default);
Vue.component('ecosystem-cash-totals', require('@/components/insights/ecosystem-cash-totals.vue').default);
Vue.component('ecosystem-fearless-totals', require('@/components/insights/ecosystem-fearless-totals.vue').default);
Vue.component('ecosystem-co2-totals', require('@/components/insights/ecosystem-co2-totals.vue').default);

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);
