import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class GenericSearch extends Vue {
    @Prop({ default: () => [] }) public dataItems: any[];
    @Prop({ default: () => [] }) public properties: string[];

    private query: string = '';
    private timeout: any;

    get searchTerm() {
        return this.query;
    }

    @Watch('query')
    public OnQueryChange(val) {
        if (val) {
            clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                const dataItems = this.filterDataItems();
                this.$emit('finished-search', dataItems);
            }, 300);
        }
    }

    public filterDataItems() {
        return this.dataItems.filter((item) => {
            for (const property of this.properties) {
                if (this.containsVal(item, property)) {
                    return true;
                }
            }
        });
    }

    public containsVal(object, key): boolean {
        let value = '';
        return Object.keys(object).some((k) => {
            if (k === key) {
                value = object[k];
                return (
                    value
                        .toString()
                        .toLowerCase()
                        .indexOf(this.query.toLowerCase()) > -1
                );
            }
            if (object[k] && typeof object[k] === 'object') {
                return this.containsVal(object[k], key);
            }
        });
    }
}
