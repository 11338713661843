import Component from 'vue-class-component';
import Vue from 'vue';
import { Member } from '@/models/Member';
import {
    memberHelper,
    loginHelper,
    financeAccountService,
    groupService,
    teamHelper,
    meService,
    financeService,
    roleHelper,
    ecosystemHelper,
} from '@/main';
import { FinanceAccount } from '@/models/Interfaces';
import { AccountType } from '@/models/AccountType';
import BaseGroupPage from './BaseGroupPage';
import to from 'await-to-js';
import { AxiosResponse } from 'axios';
import FinancialActivity from '@/models/FinancialActivity';
import { Route } from 'vue-router';
import { Person } from '@/models/Person';
import { periodModule } from '@/store/modules/period';
import { translateModule } from '@/store/modules/translate';

@Component
export default class GroupDetailMembers extends BaseGroupPage {
    public members: Member[] = [];
    public allMembers: Person[] = [];
    public notMembers: Person[] = [];
    public isMember: boolean = false;
    public isGroupLead: boolean = false;
    public sendingAccounts: FinanceAccount[] = []; // current group saving account + current group member accounts
    public receivingAccounts: FinanceAccount[] = []; // all gourp incoome acounts + current group member accounts
    public transferGroupLeadForm: any = { newLeader: null };
    public newMember: number = null;
    public kickedMember: number = null;
    public totalEnergySpent: number = 0;

    public memberColumns: any[] = [
        { cell: this.renderFullname, title: 'Name', field: 'firstName' },
        { cell: this.renderGrouprole, title: 'Grouprole', field: 'firstName' },
        { cell: this.renderEnergyDedication, title: 'Energy dedication' },
        { title: 'E-mail', field: 'emailAddress' },
    ];

    public async created() {
        const self = this;
        this.isGroupLead = roleHelper.isGroupLead(this.group);

        if (this.isGroupLead || roleHelper.isSiteAdmin()) {
            this.memberColumns.push({ title: 'Actions', cell: this.renderActions, width: 150 });
        }

        const membersEnergyResponse = await groupService.getEnergyDedication(
            teamHelper.currentTeamId,
            self.group.groupId,
            periodModule.selectedPeriod,
        );

        const members = this.group.members;
        for (let i = 0; i < members.length; i++) {
            const energy = membersEnergyResponse.data.find((memb) => memb.personId === members[i].memberId);
            members[i].energyDedication = energy ? energy.amount : 0;
            this.members.push(members[i]);
        }

        this.isMember =
            this.members.filter((item: Member) => {
                return item.memberId === loginHelper.getUser().personId;
            }).length > 0;

        if (this.group) {
            this.sendingAccounts.push(this.group.account);
        }

        const groupAccounts = (await financeAccountService.getAccounts(
            AccountType.GroupIncome,
            teamHelper.currentTeam.id,
            periodModule.selectedPeriod,
        )).data;
        this.receivingAccounts = this.receivingAccounts.concat(groupAccounts);

        this.isLoading = false;
    }

    public async reloadEnergyMembers() {
        const membersEnergyResponse = await groupService.getEnergyDedication(
            teamHelper.currentTeamId,
            this.group.groupId,
            periodModule.selectedPeriod,
        );

        for (const member of this.members) {
            const energy = membersEnergyResponse.data.find((memb) => memb.personId === member.memberId);
            member.energyDedication = energy ? energy.amount : 0;
        }
    }

    public async confirmKick() {
        const self = this;
        this.showPending('REMOVING_MEMBER_PENDING');

        const [err] = await to(groupService.kickMember(this.group.groupId, this.kickedMember, teamHelper.currentTeamId, periodModule.selectedPeriod));
        if (err) {
            return this.clearAndShowError('REMOVING_MEMBER_FAILED', err);
        }

        self.$refs.kickConfirm.hide();
        this.clearAndShowSuccess('REMOVING_MEMBER_SUCCESS');
    }

    public cancelKick() {
        this.$refs.kickConfirm.hide();
    }

    public async checkValidKick(memberId) {
        const [err, response] = await to<AxiosResponse<FinancialActivity>>(
            financeService.getPersonFinancialActiviy(teamHelper.getTeamId(), this.group.groupId, memberId, periodModule.selectedPeriod),
        );
        if (err) {
            this.clearAndShowError('Failed to check financial activity for this member.', err);
            return false;
        }

        return !response.data.isCompensated;
    }

    public async kickMember(item: Member) {
        const result = await this.checkValidKick(item.memberId);
        if (result) {
            this.kickedMember = item.memberId;
            this.$refs.kickConfirm.show();
        } else {
            this.clearAndShowError(`There are financial activities in this period for this person. You aren't allowed to remove this person.`, null);
        }
    }

    public renderEnergyDedication(h, _, row) {
        const props = {
            value: row.dataItem.energyDedication,
            showPercentage: true,
            action: null,
        };

        if (roleHelper.isGroupLead(this.group) || roleHelper.isSiteAdmin()) {
            props.action = async () => {
                await this.openEditEnergyEdicationModal(row.dataItem);
            };
        }
        return h(Vue.component('grid-percentage'), { props });
    }

    public async openEditEnergyEdicationModal(member: Member) {
        const self = this;
        const modal = self.$refs.energyDedicationEdit;

        await modal.setMember(member);
        modal.show();
    }

    public hideEnergyDedicationModal() {
        this.$refs.energyDedicationEdit.hide();
    }

    public renderFullname(h, _, row) {
        const member = row.dataItem;
        const name = `${member.firstName} ${member.insertion ? member.insertion : ''} ${member.lastName}`;
        return h('td', [name]);
    }

    public renderActions(h, _, row: any): any {
        const actions = [
            { title: 'Remove member from group', function: this.kickMember },
            { title: 'Edit energy dedication', function: this.openEditEnergyEdicationModal },
        ];
        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    public renderGrouprole(h, _, row: { dataItem: Member }) {
        const role = typeof this.group.groupLead !== 'undefined' && this.group.groupLead.memberId === row.dataItem.memberId ? 'Group lead' : 'Member';
        return h('td', [role]);
    }

    public exploreAllGroups(): Promise<Route> {
        return this.$router.push({
            name: 'team-directory',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                team: teamHelper.teamParam,
            },
        });
    }

    public openModalJoinGroup(): void {
        const self = this;
        const modal = self.$refs.joinGroup;
        modal.show();
    }

    public async openModalLeaveGroup() {
        const self = this;
        const result = await this.checkValidKick(loginHelper.getUser().personId);

        if (result) {
            const modal = self.$refs.leaveGroup;
            modal.show();
        } else {
            this.clearAndShowError(
                `There are financial activities in this period for this person. You aren't allowed to leave this group in this period.`,
                null,
            );
        }
    }

    public openModalTransferGroupLead(): void {
        const self = this;
        const modal = self.$refs.transferGroupLead;
        modal.show();
    }

    public optionListMembers(): any[] {
        const filteredMembers = [{ value: null, text: translateModule.value('SELECT_A_MEMBER', 'DROPDOWN') }];

        this.members.forEach((member) => {
            if (!this.group.groupLead || (this.group.groupLead && member.memberId !== this.group.groupLead.memberId)) {
                filteredMembers.push({
                    value: member.memberId,
                    text: member.firstName + ' ' + (member.insertion ? member.insertion + ' ' : '') + member.lastName,
                });
            }
        });

        return filteredMembers;
    }

    public optionListNotMembers(): any[] {
        const list = this.notMembers.map((member) => {
            return { value: member.personId, text: member.firstName + ' ' + (member.insertion ? member.insertion + ' ' : '') + member.lastName };
        });

        list.unshift({ value: null, text: translateModule.value('SELECT_A_MEMBER', 'DROPDOWN') });

        return list;
    }

    public optionListSenders(): any[] {
        const list = this.sendingAccounts.map((sender) => {
            return { value: sender.accountId, text: sender.name };
        });

        list.unshift({ value: null, text: 'Select an account' });

        return list;
    }

    public grouprole(member: any): string {
        return typeof this.group.groupLead !== 'undefined' && this.group.groupLead.memberId === member.id ? 'Group lead' : 'Member';
    }

    public async transferLeadership(evt: any) {
        const self = this;
        evt.preventDefault();
        const modal = self.$refs.transferGroupLead;

        if (!this.transferGroupLeadForm.newLeader) {
            return this.showError('Select a valid member');
        }

        self.showPending('TRANSFER_LEADERSHIP_PENDING');
        const [err] = await to(
            groupService.transferLeadership(
                teamHelper.currentTeamId,
                this.group.groupId,
                { newLeader: this.transferGroupLeadForm.newLeader },
                periodModule.selectedPeriod,
            ),
        );
        if (err) {
            return self.clearAndShowError('TRANSFER_LEADERSHIP_FAILED', err);
        }

        self.clearAndShowSuccess('TRANSFER_LEADERSHIP_SUCCESS');
        modal.hide();
        self.isGroupLead = self.transferGroupLeadForm.newLeader === loginHelper.getUser().personId;

        const newLeader = self.members.find((item: Member) => {
            return item.memberId === self.transferGroupLeadForm.newLeader;
        });

        if (newLeader) {
            self.group.groupLead.memberId = newLeader.memberId;
            self.group.groupLead.firstName = newLeader.firstName;
            self.group.groupLead.insertion = newLeader.insertion;
            self.group.groupLead.lastName = newLeader.lastName;
            self.transferGroupLeadForm.oldLeader = newLeader.memberId;
        }
    }

    public async enroll() {
        const self = this;
        self.showPending('JOINING_GROUP_PENDING');
        const [err] = await to(groupService.enroll(this.group.groupId, teamHelper.currentTeamId, periodModule.selectedPeriod));

        if (err) {
            return self.clearAndShowError('JOINING_GROUP_FAILED', err);
        }

        const modal = self.$refs.joinGroup;
        const [profErr, profileResponse] = await to(meService.profile());
        if (profErr) {
            console.log(profErr);
        }
        const profile = profileResponse;
        self.isMember = true;
        self.members.push(new Member(profile));
        self.clearAndShowSuccess('JOINING_GROUP_SUCCESS');
        modal.hide();
    }

    public async unenroll() {
        const self = this;
        self.showPending('LEAVING_GROUP_PENDING');
        const [err] = await to(groupService.unenroll(this.group.groupId, teamHelper.currentTeamId, periodModule.selectedPeriod));

        if (err) {
            return self.clearAndShowError('LEAVING_GROUP_FAILED', err);
        }

        const modal = self.$refs.leaveGroup;
        const myself = self.members.findIndex((item: Member) => item.memberId === loginHelper.getUser().personId);
        self.members.splice(myself, 1);
        self.isMember = false;
        self.clearAndShowSuccess('LEAVING_GROUP_SUCCESS');
        modal.hide();
    }

    public fullName(item: any): string {
        const member = new Member(item);
        return member.getFullName();
    }

    public async addMemberToGroup() {
        const self = this;
        self.allMembers = await memberHelper.getMembers(true);
        self.notMembers = self.allMembers.filter((member: Person) => {
            return !member.inGroup(self.group.groupId);
        });

        const modal = self.$refs.addMemberModal;
        modal.show();
    }

    public async addMember() {
        const self = this;
        self.showPending('JOINING_GROUP_PENDING');

        const [err] = await to(groupService.addMember(this.newMember, this.group.groupId, teamHelper.currentTeamId, periodModule.selectedPeriod));
        if (err) {
            return this.clearAndShowError('JOINING_GROUP_FAILED', err);
        }

        const member = this.notMembers.find((m) => m.personId === this.newMember);

        const profile = member;

        this.members.push(new Member(profile));

        self.clearAndShowSuccess('JOINING_GROUP_SUCCESS');

        const modal = self.$refs.addMemberModal;
        modal.hide();
    }
}
