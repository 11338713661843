import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class ErrorComponent extends Vue {
    public message: string = '';
    public returnUrl: string = null;

    public mounted() {
        this.message = this.$route.params.message as string;

        const r = this.$route.params.returnUrl as string;
        if (r !== undefined) {
            this.returnUrl = r;
        }
    }
}
