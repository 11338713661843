import Vue from 'vue';
import { BaseService } from './baseService';
import { AxiosHelper } from '@/helpers/axiosHelper';
import to from 'await-to-js';
import InsightView from '@/models/Insights/InsightView';
import { periodFilterHelper } from '@/main';
import Lens from '@/models/Insights/Lens';
import PaymentStatus from '@/models/Insights/PaymentStatus';
import PeriodRange from '@/models/PeriodRange';

export default class InsightsService extends BaseService {
    private endpoint = `${Vue.$env().insightsEndpoint}insight-views`;
    private lensEndpoint = `${Vue.$env().insightsEndpoint}lenses`;
    private cashLensEndpoint = `${Vue.$env().insightsEndpoint}cash-lens`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getViews(personal: boolean = false): Promise<InsightView[]> {
        const [err, response] = await to(this.get(`${this.endpoint}${personal ? '/personal' : ''}`));
        if (err) {
            this.clearAndShowError('LOAD_VIEWS_FAILED', err);
            return [];
        }

        return response.data.map((x) => new InsightView(x));
    }

    public async updateView(view: InsightView) {
        return this.put(`${this.endpoint}/${view.insightViewId}`, view);
    }

    public async createView(view) {
        return this.post(`${this.endpoint}`, view);
    }

    public async deleteView(view) {
        return this.delete(`${this.endpoint}/${view.insightViewId}`);
    }

    public async getLenses() {
        const [err, response] = await to(this.get(`${this.lensEndpoint}`));
        if (err) {
            this.clearAndShowError('LOAD_LENSES_FAILED', err);
            return [];
        }

        return response.data.map((x) => new Lens(x));
    }

    public async getLens(lens, view, dates: PeriodRange, paymentTerm: number = null) {
        const config = {
            headers: {
                'x-startPeriod': dates && dates.fromFilterMonth ? dates.fromFilterMonth : periodFilterHelper.selectedPeriod(),
                'x-endPeriod': dates && dates.toFilterMonth ? dates.toFilterMonth : periodFilterHelper.selectedPeriod(),
            },
        };

        return this.get(`${this.lensEndpoint}/${lens}?insight_view=${view}${paymentTerm ? `&payment_term=${paymentTerm}` : ''}`, null, config);
    }

    public async getPaymentStatusses(teamId): Promise<PaymentStatus[]> {
        const [err, response] = await to(this.get(`${this.cashLensEndpoint}/payments?team_id=${teamId}`));
        if (err) {
            this.clearAndShowError('LOAD_PAYMENT_STATUSSES_FAILED', err);
            return [];
        }

        return response.data.map((x: Partial<PaymentStatus>) => new PaymentStatus(x));
    }
}
