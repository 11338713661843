import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseModal from './BaseModal';
import {
    teamHelper,
    attributionService,
    financeAccountService,
    teamService,
    memberHelper,
    loginHelper,
    transferService,
    periodService,
} from '@/main';
import Transfer from '@/models/Transfer';
import { FinanceAccount } from '@/models/Interfaces';
import Group from '@/models/Group';
import { InvoiceType } from '@/models/InvoiceType';
import to from 'await-to-js';
import { AccountType } from '@/models/AccountType';
import { AttributionType } from '@/models/AttributionType';
import AttributionPercentages from '@/models/Finance/AttributionPercentages';
import { TransferType } from '@/models/TransferType';
import Vue from 'vue';
import Team from '@/models/Team';
import { Person } from '@/models/Person';
import { periodModule } from '@/store/modules/period';
import IRecurringTransferRequest from '@/interfaces/IRecurringTransferRequest';
import IRecurringTransfer from '@/interfaces/IRecurringTransfer';
import { translateModule } from '@/store/modules/translate';

@Component
export default class TransferModalComponent extends BaseModal {
    @Prop({ default: () => 'Make transfer' }) public title: string;
    @Prop({ default: () => 'Finalize transfer' }) public buttonText: string;
    @Prop({ default: () => new Group() }) public group: Group;
    @Prop({ default: () => new Transfer() }) public transfer: Transfer;
    @Prop({ default: () => null }) public selectedTeamId: number;
    @Prop({ default: () => null }) public selectedGroupId: number;
    @Prop({ default: () => TransferType.Unknown }) public type;
    @Prop({ default: false }) public meScope;
    @Prop({ default: null }) public recurringTransfer: IRecurringTransfer;
    @Prop({ default: false }) public readonly: boolean;
    @Prop({ default: false }) public memberCompensation: boolean;

    public receivingAccounts: FinanceAccount[] = [];
    public sendingAccounts: FinanceAccount[] = [];

    public editMode: boolean = false;
    public editIsLoading: boolean = false;

    public receivingTeams: Team[] = [];
    public teams: Team[] = [];

    public amountIsDirty: boolean = false;
    public amount: number = 0;

    public internalAmountIsDirty: boolean = false;
    public internalAmount: number = 0;

    public penlAmmountIsDirty: boolean = false;
    public penlAmmount: number = 0;

    public calculatedAmount: number = 0;
    public groupRemittance: number = 0;
    public teamAttribution: number = null;
    public groupAttribution: number = null;

    public attributionPercentages: AttributionPercentages = new AttributionPercentages();

    public attributionsAreLoading: boolean = false;

    public GroupToGroup: boolean = false;
    public GroupToPerson: boolean = false;
    public PersonToPerson: boolean = false;
    public currentBalance: number = 0;
    public selectedPerson: Person = null;

    public applyMissingAttributions: boolean = false;

    public otherTeamKey: string = null;
    public otherTeam: Team = null;

    public isTransferValid: any = {
        amount: true,
        sender: true,
        receiver: true,
        reason: true,
        internalAmount: true,
        team: true,
    };
    public isRecurring: boolean = false;
    public transferSchedule: string = '';

    @Watch('transfer.receiverTeam.teamId', { deep: true, immediate: true })
    public async onTeamSelected(val: number, oldVal: number) {
        if (val && val !== oldVal && !this.isRecurring) {
            const team = this.teams.find((t) => t.teamId === val);

            if (team) {
                const teamPeriods = await periodService.getPeriods(team.teamId);
                const teamPeriod = teamPeriods.find((x) => x.periodId === periodModule.selectedPeriodObject.periodId);
                if (teamPeriod && teamPeriod.closed) {
                    this.showWarning('OTHER_TEAM_PERIOD_CLOSED_WARNING');
                    return;
                } else {
                    this.clearNotifications();
                }

                if (this.group && this.group.groupId > 0) {
                    if (this.type === TransferType.GroupToGroup) {
                        const financeGroupAccounts = await this.getFinanceGroupAccounts(team.teamId);
                        this.receivingAccounts = financeGroupAccounts;
                    }

                    if (this.type === TransferType.GroupToPerson && this.memberCompensation) {
                        const financePersonAccounts = await this.getFinancePersonsAccounts(team.teamId, this.group.groupId);
                        this.receivingAccounts = financePersonAccounts;
                    }
                    this.setReceiverTransferTeam(team);
                }

                if (!this.editMode && this.type !== TransferType.PersonToPerson) {
                    this.transfer.receiverId = null;
                }
            } else {
                const newTeam = new Team();
                this.setReceiverTransferTeam(newTeam);
                this.receivingAccounts = [];
            }
        }
    }

    @Watch('transfer.receiverId')
    public async onReceiverSelected(val) {
        if (!val && this.isRecurring) {
            return;
        }

        if (!this.editMode) {
            this.amount = 0;
            this.internalAmount = 0;

            this.transfer.receiver = this.receivingAccounts.find((account) => account.accountId === this.transfer.receiverId);

            if (this.type === TransferType.PersonToPerson && !this.meScope) {
                this.transfer.sender = this.transfer.receiver;
            }

            this.setTransferDirections();

            if (this.type === TransferType.GroupToGroup || this.type === TransferType.GroupToPerson) {
                this.attributionsAreLoading = true;

                const [groupAttributions, groupRemittances, teamAttributions] = await Promise.all([
                    this.getGroupAttributions(
                        this.transfer.receiverTeam.teamId,
                        this.GroupToGroup ? this.transfer.receiver.relatedEntityId : this.group.groupId,
                    ),
                    this.getGroupRemittances(
                        this.transfer.receiverTeam.teamId,
                        this.GroupToGroup ? this.transfer.receiver.relatedEntityId : this.group.groupId,
                    ),
                    this.getTeamAttributions(this.transfer.receiverTeam.teamId),
                ]);
                this.attributionPercentages.teamAttributionsPercentage = this.attributionPercentages.groupRemittancePercentage = this.attributionPercentages.groupAttributionsPercentage = 0;

                groupRemittances.forEach((attr) => {
                    this.attributionPercentages.groupRemittancePercentage += attr.percentage;
                });

                // only do this when check box in settings is checked
                groupAttributions.forEach((attr) => {
                    this.attributionPercentages.groupAttributionsPercentage += attr.percentage;
                });

                teamAttributions.forEach((attr) => {
                    this.attributionPercentages.teamAttributionsPercentage += attr.percentage;
                });

                this.attributionsAreLoading = false;
            }

            if (this.PersonToPerson) {
                await this.getPersonalAmountToSpent();

                if (!this.meScope) {
                    const [err, response] = await to(teamService.getMembers(teamHelper.currentTeamId, periodModule.selectedPeriod));
                    if (err) {
                        return this.clearAndShowError('Failed to load teammemberships', err);
                    }
                    this.selectedPerson = response.find((x) => x.personId === this.transfer.sender.relatedEntityId);
                }
            }
        }
    }

    @Watch('amount')
    public onAmountChanged(val: any) {
        if (!this.internalAmountIsDirty && !this.penlAmmountIsDirty) {
            // this only works cuz output of prd-numeric-input is ,
            if (isNaN(val)) {
                val = parseFloat(val.replace(',', '.').toString());
            }
            const result = this.calculateTariff(val, true);
            this.internalAmount = result;

            if (this.GroupToPerson || (this.GroupToGroup && this.applyMissingAttributions)) {
                this.calculateAttributions(val);
            }
        }
    }
    @Watch('internalAmount')
    public onInternalAmountChanged(val: any) {
        if (!this.amountIsDirty) {
            // this only works cuz output of prd-numeric-input is ,
            if (isNaN(val)) {
                val = parseFloat(val.replace(',', '.').toString());
            }
            const result = this.calculateTariff(val, false);
            this.amount = result;

            if (this.GroupToPerson) {
                this.calculateAttributions(val, false);
            }
        }
    }

    @Watch('penlAmmount')
    public onPenLAmountChanged(val: any) {
        if (!this.amountIsDirty) {
            // this only works cuz output of prd-numeric-input is ,
            if (isNaN(val)) {
                val = parseFloat(val.replace(',', '.').toString());
            }

            const result = this.calculateFromPenLTariff(val);
            this.internalAmount = result;

            if (this.GroupToPerson) {
                this.calculateAttributions(val, false);
            }
        }
    }

    public async init() {
        this.editIsLoading = true;
        this.show();

        this.teams = await teamService.getTeams();

        if (this.selectedTeamId) {
            this.transfer.senderTeam = { ...this.teams.find((x) => x.teamId === this.selectedTeamId) };
        }

        if (this.group && this.group.groupId > 0) {
            this.sendingAccounts = await this.getFinanceGroupSavingAccounts(teamHelper.currentTeamId);
            this.transfer.sender = this.group.account;
            this.transfer.senderId = this.group.account.accountId;
            this.setReceiverTransferTeam(this.transfer.senderTeam);

            const attributionSettings = await this.getAttributionsSettings(this.group.groupId);
            this.applyMissingAttributions = attributionSettings.applyMissingAttributionsOnTransfer;
            this.transfer.includeMissingAttributions = this.applyMissingAttributions;
            this.transfer.invoiceType = this.applyMissingAttributions ? InvoiceType.Income : InvoiceType.InternalIncome;
        }

        if (this.type === TransferType.PersonToPerson && !this.meScope) {
            const financePersonAccounts = await this.getFinancePersonsAccounts(teamHelper.currentTeamId);
            const members = await memberHelper.getMembers(true);
            const list = financePersonAccounts.filter((acc) => {
                const member = members.find((m) => m.personId === acc.relatedEntityId);
                if (member && member.teamMemberships.length > 1) {
                    return acc;
                }
            });
            this.receivingAccounts = list;
        }

        if (this.type === TransferType.PersonToPerson && this.meScope) {
            const currentUser = loginHelper.getUser();
            const period = periodModule.selectedPeriod;
            const financeAccount = await financeAccountService.getAccount(teamHelper.currentTeam.id, currentUser.personId, period);

            this.receivingAccounts = [financeAccount];

            this.transfer.sender = financeAccount;
            this.transfer.senderId = financeAccount.accountId;
            this.transfer.receiver = financeAccount;
            this.transfer.receiverId = financeAccount.accountId;
            this.transfer.senderTeam = this.teams.find((x) => x.teamId === teamHelper.currentTeamId);

            await this.getPersonalAmountToSpent();
        }

        this.editIsLoading = false;
    }

    public async initTransfer(transfer: Transfer) {
        await this.init();

        Object.assign(this.transfer, { ...transfer });

        this.transfer.receiverId = this.transfer.receiver.accountId;
        this.transfer.senderId = this.transfer.sender.accountId;

        this.applyMissingAttributions = this.transfer.invoiceType === InvoiceType.Income;

        this.setTransferDirections();

        this.attributionsAreLoading = true;

        if (this.transfer.receiver.accountType === AccountType.GroupIncome) {
            await this.getGroupAttributions(this.transfer.receiverTeam.teamId, this.transfer.receiver.relatedEntityId);
        }

        if (this.transfer.receiver.accountType === AccountType.Person) {
            await this.getGroupAttributions(
                this.transfer.receiverTeam.teamId,
                this.GroupToGroup ? this.transfer.receiver.relatedEntityId : this.group.groupId,
            );
            await this.getGroupRemittances(
                this.transfer.receiverTeam.teamId,
                this.GroupToGroup ? this.transfer.receiver.relatedEntityId : this.group.groupId,
            );
            await this.getTeamAttributions(this.transfer.receiverTeam.teamId);
        }

        if (this.GroupToPerson) {
            this.amountIsDirty = true;
            this.amount = this.calculateTariff(this.transfer.amount, false);
            this.$nextTick(() => {
                this.amountIsDirty = false;
            });
        } else {
            if (this.applyMissingAttributions) {
                this.amountIsDirty = true;
                this.amount = this.transfer.amount;
                this.amountIsDirty = false;
            } else {
                this.internalAmountIsDirty = true;
                this.internalAmount = this.transfer.amount;
                this.internalAmountIsDirty = false;
            }
        }

        if (this.type === TransferType.PersonToPerson) {
            this.currentBalance = await this.getPersonalAmountToSpent();
        }

        this.attributionsAreLoading = false;
    }

    public async initRecurringTransfer(transfer: IRecurringTransfer) {
        this.isRecurring = true;

        Object.assign(this.transfer, { ...transfer });

        this.transferSchedule = this.recurringTransfer.schedule;
        this.transfer.reason = this.recurringTransfer.description;
        this.transfer.internalAmount = this.recurringTransfer.amount;

        this.teams = await teamService.getTeams();

        this.transfer.receiverId = transfer.receiverAccount.accountId;
        this.transfer.senderId = transfer.senderAccount.accountId;
        // this.transfer.receiverTeam = transfer.receiverTeam;

        this.receivingAccounts = [transfer.receiverAccount];
        this.amount = transfer.amount;

        this.show();
    }

    public async onHide() {
        this.transfer.receiver = null;
        this.transfer.receiverId = null;
        const emptyTeam = new Team();
        this.setReceiverTransferTeam(emptyTeam);

        if (this.selectedTeamId) {
            this.setReceiverTransferTeam(this.teams.find((x) => x.teamId === this.selectedTeamId));
        } else if (!this.group || this.group.groupId === 0) {
            this.setReceiverTransferTeam(emptyTeam);
        }

        if (this.group && this.group.groupId > 0) {
            const attributionSettings = await this.getAttributionsSettings(this.group.groupId);
            this.applyMissingAttributions = attributionSettings.applyMissingAttributionsOnTransfer;
        }

        this.transfer.reason = '';
        delete this.transfer.transferId;
        this.internalAmount = 0;
        this.amount = 0;
        this.editMode = false;
    }

    public async doTransfer() {
        if (!this.validateTransfer(true)) {
            return;
        }

        this.checkTransferAmount();
        this.transfer.period = periodModule.selectedPeriodObject;

        this.showPending('TRANSFERRING_PENDING');

        if (this.editMode && this.transfer && this.transfer.transferId > 0) {
            transferService
                .updateTransfer(this.transfer)
                .then(() => {
                    if (this.$appInsights) {
                        this.$appInsights.trackEvent({ name: 'transfer-success' });
                    }
                    this.clearAndShowSuccess('TRANSFERRING_SUCCESS');
                    this.$emit('transfer-completed');
                    this.hide();
                })
                .catch((e) => {
                    if (e.response && e.response.status && e.response.status === 400) {
                        const transferResponse = e.response.data;
                        this.clearAndShowError(`${transferResponse.title}. <br/> <br/>  Reason: <br/>${transferResponse.detail}`, e);
                    } else {
                        this.clearAndShowError(e, e);
                    }
                });

            return;
        }

        await transferService
            .doTransfer(this.transfer)
            .then(() => {
                if (this.$appInsights) {
                    this.$appInsights.trackEvent({ name: 'transfer-success' });
                }
                this.clearAndShowSuccess('TRANSFERRING_SUCCESS');
                this.$emit('transfer-completed');
                this.hide();
            })
            .catch((e) => {
                if (e.response && e.response.status && e.response.status === 400) {
                    const transferResponse = e.response.data;
                    this.clearAndShowError(`${transferResponse.title}. <br/> <br/>  Reason: <br/>${transferResponse.detail}`, e);
                } else {
                    this.clearAndShowError(e, e);
                }
            });
    }

    public async createRecurringTransfer() {
        if (!this.validateTransfer(true)) {
            return;
        }

        this.checkTransferAmount();
        const team = parseInt(this.$route.params.teamId);
        const group = parseInt(this.$route.params.groupId);
        const recurringTransfer: IRecurringTransferRequest = {
            description: this.transfer.reason,
            amount: this.transfer.amount,
            schedule: this.transferSchedule,
            senderTeam: team,
            receiverTeam: this.transfer.receiverTeam.teamId,
            invoiceType: this.transfer.invoiceType,
        };

        if (this.GroupToGroup || this.GroupToPerson) {
            recurringTransfer.senderAccount = this.transfer.sender.accountId;
            recurringTransfer.receiverAccount = this.transfer.receiverId;
            recurringTransfer.senderGroup = group;
        }

        if (this.PersonToPerson) {
            recurringTransfer.receiverAccount = this.transfer.receiverId;
            recurringTransfer.receiverPerson = this.transfer.receiver.relatedEntityId;
            recurringTransfer.senderAccount = this.transfer.sender.accountId;
            recurringTransfer.senderPerson = this.transfer.sender.relatedEntityId;
        }

        if (this.GroupToGroup) {
            recurringTransfer.receiverGroup = this.transfer.receiver.relatedEntityId;
        }

        if (this.GroupToPerson) {
            recurringTransfer.receiverPerson =
                this.transfer.receiver.accountType === AccountType.Person ? this.transfer.receiver.relatedEntityId : null;
        }

        const [err] = await to(transferService.createRecurringTransfer(recurringTransfer));
        if (err) {
            return this.clearAndShowError('CREATE_RECURRING_TRANSFER_FAILED', null);
        } else {
            this.$refs.modal.hide();
            this.$emit('transfer-completed', recurringTransfer);
            return this.clearAndShowSuccess('CREATE_RECURRING_TRANSFER_SUCCESS');
        }
    }

    public optionListSenders(): any[] {
        const list = this.sendingAccounts.map((sender) => {
            return { value: sender.accountId, text: sender.name };
        });
        return list;
    }

    public optionListReceivers(): any[] {
        if (!this.receivingAccounts) {
            return [];
        }

        const list = this.receivingAccounts.map((sender) => {
            return { value: sender.accountId, text: sender.name };
        });

        return list;
    }

    public optionListOtherTeams(): any[] {
        const includeOwn = !this.PersonToPerson;

        let list = this.teams.filter((team) => includeOwn || team.teamId !== teamHelper.currentTeamId);

        if (this.selectedPerson && !this.meScope) {
            list = list.filter((x) => {
                for (const ship of this.selectedPerson.teamMemberships) {
                    if (ship.id === x.teamId) {
                        return x;
                    }
                }
            });
        }

        return list.map((team) => {
            return { value: team.teamId, text: team.name };
        });
    }

    public optionListExternalReceivers(): any[] {
        const list = this.receivingAccounts.map((sender) => {
            return { value: sender.accountId, text: sender.name };
        });
        return list;
    }

    public async getAttributionsSettings(groupId: number) {
        const [err, response] = await to(attributionService.getAttributionSetting(groupId));
        if (err) {
            this.showFailedResponse('Failed to load attribution settings', null);
        }
        return response.data;
    }

    public disableInternal() {
        this.amountIsDirty = true;
    }

    public disableAmount() {
        this.internalAmountIsDirty = true;
    }

    public calculateAttributions(val: number, isExternal: boolean = true) {
        if (!isExternal) {
            val = this.calculateTariff(val, false);
        }

        let groupResult = 0;
        let teamResult = 0;
        let groupAttrResult = 0;
        let penlResult = 0;

        if (!this.applyMissingAttributions) {
            groupResult = val * (1 - this.attributionPercentages.groupAttributionsPercentage / 100);
            teamResult =
                (val *
                    (1 - this.attributionPercentages.groupAttributionsPercentage / 100) *
                    (this.attributionPercentages.teamAttributionsPercentage / 100)) /
                (1 - this.attributionPercentages.groupAttributionsPercentage / 100);
            groupAttrResult = val * (this.attributionPercentages.groupAttributionsPercentage / 100);
            penlResult = val - teamResult - groupAttrResult;
        }

        if (this.applyMissingAttributions) {
            groupResult =
                val *
                (1 - this.attributionPercentages.groupAttributionsPercentage / 100) *
                (this.attributionPercentages.groupRemittancePercentage / 100);
            teamResult =
                (val *
                    (1 - this.attributionPercentages.groupAttributionsPercentage / 100) *
                    (1 - this.attributionPercentages.groupRemittancePercentage / 100) *
                    (this.attributionPercentages.teamAttributionsPercentage / 100)) /
                (1 - this.attributionPercentages.groupAttributionsPercentage / 100);
            groupAttrResult = val * (this.attributionPercentages.groupAttributionsPercentage / 100);
            penlResult = val - teamResult - groupAttrResult - groupResult;
        }

        this.groupRemittance = parseFloat(groupResult.toFixed(2));
        this.teamAttribution = parseFloat(teamResult.toFixed(2));
        this.groupAttribution = parseFloat(groupAttrResult.toFixed(2));

        if (this.GroupToPerson && !this.penlAmmountIsDirty) {
            this.penlAmmount = parseFloat(penlResult.toFixed(2));
        }
    }

    public setTransferDirections() {
        if (this.transfer.receiverId && this.transfer.receiver.accountId) {
            this.GroupToGroup =
                this.transfer.receiver.accountType === AccountType.GroupIncome && this.transfer.sender.accountType === AccountType.GroupSavings;
            this.GroupToPerson =
                this.transfer.receiver.accountType === AccountType.Person && this.transfer.sender.accountType === AccountType.GroupSavings;
            this.PersonToPerson =
                this.transfer.receiver.accountType === AccountType.Person && this.transfer.sender.accountType === AccountType.Person;
        }
    }

    public setReceiverTransferTeam(team: Team) {
        this.transfer.receiverTeam.teamId = team.teamId;
        this.transfer.receiverTeam.teamKey = team.teamKey;
        this.transfer.receiverTeam.name = team.name;

        if (team.groups) {
            this.transfer.receiverTeam.groups = team.groups;
        }
        if (team.periods) {
            this.transfer.receiverTeam.periods = team.periods;
        }
    }

    public calculateTariff(amount: number, isExternal: boolean = true) {
        let result = 0;
        if (isExternal) {
            result = amount * (1 - this.attributionPercentages.groupAttributionsPercentage / 100);
        } else {
            result = amount / (1 - this.attributionPercentages.groupAttributionsPercentage / 100);
        }
        return parseFloat(result.toFixed(2));
    }

    public calculateFromPenLTariff(amount) {
        return (
            amount /
            (1 - this.attributionPercentages.teamAttributionsPercentage / 100 / (1 - this.attributionPercentages.groupAttributionsPercentage / 100))
        );
    }

    public async goToGroupTransfers() {
        const params = this.$route.params;
        params.tab = 'transfers';
        await this.$router.push({ name: 'team-finances-group', params });
    }

    public async goToGroupOverview() {
        const params = this.$route.params;
        params.tab = 'overview';
        await this.$router.push({ name: 'team-finances-group', params });
    }

    public async getPersonalAmountToSpent() {
        if (!this.transfer.sender || !this.transfer.senderTeam || this.transfer.sender.accountId === 0 || this.transfer.senderTeam.teamId === 0) {
            return 0;
        }

        const [err, response] = await to(
            financeAccountService.getBalance(
                this.transfer.sender.accountId,
                periodModule.selectedPeriod,
                this.transfer.senderTeam.teamId,
                AccountType.Person,
            ),
        );

        if (err) {
            this.showFailedResponse('Failed to load balance');
            return 0;
        }

        if (!response || !response.items || response.items.length === 0) {
            return 0;
        }

        return (this.currentBalance = response.items[0].item.totalBalance);
    }

    public getCurrentBalance() {
        return Vue.filter('number-format')(this.currentBalance);
    }

    public getBalanceWithoutAmount() {
        return Vue.filter('number-format')(this.currentBalance - this.amount);
    }

    public validateTransfer(isBetweenTeams: boolean = false): boolean {
        const errors = [];
        const minLengthReason = 1;
        const transfer = this.transfer;

        this.isTransferValid.amount = true;
        this.isTransferValid.sender = true;
        this.isTransferValid.receiver = true;
        this.isTransferValid.reason = true;
        this.isTransferValid.internalAmount = true;
        this.isTransferValid.team = true;

        if (transfer.receiverTeam == null && isBetweenTeams) {
            this.isTransferValid.team = false;
            errors.push(translateModule.value('SELECT_A_TEAM', 'DROPDOWN'));
        }

        if (transfer.senderId == null) {
            this.isTransferValid.sender = false;
            errors.push(translateModule.value('SELECT_SENDER'));
        }

        if (transfer.receiverId == null) {
            this.isTransferValid.receiver = false;
            errors.push(translateModule.value('SELECT_RECEIVER'));
        }

        if (transfer.reason.length < minLengthReason) {
            this.isTransferValid.reason = false;
            errors.push(translateModule.value('RECOMMEND_DESCRIPTION'));
        }

        if (errors.length > 0) {
            this.showValidationErrors(errors);
            return false;
        }

        return true;
    }

    public async getFinanceGroupSavingAccounts(team: number) {
        const [err, response] = await to(financeAccountService.getAccounts(AccountType.GroupSavings, team, periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse('Failed to load group accounts', null);
        }

        if (team === teamHelper.currentTeam.id) {
            this.group.account = response.data.find((item: FinanceAccount) => {
                return item.relatedEntityId === this.group.groupId;
            });
        }

        return response.data.filter((item: FinanceAccount) => {
            return item.relatedEntityId !== this.group.groupId;
        });
    }

    public async getFinanceGroupAccounts(team: number) {
        const [err, response] = await to(financeAccountService.getAccounts(AccountType.GroupIncome, team, periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse('Failed to load group accounts', null);
        }

        if (team === teamHelper.currentTeam.id) {
            this.group.accountIncome = response.data.find((item: FinanceAccount) => {
                return item.relatedEntityId === this.group.groupId;
            });
        }

        return response.data.filter((item: FinanceAccount) => {
            return item.relatedEntityId !== this.group.groupId;
        });
    }

    public async getFinancePersonsAccounts(team: number, groupId?: number) {
        const [err, response] = await to(financeAccountService.getAccounts(AccountType.Person, team, periodModule.selectedPeriod, groupId));
        if (err) {
            this.showFailedResponse('FINANCE_PERSONS_FAILED', null);
        }
        return response.data;
    }

    public async getGroupAttributions(teamId: number, groupId: number) {
        const self = this;
        const [err, response] = await to(
            attributionService.getAttributions(AttributionType.GroupAttribution, teamId, periodModule.selectedPeriod, groupId),
        );
        if (err) {
            this.showFailedResponse('Failed to load group attributions', null);
        }

        self.attributionPercentages.groupAttributionsPercentage = 0;

        response.data.forEach((attr) => {
            self.attributionPercentages.groupAttributionsPercentage += attr.percentage;
        });

        return response.data;
    }

    public async getGroupRemittances(teamId: number, groupId: number) {
        const self = this;
        const [err, response] = await to(
            attributionService.getAttributions(AttributionType.GroupRemittance, teamId, periodModule.selectedPeriod, groupId),
        );
        if (err) {
            this.showFailedResponse('Failed to load group remittances', null);
        }

        self.attributionPercentages.groupRemittancePercentage = 0;

        response.data.forEach((attr) => {
            self.attributionPercentages.groupRemittancePercentage += attr.percentage;
        });

        return response.data;
    }

    public async getTeamAttributions(teamId: number) {
        const self = this;
        const [err, response] = await to(attributionService.getAttributions(AttributionType.TeamAttribution, teamId, periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse('Failed to load team attributions', null);
        }

        self.attributionPercentages.teamAttributionsPercentage = 0;
        response.data.forEach((attr) => {
            self.attributionPercentages.teamAttributionsPercentage += attr.percentage;
        });

        return response.data;
    }

    private checkTransferAmount() {
        if ((this.applyMissingAttributions && this.GroupToGroup) || this.PersonToPerson) {
            if (isNaN(this.amount) && this.amount.toString().indexOf(',')) {
                const amount = this.amount.toString().replace(',', '.');
                this.transfer.amount = parseFloat(amount);
            } else {
                this.transfer.amount = this.amount;
            }
        } else {
            if (isNaN(this.internalAmount) && this.internalAmount.toString().indexOf(',')) {
                const amount = this.internalAmount.toString().replace(',', '.');
                this.transfer.amount = parseFloat(amount);
            } else {
                this.transfer.amount = this.internalAmount;
            }
        }
    }
}
