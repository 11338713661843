import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { teamHelper, loginHelper, periodFilterHelper, roleHelper, teamService, transferService } from '@/main';
import BaseFinancePage from './BaseFinancePage';
import Transfer from '../../../../models/Transfer';
import TransferModalComponent from '@/components/modals/transfer-modal';
import { TransferType } from '@/models/TransferType';
import Team from '@/models/Team';
import { Person } from '@/models/Person';
import { periodModule } from '@/store/modules/period';

@Component
export default class TeamFinanceTransfers extends BaseFinancePage {
    public isLoaded: boolean = false;

    public componentKey: number = 0;
    public editMode: boolean = false;
    public editIsLoading: boolean = false;
    public amount: number = 0;
    public teams: Team[] = [];
    public members: Person[] = [];
    public team: string = '';
    public teamId: number;
    public receivingTeams: Team[] = [];
    public externalTransfers: Transfer[];
    public transferGridUpdate: number = 0;

    public transfer: Transfer = new Transfer();
    public type: TransferType = TransferType.PersonToPerson;

    public $refs!: {
        transferPAndLModal: TransferModalComponent;
    };

    public isTransferValid: any = {
        amount: true,
        sender: true,
        receiver: true,
        reason: true,
        internalAmount: true,
        team: true,
    };

    public externalTransfersColumns: any[] = [
        { cell: this.renderTransferAmount, title: 'AMOUNT', width: 100 },
        { field: 'reason', title: 'DESCRIPTION' },
        { cell: this.renderMemberBy, title: 'SENDER', width: 250 },
        { cell: this.renderMemberFor, title: 'RECEIVER', width: 250 },
        { cell: this.renderMemberInitiator, title: 'INITIATOR', width: 250 },
        { cell: this.renderActions, title: 'ACTIONS', width: 120 },
    ];

    public async created() {
        this.teamId = teamHelper.currentTeam.id;
        this.teams = await teamService.getTeams();
        this.members = await teamService.getMembers(this.teamId);

        await this.loadExternalTransfers();
        this.transfer.senderTeam = this.teams.find((x) => x.teamId === this.teamId);
        this.isLoaded = true;
    }

    public async loadExternalTransfers() {
        const period = periodModule.selectedPeriod;
        const transfers = await transferService.getCrossTeamPersonTransfers(this.teamId, period);

        transfers.forEach((transfer) => {
            transfer.receiverTeam = this.teams.find((x) => x.teamId === transfer.receiverTeam.teamId);
            transfer.senderTeam = this.teams.find((x) => x.teamId === transfer.senderTeam.teamId);
        });

        this.externalTransfers = transfers;
    }

    get transferTitle() {
        return 'Move a P&L of one of your team members';
    }

    public async openTransferModal() {
        await this.$refs.transferPAndLModal.init();
    }

    public async reloadData() {
        this.isLoaded = false;
        await this.loadExternalTransfers();
        this.isLoaded = true;
    }

    public renderTransferAmount(h, _, row) {
        const props = { value: row.dataItem.amount };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderMemberInitiator(h, _, row) {
        const self = this;
        let member = self.members.find((m: Person) => {
            return m.personId === row.dataItem.initiator;
        });

        if (!member) {
            member = new Person();
            member.firstName = 'Not available';
        }

        return this.renderMember(h, member);
    }

    public amountUpdated(amount) {
        this.amount = amount;
    }

    public renderActions(h, _, row): any {
        let actions = [];
        const userId = loginHelper.getUser().personId;
        const initiator = row.dataItem && typeof row.dataItem.initiator !== 'undefined' ? parseInt(row.dataItem.initiator) : 0;

        if (initiator === userId || roleHelper.isSiteAdmin() || roleHelper.isFinanceEmployee()) {
            actions = [
                { title: 'Edit', function: this.editTransfer, disabled: periodFilterHelper.isCurrentPeriodClosed() },
                { title: 'Delete', function: this.removeTransfer, disabled: periodFilterHelper.isCurrentPeriodClosed() },
            ];
        }

        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    public removeTransfer(item) {
        transferService.deleteTransfer(item.transferId);

        const transfer = this.externalTransfers.findIndex((t: Transfer) => {
            return t.transferId === item.transferId;
        });

        this.externalTransfers.splice(transfer, 1);
        this.transferGridUpdate++;
    }

    public async editTransfer(item) {
        this.editMode = true;

        this.$refs.transferPAndLModal.show();
        Vue.nextTick(async () => {
            this.$refs.transferPAndLModal.editMode = true;
            await this.$refs.transferPAndLModal.initTransfer(item);
        });

        this.editIsLoading = false;
    }

    public calculateTransferGridHeight() {
        if (this.externalTransfers.length < 25) {
            return { height: `${39 + this.externalTransfers.length * 47}px` };
        }
    }
}
