import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridGroupFilterComponent extends Vue {
    @Prop({ default: '' }) public value: string;

    public getValue() {
        return this.value;
    }
}
