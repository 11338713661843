import BaseGroupPage from './BaseGroupPage';
import { periodFilterHelper, teamHelper, roleHelper, compensationService, ecosystemHelper } from '@/main';
import { FinanceAccount } from '../../../../models/Interfaces';
import Component from 'vue-class-component';
import Vue from 'vue';
import Ambition from '@/models/Finance/Ambition';
import { InvoiceType } from '@/models/InvoiceType';
import moment from 'moment';
import to from 'await-to-js';
import { periodModule } from '@/store/modules/period';

@Component
export default class GroupDetailAmbitions extends BaseGroupPage {
    public isLoaded: boolean = false;
    public financeAccounts: FinanceAccount[] = [];
    public ambitions: Ambition[] = [];
    public editID: number = null;
    public compensations: any[] = [];
    public ambitionsUpdated: number = 0;

    public ambitionsColumns: any[] = [
        {
            field: 'member',
            cell: this.renderMemberRevenue,
            title: 'Member',
            className: 'bg-white',
            locked: true,
            width: 180,
        },
        { field: 'externalRevenueGoal', title: 'Client rate goal', width: 165, cell: this.renderExternalRevenueGoal },
        { field: 'internalRevenueAmbition', title: 'Ecosystem rate ambition', width: 185, cell: this.renderBla },
        { field: 'actualRevenueGoal', title: 'Actual client rate revenue', width: 175, cell: this.renderActualInternalRevenue },
        { field: 'actualExternalRevenue', title: 'Actual ecosystem rate revenue', width: 175, cell: this.renderActualExternalRevenue },
        { field: 'cost', title: 'Cost (Needed compensation for member based on energy and fixed cost)', width: 200, cell: this.renderCosts },
        { field: 'actualCompensations', title: 'Actual compensation for member (transfers)', width: 200, cell: this.renderActualCompensations },
        { field: 'guidancePercentage', title: 'Guidance percentage', width: 160, cell: this.renderBla },
        { field: 'expertedMargin', title: 'Expected margin', width: 135, cell: this.renderExpectedMargin },
        { field: 'expectedFee', title: 'Expected guidance fee (guild mode)', width: 160, cell: this.renderExpectedFee },
        { field: 'expectedResult', title: 'Expected Result', width: 130, cell: this.renderExpectedResult },
        { field: 'actualMargin', title: 'Actual margin', width: 120, cell: this.renderActualMargin },
        { field: 'actualFee', title: 'Actual guidance fee (guild mode)', width: 160, cell: this.renderActualFee },
        { field: 'actualResult', title: 'Actual result', width: 115, cell: this.renderActualResult },
    ];

    public async mounted() {
        await this.initInvoicesOrExpensesGroup(InvoiceType.Income);

        if (!roleHelper.isSiteAdmin() && !roleHelper.isFinanceEmployee() && !roleHelper.isGroupLead(this.group)) {
            await this.$router.push({
                name: 'team-finances-group',
                params: {
                     ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                            ecosystemKey: ecosystemHelper.currentEcosystem.key,
                    groupKey: this.group.groupKey,
                    teamId: teamHelper.currentTeam.id.toString(),
                            teamKey: teamHelper.currentTeam.key,
                    tab: 'overview',
                    period: periodModule.selectedPeriod,
                },
            });
        }

        await this.initAttributions();

        this.isLoaded = true;
    }

    public async recalculateInternalIncome(dates) {
        this.invoicesReloading = true;
        this.showPending('CALCULATING_REVENUE_PENDING');

        this.invoices = [];
        this.compensations = [];

        const startPeriod = periodModule.periods.find((x) => moment(x.startDate, 'YYYY-MM-DD').format('YYYY-MM') === dates.fromFilterMonth);
        const endPeriod = periodModule.periods.find((x) => moment(x.startDate, 'YYYY-MM-DD').format('YYYY-MM') === dates.toFilterMonth);

        if (startPeriod && endPeriod) {
            for (let i = startPeriod.periodId; i <= endPeriod.periodId; i++) {
                const period = periodModule.periods.find((x) => x.periodId === i);

                const invoices = await this.loadInvoices(InvoiceType.Income, moment(period.startDate, 'YYYY-MM-DD').format('YYYY-MM'));
                const compensations = await this.loadGroupCompensations(moment(period.startDate, 'YYYY-MM-DD').format('YYYY-MM'));

                this.invoices.push(...invoices);
                this.compensations.push(...compensations);
            }
        }

        await this.calculateRevenue();

        this.populateGrid();
        this.clearNotifications();
        this.invoicesReloading = false;
        this.ambitionsUpdated++;
    }

    public populateGrid() {
        this.ambitions = [];
        for (const member of this.group.members) {
            const ambition = new Ambition({ member });

            const personIncome = this.personIncomes.find((x) => x.member.id === member.memberId);
            if (personIncome) {
                ambition.actualInternalRevenue = personIncome.totalAmount;
                ambition.actualExternalRevenue = personIncome.totalExternalAmount;
            }

            const personCompensations = this.compensations.filter((x) => x.member.personId === member.memberId);
            for (const pc of personCompensations) {
                ambition.cost += pc.costs;
                ambition.actualCompensations += pc.compensation;
            }

            this.ambitions.push(ambition);
        }
    }

    public updateAmbition(a) {
        const ambition = a.dataItem;
        ambition[a.prop] = a.value;

        Vue.set(ambition, 'inEdit', false);
    }

    public async loadGroupCompensations(period?: string) {
        const [err, response] = await to(
            compensationService.getCompensations(this.teamId, this.group.groupId, period ? period : periodModule.selectedPeriod),
        );
        if (err) {
            this.showFailedResponse('Failed to load compensations');
        }

        return response.data.memberCompensations;
    }

    public renderBla(h, _, row, listeners) {
        const column = this.ambitionsColumns[row.columnIndex];
        const props = {
            value: row.dataItem[column.field],
            prop: column.field,
            dataItem: row.dataItem,
            allowNegative: true,
            appendPercentage: column.field === 'guidancePercentage',
        };

        return h(Vue.component('grid-numeric-editor'), {
            props,
            on: {
                saved: (a) => {
                    listeners.saved(a);
                },
                changed: (a) => {
                    listeners.changed(a);
                },
            },
        });
    }

    public getActualGroupResult() {
        let count = 0;
        this.ambitions.forEach((x) => (count += x.actualResult));
        return Vue.filter('number-format')(count);
    }
    public getActualGroupFee() {
        let count = 0;
        this.ambitions.forEach((x) => (count += x.actualFee));
        return Vue.filter('number-format')(count);
    }
    public getExpectedGroupResult() {
        let count = 0;
        this.ambitions.forEach((x) => (count += x.expectedResult));
        return Vue.filter('number-format')(count);
    }
    public getExpectedGroupFee() {
        let count = 0;
        this.ambitions.forEach((x) => (count += x.expectedFee));
        return Vue.filter('number-format')(count);
    }

    public renderExternalRevenueGoal(h, _, row) {
        const props = { value: row.dataItem.externalRevenueGoal, classNames: 'bg-secondary' };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderActualInternalRevenue(h, _, row) {
        const amount = row.dataItem.actualInternalRevenue;
        const props = { value: amount, classNames: 'bg-secondary' };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderActualExternalRevenue(h, _, row) {
        const amount = row.dataItem.actualExternalRevenue;
        const props = { value: amount, classNames: 'bg-secondary' };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderCosts(h, _, row) {
        const amount = row.dataItem.cost;
        const props = { value: amount, classNames: 'bg-secondary' };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderActualCompensations(h, _, row) {
        const props = { value: row.dataItem.actualCompensations, classNames: 'bg-secondary' };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderActualMargin(h, _, row) {
        const amount = row.dataItem.actualMargin;
        const props = { value: amount, classNames: 'bg-secondary' };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderActualFee(h, _, row) {
        const props = { value: row.dataItem.actualFee, classNames: 'bg-secondary' };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderActualResult(h, _, row) {
        const amount = row.dataItem.actualResult;
        const props = { value: amount, classNames: 'bg-secondary' };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderExpectedMargin(h, _, row) {
        const amount = row.dataItem.expectedMargin;
        const props = { value: amount, classNames: 'bg-secondary' };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderExpectedFee(h, _, row) {
        const props = { value: row.dataItem.expectedFee, classNames: 'bg-secondary' };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderExpectedResult(h, _, row) {
        const amount = row.dataItem.expectedResult;
        const props = { value: amount, classNames: 'bg-secondary' };
        return h(Vue.component('number-formatter'), { props });
    }

    public rowClick(e) {
        if (periodFilterHelper.isCurrentPeriodClosed()) {
            return;
        }

        const ambition = this.ambitions.find((i) => i.member.memberId === this.editID);
        this.ambitions.forEach((expense) => (expense[this.selectedField] = false));

        if (ambition) {
            Vue.set(ambition, 'inEdit', false);
        }

        this.editID = e.dataItem.member.id;
        Vue.set(e.dataItem, 'inEdit', true);
    }
}
