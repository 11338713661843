import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { groupService } from '@/main';
import to from 'await-to-js';
import { Member } from '@/models/Member';

@Component
export default class MembersComponent extends Vue {
    @Prop({ default: () => [] }) public members: any;
    @Prop({ default: false }) public lazy: boolean;
    @Prop({ default: null }) public groupId: number;
    @Prop({ default: null }) public teamId: number;
    @Prop({ default: null }) public memberCount: number;

    public memberItems: Member[] = [];
    public defaultItem = {
        text: this.memberDropdownButtonTemplate(),
    };

    public valueRender = 'valueTemplate';
    public itemRender = 'itemTemplate';

    public mounted() {
        if (this.members) {
            this.memberItems = this.members;
        }
    }

    public get dropdownMembers() {
        return this.memberItems;
    }

    public memberDropdownButtonTemplate() {
        if (this.lazy) {
            return `<span class="icon-users mr-1"></span>${this.memberCount}`;
        }

        return `<span class="icon-users mr-1"></span>${this.members.length}`;
    }

    public async loadMembers() {
        if (this.lazy) {
            const [err, response] = await to(groupService.getMembers(this.groupId, this.teamId));
            if (!err) {
                this.memberItems = response.data;
            }
        }
    }

    public memberDropdownTemplate(item) {
        return {
            template: Vue.component('member-name'),
            templateArgs: { member: item },
        };
    }
}
