import { AxiosHelper } from '../helpers/axiosHelper';
import Period from '../models/Period';
import { BaseService } from './baseService';
import Vue from 'vue';
import to from 'await-to-js';
import { $router } from '@/main';

export class PeriodService extends BaseService {
    private endpoint = `${Vue.$env().financeEndpoint}`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getPeriods(team?: number) {
        const [err, response] = await to(this.get<Period[]>(`${this.endpoint}periods?teamId=:teamId`, [['teamId', team]]));
        if (err) {
            await $router.push({
                name: 'app-error',
                params: { message: `Failed to load periods.` },
            });

            return [];
        }

        return response.data.map((x) => new Period(x));
    }

    public async getTeamPeriodStatusses() {
        const [err, response] = await to(this.get(`${this.endpoint}periods/status`));
        if (err) {
            await $router.push({
                name: 'app-error',
                params: { message: `Failed to load periods.` },
            });

            return [];
        }

        return response.data;
    }

    public closePeriod(period: number, team: number, soft: boolean = false) {
        return this.post(`${this.endpoint}periods/:period/close?teamId=:team&softClose=:soft`, null, [
            ['period', period],
            ['team', team],
            ['soft', soft],
        ]);
    }

    public openPeriod(period: number, team: number) {
        return this.post(`${this.endpoint}periods/:period/open?teamId=:team`, null, [['period', period], ['team', team]]);
    }

    public canClose(team: number, period: string) {
        return this.get(`${this.endpoint}periods/can-close?teamId=${team}`, null, { headers: { 'x-period': period } });
    }
}
