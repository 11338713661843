import PageRender from '@/models/PageRender';
import { Component } from 'vue-property-decorator';
import IMapSetting from '@/models/Map/IMapSettings';
import { mapService, teamService, financeService, groupService, financeAccountService, roleHelper, $router, ecosystemHelper } from '@/main';
import to from 'await-to-js';
import GraphData from '@/models/Graph/GraphData';
import Team from '@/models/Team';
import Group from '@/models/Group';
import { AccountType } from '@/models/AccountType';
import { periodModule } from '@/store/modules/period';

@Component
export default class MapSVGDashboardComponent extends PageRender {
    // tslint:disable: restrict-plus-operands
    public isLoading: boolean = true;
    public editMode: boolean = false;
    public isTeamScope: boolean = false;
    public team: Team = new Team();
    public teams: Team[] = [];
    public groups: Group[] = [];
    public teamRevenue: any[] = [];
    public ecosystemevenue = [];
    public graph = new GraphData();

    private mapSettings: IMapSetting[] = [];

    public async created() {
        this.isTeamScope = typeof this.$route.params.teamId !== 'undefined';
        if (this.isTeamScope) {
            if (!roleHelper.isSiteAdmin() && !roleHelper.isFinanceEmployee()) {
                await $router.push({ name: 'not-found' });
            }
        }
    }

    public async mounted() {
        if (this.isTeamScope) {
            const respose = await teamService.getTeam(parseInt(this.$route.params.teamId), periodModule.selectedPeriod);
            this.team = respose.data;
        }

        this.mapSettings = await mapService.getSettings(this.team.teamId);

        if (!this.isTeamScope) {
            await this.handleEcosystemScope();
        } else {
            await this.handleTeamScope();
        }

        this.isLoading = false;
    }

    public async handleEcosystemScope() {
        this.teams = await teamService.getTeams();
        this.ecosystemevenue = await this.getEcosystemRevenue();

        for (const [index, team] of this.teams.entries()) {
            let settings = this.mapSettings.find((x) => x.team === team.teamId);

            if (!settings) {
                settings = { x: 20 * index, y: 20, radius: 20, team: team.teamId, group: null };
            }

            this.graph.vertices.push({
                id: team.teamId,
                key: team.teamKey,
                name: team.name,
                x: settings.x,
                y: settings.y,
                radius: settings.radius,
                onclick: () => this.goToTeam(team),
            });
        }

        for (const transfer of this.ecosystemevenue) {
            const v1 = this.graph.vertices.find((v) => v.id === transfer.teamId);

            if (!v1) {
                continue;
            }

            for (const revenue of transfer.revenue) {
                const v2 = this.graph.vertices.find((v) => v.id === revenue.teamId);

                if (!v2) {
                    continue;
                }

                this.graph.edges.push({ edge: `${v1.id}${v2.id}`, v1, v2, weight: revenue.total });
            }
        }
    }

    public goToTeam(team: Team) {
        if (this.editMode || team.groups.length === 0) {
            return;
        }

        if (roleHelper.isSiteAdmin() || roleHelper.isFinanceEmployee()) {
            // tslint:disable-next-line: no-floating-promises
            $router.push({
                name: 'ecosystem-graph',
                params: {
                    ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                    ecosystemKey: ecosystemHelper.currentEcosystem.key,
                    period: periodModule.selectedPeriod,
                    teamId: team.teamId.toString(),
                },
            });
        }
    }

    public async handleTeamScope() {
        this.groups = await this.getTeamGroups(this.team.teamId);

        const groupsIcomeAccountIds = (await financeAccountService.getAccounts(
            AccountType.GroupIncome,
            this.team.teamId,
            periodModule.selectedPeriod,
        )).data;
        this.groups.forEach((item: Group) => {
            return (item.accountIncome = groupsIcomeAccountIds.find((account) => {
                return account.relatedEntityId === item.groupId;
            }));
        });

        this.teamRevenue = await this.getTeamRevenue(this.team.teamId);

        for (const [index, group] of this.groups.entries()) {
            let settings = this.mapSettings.find((x) => x.group === group.groupId);
            if (!settings) {
                settings = { x: 20 * index, y: 20, radius: 20, team: null, group: group.groupId };
            }
            this.graph.vertices.push({ id: group.groupId, name: group.name, x: settings.x, y: settings.y, radius: settings.radius });
        }

        for (const transfer of this.teamRevenue) {
            const v1 = this.graph.vertices.find((v) => v.id === transfer.teamId);

            if (!v1) {
                continue;
            }

            for (const revenue of transfer.revenue) {
                const v2 = this.graph.vertices.find((v) => v.id === revenue.teamId);

                if (!v2) {
                    continue;
                }

                this.graph.edges.push({ edge: `${v1.id}${v2.id}`, v1, v2, weight: revenue.total });
            }
        }
    }

    public async getTeamGroups(teamId: number) {
        const [err, response] = await to(groupService.getGroups(teamId, periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse(`Failed to load groups for team ${teamId}`, null);
        }

        return response.data;
    }

    public async getTeamRevenue(teamId: number) {
        const [err, response] = await to(financeService.getTeamRevenue(periodModule.selectedPeriod, teamId));
        if (err) {
            this.showFailedResponse('Failed to load group intra revenue on team level', null);
        }

        return response.data;
    }

    public async getEcosystemRevenue() {
        const [err, response] = await to(financeService.getEcosystemRevenue(periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse('Failed to load team intra revenue on ecosystem level', null);
        }

        return response.data;
    }
}
