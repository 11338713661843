import { Component } from 'vue-property-decorator';
import { teamHelper, roleHelper, ecosystemHelper, insightService } from '@/main';
import Vue from 'vue';
import { periodModule } from '@/store/modules/period';
import PaymentStatus from '@/models/Insights/PaymentStatus';
import { InvoiceType } from '@/models/InvoiceType';
import PaymentDetail from './team-insights-payment-detail.vue';

@Component
export default class TeamFinancePaymentStatusses extends Vue {
    public teamId: number = null;
    public paymentStatusses: PaymentStatus[] = [];
    public isLoaded: boolean = false;
    public onlyUnpaid: boolean = false;
    public invoiceType: InvoiceType = InvoiceType.Income;
    public InvoiceType = InvoiceType;
    public detailTemplate = PaymentDetail;

    public aggregateColumns: any[] = [
        { field: 'invoiceReference', title: 'REFERENCE' },
        { field: 'invoiceAmount', cell: this.formatAmountPaidValue, title: 'TOTAL_INVOICE_AMOUNT', footerCell: this.renderFooterCellSum },
        { field: 'amountPaid', cell: this.formatAmountPaidValue, title: 'TOTAL_AMOUNT_PAID', footerCell: this.renderFooterCellSum },
    ];

    public aggregatePayments: any[] = [];

    public get payments() {
        return this.paymentStatusses.filter((status) => {
            const matchOnlyUnpaid = (this.onlyUnpaid && !status.periodMatched) || !this.onlyUnpaid;
            const matchInvoiceType = status.invoiceType === this.invoiceType;
            return matchOnlyUnpaid && matchInvoiceType;
        });
    }

    public async mounted() {
        if (!roleHelper.isSiteAdmin() && !roleHelper.isFinanceEmployee()) {
            await this.$router.push({
                name: 'team-finances',
                params: {
                    ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                    ecosystemKey: ecosystemHelper.currentEcosystem.key,
                    teamId: teamHelper.currentTeam.id.toString(),
                    teamKey: teamHelper.currentTeam.key,
                    tab: 'group-overview',
                    period: periodModule.selectedPeriod,
                },
            });
        }

        this.teamId = teamHelper.currentTeamId;
        this.paymentStatusses = await insightService.getPaymentStatusses(teamHelper.getTeamId());
        this.calculateAggregatePayments();
        this.isLoaded = true;

        this.$watch('paymentStatusses', this.calculateAggregatePayments);
        this.$watch('invoiceType', this.calculateAggregatePayments);
        this.$watch('onlyUnpaid', this.calculateAggregatePayments);
    }

    private formatAmountPaidValue(h, _, row) {
        const props = { value: row.dataItem[row.field] };
        return h(Vue.component('number-formatter'), { props });
    }

    private calculateAggregatePayments() {
        const aggregates = {};
        this.payments.forEach((payment: PaymentStatus) => {
            const key = payment.externalIdentifier;
            if (!aggregates[key]) {
                aggregates[key] = {
                    externalIdentifier: payment.externalIdentifier,
                    invoiceReference: payment.invoiceReference,
                    invoiceAmount: 0,
                    amountPaid: 0,
                    payments: [],
                };
            }
            aggregates[key].invoiceAmount += payment.invoiceAmount;
            aggregates[key].amountPaid += payment.amountPaid;
            aggregates[key].payments.push(payment);
        });

        this.aggregatePayments = Object.values(aggregates);
    }

    private renderFooterCellSum(h, _, row) {
        const total = this.aggregatePayments.reduce((acc: number, current) => acc + (current[row.field] as number), 0);
        const props = { value: total, renderTd: false };
        return h(Vue.component('number-formatter'), { props });
    }
}
