import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class InsightsEcosystemCashTotalsComponent extends Vue {
    @Prop({ default: () => null }) public data: any;

    public getCashflowResult() {
        return Vue.filter('number-format')(this.data ? this.data.result : 0);
    }

    public getCashflowBalance() {
        return Vue.filter('number-format')(this.data ? this.data.balance : 0);
    }

    public getUnpaidRevenue() {
        return Vue.filter('number-format')(this.data ? this.data.unpaidRevenue : 0);
    }

    public getUnpaidExpenses() {
        return Vue.filter('number-format')(this.data ? this.data.unpaidExpenses : 0);
    }
}
