import { Container } from '@svgdotjs/svg.js';
import ISvgElement from './ISvgElement';

export default class SvgLabel implements ISvgElement {
    private paddingY = 4;
    private paddingX = 8;
    private radius = 4;
    private fill = '#fff';
    private stroke = { width: 1, color: '#f2f6f9' };
    private container: Container;

    constructor(container: Container) {
        this.container = container;
    }

    public draw(content: string) {
        const label = this.container.group();
        const text = label.text(content).move(0, 0);
        const rect = label.rect(text.bbox().width + this.paddingX, text.bbox().height + this.paddingY);
        rect.fill(this.fill).stroke(this.stroke);
        rect.x(-this.paddingX / 2).y(-this.paddingY / 2);
        rect.insertBefore(text);
        rect.radius(this.radius, this.radius);

        return label;
    }
}
