import { Container, Element } from '@svgdotjs/svg.js';

export default class SvgAnimate {
    public static duration = 500;

    public static appear(container: Container): Promise<void> {
        return new Promise((resolve) => {
            let done = 0;
            const children = container.children();
            children.forEach((child: Element) => {
                const { factor, delay, duration } = this.randomTimes();
                child.transform({ scale: factor })
                    .attr({ opacity: 0 })
                    .animate(duration, delay, 'now')
                    .attr({ opacity: 1 })
                    .transform({ scale: 1 })
                    .after(() => {
                        done++;
                        if (done === children.length) {
                            resolve();
                        }
                    });
            });
        });
    }

    public static disappear(container: Container): Promise<void> {
        return new Promise((resolve) => {
            let done = 0;
            const children = container.children();
            children.forEach((child: Element) => {
                const { delay, duration } = this.randomTimes();
                child.transform({ scale: 1 })
                    .attr({ opacity: 1 })
                    .animate(duration / 5, delay, 'now')
                    .attr({ opacity: 0 })
                    .transform({ scale: 1.2 })
                    .after(() => {
                        done++;
                        if (done === children.length) {
                            resolve();
                        }
                    });
            });
        });
    }

    public static fadeIn(container: Container) {
        const children = container.children();
        children.forEach((child: Element) => {
            const { delay, duration } = this.randomTimes();
            child.attr({ opacity: 0 }).animate(duration, delay).attr({ opacity: 1 });
        });
    }

    public static fadeOut(container: Container) {
        const children = container.children();
        children.forEach((child: Element) => {
            const { delay, duration } = this.randomTimes();
            child.attr({ opacity: 1 }).animate(duration, delay).attr({ opacity: 0 });
        });
    }

    private static randomTimes(): { factor: number, delay: number, duration: number } {
        const factor = Math.ceil(Math.random() * 100) / 100;
        const delay = Math.ceil(Math.random() * 250);
        const duration = factor * this.duration;

        return { factor, delay, duration };
    }
}