import moment from 'moment';
import PageRender from '../models/PageRender';
import { periodModule } from '@/store/modules/period';

export class PeriodFilterHelper extends PageRender {
      public selectedPeriod() {
        return periodModule.selectedPeriod;
    }

    public selectedPeriodObject() {
        return periodModule.selectedPeriodObject;
    }

    public previousMonth(current = null, format = 'YYYY-MM'): string {
        const input = current ? current : periodModule.selectedPeriod;
        const now = moment(input, 'YYYY-MM');
        const previous = now.subtract(1, 'month');

        return previous.format(format);
    }

    public currentMonth(format = 'YYYY-MM'): string {
        const now = moment(periodModule.selectedPeriod, 'YYYY-MM');
        return now.format(format);
    }

     public getSelectedPeriodObject() {
        return periodModule.selectedPeriodObject;
    }

    public isCurrentPeriodClosed() {
        if (periodModule.selectedPeriodObject) {
            return periodModule.selectedPeriodObject.closed;
        }

        return true;
    }

    public areAllPreviousPeriodsClosed() {
        const currentPeriodIndex = periodModule.periods.findIndex((x) => x.periodId === periodModule.selectedPeriodObject.periodId);
        const periods = periodModule.periods.slice(currentPeriodIndex + 1);
        return periods.every((x) => x.closed);
    }

    public isValidPeriod(periodToCheck: string): boolean {
        return (
            periodModule.periods.find((period) => {
                return moment(period.startDate, 'YYYY-MM-DD').format('YYYY-MM') === periodToCheck;
            }) != null
        );
    }
}
