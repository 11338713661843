import VueRouter, { Route } from 'vue-router';
import { navigationHelper } from '@/main';
import BaseEcosystem from '@/pages/Base.vue';
import { profileModule } from '@/store/modules/profile';
import Vue from 'vue';

Vue.use(VueRouter);

const routes = [
    {
        name: 'login',
        path: '/login',
        component: () => import(/* webpackChunkName: "login" */ '../pages/account/login/login.vue'),
        meta: { title: 'Login' },
    },
    {
        name: 'home',
        path: '/',
        component: () => import(/* webpackChunkName: "home" */ '../pages/home/home.vue'),
        meta: { title: 'Loading..' },
    },
    {
        name: 'ecosystem-base',
        path: '/:ecosystemId(\\d+)-:ecosystemKey/',
        component: BaseEcosystem,
        children: [
            {
                name: 'ecosystem-settings',
                path: 'settings/:period/:tab?',
                component: require('@/pages/ecosystem/settings/settings.vue').default,
                meta: {
                    title: 'Ecosystem',
                    scope: 'ecosystem',
                    section: 'settings',
                    breadcrumb: {
                        text: 'SETTINGS',
                        items: ['ecosystem-overview', 'ecosystem-directory', 'ecosystem-finances', 'ecosystem-graph'],
                    },
                },
            },
            {
                name: 'ecosystem-insights',
                path: 'insights/:period/:view?',
                component: require('@/pages/ecosystem/insights.vue').default,
                meta: {
                    title: 'Ecosystem',
                    scope: 'ecosystem',
                    section: 'insights',
                    breadcrumb: {
                        text: 'INSIGHTS',
                        items: ['ecosystem-graph', 'ecosystem-overview', 'ecosystem-directory', 'ecosystem-finances', 'ecosystem-settings'],
                    },
                },
            },
            {
                name: 'ecosystem-cells',
                path: 'cells/:period/:view?',
                component: require('@/modules/cells/views/cells.vue').default,
                meta: {
                    title: 'Ecosystem',
                    scope: 'ecosystem',
                    section: 'cells',
                    breadcrumb: {
                        text: 'CELLS',
                        items: ['ecosystem-graph', 'ecosystem-overview', 'ecosystem-directory', 'ecosystem-finances', 'ecosystem-settings'],
                    },
                },
            },
            {
                name: 'ecosystem-subscription',
                path: 'subscription/:period/',
                component: require('@/pages/ecosystem/subscription/subscription.vue').default,
                meta: {
                    title: 'Ecosystem',
                    scope: 'ecosystem',
                    section: 'subscription',
                    breadcrumb: {
                        text: 'SUBSCRIPTION',
                        items: ['ecosystem-graph', 'ecosystem-overview', 'ecosystem-directory', 'ecosystem-finances', 'ecosystem-settings'],
                    },
                },
            },
            {
                name: 'ecosystem-graph',
                path: 'graph/:period/:teamId?',
                component: require('@/pages/ecosystem/svg-graph.vue').default,
                meta: {
                    title: 'Ecosystem',
                    scope: 'ecosystem',
                    section: 'graph',
                    breadcrumb: {
                        text: 'GRAPH',
                        items: ['ecosystem-overview', 'ecosystem-directory', 'ecosystem-finances', 'ecosystem-settings'],
                    },
                },
            },
            {
                name: 'ecosystem-overview',
                path: 'overview/:period/',
                component: require('@/pages/ecosystem/overview.vue').default,
                meta: {
                    title: 'Ecosystem',
                    scope: 'ecosystem',
                    section: 'overview',
                    breadcrumb: {
                        text: 'OVERVIEW',
                        items: ['ecosystem-directory', 'ecosystem-finances', 'ecosystem-graph', 'ecosystem-settings'],
                    },
                },
                children: [
                    {
                        name: 'ecosystem-overview-team',
                        path: ':teamId(\\d+)?-:teamKey?',
                        component: require('@/pages/ecosystem/overview.vue').default,
                        meta: { title: 'Ecosystem', scope: 'ecosystem', section: 'overview' },
                        children: [
                            {
                                name: 'ecosystem-overview-group',
                                path: ':groupId(\\d+)-:groupKey?',
                                component: require('@/pages/ecosystem/overview.vue').default,
                                meta: { title: 'Ecosystem', scope: 'ecosystem', section: 'overview' },
                                children: [],
                            },
                        ],
                    },
                ],
            },
            {
                name: 'ecosystem-ceate-team',
                path: 'create-team/:period/',
                component: require('@/pages/ecosystem/create-team.vue').default,
                meta: { title: 'Ecosystem', scope: 'ecosystem', section: 'create-team' },
            },
            {
                name: 'ecosystem-directory',
                path: 'directory/:period/:tab?',
                component: require('@/pages/ecosystem/directory/directory.vue').default,
                meta: {
                    title: 'Ecosystem',
                    scope: 'ecosystem',
                    section: 'directory',
                    breadcrumb: {
                        text: 'DIRECTORY',
                        items: ['ecosystem-overview', 'ecosystem-finances', 'ecosystem-graph', 'ecosystem-settings'],
                    },
                },
            },
            {
                name: 'ecosystem-finances',
                path: 'finances/:period',
                component: require('@/pages/ecosystem/finances.vue').default,
                meta: {
                    title: 'Ecosystem',
                    scope: 'ecosystem',
                    section: 'finances',
                    breadcrumb: {
                        text: 'FINANCES',
                        items: ['ecosystem-overview', 'ecosystem-finances', 'ecosystem-directory', 'ecosystem-graph', 'ecosystem-settings'],
                    },
                },
            },
            {
                name: 'me',
                path: ':teamId(\\d+)-:teamKey/me/:period',
                component: require('@/pages/dashboard/me.vue').default,
            },
            {
                name: 'me-directory',
                // path: '/:ecosystem((\\d+)\\-([\\w\\-]+))/:team((\\d+)\\-([\\w\\-]+))/me/directory/:tab/:period',
                path: ':teamId(\\d+)-:teamKey/me/directory/:tab/:period',
                component: () => import(/* webpackChunkName: "me" */ '../pages/me/directory/directory.vue'),
                meta: { title: 'Me - Directory', section: 'directory', breadcrumb: { text: 'DIRECTORY', items: ['me-finances'] } },
            },
            {
                name: 'me-finances',
                path: ':teamId(\\d+)-:teamKey/me/finances/:tab/:period',
                component: () => import(/* webpackChunkName: "me" */ '../pages/me/finance/finance.vue'),
                meta: { title: 'Me - Finance', section: 'finances', breadcrumb: { text: 'FINANCES', items: ['me-directory'] } },
            },
            {
                name: 'me-insights',
                path: ':teamId(\\d+)-:teamKey/me/insights/:period',
                component: () => import(/* webpackChunkName: "me" */ '../pages/me/insights/insights.vue'),
                meta: { title: 'Me - Insights' },
            },
            {
                name: 'me-profile',
                path: ':teamId(\\d+)-:teamKey/me/profile/:id',
                component: require('@/pages/account/profile/profile.vue').default,
                meta: { title: 'Me - Profile' },
            },
            {
                name: 'team-finances',
                path: ':teamId(\\d+)-:teamKey/team/finances/:tab/:period',
                component: require('@/pages/teams/finance/finance.vue').default,
                meta: {
                    title: 'Team - Finance',
                    section: 'finances',
                    breadcrumb: { text: 'FINANCES', items: ['team-directory', 'team-insights'] },
                },
            },
            {
                name: 'team-directory-add-group',
                path: ':teamId(\\d+)-:teamKey/team/directory/groups/:period/add',
                component: require('@/pages/teams/directory/add-group.vue').default,
                meta: { title: 'Team - Directory - Add group' },
            },
            {
                name: 'team-directory-add-member',
                path: ':teamId(\\d+)-:teamKey/team/directory/members/:period/add',
                component: require('@/pages/teams/directory/add-member.vue').default,
                meta: { title: 'Team - Directory - Add member' },
            },
            {
                name: 'team-directory',
                path: ':teamId(\\d+)-:teamKey/team/directory/:tab/:period',
                component: require('@/pages/teams/directory/directory.vue').default,
                meta: {
                    title: 'Team - Directory',
                    section: 'directory',
                    breadcrumb: { text: 'DIRECTORY', items: ['team-finances', 'team-insights'] },
                },
            },
            {
                name: 'team-directory-group',
                path: ':teamId(\\d+)-:teamKey/team/directory/:groupId(\\d+)?-:groupKey?/:tab/:period',
                component: require('@/pages/groups/details/details.vue').default,
                meta: {
                    title: 'Team - Directory',
                    section: 'directory',
                    scope: 'team',
                    breadcrumb: { text: 'DIRECTORY', items: ['team-finances-group'], split: 'team-directory' },
                },
            },
            {
                name: 'team-finances-group',
                path: ':teamId(\\d+)-:teamKey/team/finances/:groupId(\\d+)?-:groupKey?/:tab/:period',
                component: require('@/pages/groups/details/details.vue').default,
                meta: {
                    section: 'finances',
                    scope: 'team',
                    title: 'Team - Finance',
                    breadcrumb: { text: 'FINANCES', items: ['team-directory-group'], split: 'team-finances' },
                },
            },
            {
                name: 'team-insights',
                path: ':teamId(\\d+)-:teamKey/team/insights/:tab/:period',
                component: require('@/pages/teams/insights/insights.vue').default,
                meta: {
                    title: 'Team - Insights',
                    section: 'insights',
                    breadcrumb: { text: 'ANALYTICS', items: ['team-directory', 'team-finances'] },
                },
            },
            // {
            //     name: 'team',
            //     path: '/:team/team/:key/:period',
            //     component: require('@/pages/teams/details/details.vue').default,
            //     meta: { title: 'Team' },
            // },
            {
                name: 'invite',
                path: ':teamId(\\d+)-:teamKey/team/invite/:key/:period',
                component: require('@/pages/account/invited/invited.vue').default,
                meta: { title: 'Team - Invite' },
            },
            {
                name: 'joinrequest',
                path: ':teamId(\\d+)-:teamKey/team/joinrequest/:id',
                component: require('@/pages/teams/joinrequest.vue').default,
                meta: { title: 'Team - Join request' },
            },
        ],
    },
    {
        name: 'app-error',
        path: '/error',
        component: require('@/pages/error/error.vue').default,
        meta: { title: 'Error Page' },
    },
    {
        name: 'not-found',
        path: '*',
        component: require('@/pages/notfound/notfound.vue').default,
        meta: { title: 'Woops!?' },
    },
    {
        name: 'settings-base',
        path: '/settings',
        redirect: '/settings/translate',
    },
    {
        name: 'settings-translate',
        path: '/settings/translate',
        component: require('@/pages/settings/translate.vue').default,
        meta: { title: 'Settings', scope: 'settings' },
    },
];
// tslint:disable-next-line:no-unused-variable
const beforeRoute = async (to: Route, from: Route, next: any) => {
    const anonymousRoutes = ['login', 'app-error', 'not-found', 'invite'];
    await profileModule.init();
    const loggedIn = profileModule.isLoggedIn;
    const matchedRoute = to.matched.length > 0 ? to.matched[to.matched.length - 1] : to;
    const matchedRouteName = matchedRoute && matchedRoute.name ? matchedRoute.name : '';

    if (anonymousRoutes.indexOf(matchedRouteName) > -1 || loggedIn) {
        return next();
    }

    next(`/login?redirectUrl=${to.fullPath}`);
};

const onRouteFinished = (to: Route) => {
    const meta = to.meta || {};
    const params = to.params || {};

    let title = 'Bright Ecology';

    if (params.team) {
        title += ` - ${params.team}`;
    }

    if (meta.title) {
        title += ` - ${meta.title}`;
    }

    Vue.nextTick(() => {
        document.title = title;
        navigationHelper.hideNavigation();
        navigationHelper.loadRouteMeta(meta, params);
    });
};

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach(beforeRoute);
router.afterEach(onRouteFinished);

export default router;
