import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { teamService, loginHelper, memberHelper, teamHelper, personService, roleHelper, financeService, groupService } from '@/main';
import to from 'await-to-js';
import PageRender from '@/models/PageRender';
import { Member } from '@/models/Member';
import { AxiosResponse } from 'axios';
import FinancialActivity from '@/models/FinancialActivity';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import moment from 'moment';
import { Person } from '@/models/Person';
import { periodModule } from '@/store/modules/period';
import EnergyDedicationModalComponent from '@/components/modals/energy-dedication-modal';
import { PrivacyLevel } from '@/models/PrivacyLevel';
import MovePLModalComponent from '@/components/modals/move-pl-modal';

@Component
export default class MembersComponent extends PageRender {
    public members: Person[] = [];
    public admins: Member[] = [];
    public isLoading: boolean = true;
    public cacheKeyMember: number = 0;
    public invitePersonModel = { name: null, personId: null, emailAddress: null, teamId: null, accessFrom: null, accessUntil: null };
    public accessFromPeriod: number = null;
    public accessUntilPeriod: number = null;

    public memberColumns: any[] = [
        { cell: this.renderMemberWithIcon, title: 'NAME', field: 'firstName' },
        { cell: this.renderMemberships, title: 'MEMBERS_OF', field: 'firstName' },
        { title: 'EMAIL', field: 'emailAddress' },
        { title: 'PHONE', field: 'phoneNumber' },
        { title: 'ACTIONS', cell: this.renderActions, width: 150 },
    ];

    public exportMemberGridColumns: any[] = [
        { title: 'EMAIl', field: 'emailAddress' },
        { title: 'FIRST_NAME', field: 'firstName' },
        { title: 'LAST_NAME', field: 'lastName' },
        { title: 'PHONE', field: 'phoneNumber' },
        { title: 'GROUPS', field: 'groups' },
    ];

    public $refs!: {
        inviteMember: BModal;
        transferPAndLModal: MovePLModalComponent;
        energyDedicationEdit: EnergyDedicationModalComponent;
    };

    public newMemberForm: any = {
        firstName: '',
        insertion: '',
        lastName: '',
    };

    get periods() {
        return periodModule.periods.map((period) => {
            return {
                text: moment(period.startDate).format(`MMMM 'YY`),
                value: period.periodId,
            };
        });
    }

    public async created() {
        const self = this;

        this.members = await memberHelper.getMembers(true);
        this.accessFromPeriod = periodModule.selectedPeriodObject.periodId;

        if (roleHelper.checkAccess(PrivacyLevel.FinanceEmployee)) {
            this.memberColumns.splice(2, 0, { cell: this.renderEnergyDedication, title: 'ENERGY_DEDICATION' });
        }

        self.isLoading = false;
    }

    public renderMemberWithIcon(h, _, row) {
        return this.renderMember(h, row.dataItem);
    }

    public renderFullname(h, _, row) {
        const member = row.dataItem;
        const name = `${member.firstName} ${member.insertion ? member.insertion : ''} ${member.lastName}`;
        return h('td', [name]);
    }

    public renderActions(h, _, row: any): any {
        const actions = [];
        if (!row.dataItem.hasAccount && roleHelper.isSiteAdmin()) {
            actions.push({ title: 'Upgrade account', function: this.showUpgradeAccountModal });
        }

        if (row.dataItem.hasAccount && roleHelper.isSiteAdmin()) {
            actions.push({ title: 'Downgrade account', function: this.downgradeAccount });
        }

        if ((roleHelper.isSiteAdmin() || roleHelper.isFinanceEmployee()) && periodModule.periods[0] === periodModule.selectedPeriodObject) {
            actions.push({ title: 'Remove member from team', function: this.removeMemberFromTeam });
        } else if ((roleHelper.isSiteAdmin() || roleHelper.isFinanceEmployee()) && periodModule.periods[0] !== periodModule.selectedPeriodObject) {
            actions.push({
                title: 'Remove member from team (only available in most recent period)',
                disabled: true,
                function: () => {
                    return;
                },
            });
        }

        if (actions && actions.length > 0) {
            const props = { actions, item: row.dataItem };
            return h(Vue.component('grid-actions'), { props });
        } else {
            return h('td');
        }
    }

    public async leaveTeam() {
        const result = await this.checkValidLeave(loginHelper.getUser().personId);
        if (!result) {
            return this.clearAndShowError('LEAVE_TEAM_FINANCE_ERROR', null);
        }
        this.showPending('LEAVE_TEAM_PENDING');
        const [err] = await to(teamService.unenroll(teamHelper.currentTeamId, periodModule.selectedPeriod));
        if (err) {
            return this.clearAndShowError(`LEAVE_TEAM_FAILED`, err);
        }

        this.clearAndShowSuccess(`LEAVE_TEAM_SUCCESS`);
        return this.$router.push({ name: 'home', query: { reloadProfile: 'true' } });
    }

    public async removeMemberFromTeam(item: Person) {
        const result = await this.checkValidLeave(item.personId);
        if (!result) {
            await this.$refs.transferPAndLModal.init(item);
            return this.$refs.transferPAndLModal.show();
        }

        const groups = await groupService.getGroups(teamHelper.currentTeamId, periodModule.selectedPeriod);
        const foundGroup = groups.data.find((x) => x.groupLead && x.groupLead.memberId === item.personId);
        if (foundGroup) {
            return this.clearAndShowError(`The member you're trying to remove is still a group lead (${foundGroup.name})`, null);
        }

        this.showPending('REMOVING_MEMBER_PENDING');
        const [err] = await to(teamService.removeMemberFromTeam(item.personId, teamHelper.currentTeamId, periodModule.selectedPeriod));
        if (err) {
            return this.clearAndShowError(`REMOVING_MEMBER_FAILED`, err);
        }

        this.clearAndShowSuccess(`REMOVING_MEMBER_SUCCESS`);
        await this.refreshMembersGrid();
    }

    public async checkValidLeave(personId: number) {
        const [err, response] = await to<AxiosResponse<FinancialActivity>>(
            financeService.getPersonFinancialActityInTeam(teamHelper.getTeamId(), personId, periodModule.selectedPeriod),
        );

        if (err) {
            this.clearAndShowError('Failed to check financial activity for this member.', err);
            return false;
        }

        return !response.data.isCompensated;
    }

    public showUpgradeAccountModal(item: Person) {
        const modal = this.$refs.inviteMember;
        modal.show();

        this.invitePersonModel.emailAddress = item && item.emailAddress && item.emailAddress.indexOf('@') > -1 ? item.emailAddress : '';
        this.invitePersonModel.personId = item.personId;
        this.invitePersonModel.name = new Member(item).getFullName();
        this.invitePersonModel.teamId = teamHelper.getTeamId();
    }

    public async upgradeAccount() {
        this.showPending('INVITE_PENDING');
        this.invitePersonModel.accessFrom = this.accessFromPeriod;
        this.invitePersonModel.accessUntil = this.accessUntilPeriod;

        const [err] = await to(personService.invitePerson(this.invitePersonModel, periodModule.selectedPeriod));
        if (err) {
            return this.clearAndShowError(`INVITE_FAILED`, err);
        }

        this.clearAndShowSuccess('INVITE_SUCCESS');

        const modal = this.$refs.inviteMember;
        modal.hide();
    }

    public async downgradeAccount(item: Person) {
        this.showPending('DOWNGRADE_PENDING');
        const [err] = await to(personService.downgrageAccount(item.personId));
        if (err) {
            return this.clearAndShowError(`DOWNGRADE_FAILED`, err);
        }

        this.clearAndShowSuccess('DOWNGRADE_SUCCESS');
    }

    public async refreshMembersGrid() {
        this.members = await memberHelper.getMembers(true);
        this.cacheKeyMember++;
    }

    public getPeriodString() {
        return moment(periodModule.selectedPeriodObject.startDate, 'YYYY-MM-DD').format(`MMMM 'YY`);
    }

    public async exportMembers() {
        const members = [];
        this.members.forEach((member) => {
            const memberObj = new Member(member);
            members.push({
                emailAddress: memberObj.emailAddress,
                firstName: memberObj.firstName,
                lastName: (memberObj.insertion ? memberObj.insertion + ' ' : '') + memberObj.lastName,
                phoneNumber: memberObj.phoneNumber ? memberObj.phoneNumber : '',
                groups: memberObj.groupMemberships.map((mem) => mem.name).join(','),
            });
        });

        saveExcel({
            data: members,
            fileName: `Team-${teamHelper.currentTeamId}-members-${moment().format('YYYY-MM-DD HH:mm:')}`,
            columns: this.exportMemberGridColumns,
        });
    }

    public reloadEnergyDedications() {
        // implement;
    }

    public renderEnergyDedication(h, _, row) {
        if (row.dataItem.groupMemberships.length > 0) {
            const props = {
                value: row.dataItem.energyDedication,
                text: 'Edit energy',
                showPercentage: true,
                action: async () => {
                    await this.openEditEnergyEdicationModal(row.dataItem);
                },
            };
            return h(Vue.component('grid-percentage'), { props });
        }

        return h('td');
    }

    public async openEditEnergyEdicationModal(item: Person) {
        const self = this;
        const modal = self.$refs.energyDedicationEdit;
        const memberObj = new Member(item);
        await modal.setMember(memberObj);
        modal.show();
    }
}
