import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class InsightsEcosystemFinanceTotalsComponent extends Vue {
    @Prop({ default: () => null }) public data: any;

    public getPSI() {
        return Vue.filter('number-format')(this.data && this.data.psi ? this.data.psi : 95);
    }

    public getConversation() {
        return Vue.filter('number-format')(this.data && this.data.conversation ? this.data.conversation : 87);
    }

    public getWillingness() {
        return Vue.filter('number-format')(this.data && this.data.willingness ? this.data.willingness : 100);
    }

    public getInclusion() {
        return Vue.filter('number-format')(this.data && this.data.inclusion ? this.data.inclusion : 92);
    }

    public getAttitude() {
        return Vue.filter('number-format')(this.data && this.data.attitude ? this.data.attitude : 92);
    }
}
