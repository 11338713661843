import { Invoice } from '../Invoice';
import Team from '../Team';
import { RelationSettings } from './Relation';

export default class CrossLegalTransaction {
    public matchId: string = '';
    public dateHandled: string = '';
    public destinationLegalEntityReference: string = '';
    public destinationTeam: Team = null;
    public destinationTeamId: number = null;
    public invoice: Invoice = null;
    public originLegalEntityReference: string = '';
    public originTeam: Team = null;
    public originTeamId: number = null;
    public relationSettings: RelationSettings = null;
    public status: string = '';
    public amount: number = 0;

    /**
     *
     */
    constructor(obj?: Partial<CrossLegalTransaction>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
