import { AxiosHelper } from '../helpers/axiosHelper';
import Attribution from '../models/Attribution';
import { BaseService } from './baseService';
import Vue from 'vue';
import to from 'await-to-js';
import { ecosystemHelper } from '@/main';
import { Member } from '@/models/Member';

export class EcosystemService extends BaseService {
    private endpoint = `${Vue.$env().directoryEndpoint}`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getSubscriptionInfo() {
        const [err, response] = await to(this.get<Attribution[]>(`${this.endpoint}subscription-data`));
        if (err) {
            return [];
        }

        return response.data;
    }

    public async createEcosytem(name: string) {
        const model = {
            name,
        };

        const [err, response] = await to(this.post(`${this.endpoint}ecosystems`, model, null));
        if (err) {
            this.clearAndShowWarning('FAILED_TO_CREATE_ECOSYSTEM');
            return null;
        }

        return response.data;
    }

    public async getMembers(): Promise<Member[]> {
        const ecosystemId  = ecosystemHelper.currentEcosystem.id;
        const [err, response] = await to(this.get<Member[]>(`${this.endpoint}ecosystems/${ecosystemId}/members`));
        return err ? null : response.data;
    }

    public removeMember(memberId: number, ecosystemId: number, period: string) {
        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.delete(`${this.endpoint}ecosystems/:ecosystemId/members/:memberId`, [['ecosystemId', ecosystemId], ['memberId', memberId]], config);
    }
}
