import IValidation from './IValidation';

export default class MinLength implements IValidation {
    private length: number = 10;

    constructor(length: number) {
        this.length = length;
    }

    public valid(value: string) {
        return value.length >= this.length;
    }

    public get message() {
        return `Must contain at least ${this.length} characters`;
    }
}
