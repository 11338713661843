import { Component, Prop } from 'vue-property-decorator';
import TranslateBase from './base';

@Component
export default class TranslateText extends TranslateBase {
    @Prop({ default: () => '' }) public variant;
    @Prop({ default: () => 'div' }) public element;

    public onSpace(e) {
        e.preventDefault();
    }
}
