import Leaflet, { CRS } from 'leaflet';
import { LMap, LImageOverlay, LMarker, LPopup, LPolyline, LIcon, LCircle, LTooltip, LLayerGroup, LRectangle } from 'vue2-leaflet';
import '@elfalem/leaflet-curve';
import 'leaflet/dist/leaflet.css';
import Vue from 'vue';

export default {
    components: {
        LMap,
        LImageOverlay,
        LMarker,
        LPopup,
        LPolyline,
        LIcon,
        LCircle,
        LRectangle,
        LTooltip,
        LLayerGroup,
    },
    props: {
        mapData: { Type: Object },
        editMode: { type: Boolean },
    },
    data() {
        return {
            crs: CRS.Simple,
            width: 400,
            height: 600,
            mousePosition: [0, 0],
            selectedEntity: null,
            placeHolderFill: '#ccc',
            content: '',
            contentTitle: '',
            circle: {
                opacity: 0,
                fill: true,
                fillOpacity: 0,
            },
            tooltipLatLng: {
                lat: -99999,
                lng: -99999,
            },
            tooltipLatLngArray: [0, 0],
            permanent: false,
            biggestTotal: 0,
        };
    },

    mounted() {
        this.$watch('editMode', this._watchEditModeChange);
        this.$watch('width', this._refreshCanvas);
        this.$watch('height', this._refreshCanvas);

        this.mapData.entities.forEach((entity) => {
            entity.revenue.forEach((revenue) => {
                if (revenue.total > this.biggestTotal) {
                    this.biggestTotal = revenue.total;
                }
            });
        });

        this.$nextTick(() => {
            this._addResizeListener();
            this.stretchCanvasWidth();
            this.drawRelations();
        });
    },
    methods: {
        drawRelations() {
            this.mapData.entities.forEach((entity) => {
                entity.revenue.forEach((revenue) => {
                    const sendingTeam = this.mapData.entities.find((entity) => entity.id == revenue.teamId);
                    this.drawArc(
                        entity.transferPointReceiving,
                        sendingTeam.transferPointSending,
                        this._getMapObject(),
                        revenue,
                        entity.name,
                        sendingTeam.name,
                    );
                });
            });
        },
        drawArc(latlng1, latlng2, map, revenue, to, from) {
            const offsetX = latlng2[1] - latlng1[1];
            const offsetY = latlng2[0] - latlng1[0];
            const r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2));
            const theta = Math.atan2(offsetY, offsetX);
            const thetaOffset = 3.14 / 10;
            const r2 = r / 2 / Math.cos(thetaOffset);
            const theta2 = theta + thetaOffset;
            const midpointX = r2 * Math.cos(theta2) + latlng1[1];
            const midpointY = r2 * Math.sin(theta2) + latlng1[0];
            const midpointLatLng = [midpointY, midpointX];
            const pathOptions = {
                color: 'rgba(0, 0, 0, 0.3)',
                weight: revenue.total < 1000 ? 5 : revenue.total / (this.biggestTotal / 10) + 5,
                title: `${Vue.filter('number-format')(revenue.total)} from ${from} to ${to}`,
                transfers: `Total transfers amount: ${Vue.filter('number-format')(revenue.transfers)}`,
                attributions: `Total attribution amount: ${Vue.filter('number-format')(revenue.attributions)}`,
            };

            const curve = Leaflet.curve(['M', latlng1, 'Q', midpointLatLng, latlng2], pathOptions);
            curve.on('mouseover', (e) => this.showTooltip(e));
            curve.on('mouseout', (e) => this.hideToolTip(e));
            curve.addTo(map);
        },
        showTooltip(event) {
            this.contentTitle = event.target.options.title;
            this.content = event.target.options.transfers + '<br />' + event.target.options.attributions;
            this.$refs.tooltipContainer.latlng = event.latlng;
            this.tooltipLatLng = event.latlng;
        },
        hideToolTip(event) {
            this.tooltipLatLng = {
                lat: -99999,
                lng: -99999,
            };
        },
        stretchCanvasWidth() {
            this.width = this.$el.clientWidth;
            this._refreshCanvas();
        },
        getEntityPos(entity) {
            return [parseFloat(entity.pos[0]), parseFloat(entity.pos[1])];
        },
        getEntityRadius(entity) {
            return entity && entity.radius ? parseFloat(entity.radius) : 10;
        },
        selectEntity(entity) {
            this.selectedEntity = entity;
        },
        clearSelectedEntity() {
            this.selectedEntity = null;
        },
        _getMapObject() {
            if (this.$refs.prdMap && this.$refs.prdMap.mapObject) {
                return this.$refs.prdMap.mapObject;
            }
        },
        _watchEditModeChange() {
            const map = this._getMapObject();

            if (this.editMode) {
                this._disableMapMutations(map);
                this._addEventListeners();
            } else {
                this._enableMapMutations(map);
                this._removeEventListeners();
            }
        },
        _addEventListeners() {
            const map = this._getMapObject();

            map.on('click', this._setEntityPositionOnClick);
            map.on('mousemove', this._setMousePosition);

            this.$refs.circleEntity.forEach((circle) => {
                const map = circle.mapObject;
                map.on('click', this._selectEntityOnClick);
            });
        },
        _removeEventListeners() {
            const map = this._getMapObject();

            map.off('click', this._setEntityPositionOnClick);
            map.off('mousemove', this._setMousePosition);

            this.$refs.circleEntity.forEach((circle) => {
                const map = circle.mapObject;
                map.off('click', this._selectEntityOnClick);
            });
        },
        _disableMapMutations(map) {
            map.dragging.disable();
            map.touchZoom.disable();
            map.doubleClickZoom.disable();
            map.scrollWheelZoom.disable();
            map.boxZoom.disable();
            map.keyboard.disable();
        },
        _enableMapMutations(map) {
            map.dragging.enable();
            map.touchZoom.enable();
            map.doubleClickZoom.enable();
            map.scrollWheelZoom.enable();
            map.boxZoom.enable();
            map.keyboard.enable();
        },
        _setEntityPositionOnClick(e) {
            if (this.selectedEntity) {
                this._setEntityPosition(this.selectedEntity, e);
            }
        },
        _setEntityPosition(entity, mouseEvent) {
            entity.pos = [mouseEvent.latlng.lat, mouseEvent.latlng.lng];
            this.selectedEntity = null;
        },
        _setMousePosition(e) {
            this.mousePosition = [e.latlng.lat, e.latlng.lng];
        },
        _selectEntityOnClick(e) {
            const self = this;
            const center = e.target._latlng;
            const entity = this.mapData.entities.find((ent) => {
                return ent.pos[0] === center.lat && ent.pos[1] === center.lng;
            });

            if (entity) {
                setTimeout(() => {
                    self.selectEntity(entity);
                });
            }
        },
        _addResizeListener() {
            const self = this;
            window.addEventListener('resize', () => {
                self.stretchCanvasWidth();
            });
        },
        _refreshCanvas() {
            this._getMapObject()._onResize();
        },
        _groupBy(arr, prop) {
            const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
            arr.forEach((obj) => map.get(obj[prop]).push(obj));
            return Array.from(map.values());
        },
    },
};
