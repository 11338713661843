import Vue from 'vue';
import { BaseService } from './baseService';
import { AxiosHelper } from '@/helpers/axiosHelper';
import IMapSetting from '@/models/Map/IMapSettings';
import to from 'await-to-js';

export default class MapService extends BaseService {
    private endpoint = `${Vue.$env().directoryEndpoint}map/settings`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getSettings(team?: number): Promise<IMapSetting[]> {
        // tslint:disable-next-line: no-dead-store
        let [err, response] = [null, null];
        if (team) {
            [err, response] = await to(this.get(`${this.endpoint}?teamId=:team`, [['team', team]]));
        } else {
            [err, response] = await to(this.get(`${this.endpoint}`));
        }

        if (err) {
            return [];
        }

        return response.data as IMapSetting[];
    }

    public async saveSettings(settings: IMapSetting[]): Promise<boolean> {
        settings.forEach((x) => (!isNaN(x.radius) ? (x.radius = parseInt(x.radius.toString())) : x.radius));

        const [err] = await to(this.post(`${this.endpoint}`, settings));

        if (err) {
            return false;
        }

        return true;
    }
}
