import { relationService } from '@/main';
import Relation, { IntellectualPropertyLimitation, TaxRate } from '@/models/Finance/Relation';
import PageRender from '@/models/PageRender';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class TeamFinanceRelations extends PageRender {
    public columns: any = [
        { title: 'Legal entity', field: 'originLegalEntityReference' },
        { title: 'Team', field: 'originTeam', cell: this.renderTeamName, filterable: true },
        { title: 'Group', field: 'originTeam', cell: this.renderGroupName },
        { title: 'Legal entity', field: 'destinationLegalEntityReference' },
        { title: 'Team', field: 'destinationTeam', cell: this.renderDestinationTeamName, filterable: true },
        { title: 'Group', field: 'destinationGroup', cell: this.renderDestinationGroupName },
        { title: 'Settings', cell: this.renderSettingsLink },
        { title: 'ACTIONS', cell: this.renderActions, width: 100 },
    ];

    public $refs!: {
        relationModal: any;
    };

    public onlyWithoutSettings: boolean = true;
    public includeDeletedRelations: boolean = false;
    public relations: Relation[] = [];
    public allRelations: Relation[] = [];
    public intellectualPropertyLimitations: IntellectualPropertyLimitation[] = [];
    public taxRates: TaxRate[] = [];
    public relationsGridKey: number = 0;
    public origins: { relationId: number; legalEntity: string; team: string; group: string; label: string }[] = [];
    public destinations: { relationId: number; legalEntity: string; team: string; group: string; label: string }[] = [];
    public selectedOrigin: { relationId: number; legalEntity: string; team: string; group: string; label: string } | null = null;
    public selectedDestination: { relationId: number; legalEntity: string; team: string; group: string; label: string } | null = null;

    public async mounted() {
        await this.loadRelations(this.includeDeletedRelations);
        const data = (await relationService.getRelationProperties()).data;
        this.intellectualPropertyLimitations = data.intellectualPropertyLimitations;
        this.taxRates = data.taxRates;

        this.populateDropdowns();

        this.isLoading = false;
    }

    public populateDropdowns() {
        this.origins = [];
        this.destinations = [];

        this.relations.forEach((relation) => {
            this.origins.push({
                relationId: relation.relationId,
                legalEntity: relation.originLegalEntityReference,
                team: relation.originTeam?.name,
                group: relation.originGroup?.name,
                label: `${relation.originLegalEntityReference} - ${relation.originTeam?.name ? relation.originTeam.name : '(no team)'} - ${relation.originGroup?.name ? relation.originGroup.name : '(no group)'}`,
            });

            this.destinations.push({
                relationId: relation.relationId,
                legalEntity: relation.destinationLegalEntityReference,
                team: relation.destinationTeam?.name,
                group: relation.destinationGroup?.name,
                label: `${relation.destinationLegalEntityReference} - ${relation.destinationTeam?.name ? relation.destinationTeam.name : '(no team)'} - ${relation.destinationGroup?.name ? relation.destinationGroup.name : '(no group)'}`,
            });
        });
    }

    public reloadRelations() {
        this.relations = this.filterRelations();
        this.populateDropdowns();

        this.relationsGridKey++;
    }

    public async loadRelations(includeDeleted: boolean) {
        this.allRelations = await relationService.getRelations(includeDeleted);
        this.relations = this.filterRelations();
    }

    public async reloadWithDeletedRelations() {
        await this.loadRelations(this.includeDeletedRelations);
        this.reloadRelations();
    }

    private filterRelations() {
        let relations = this.allRelations;
        if (this.selectedOrigin) {
            relations = relations.filter(
                (x) =>
                    x.originLegalEntityReference === this.selectedOrigin.legalEntity &&
                    x.originTeam?.name === this.selectedOrigin.team &&
                    x.originGroup?.name === this.selectedOrigin.group,
            );
        }

        if (this.selectedDestination) {
            relations = relations.filter(
                (x) =>
                    x.destinationLegalEntityReference === this.selectedDestination.legalEntity &&
                    x.destinationTeam?.name === this.selectedDestination.team &&
                    x.destinationGroup?.name === this.selectedDestination.group,
            );
        }

        if (this.onlyWithoutSettings) {
            return relations.filter((x) => x.relationSettings.length === 0);
        }
        return relations;
    }

    private renderTeamName(h, _, row) {
        if (row.dataItem.originTeam && row.dataItem.destinationTeam && row.dataItem.originTeam.teamId === row.dataItem.destinationTeam.teamId) {
            return h('td');
        }

        return h('td', row.dataItem.originTeam ? row.dataItem.originTeam.name : '');
    }

    private renderGroupName(h, _, row) {
        return h('td', row.dataItem.originGroup ? row.dataItem.originGroup.name : '');
    }

    private renderDestinationTeamName(h, _, row) {
        return h('td', row.dataItem.destinationTeam ? row.dataItem.destinationTeam.name : '');
    }

    private renderDestinationGroupName(h, _, row) {
        return h('td', row.dataItem.destinationGroup ? row.dataItem.destinationGroup.name : '');
    }

    private renderSettingsLink(h, _, row) {
        const props = { relation: row.dataItem, intellectualPropertyLimitations: this.intellectualPropertyLimitations, taxRates: this.taxRates };
        return h(Vue.component('grid-relation-settings'), { props });
    }

    private async deleteRelation(item: Relation): Promise<void> {
        const success = await relationService.deleteRelation(item.relationId);

        if (success) {
            this.clearAndShowSuccess('RELATION_DELETED_SUCCESS');
        } else {
            this.clearAndShowError('RELATION_DELETED_FAILED');
        }

        await this.loadRelations(this.includeDeletedRelations);
        this.reloadRelations();
    }

    private renderActions(h, _, row: { dataItem: Relation }): any {
        const actions = [{ title: 'Delete relation', function: this.deleteRelation }];
        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }
}
