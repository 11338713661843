import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueAppInsights from 'vue-application-insights';
import Vuelidate from 'vuelidate';

import SharedUI from '@prd/shared-ui';
import './components/index';
import VueGoogleCharts from 'vue-google-charts';

import { AxiosHelper } from './helpers/axiosHelper';
import { LoginHelper } from './helpers/loginHelper';
import { MeService } from './services/meService';
import { GroupService } from './services/groupService';
import { PersonService } from './services/personService';
import { TeamService } from './services/teamService';
import { FinanceService } from './services/financeService';
import { FinanceAccountService } from './services/financeAccountService';
import { AttributionService } from './services/attributionService';
import { ResultService } from './services/resultService';
import { CompensationService } from './services/compensationService';
import UserRoleService from './services/userRoleService';
import { PeriodService } from './services/periodService';
import { ErrorHelper } from './helpers/errorHelper';
import { RoleHelper } from './helpers/roleHelper';
import { TeamHelper } from './helpers/teamHelper';
import { GroupHelper } from './helpers/GroupHelper';
import { MemberHelper } from './helpers/memberHelper';
import { PeriodFilterHelper } from './helpers/periodFilterHelper';
import NavigationHelper from './helpers/navigationHelper';
import { EventBus } from './events/EventBus';
import { LoginService } from './services/loginService';
import '@/extensions/numberExtensions';
import MapService from './services/mapService';
import store from '@/store/index';
import ConnectorService from './services/connectorsService';
import { FinanceGroupService } from './services/financeGroupService';
import { TransferService } from './services/transferService';
import { SettingService } from './services/settingService';
import { EcosystemHelper } from './helpers/ecosystemHelper';
import { translateModule } from './store/modules/translate';
import LedgerService from './services/LedgerService';
import RelationService from './services/RelationService';
import { TemplateService } from './services/templateService';
import { NotificationsService } from './services/notificationsService';
import SideActionsPlugin from './plugin/sideActions/sideActions';
import InsightsService from './services/insightViewService';
import { EcosystemService } from './services/ecosystemService';
import { numberFormat } from './filters';

Vue.use(SharedUI);
Vue.use(Vuelidate);
Vue.use(SideActionsPlugin);
Vue.use(VueGoogleCharts);

if (Vue.$env().instrumentationKey) {
    Vue.use(VueAppInsights, {
        id: Vue.$env().instrumentationKey,
        router,
    });
}

export const $router = router;
export const eventBus = new EventBus();
export const axiosHelper = new AxiosHelper({}, {});
export const loginService = new LoginService();
export const loginHelper = new LoginHelper(loginService);
export const meService = new MeService(axiosHelper);
export const groupService = new GroupService(axiosHelper);
export const personService = new PersonService(axiosHelper);
export const teamService = new TeamService(axiosHelper);
export const financeService = new FinanceService(axiosHelper);
export const transferService = new TransferService(axiosHelper);
export const financeAccountService = new FinanceAccountService(axiosHelper);
export const attributionService = new AttributionService(axiosHelper);
export const resultService = new ResultService(axiosHelper);
export const compensationService = new CompensationService(axiosHelper);
export const userRoleService = new UserRoleService(axiosHelper);
export const periodService = new PeriodService(axiosHelper);
export const mapService = new MapService(axiosHelper);
export const connectorService = new ConnectorService(axiosHelper);
export const financeGroupService = new FinanceGroupService(axiosHelper);
export const settingService = new SettingService(axiosHelper);
export const ledgerService = new LedgerService(axiosHelper);
export const relationService = new RelationService(axiosHelper);
export const templateService = new TemplateService(axiosHelper);
export const notificationsService = new NotificationsService(axiosHelper);
export const insightService = new InsightsService(axiosHelper);
export const ecosystemService = new EcosystemService(axiosHelper);
export const errorHelper = new ErrorHelper(router);
export const roleHelper = new RoleHelper();
export const teamHelper = new TeamHelper();
export const ecosystemHelper = new EcosystemHelper();
export const groupHelper = new GroupHelper();
export const memberHelper = new MemberHelper();
export const periodFilterHelper = new PeriodFilterHelper();
export const navigationHelper = new NavigationHelper();

Vue.config.productionTip = false;

Vue.filter('number-format', (n: number) => {
    if (!n || typeof n === 'undefined' || n == null) {
        return 0;
    }

    if (n > -1000 && n < 1000) {
        return n.round(2).replace('.', ',');
    }

    const parts = n.round(2).split('.');
    const chars = [];
    let i = 0;

    if (parts.length === 2) {
        chars.push(`,${parts[1]}`);
    }

    parts[0]
        .split('')
        .reverse()
        .forEach((c) => {
            chars.push(c);
            i++;
            if (i > 0 && i % 3 === 0) {
                chars.push(' ');
            }
        });

    return chars.reverse().join('');
});

Vue.filter('numberFormat', numberFormat);

new Vue({
    store,
    data: {
        loginHelper,
        periodFilterHelper,
        teamHelper,
        ecosystemHelper,
        roleHelper,
        navigationHelper,
    },
    computed: {
        inEditMode() {
            return translateModule.inEditMode;
        },
    },
    router,
    render: (h) => h(App),
}).$mount('#app');
