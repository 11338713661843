import Component from 'vue-class-component';
import { Provide, Vue } from 'vue-property-decorator';
import { UserRoles } from '@/models/UserRoles';
import { userRoleService, memberHelper } from '@/main';
import PageRender from '@/models/PageRender';
import '@/extensions/stringExtensions';
import to from 'await-to-js';
import { BModal } from 'bootstrap-vue';
import { Person } from '@/models/Person';
import { Member } from '@/models/Member';
import { translateModule } from '@/store/modules/translate';

/**
 * Encompasses a control that allows changing the admins for a team, group or other entities that can later be added.
 */
@Component({
    filters: {
        firstCharToUpper: (value: string) => value.firstCharToUpper(),
    },
})
export default class RolesSettings extends PageRender {
    // Properties used in HTML
    @Provide() public admins: Person[] = [];
    @Provide() public financeEmployees: Person[] = [];
    public roles: any[] = [{ id: 3, role: UserRoles.FinanceEmployee }, { id: 1, role: UserRoles.SiteAdmin }];
    public members: Person[] = [];

    public externalIdentifier: string = null;
    public role: UserRoles = null;
    public modalUser: Member = new Member();
    public oldRoles: any[] = [];

    public gridColumns: any[] = [
        { title: 'NAME', cell: this.renderMemberOfTeam },
        { field: 'emailAddress', title: 'EMAIL' },
        { title: 'ACTIONS', cell: this.renderActions, width: 120 },
    ];

    public $refs!: {
        rolesModal: BModal;
    };

    public async mounted() {
        await this.loadMembers();
    }

    public async loadMembers() {
        const [err, response] = await to(memberHelper.getMembers());
        if (err) {
            return this.showFailedResponse('Failed to retrieve members', null);
        }

        this.members = response.filter((member: Person) => {
            return member.externalIdentifier;
        });
    }

    public addRoleToMemberPopup(role: UserRoles) {
        this.$refs.rolesModal.show();
        this.role = role;
    }

    public get roleOptions() {
        return this.roles.map((role) => {
            return {
                text: role.role,
                value: role.id,
            };
        });
    }

    public async saveRole() {
        const self = this;
        self.showPending('SAVE_ROLE_PENDING');
        const [err] = await to(userRoleService.setUserRole(self.externalIdentifier, self.role));
        if (err) {
            return self.clearAndShowError('SAVE_ROLE_FAILED', null);
        }

        self.clearAndShowSuccess('SAVE_ROLE_SUCCESS');
        if (self.role === UserRoles.SiteAdmin) {
            self.admins.push(
                self.members.find((filteredMember) => {
                    return filteredMember.externalIdentifier === self.externalIdentifier;
                }),
            );
        }

        if (self.role === UserRoles.FinanceEmployee) {
            self.financeEmployees.push(
                self.members.find((filteredMember) => {
                    return filteredMember.externalIdentifier === self.externalIdentifier;
                }),
            );
        }
        self.$refs.rolesModal.hide();
    }

    public openRolesModal(member: Member) {
        this.modalUser = member;
        this.oldRoles = member.roles;
        this.$refs.rolesModal.show();
    }

    public renderActions(h, _, row: any): any {
        const actions = [{ title: translateModule.value('UPDATE_ROLES', 'MODALS'), function: this.openRolesModal }];
        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    public renderMemberOfTeam(h, _, row): any {
        return this.renderMember(h, row.dataItem);
    }

    public async updateRoles() {
        this.showPending('UPDATING_ROLES_PENDING');
        const removedRoles = [];
        const addedRoles = [];

        for (const role of this.oldRoles) {
            if (this.modalUser.roles.indexOf(role) === -1) {
                removedRoles.push(role);
            }
        }

        for (const role of this.modalUser.roles) {
            if (this.oldRoles.indexOf(role) === -1) {
                addedRoles.push(role);
            }
        }

        for (const addedRole of addedRoles) {
            const [err] = await to(userRoleService.setUserRole(this.modalUser.externalIdentifier, addedRole));
            if (err) {
                return this.clearAndShowError('UPDATING_ROLES_FAILED', err);
            }
        }

        for (const removedRole of removedRoles) {
            const [err] = await to(userRoleService.deleteUserRole(this.modalUser.externalIdentifier, removedRole));
            if (err) {
                return this.clearAndShowError('UPDATING_ROLES_FAILED', err);
            }
        }

        this.clearAndShowSuccess('UPDATING_ROLES_SUCCEEDED');
        this.$refs.rolesModal.hide();

        this.isLoading = true;
        await this.loadMembers();
        this.isLoading = false;
    }
}
