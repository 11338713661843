import { ecosystemHelper, loginHelper, teamHelper } from '@/main';
import { periodModule } from '@/store/modules/period';
import { profileModule } from '@/store/modules/profile';
import { Component } from 'vue-property-decorator';
import BaseModal from './BaseModal';

@Component
export default class EcosystemSwitcherModalComponent extends BaseModal {
    public currentEcosystem: number = null;

    public optionListEcosystems() {
        return loginHelper
            .getUser()
            .ecosystemMemberships.filter((x) => x.id !== ecosystemHelper.currentEcosystem.id)
            .map((ship) => {
                return { value: ship.id, text: ship.name };
            });
    }

    public async switchToAnotherEcosystem(ecosystemId: number, reloadProfile: boolean = false) {
        if (reloadProfile) {
            await profileModule.loadBrightProfileIfNeeded(true);
            const reloadedProfile = await loginHelper.reloadProfile();
            profileModule.setBrightProfile(reloadedProfile);
        }

        const profile = profileModule.brightProfile;
        const ecosystem = profile.ecosystemMemberships.find((x) => x.id === ecosystemId);

        let team = profile.teamMemberships
            .filter((x) => x.ecosystem === ecosystem.id)
            .find((t) => {
                if (profile.primaryTeam) {
                    return t.id === profile.primaryTeam;
                }
            });

        if (!team) {
            team = profile.teamMemberships.filter((x) => x.ecosystem === ecosystem.id)[0];
        }

        if (this.$refs.modal) {
            this.$refs.modal.hide();
        }

        ecosystemHelper.setEcosystemByEcosystemId(ecosystem.id);
        teamHelper.setTeam(team);
        await periodModule.init();

        this.currentEcosystem = null;

        if (reloadProfile) {
            return this.$router.push({
                name: 'team-directory',
                params: {
                    ecosystemId: ecosystem.id.toString(),
                    ecosystemKey: ecosystem.key,
                    teamId: team.id.toString(),
                    teamKey: team.key,
                    period: periodModule.selectedPeriod,
                    tab: 'groups',
                },
            });
        }

        return this.$router.push({
            name: 'me-finances',
            params: {
                ecosystemId: ecosystem.id.toString(),
                ecosystemKey: ecosystem.key,
                teamId: team.id.toString(),
                teamKey: team.key,
                period: periodModule.selectedPeriod,
                tab: 'overview',
            },
        });
    }

    public openCreateEcosystemSideAction() {
        this.hide();

        this.$sideActions.push(
            'create-ecosystem-side-action',
            {
                // options
            },
            async (ecosystem) => {
                await this.switchToAnotherEcosystem(ecosystem.ecosystemId, true);
            },
        );
    }
}
