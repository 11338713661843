import BaseFinancePage from './BaseFinancePage';
import { Component } from 'vue-property-decorator';
import Period from '../../../../models/Period';
import { BModal } from 'bootstrap-vue';
import { periodService, teamHelper } from '@/main';
import moment from 'moment';
import to from 'await-to-js';
import { periodModule } from '@/store/modules/period';
import ICanClosePeriodResponse from '@/interfaces/ICanClosePeriodResponse';

@Component
export default class TeamFinancePeriod extends BaseFinancePage {
    public isLoading: boolean = true;
    public periods: Period[] = null;
    public closedPeriods: Period[] = null;
    public period: Period = null;
    public breachedPeriod: Period = null;
    public showGif: boolean = false;
    public canCloseResponse: ICanClosePeriodResponse = null;
    public checkCanClose: boolean = false;

    public $refs!: {
        closePeriodConfirm: BModal;
        softClosePeriodConfirm: BModal;
    };

    public async created() {
        await this.loadPeriods();

        this.isLoading = false;
    }

    public get alreadyClosed() {
        return this.period && this.period.status === 'Opened';
    }

    public get FirstTimeClosing() {
        return this.period && this.period.status === 'Open';
    }

    public async showClosePeriodConfirmModal() {
        this.$refs.closePeriodConfirm.show();
        this.canCloseResponse = null;
        await this.checkIfPeriodCanBeClosed();
    }

    public async showSoftClosePeriodConfirmModal() {
        this.$refs.softClosePeriodConfirm.show();
        this.canCloseResponse = null;
        await this.checkIfPeriodCanBeClosed();
    }

    public async openPeriod() {
        const team = teamHelper.getTeamId();
        this.showPending('Softly picking the lock of this period..');

        const [err] = await to(periodService.openPeriod(this.breachedPeriod.periodId, team));
        if (err) {
            this.clearAndShowError('Failed to breach period', err);
        }

        this.clearNotifications();
        this.showGif = true;
        await this.loadPeriods();
    }

    public async loadPeriods() {
        const allPeriods = await periodService.getPeriods(teamHelper.getTeamId());
        const periods = allPeriods.filter((period) => {
            return !period.closed;
        });

        if (periods.length > 0) {
            this.periods = [periods.pop()];
        }

        this.closedPeriods = allPeriods.filter((period) => {
            return period.closed;
        });

        this.period = null;
        this.breachedPeriod = null;
        this.isLoading = false;

        await periodModule.reloadPeriods();
    }

    public async closePeriod(soft: boolean = false) {
        const team = teamHelper.getTeamId();

        this.showPending('CLOSE_PERIOD_PENDING');
        const [err, response] = await to(periodService.closePeriod(this.period.periodId, team, soft));
        if (err) {
            this.clearAndShowError('CLOSE_PERIOD_FAILED', err);
        }

        if (response.status === 202 && !soft) {
            this.clearAndShowSuccess('CLOSE_PERIOD_SUCCESS');
        } else if (response.status === 202 && soft) {
            this.clearAndShowSuccess('Closed period');
        }

        this.$refs.closePeriodConfirm.hide();
        await this.loadPeriods();
    }

    public getFancyDate(period: Period) {
        return `${moment(period.startDate, 'YYYY-MM-DD').format(`MMMM 'YY`)} ${period.status === 'Openend' ? 'breached' : ''} `;
    }

    public getDateString(period: Period) {
        return moment(period.startDate, 'YYYY-MM-DD').format('YYYY-MM');
    }

    private async checkIfPeriodCanBeClosed() {
        this.checkCanClose = true;
        const teamId = parseInt(this.$route.params.teamId);
        const period = moment(this.period.startDate, 'YYYY-MM-DD').format('YYYY-MM');
        const [err, response] = await to(periodService.canClose(teamId, period));
        this.checkCanClose = false;
        this.canCloseResponse = err ? null : response.data;
    }
}
