import { AxiosPromise } from 'axios';
import { AxiosHelper } from '../helpers/axiosHelper';
import Transfer from '../models/Transfer';
import { BaseService } from './baseService';
import Vue from 'vue';
import to from 'await-to-js';
import TransferRequest from '@/models/Finance/TransferRequest';
import IRecurringTransferRequest from '@/interfaces/IRecurringTransferRequest';

export class TransferService extends BaseService {
    private endpoint = `${Vue.$env().financeEndpoint}`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getTransfersRequests(group: number): Promise<TransferRequest[]> {
        const [err, response] = await to(this.get<TransferRequest[]>(`${this.endpoint}transfer-requests?group=:groupId`, [['groupId', group]]));

        if (err) {
            this.showFailedResponse('Failed to load transfer requests', err);
            return [];
        }

        return response.data;
    }

    public async createTransferRequest(model: any, amount: number, teamId: number, groupId?: number): Promise<TransferRequest> {
        let url = `${this.endpoint}transfer-requests?teamId=:team`;

        if (groupId) {
            url += '&groupId=:?group';
        }

        const requestModel = Object.assign({}, model);
        requestModel.transfer.amount = amount;

        const [err, response] = await to(this.post<TransferRequest>(url, requestModel, [['team', teamId], ['group', groupId]]));
        if (err) {
            this.showFailedResponse('UPDATE_TRANSFER_FAILED', err);
            return;
        }

        this.clearAndShowSuccess('UPDATE_TRANSFER_SUCCESS');
        return response.data;
    }

    public async updateTransferRequest(model: any, teamId: number, groupId?: number): Promise<TransferRequest> {
        this.showPending('UPDATE_TRANSFER_PENDING');

        let url = `${this.endpoint}transfer-requests/${model.transferRequestId}?teamId=:team`;

        if (groupId) {
            url += '&groupId=:?group';
        }
        const [err, response] = await to(this.put<TransferRequest>(url, model, [['team', teamId], ['group', groupId]]));
        if (err) {
            this.showFailedResponse('UPDATE_TRANSFER_FAILED', err);
            return;
        }

        this.clearAndShowSuccess('UPDATE_TRANSFER_SUCCESS');
        return response.data;
    }

    public async acceptTransferRequest(transferRequestId: number): Promise<boolean> {
        this.showPending('ACCEPT_TRANSFER_PENDING');
        const [err] = await to(
            this.post<TransferRequest[]>(`${this.endpoint}transfer-requests/:id/accept`, { transferRequestId }, [['id', transferRequestId]]),
        );
        if (err) {
            const localErr = err as any;
            this.clearAndShowError(`ACCEPT_TRANSFER_FAILED`, localErr);
            return false;
        }

        this.clearAndShowSuccess('ACCEPT_TRANSFER_SUCCESS');
        return true;
    }

    public async withdrawRequest(transferRequestId: number): Promise<boolean> {
        this.showPending('REJECT_TRANSFER_PENDING');

        const [err] = await to(
            this.post<TransferRequest[]>(`${this.endpoint}transfer-requests/:id/withdraw`, { transferRequestId }, [['id', transferRequestId]]),
        );
        if (err) {
            const localErr = err as any;
            this.clearAndShowError(`REJECT_TRANSFER_FAILED`, localErr);
            return false;
        }

        this.clearAndShowSuccess('REJECT_TRANSFER_SUCCESS');
        return true;
    }

    public async rejectTransferRequest(transferRequestId: number): Promise<boolean> {
        this.showPending('REJECT_TRANSFER_PENDING');

        const [err] = await to(
            this.post<TransferRequest[]>(`${this.endpoint}transfer-requests/:id/reject`, { transferRequestId }, [['id', transferRequestId]]),
        );
        if (err) {
            const localErr = err as any;
            this.clearAndShowError(`REJECT_TRANSFER_FAILED`, localErr);
            return false;
        }

        this.clearAndShowSuccess('REJECT_TRANSFER_SUCCESS');
        return true;
    }

    public doTransfer(transfer: Transfer): AxiosPromise<Transfer> {
        const model = {
            group: transfer.group,
            initiator: transfer.initiator,
            reason: transfer.reason,
            sender: transfer.sender,
            receiver: transfer.receiver,
            senderTeam: transfer.senderTeam.teamId,
            receiverTeam: transfer.receiverTeam.teamId,
            amount: transfer.amount,
            invoiceType: transfer.invoiceType,
        };

        return this.post<Transfer>(`${this.endpoint}transfers`, model);
    }

    public updateTransfer(transfer: Transfer) {
        const model = {
            reason: transfer.reason,
            amount: transfer.amount,
        };

        return this.put<Transfer>(`${this.endpoint}transfers/${transfer.transferId}`, model);
    }

    public deleteTransfer(transferId: number): AxiosPromise {
        return this.delete(`${this.endpoint}transfers/${transferId}`);
    }

    public async getTransfers(team: number, group: number, personId: number, period: string): Promise<Transfer[]> {
        const config = {
            headers: {
                'x-period': period,
            },
        };

        const [err, response] = await to(
            this.get<Transfer[]>(
                `${this.endpoint}transfers?teamId=:team&groupId=:?group&personId=:?personId`,
                [['team', team], ['group', group], ['personId', personId]],
                config,
            ),
        );

        if (err) {
            this.clearAndShowError('Failed to load transfers', err);
            return [];
        }

        return response.data;
    }

    public async getCrossTeamPersonTransfers(team: number, period: string, personId?: number): Promise<Transfer[]> {
        const config = {
            headers: {
                'x-period': period,
            },
        };

        if (personId) {
            const [errPerson, responsePerson] = await to(
                this.get<Transfer[]>(
                    `${this.endpoint}transferred-funds?teamId=:team&personId=:?personId`,
                    [['team', team], ['personId', personId]],
                    config,
                ),
            );

            if (errPerson) {
                // this.clearAndShowError('Failed to load transfer', errPerson);
                return [];
            }

            return responsePerson.data;
        }

        const [err, response] = await to(this.get<Transfer[]>(`${this.endpoint}transferred-funds?teamId=:team`, [['team', team]], config));
        if (err) {
            // this.clearAndShowError('Failed to load transfer', err);
            return [];
        }

        return response.data;
    }

    public createRecurringTransfer(transfer: IRecurringTransferRequest) {
        return this.post(`${this.endpoint}recurring-transfers`, transfer);
    }

    public stopRecurringTransfer(transferId: number, endPeriod: string) {
        return this.post(`${this.endpoint}recurring-transfers/${transferId}/stop`, { endPeriod }, null, {
            headers: { 'Content-Type': 'application/json' },
        });
    }

    public getReucrringTransfers(period: string, teamId: number, groupId?: number) {
        let url = `${this.endpoint}recurring-transfers?teamId=${teamId}`;
        if (groupId) {
            url += `&groupId=${groupId}`;
        }
        if (period) {
            const headers = { 'x-period': period };
            return this.get(url, null, { headers });
        } else {
            return this.get(url);
        }
    }
}
