import { Container, G, Svg } from '@svgdotjs/svg.js';
import { HorizontalAlign } from './HorizontalAlign';
import SvgRender from './SvgRender';
import SvgHelper from './SvgHelper';
import CellColor from '../models/CellColor';
import MapConfig from '../models/MapConfig';
import MapCellData from '../models/MapCellData';
import { CellIndicatorScore } from '../models/CellIndicatorScore';

export default class SvgRenderInfoCell extends SvgRender {
    public type = 'InfoCell';
    public canvas: Svg = null;
    private data: MapCellData = new MapCellData();

    constructor(canvas?: Svg) {
        super();
        this.canvas = canvas;
    }

    public setData(data: MapCellData) {
        this.data = data;
    }

    public getId() {
        return this.data.id;
    }

    public render() {
        this.canvas.clear();
        this.container = this.canvas.group();
        this.container.addClass('cursor-pointer');
        this.container.click(() => this.fire('navigate', true));

        const circleBackground = this.container.group();
        const circleContent = this.container.group();
        this.drawHeader(circleContent.group().id('header'));
        this.drawTextContainer(circleContent);
        SvgHelper.stackChildren(circleContent, HorizontalAlign.Center);

        const size = Math.max(+this.container.width() / 2, +this.container.height() / 2);
        this.drawCircle(circleBackground, size);

        SvgHelper.center(circleContent);
        SvgHelper.center(this.container);
    }

    private drawHeader(header: G): G {
        const title = header.group().id('title');
        title.text(this.data.name).font(MapConfig.text);
        const t = title.text(this.data.title).font(MapConfig.title);
        SvgHelper.stackChildren(title, HorizontalAlign.Center);
        t.dy(24);

        const subtitle = header.group().id('subtitle');
        const subtitleText = subtitle.text(this.data.subtitle).font(MapConfig.subTitle);
        const indicator = this.indicator(subtitle, subtitleText.bbox().height / 2)
            .fill(MapConfig.red)
            .move(subtitleText.bbox().width + 8, -subtitleText.bbox().height / 2);

        if (this.data.indicatorScore === CellIndicatorScore.Positive) {
            indicator.rotate(180).fill(MapConfig.green);
        }

        SvgHelper.stackChildren(header, HorizontalAlign.Center);
        return header;
    }

    private drawTextContainer(container: G) {
        const textContainer = container.group();
        const labels = textContainer.group().id('labels');
        const values = textContainer.group().id('values');

        SvgHelper.lines(labels, this.data.labels, HorizontalAlign.Right, MapConfig.text);
        labels.font(MapConfig.text).fill(MapConfig.textMutedColor);

        SvgHelper.lines(values, this.data.values, HorizontalAlign.Left, MapConfig.text);
        values.font(MapConfig.text);

        const width = Math.max(labels.bbox().width, values.bbox().width);
        const height = Math.max(labels.bbox().height, values.bbox().height);

        values.dx(width + 16);
        labels.dx(width - labels.bbox().width);
        textContainer.rect(width * 2, height + 48).dy(-24).fill('transparent');
        return textContainer;
    }

    private drawCircle(container: G, size: number) {
        const radius = Math.sqrt(size**2 + size**2) + MapConfig.padding;
        const circle = container.circle(radius * 2);
        circle.stroke(MapConfig.cell.stroke);

        const colors = CellColor.score(this.data.colorScore);
        circle.fill(colors.light);
        circle.stroke({ color: colors.dark });
    }

    private indicator(container: Container, size: number) {
        return container.polyline(`0,0 ${size / 2},${size} ${size},0`);
    }
}
