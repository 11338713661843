import Transfer from '../Transfer';

export default class TransferRequest {
    public transferRequestId: number;
    public senderGroup: number;
    public receiverGroup: number;

    public direction: string;
    public status: string;
    public initiator: number;
    public dateCreated: string;

    public transfer: Transfer = new Transfer();
    public recipients: any[];

}
