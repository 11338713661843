import { InvoiceType } from '../InvoiceType';

export default class PaymentStatus {
    public ecosystem: number = null;
    public invoiceId: number = null;
    public invoiceReference?: string = null;
    public invoiceDate: string = '';
    public externalIdentifier: string = '';
    public period: number = null;
    public periodMatched: number = null;
    public invoiceType: InvoiceType;
    public team: number = null;
    public invoiceAmount: number = null;
    public amountPaid: number = null;
    public paymentDate: string = '';

    constructor(init?: Partial<PaymentStatus>) {
        Object.assign(this, init);
    }
}
