import { Element, G, Svg } from '@svgdotjs/svg.js';
import { HorizontalAlign } from './HorizontalAlign';
import { VerticalAlign } from './VerticalAlign';

export default class SvgHelper {
    public static hAlign(node: Element, align: HorizontalAlign) {
        const box = this.getParentBox(node);
        switch (align) {
            case HorizontalAlign.Center:
                node.x((box.width - +node.bbox().width) / 2);
                break;
            case HorizontalAlign.Right:
                node.x(box.width - node.bbox().width);
                break;
            case HorizontalAlign.Left:
                node.x(0);
                break;
        }
    }

    public static vAlign(node: Element, align: VerticalAlign) {
        const box = this.getParentBox(node);
        switch(align) {
            case VerticalAlign.Center:
                node.y((box.height - node.bbox().height) / 2);
                break;
        }
    }

    public static center(node: Element) {
        this.hAlign(node, HorizontalAlign.Center);
        this.vAlign(node, VerticalAlign.Center);
    }

    public static stackChildren(node: Element, align: HorizontalAlign) {
        let y = 0;
        node.children().forEach((child: Element) => {
            child.y(y);
            this.hAlign(child, align);
            y += child.bbox().height;
        });
    }

    public static verticalCenter(node: Element, parent: Element) {
        node.move(node.x(), (+parent.height() - +node.height()) / 2);
    }

    public static lines(container: G, lines: string[], align: HorizontalAlign, font: any = null) {
        lines.forEach((line) => {
            const text = container.text(line);
            if (font) {
                text.font(font);
            }
        });
        this.stackChildren(container, align);
    }

    private static getParentBox(node: Element) {
        if (!node.parent()) {
            throw Error('No parent specified');
        }

        if (node.parent().type === 'svg') {
            const parent = node.parent() as Svg;
            return { width: +parent.width(), height: +parent.height(), x: +parent.x(), y: +parent.y() };
        }
        return (node.parent() as Element).bbox();
    }
}
