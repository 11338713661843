import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { teamHelper, teamService, ecosystemHelper } from '@/main';
import BaseEcosystemDirectory from './BaseEcosystemDirectory';
import { orderBy } from '@progress/kendo-data-query';
import Team from '@/models/Team';
import { periodModule } from '@/store/modules/period';
import AreYouSureModal from '@/components/modals/are-you-sure-modal';

@Component
export default class EcosystemTeamsComponent extends BaseEcosystemDirectory {
    public allTeams: Team[] = [];
    public teamsColumns: any[] = [
        { field: 'name', title: 'NAME' },
        { cell: this.renderEcosystem, title: 'ECOSYSTEM', width: 300 },
        { cell: this.renderGroups, title: 'GROUPS', width: 125 },
        { cell: this.renderActions, title: 'ACTIONS', width: 125 },
    ];

    public $refs!: {
        areYouSureModal: AreYouSureModal;
    };

    public async created() {
        const self = this;

        this.teams = await teamService.getTeams();
        this.allTeams = this.teams = orderBy(this.teams, [{ field: 'name', dir: 'asc' }]);

        self.isLoading = false;
    }

    public updateGridData(dataItems) {
        this.teams = orderBy(dataItems, [{ field: 'name', dir: 'asc' }]);
        this.refreshGrid++;
    }

    public renderGroupLead(h, _, row): any {
        return this.renderMember(h, row.dataItem.groupLead);
    }

    public renderRounterLink(h, _, row) {
        const routeUrl = this.$router.resolve({
            name: 'team-directory-group',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                groupKey: row.dataItem.groupKey,
                groupId: row.dataItem.groupId,
                teamId: teamHelper.currentTeam.id.toString(),
                teamKey: teamHelper.currentTeam.key,
                tab: 'members',
                period: periodModule.selectedPeriod,
            },
        }).href;

        const props = {
            text: row.dataItem.name,
            url: routeUrl,
        };

        return h(Vue.component('grid-router-link'), { props });
    }

    public fullname(member: any): string {
        return `${member.firstName} ${member.insertion ? member.insertion : ''} ${member.lastName}`;
    }

    public renderFullname(h, _, row) {
        const member = row.dataItem;
        const name = `${member.firstName} ${member.insertion ? member.insertion : ''} ${member.lastName}`;
        return h('td', [name]);
    }

    public renderEcosystem(h) {
        return h('td', ecosystemHelper.currentEcosystem.name);
    }

    public showDeleteTeamModal(item) {
        this.$refs.areYouSureModal.setScope(item, 'name', 'Team');
        this.$refs.areYouSureModal.show();
    }

    public async deleteTeam(team: Team) {
        this.showPending('DELETE_TEAM_PENDING');
        const success = await teamService.deleteTeam(team.teamId);
        if (!success) {
            return;
        }

        this.clearAndShowSuccess('DELETE_TEAM_SUCCESS');
        this.$refs.areYouSureModal.hide();

        this.isLoading = true;
        this.teams = await teamService.getTeams();
        this.allTeams = this.teams = orderBy(this.teams, [{ field: 'name', dir: 'asc' }]);
        this.isLoading = false;
    }

    public renderActions(h, _, row: any): any {
        const actions = [{ title: 'Delete team', function: this.showDeleteTeamModal }];
        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }
}
