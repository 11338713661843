import to from 'await-to-js';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import store from '../index';
import { teamService, teamHelper } from '@/main';
import Team from '@/models/Team';

@Module
class TeamsModule extends VuexModule {
    public failed: boolean = false;
    private teams: Team[] = [];

    public get all() {
        return this.teams;
    }

    public get current() {
        return this.findTeam(teamHelper.getTeamId());
    }

    public get findTeam() {
        return (teamId: number) => {
            return this.teams.find((x) => x.teamId === teamId);
        };
    }

    @Action
    public async fetchTeamsIfNeeded(ecosystem?: number, period?: string) {
        if (typeof this.teams === 'undefined' || this.teams.length === 0) {
            return this.fetchTeams(ecosystem, period);
        }
    }

    @Action
    public async fetchTeams(ecosystem?: number, period?: string) {
        const [err, items] = await to(teamService.getTeams(ecosystem, period));
        if (err) {
            this.failTeams();
        }

        this.setTeams({ items });
    }

    @Mutation
    private failTeams() {
        this.teams = [];
        this.failed = true;
    }

    @Mutation
    private setTeams(payload) {
        this.teams = payload.items;
        this.failed = false;
    }
}

export const teamsModule = new TeamsModule({ store, name: 'teams' });
