import { FinanceAccount } from './Interfaces';
import { Member } from './Member';
import { AccountType } from './AccountType';
import GroupAlias from './GroupAlias';
import Connector from './Connectors/Connector';

export default class Group {
    public groupId: number = 0;
    public groupKey: string = '';
    public name: string = '';
    public members: Member[] = [];
    public groupLead: Member;
    public purpose: string = '';
    public energyDedication: any;
    public averageEnergyDedication: number = 0;
    public aliases: GroupAlias = new GroupAlias();
    public teamId: number = 0;
    public readMe: string = '';
    public connectors: Connector[] = [];
    public collaborationMode: string = null;
    public templateId: number = null;
    public baseTemplate: number = null;

    public failed: boolean;

    public accountIncome: FinanceAccount = {
        accountId: 0,
        accountType: AccountType.GroupIncome,
        name: '',
        relatedEntityId: 0,
    };

    public account: FinanceAccount = {
        accountId: 0,
        accountType: AccountType.GroupSavings,
        name: '',
        relatedEntityId: 0,
    };

    constructor(obj?: Partial<Group>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    public isConnectorMember(connector: Connector) {
        return this.connectors.filter((x) => x.connectorId === connector.connectorId).length > 0;
    }
}
