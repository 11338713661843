import PageRender from '@/models/PageRender';
import Group from '@/models/Group';
import Team from '@/models/Team';
import { Member } from '@/models/Member';

export default class BaseEcologyDirectory extends PageRender {
    public teams: Team[] = [];
    public isLoading: boolean = true;
    public groups: Group[] = [];
    public members: Member[] = [];
    public refreshGrid: number = 0;
}
