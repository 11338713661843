import BaseGroupPage from './BaseGroupPage';
import Vue from 'vue';
import DistributedInvoice from '../../../../models/DistributedInvoice';
import { teamHelper, roleHelper, ecosystemHelper, compensationService } from '@/main';
import { InvoiceType } from '../../../../models/InvoiceType';
import { Member } from '../../../../models/Member';
import moment from 'moment';
import { FinanceAccount } from '../../../../models/Interfaces';
import Component from 'vue-class-component';
import { periodModule } from '@/store/modules/period';
import to from 'await-to-js';
import Compensation from '@/models/Compensation';

@Component
export default class GroupDetailAnalytics extends BaseGroupPage {
    public invoices: DistributedInvoice[] = [];
    public compensations: Compensation[] = [];
    public members: Member[] = [];
    public isLoaded: boolean = false;
    public financeAccounts: FinanceAccount[] = [];
    public invoicesReloading: boolean = false;
    public invoicesReloaded: number = 0;

    public async mounted() {
        const self = this;
        const groupKey = this.$route.params.groupKey;

        await this.initInvoicesOrExpensesGroup(InvoiceType.Income);

        if (!roleHelper.isSiteAdmin() && !roleHelper.isFinanceEmployee() && !roleHelper.isGroupLead(this.group)) {
            await this.$router.push({
                name: 'team-finances-group',
                params: {
                    ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                    ecosystemKey: ecosystemHelper.currentEcosystem.key,
                    groupKey,
                    teamId: teamHelper.currentTeam.id.toString(),
                    teamKey: teamHelper.currentTeam.key,
                    tab: 'overview',
                    period: periodModule.selectedPeriod,
                },
            });
        }

        this.personIncomesColumns.push(
            { title: 'Compensation', cell: this.formatNumber('compensation') },
            { title: 'Result', cell: this.formatNumber('result') },
            { title: 'ROI', cell: this.formatField('roi', (x: number) => `${x.toFixed()}%`, 0) });

        await this.initAttributions();

        self.isLoaded = true;
    }

    public async recalculateInternalIncome(dates) {
        this.invoicesReloading = true;
        this.showPending('CALCULATING_REVENUE_PENDING');

        this.invoices = [];
        this.compensations = [];

        const startPeriod = periodModule.periods.find((x) => moment(x.startDate, 'YYYY-MM-DD').format('YYYY-MM') === dates.fromFilterMonth);
        const endPeriod = periodModule.periods.find((x) => moment(x.startDate, 'YYYY-MM-DD').format('YYYY-MM') === dates.toFilterMonth);

        if (startPeriod && endPeriod) {
            for (let i = startPeriod.periodId; i <= endPeriod.periodId; i++) {
                const period = periodModule.periods.find((x) => x.periodId === i);

                const invoices = await this.loadInvoices(InvoiceType.Income, moment(period.startDate, 'YYYY-MM-DD').format('YYYY-MM'));
                const compensations = await this.loadCompensations(moment(period.startDate, 'YYYY-MM-DD').format('YYYY-MM'));
                this.invoices.push(...invoices);
                this.compensations.push(...compensations);
            }
        }

        await this.calculateRevenue();
        await this.calculateCompensations();
        this.clearNotifications();
        this.invoicesReloading = false;
        this.invoicesReloaded++;
    }

    public async calculateCompensations() {
        for (let i = 0; i < this.members.length; i++) {
            const member = this.members[i];
            const personIncome = this.personIncomes.find((x) => x.member.memberId === member.memberId);
            const compensation = this.getTotalCompensations(member.memberId);
            const result = personIncome.totalAmount - compensation;
            personIncome.compensation = compensation;
            personIncome.costs = this.getTotalCosts(member.memberId);
            personIncome.result = result;
            personIncome.roi = result && compensation
                ? (result / compensation) * 100
                : 0;
        }
    }

    public calculateFromPenLTariff(amount) {
        return (
            amount /
            (1 - this.attributionPercentages.teamAttributionsPercentage / 100 / (1 - this.attributionPercentages.groupAttributionsPercentage / 100))
        );
    }

    public getTotalCompensations(personId: number) {
        const compensations = this.compensations.filter((x) => x.member.personId === personId);
        const total = compensations.reduce((x, y) => {
            return x + y.compensation;
        }, 0);

        return this.calculateFromPenLTariff(total);
    }

    public getTotalCosts(personId: number) {
        const compensations = this.compensations.filter((x) => x.member.personId === personId);
        const total = compensations.reduce((x, y) => {
            return x + y.costs;
        }, 0);

        return this.calculateFromPenLTariff(total);
    }

    public async loadCompensations(period: string) {
        const [err, response] = await to(compensationService.getCompensations(this.teamId, this.group.groupId, period));
        if (err) {
            this.clearAndShowError('FAILED', err);
            return [];
        }
        return response.data.memberCompensations.map((x) => new Compensation(x));
    }

    public renderMemberRevenue(h, _, row: any) {
        const props = { member: row.dataItem.member };
        return h(Vue.component('member-name-native'), { props });
    }
}
