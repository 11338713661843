import { Component, Prop } from 'vue-property-decorator';
import TranslateBase from './base';
import PrdActionBtn from '@prd/shared-ui/src/components/Form/ActionButton.vue';

@Component({
    components: {
        PrdActionBtn,
    }
})
export default class TranslateButton extends TranslateBase {
    @Prop() public variant: string;
    @Prop() public size: string;
    @Prop() public action: CallableFunction;
    @Prop({ type: Boolean, default: false }) public disabled: boolean;

    public clicked(e) {
        this.$emit('click', e);
    }
}
