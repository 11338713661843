import { ecosystemHelper, ecosystemService } from '@/main';
import { Member } from '@/models/Member';
import { periodModule } from '@/store/modules/period';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BaseEcologyDirectory from '../directory/components/BaseEcosystemDirectory';

@Component
export default class EcosystemSettingsLedgerAccounts extends BaseEcologyDirectory {
    public isLoading: boolean = true;
    public info: any = null;
    public allMembers: Member[] = [];

    public columns: any = [
        { title: 'TYPE_OF_NODES', field: 'name', footerCell: this.renderTotalLabel },
        { title: 'NODES_IN_USE', field: 'value', footerCell: this.renderFooterCellSum },
        { title: 'INFORMATION', cell: this.renderLink },
        // { title: 'ACTIONS', cell: this.renderActions, width: 100 },
    ];

    public get subscriptionNodes() {
        if (!this.info) {
            return [];
        }

        const rows = [];
        for (const property in this.info) {
            rows.push({
                name: property === 'groupCount' ? 'Groups' : 'Members',
                type: property,
                value: this.info[property],
            });
        }

        return rows;
    }

    public async created() {
        this.info = await ecosystemService.getSubscriptionInfo();
        this.isLoading = false;
    }

    public renderLink(h, _, row: any): any {
        const type = row.dataItem.type === 'groupCount' ? 'groups' : 'members';
        const routeUrl = this.$router.resolve({
            name: 'ecosystem-directory',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                tab: type,
                period: periodModule.selectedPeriod,
            },
        }).href;

        const props = {
            text: row.dataItem.type === 'groupCount' ? 'See all groups' : 'See all members',
            url: routeUrl,
        };

        return h(Vue.component('grid-router-link'), { props });
    }

    public renderActions(h, _, row: any): any {
        const actions = [];
        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    public renderTotalLabel(h) {
        return h('td', 'Total active nodes');
    }

    public renderFooterCellSum(h, _, row) {
        const total = this.subscriptionNodes.reduce((acc: number, current) => acc + (current[row.field] as number), 0);
        return h('td', total);
    }
}
