import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { translateModule } from '@/store/modules/translate';
import { BModal } from 'bootstrap-vue';
import TranslateGroup from './translate-group.vue';

@Component({
    components: {
        TranslateGroup,
    },
})
export default class LanguagePage extends Vue {
    public newLanguage: string = '';
    public searchAlertMessage: string = '';
    public $refs!: {
        addLanguageModal: BModal,
    };

    public get languages() {
        return translateModule.languages;
    }

    public showAddLanguage() {
        this.$refs.addLanguageModal.show();
    }

    public async addLanguage() {
        translateModule.addLanguage(this.newLanguage.toLowerCase());
    }
}
