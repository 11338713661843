import { Component, Prop } from 'vue-property-decorator';
import BaseModal from './BaseModal';
import DistributedInvoice from '@/models/DistributedInvoice';
import to from 'await-to-js';
import { financeService, teamHelper, financeAccountService } from '@/main';
import { Distribution, FinanceAccount } from '@/models/Interfaces';
import Group from '@/models/Group';
import { InvoiceType } from '@/models/InvoiceType';
import { AccountType } from '@/models/AccountType';
import { periodModule } from '@/store/modules/period';

@Component
export default class MoveToAccountModalComponent extends BaseModal {
    @Prop({ default: () => false }) public isExpense: boolean;
    @Prop({ default: () => [] }) public optionsList: any[];
    @Prop({ default: () => [] }) public selectedInvoices: DistributedInvoice[];
    @Prop({ default: () => new Group() }) public group: Group;

    public account: number = null;
    public financeGroupAccounts: FinanceAccount[];
    public isLoaded: boolean = false;

    public async mounted() {
        if (this.group && this.group.groupId > 0) {
            this.financeGroupAccounts = await this.loadGroupFinanceAccounts();
        }

        if (this.$refs.modal) {
            this.$refs.modal.$on('hidden.bs.BModal', this.onHide);
        }

        this.isLoaded = true;
    }

    public onHide() {
        this.account = null;
    }

    public async moveSelectedInvoicesToAccount() {
        this.showPending('DISTRIBUTING_INVOICES_PENDING');
        // tslint:disable-next-line: no-dead-store
        let [err] = [null];
        let account = null;
        if (this.group && this.group.groupId > 0 && this.financeGroupAccounts.length > 0) {
            // check if is group scope and check if its move out of current group
            account = this.financeGroupAccounts.find((x) => x.accountId === this.account);
            if (account) {
                const teamInvoices = await this.loadTeamInvoices(this.isExpense ? InvoiceType.Expenses : InvoiceType.Income);
                const invoiceIds = this.selectedInvoices.map((invoice) => invoice.invoice.invoiceId);
                const moveOutInvoices = teamInvoices.filter((tInvoice) => invoiceIds.indexOf(tInvoice.invoice.invoiceId) > -1);

                moveOutInvoices.forEach((invoice: DistributedInvoice) => {
                    const groupDistribution = invoice.distributions.find(
                        (distribution) => distribution.account.accountId === this.group.accountIncome.accountId,
                    );
                    if (groupDistribution) {
                        groupDistribution.account = account;
                    }
                });
                [err] = await to(financeService.saveDistributions(moveOutInvoices, teamHelper.getTeamId()));
            }
        }

        if (!account) {
            // when this account check is empty its a 'normal' in group/team distribution
            this.selectedInvoices.forEach((invoice: DistributedInvoice) => {
                invoice.distributions.forEach((d: Distribution) => {
                    d.amount = 0;
                });

                const distribution = invoice.distributions.find((x) => x.account.accountId === this.account);
                distribution.amount = invoice.invoice.amount;
            });

            [err] = await to(financeService.saveDistributions(this.selectedInvoices, teamHelper.getTeamId(), this.group.groupId));
        }

        if (err) {
            return this.clearAndShowError('DISTRIBUTING_INVOICES_FAILED', null);
        }

        this.selectedInvoices.forEach((invoice) => (invoice.selected = false));
        this.clearAndShowSuccess('DISTRIBUTING_INVOICES_SUCCESS');

        this.$emit('invoice-moved', this.isExpense ? InvoiceType.Expenses : InvoiceType.Income);

        this.hide();
    }

    private async loadTeamInvoices(type: InvoiceType) {
        const invoices = await financeService.getInvoices(teamHelper.getTeamId(), null, type, null, periodModule.selectedPeriod);
        return invoices;
    }

    private async loadGroupFinanceAccounts() {
        const self = this;
        const [err, response] = await to(
            financeAccountService.getAccounts(AccountType.GroupIncome, teamHelper.currentTeam.id, periodModule.selectedPeriod),
        );
        if (err) {
            this.showFailedResponse('Failed to load group accounts', null);
        }

        return response.data.filter((item: FinanceAccount) => {
            return item.relatedEntityId !== self.group.groupId;
        });
    }
}
