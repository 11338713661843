import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { loginService, ecosystemHelper } from '@/main';
import { NetworkStatus } from '../models/NetworkStatus';
import { profileModule } from '@/store/modules/profile';
import { periodModule } from '@/store/modules/period';

export class AxiosHelper {
    public axiosResponseInterceptor: number;
    private authAxiosInstance: AxiosInstance = {} as AxiosInstance;
    private publicAxiosInstance: AxiosInstance = {} as AxiosInstance;

    constructor(authConfig: AxiosRequestConfig, publicConfig: AxiosRequestConfig) {
        const self = this;

        this.authAxiosInstance = axios.create(authConfig);
        this.publicAxiosInstance = axios.create(publicConfig);

        this.authAxiosInstance.interceptors.request.use(
            async (config) => {
                if (!navigator.onLine) {
                    return Promise.reject(NetworkStatus.Offline);
                }

                if (profileModule.isLoggedIn) {
                    const user = profileModule.user;

                    if (user && user.expired) {
                        await loginService.refreshToken();
                    }

                    const jwt = user.access_token;
                    self.setAuthHeder(jwt);
                    config.headers.Authorization = `Bearer ${jwt}`;
                }

                if (typeof config.headers['x-period'] === 'undefined' && periodModule && periodModule.selectedPeriod && periodModule.setupIsDone) {
                    config.headers['x-period'] = periodModule.selectedPeriod;
                }

                if (
                    typeof config.headers['x-ecosystem'] === 'undefined' &&
                    ecosystemHelper &&
                    ecosystemHelper.currentEcosystem &&
                    ecosystemHelper.setupIsDone
                ) {
                    config.headers['x-ecosystem'] = ecosystemHelper.currentEcosystem.id;
                }
                return config;
            },
            (error) => {
                // Do something with request error
                console.error('Some error has occured with this request: ', error);
                return Promise.reject(error);
            },
        );
    }

    public getAuthAxiosInstance(): AxiosInstance {
        return this.authAxiosInstance;
    }

    public getPublicAxiosInstance(): AxiosInstance {
        return this.publicAxiosInstance;
    }

    public setAuthHeder(jwt: string): void {
        this.authAxiosInstance.defaults.headers.Authorization = 'Bearer ' + jwt;
    }

    public async setup() {
        const jwt = profileModule.access_token;
        if (profileModule.isLoggedIn) {
            this.setAuthHeder(jwt);
        }
    }
}
