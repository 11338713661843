import Group from './Group';
import Vue from 'vue';
import { Person } from './Person';

export default class Compensation {
    public group: Group = null;
    public energyDedication: number = 0;
    public amounts: number[] = [];
    public costs: number = null;
    public member: Person = null;
    public compensation: number = 0;
    public attributions: number = 0;

    constructor(obj?: Partial<Compensation>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    public getTotalAmount = function(): number {
        let amount = 0;
        this.amounts.forEach((a: number) => {
            amount += a;
        });
        return amount;
    };

    public getTotalAmountString = function(): string {
        return Vue.filter('number-format')(this.getTotalAmount());
    };

    public calculateFinancialCompensation = function(): number {
        if (this.energyDedication === 0 || this.costs === 0) {
            return 0;
        }
        return (this.getTotalAmount() / (this.costs * (this.energyDedication / 100))) * 100;
    };

    public financialCompensation = function(): string {
        const self = this;
        return `${self.calculateFinancialCompensation().toFixed(1)}%`;
    };

    public setCosts(costs: number) {
        this.costs = costs;
    }
}
