export default class FinancialGroupActivity {
    public hasActivity: boolean = false;
    public incomeInvoicesCount: number = 0;
    public expensesInvoiceCount: number = 0;
    public savingBalance: number = 0;
    public isAttributionGroup: boolean = false;

    /**
     *
     */
    constructor(obj?: Partial<FinancialGroupActivity>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
