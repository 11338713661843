import { AxiosInstance, AxiosPromise } from 'axios';
import { Person } from '../models/Person';
import Group from '../models/Group';
import { AxiosHelper } from '../helpers/axiosHelper';
import InviteResponse from '../models/InviteResponse';
import TeamJoinRequest from '../models/TeamJoinRequest';
import { BaseService } from './baseService';
import Vue from 'vue';
import Team from '@/models/Team';
import { axiosHelper, ecosystemHelper, teamHelper } from '@/main';
import to from 'await-to-js';
import EnergyDedication from '@/models/EnergyDedication';
import { periodModule } from '@/store/modules/period';
import Register from '@/models/Register';

export class TeamService extends BaseService {
    public publicAxiosInstance: AxiosInstance;

    private endpoint = `${Vue.$env().directoryEndpoint}teams`;
    private ecosystemEndpoint = `${Vue.$env().directoryEndpoint}ecosystems`;

    constructor(axiossHelper: AxiosHelper) {
        super(axiossHelper);
    }

    public getGroups(team: number, period?: string) {
        if (!period) {
            return this.get<Group[]>(`${this.endpoint}/:team/groups`, [['team', team]]);
        }

        return this.get<Group[]>(`${this.endpoint}/:team/groups?period=:period`, [
            ['team', team],
            ['period', period],
        ]);
    }

    public getTeam(team: number, period: string) {
        return this.get<Team>(`${this.endpoint}/:team?period=:period`, [
            ['team', team],
            ['period', period],
        ]);
    }

    public async getTeams(ecosystem?: number, period?: string) {
        if (!ecosystem) {
            ecosystem = ecosystemHelper.currentEcosystem.id;
        }

        if (!period) {
            period = periodModule.selectedPeriod;
        }

        const [err, response] = await to(
            this.get<Team[]>(`${this.ecosystemEndpoint}/:ecosystem/teams?period=:period`, [
                ['ecosystem', ecosystem],
                ['period', period],
            ]),
        );
        if (err) {
            this.showFailedResponse('Failed to load teams', null);
            return [];
        }

        return response.data;
    }

    public createTeam(newTeam: any, ecosytemId?: number): AxiosPromise<Team> {
        let config = {};
        if (ecosytemId) {
            config = {
                headers: {
                    'x-ecosystem': ecosytemId,
                },
            };
        }

        return this.post<Team>(`${this.endpoint}`, newTeam, null, config);
    }

    public updateTeam(team: { teamId: number; name: string; legalEntityReference: string }): Promise<unknown> {
        return this.put<Team>(`${this.endpoint}/:team`, team, [['team', team.teamId]]);
    }

    public async getMembers(teamId?: number, period?: string): Promise<Person[]> {
        let config = {};

        if (!teamId) {
            teamId = teamHelper.currentTeamId;
        }

        if (period) {
            config = {
                headers: {
                    'x-period': period,
                },
            };
        }

        const [err, response] = await to(this.get<Person[]>(`${this.endpoint}/:team/members`, [['team', teamId]], config));
        if (err) {
            this.showFailedResponse('Failed to load members', null);
            return [];
        }

        return response.data.map((x) => new Person(x));
    }

    public async getMemberById(memberId: number, period: string) {
        const members = await this.getMembers(teamHelper.currentTeamId, period);

        return members.find((x) => x.personId === memberId);
    }

    public getInvite(inviteKey: string, teamId: number, period: string, ecosystem: any): AxiosPromise<InviteResponse> {
        const config = {
            headers: {
                'x-period': period,
                'x-ecosystem': ecosystem,
            },
        };

        return this.get<InviteResponse>(
            `${this.endpoint}/:teamId/invite/:inviteKey`,
            [
                ['teamId', teamId],
                ['inviteKey', inviteKey],
            ],
            config,
        );
    }

    public acceptInvite(teamId: number, inviteCode: string, registerModel: Register): Promise<any> {
        const model = {
            inviteCode,
            period: registerModel.period,
            teamId,
            personId: registerModel.person.personId,
            firstName: registerModel.person.firstName,
            insertion: registerModel.person.insertion,
            lastName: registerModel.person.lastName,
            emailAddress: registerModel.emailAddress,
            phoneNumber: registerModel.person.phoneNumber,
            password: registerModel.password,
        };

        const config = {
            headers: {
                'x-period': registerModel.period,
            },
        };

        this.publicAxiosInstance = axiosHelper.getAuthAxiosInstance();
        return this.publicAxiosInstance.post(`${this.endpoint}/${teamId}/invite/${inviteCode}`, model, config);
    }

    public getJoinRequest(id: number) {
        return this.get(`${this.endpoint}/joinrequest/:id`, [['id', id]]);
    }

    public addJoinRequest(personId: number, teams: string[]) {
        return this.post(`${this.endpoint}/joinrequest`, { personId, teams });
    }

    public updateJoinRequest(id: number, model: TeamJoinRequest) {
        return this.put(`${this.endpoint}/joinrequest/:id`, model, [['id', id]]);
    }

    public getEnergyDedication(teamId: number, period: string, personId?: number) {
        return this.get(`${this.endpoint}/:teamId/energy-dedication/?personId=:?personId&period=:period`, [
            ['teamId', teamId],
            ['personId', personId],
            ['period', period],
        ]);
    }

    public addMember(person: Person, teamId: number, period: string) {
        if (!person.personId) {
            delete person.personId;
        }

        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.post(
            `${this.endpoint}/:teamId/members?period=:period`,
            person,
            [
                ['period', period],
                ['teamId', teamId],
            ],
            config,
        );
    }

    public unenroll(teamId: number, period: string) {
        return this.delete(`${this.endpoint}/:teamId/unenroll?period=:period`, [
            ['teamId', teamId],
            ['period', period],
        ]);
    }

    public removeMemberFromTeam(memberId: number, teamId: number, period: string) {
        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.delete(
            `${this.endpoint}/:teamId/members/:memberId`,
            [
                ['teamId', teamId],
                ['memberId', memberId],
            ],
            config,
        );
    }

    public updateEnergyDedicationsForMember(teamId: number, energyGroups: EnergyDedication[], period: string, memberId: number) {
        return this.put(
            `${this.endpoint}/:teamId/members/:memberId/energy-dedication?period=:period`,
            energyGroups,
            [
                ['teamId', teamId],
                ['period', period],
                ['memberId', memberId],
            ],
            { headers: { 'Content-Type': 'application/json' } },
        );
    }

    public async deleteTeam(teamId: number) {
        const [err, response] = await to(this.delete(`${this.endpoint}/:teamId`, [['teamId', teamId]]));

        if (err) {
            this.clearAndShowError('DELETE_TEAM_FAILED', response);
            return false;
        }

        return true;
    }

    public async transferTeamLeaderShip(teamLead: Person) {
        return this.patch(
            `${this.endpoint}/:teamId/leadership/transfer`,
            {
                newLeaderId: teamLead.personId,
            },
            [['teamId', teamHelper.currentTeamId]],
        );
    }
}
