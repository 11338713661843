import { translateModule } from '@/store/modules/translate';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class TranslateGroupSetting extends Vue {
    @Prop() public group?: string;
    public search: string = '';

    public get items() {
        const search = this.search.toLowerCase();
        const messages = this.group && this.group !== '' ? this.grouped : this.ungrouped;
        return Object.keys(messages).filter((k) => {
            const value = messages[k].toLowerCase();
            const key = k.toLowerCase();
            return search ? (value.indexOf(search) > -1 || key.indexOf(search) > -1) : true;
        });
    }

    public get grouped() {
        return translateModule.value(this.group);
    }

    public get ungrouped() {
        const messages = {};
        const all = translateModule.all;
        Object.keys(all).forEach((key) => {
            if (typeof(all[key]) === 'string') {
                messages[key] = all[key];
            }
        });
        return messages;
    }
}
