import Group from '../models/Group';
import { groupService, teamHelper } from '@/main';
import to from 'await-to-js';
import PageRender from '@/models/PageRender';
import { periodModule } from '@/store/modules/period';

export class GroupHelper extends PageRender {
    public groups: Group[] = null;

    public async getGroups() {
        const self = this;
        const [err, response] = await to(groupService.getGroups(teamHelper.currentTeamId, periodModule.selectedPeriod));
        if (err) {
            return (self.groups = null);
        }

        return (self.groups = response.data);
    }

    public async getGroup(groupId: number) {
        const [err, response] = await to(groupService.getGroup(groupId, periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse(`Failed to load group with groupId: ${groupId}`);
            return new Group();
        }

        return new Group(response.data);
    }

    public async createOrUpdateGroup(group: Group, periodString?: string): Promise<Group> {
        const postModel = {
            groupLead: typeof group.groupLead === 'object' ? group.groupLead.memberId : group.groupLead,
            name: group.name,
            purpose: group.purpose,
            teamId: teamHelper.currentTeam.id,
            connectorIds: group.connectors.map((x) => (typeof x === 'object' ? x.connectorId : x)),
            groupId: null,
            readMe: group.readMe,
            templateId: group.templateId ? group.templateId : group.baseTemplate ? group.baseTemplate : null,
        };

        if (group.groupId && group.groupId > 0) {
            postModel.groupId = group.groupId;
        } else {
            delete postModel.groupId;
        }

        const [err, response] = await to(groupService.createGroup(postModel, periodString ? periodString : periodModule.selectedPeriod));
        if (err) {
            this.clearAndShowError('Failed to create group', err);
            return new Group();
        }

        return response.data;
    }
}
