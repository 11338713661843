import CreateLedgerAccountModal from '@/components/modals/create-ledger-account-modal';
import { insightService } from '@/main';
import InsightView from '@/models/Insights/InsightView';
import PageRender from '@/models/PageRender';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class EcosystemSettingsInsightViews extends PageRender {
    public isLoading: boolean = true;
    public columns: any = [
        { title: 'ID', field: 'insightViewId' },
        { title: 'NAME', field: 'name' },
        { title: 'TEAMS', field: 'name', cell: this.renderTeams },
        { title: 'PERSONS', field: 'name', cell: this.renderPersons },
        { title: 'ROLES', field: 'name', cell: this.renderRoles },
        { title: 'ACTIONS', cell: this.renderActions, width: 100 },
    ];

    public views: InsightView[] = [];

    public $refs: {
        create: CreateLedgerAccountModal;
    };

    public async created() {
        await this.loadViews();

        this.isLoading = false;
    }

    public async loadViews() {
        this.views = await insightService.getViews();
    }

    public renderTeams(h, _, row) {
        const elements = row.dataItem.teams.map((x) => {
            return { label: x.teamKey, value: x.teamId };
        });
        const props = { elements };

        return h(Vue.component('grid-list'), { props });
    }

    public renderRoles(h, _, row) {
        const elements = row.dataItem.roles.map((x) => {
            return { value: x.role, label: x.role };
        });
        const props = { elements };

        return h(Vue.component('grid-list'), { props });
    }

    public renderPersons(h, _, row) {
        const elements = row.dataItem.persons.map((x) => {
            return { value: 'name', label: x.personId };
        });
        const props = { elements };

        return h(Vue.component('grid-list'), { props });
    }

    public opeSideActionCreateViewRow(row) {
        this.opeSideActionCreateView(row.dataItem);
    }

    public opeSideActionCreateView(item?: InsightView) {
        this.$sideActions.push(
            'create-or-edit-view-side-action',
            {
                // options
                view: item ? item : null,
            },
            async () => {
                await this.loadViews();
            },
        );
    }

    public editView(item?: InsightView) {
        if (item) {
            this.opeSideActionCreateView(item);
        }
    }

    public renderActions(h, _, row: any): any {
        const actions = [{ title: 'Edit view', function: this.editView }];
        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }
}
