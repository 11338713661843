import { AxiosHelper } from '../helpers/axiosHelper';
import { BaseService } from './baseService';
import Vue from 'vue';
import Template from '@/models/Finance/Template';
import to from 'await-to-js';

export class TemplateService extends BaseService {
    private endpoint = `${Vue.$env().financeEndpoint}`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getTemplates(teamId: number) {
        const [err, response] = await to(this.get<Template[]>(`${this.endpoint}templates?team_id=:teamId`, [['teamId', teamId]]));

        if (err) {
            this.showError('LOADING_TEMPLATES_FAILED');
            return [];
        }

        return response.data.map((x) => new Template(x));
    }

    public createTemplate(template: Template) {
        return this.post(`${this.endpoint}templates`, template);
    }

    public updateTemplate(template: Template) {
        return this.put(`${this.endpoint}templates/:templateId`, template, [['templateId', template.financeGroupTemplateId]]);
    }

    public deleteTemplate(template: Template) {
        return this.delete(`${this.endpoint}templates/:templateId`, [['templateId', template.financeGroupTemplateId]]);
    }
}
