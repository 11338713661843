export default {
    // Nav
    ME: '.me',
    TEAM: '.team',
    ECOLOGY: '.ecology',
    SETTINGS: '.settings',

    DIRECTORY: '.directory',
    FINANCES: '.finances',
    ANALYTICS: '.insights',
    CELLS: '.cells',
    OVERVIEW: '.overview',
    GRAPH: '.graph',
    SETTINGS_LANGUAGE: '.translate',

    // Me
    SWITCH_TEAM: 'Switch to team',
    PERIOD: 'Select period',
    PROFILE: 'Profile',
    ME_GROUPS: 'Your groups',
    ME_MEMBERS: 'Your team members',
    ENERGY_DEDICATION_SPENT: '',
    EXPLORE_ALL_GROUPS: 'Explore all groups',
    EXPLORE_ALL_MEMBERS: 'Explore all members of my team',
    ME_TOTAL_INCOME: 'Total income',
    ME_TOTAL_TRANSFERS: 'Total transfers',
    ME_TOTAL_EXPENSES: 'Total expenses',
    ME_TOTAL_EXTRAS: 'Total extras',
    ME_TOTAL_RESULT: 'Total result',
    ME_TOTAL_BUDGER: 'Total budget',
    ME_MAKE_TRANSFER: 'Make transfer',

    ME_FINANCE_OVERVIEW: 'Overview',
    ME_FINANCE_COMPENSATIONS: 'Compensations',
    ME_FINANCE_INVOICES: 'My Invoices',
    ME_FINANCE_EXPENSES: 'Expenses',
    ME_FINANCE_TRANSFERS: 'Transfers',

    // Team
    TEAM_GROUPS: 'Groups',
    TEAM_MEMBERS: 'Members',
    TEAM_SETTINGS: 'Settings',

    TEAM_FINANCE_GROUPS: 'Groups overview',
    TEAM_FINANCE_MEMBERS: 'Members overview',
    TEAM_FINANCE_INCOME: 'All income',
    TEAM_FINANCE_EXPENSES: 'All expenses',
    TEAM_FINANCE_PERIOD: 'Period',
    TEAM_FINANCE_TRANSFERS: 'Transfers (P&L)',
    TEAM_FINANCE_SETTINGS: 'Settings',
    TEAM_FINANCE_RELATIONS: 'Relations',

    TEAM_ANALYTICS_GROUPS: 'Groups result',
    TEAM_ANALYTICS_MEMBERS: 'Members result',

    TEAM_BALANCE: 'Balance',
    TEAM_RESULT: 'Result',
    TOTAL_GROUP_OPEN_INVOICES: 'Total open group invoices',
    TOTAL_GROUP_OPEN_EXPENSES: 'Total open group expenses',

    FINANCES_NEW: '.finance (new)',

    // Eco
    ECO_LEDGER_ACCOUNTS: 'Ledger accounts',
    ECO_CROSS_LEGAL_TRANSACTIONS: 'Cross legal transactions',

    CROSS_LEGAL_TRANSACTIONS_FAILED: 'Failed to load cross legal entity transactions',

    // General
    ANALYTICS_TITLE: 'Insights',
    ACCESS_TO_TEAM: 'Access to team',
    ACCESS_TO_TEAM_INFO: 'Access to team info',
    ACCESS_FROM: 'Access to team from',
    ACCESS_FROM_INFO: 'Access to team from info',
    ACCESS_UNTIL: 'Access to team until',
    ACCESS_UNTIL_INFO: 'Access to team until info',
    ACCESSABLE_FROM_AND_UP: 'Accessible from selected role and up',
    ACCESS_MEMBER_OVERVIEW: `Access to 'Member overview' in team finance`,
    ACCESS_ALL_EXPENSE: `Access to 'All expenses' in team finance`,
    ADD_LEDGER_ACCOUNT: 'Add ledger account',
    ADD_IN_PERIOD: 'Add in period',
    ADD_IN_PERIOD_INFO: 'Add in period info',
    ADD_ATTRIBUTION: '<span class="icon-plus"></span> Add attribution',
    ACTUAL_VS_EXPECTED: '<b>Actual</b> vs <b>expected</b>',
    ALL_TEAMS_OF: 'All teams of ',
    ALL_GROUPS_OF: 'All groups of ',
    ALL_MEMBERS_OF: 'All members of ',
    ALL_TEAMS: 'All teams',
    ALL_GROUPS: 'All groups',
    ALL_MEMBERS: 'All members',
    ALL_INCOME: 'All income',
    ALL_EXPENSES: 'All expenses',
    AMBITIONS: 'Ambitions',
    ADD_TEAM: 'Add team',
    ADD_MEMBER: 'Add member',
    ADD_MEMBER_STEP_1: '1. Add member',
    ADD_MEMBER_STEP_2: '2. Join groups',
    ADD_MEMBER_STEP_3: '3. Set up energy dedication',
    ADD_MEMBER_STEP_4: '4. Send invitation',
    ADD_GROUP_STEP_1: 'Generic properties',
    ADD_GROUP_STEP_2: 'Members',
    ADD_GROUP_STEP_3: 'Workspace',
    ADD_GROUP_STEP_4: 'Connectors',
    ADD_GROUP_STEP_5: 'Template',
    ADD_GROUP_STEP_6: 'Default settings',
    ADD_GROUP_STEP_7: 'Attributions',
    ADD_GROUP_STEP_8: 'Finishing up',
    ADD_COMPENSATION: 'Add compensation',
    ADD_TO_GROUPS: 'Add {name} to one or more groups',
    ADD_ALIAS: '<span class="icon-plus"></span> Add alias',
    APPLIED_MISSING_ATTRIBUTIONS: 'Apply (missing) attributions on transfers',
    APPLIED_MISSING_ATTRIBUTIONS_TEXT: `When this option is selected all transfers between groups and persons will be charged with the group attributions. This is needed in a case when 'tax-free' value has entered a group`,
    BACK_ONE_STEP: 'Back one step',
    FORWARD_ONE_STEP: 'Next',
    CANCEL: 'Cancel',
    DELETE: 'Delete',
    UPDATE: 'Update',
    CALCULATED_INCOME: 'Calculated income',
    CREATE_GROUP: 'Create new group',
    CREATE_ACCOUNT: 'Create account',
    CLOSE_PERIOD: 'Close period',
    CLOSE_PERIOD_SOFT: 'Soft close',
    COMPENSATIONS: 'Compensations',
    COMPENSATE_ALL_MEMBERS: 'Compensate all members',
    COMPENSATE_GROUP_MEMBERS: 'Compensate group members based on costs',
    COMPENSATE_GROUP_MEMBERS_TEXT:
        'When this option is selected all group members will be automatically compensated based on their costs and energy dedication.',
    COMPENSATE_MEMBERS_AUTO: 'Compensate members automatically',
    COPY_FROM: 'Copy from',
    COLLABORATION_MODE: 'Collaboration mode',
    COLLABORATION_MODE_TEXT: 'Define the mode {name} is operating in.',
    COLLABORATION_MODE_DESCRIPTION: `Specific collaboration modes have specific features. Only change this if you know what you're doing.`,
    CREATE_ACCOUNT_POROCEED_TO_GROUP: 'Create account, proceed to group',
    CONFIRM_GROUP_DELETE: 'Are you sure you want to delete this group?',
    DISTRIBUTE: 'Distribute',
    DISTRIBUTED_INVOICES_AMOUNT: 'Distributed invoices amount:',
    DISTRIBUTED_EXPENSES_AMOUNT: 'Distributed expenses amount:',
    DISTRIBUTE_INCOME_AUTO: 'Distribute income automatically',
    DISTRIBUTE_EXPENSE_AUTO: 'Distribute expenses automatically',
    DESCRIPTION: 'Description',
    DROP_DEAD_RATIO: 'Drop dead ratio',
    DELETE_GROUP: 'Delete group',
    DELETE_GROUP_INFO: 'A group can only be deleted if multiple conditions are met. Be sure the following state applies on the group:',
    DELETE_GROUP_NO_EXPENSES: 'The group has no open expenses',
    DELETE_GROUP_NO_INVOICES: 'The group has no open invoices',
    DELETE_GROUP_NO_ATTRIBUTION: `The group isn't used as a attribution group in current or upcoming periods`,
    DELETE_GROUP_HAS_ATTRIBUTION: `The group has the following attributions `,
    DELETE_GROUP_POINTED_TO_ME: 'Remove attributions',
    DELETE_GROUP_ZERO_BALANCE: 'The group has zero balance',
    DELETE_GROUP_APP_CONDITIONS: 'Other application wide conditions that need to be met are:',
    DELETE_GROUP_PERIODS_CLOSED: 'All previous periods of your team needs to be closed',
    DELETE_GROUP_CURRENT_PERIOD_OPEN: 'Current period need to be open',
    DELETE_WITH_ICON: '<i class="icon-trash-2"></i> Delete',
    EDIT_MAP: 'Edit position and radius',
    EXPENSES: 'Expenses',
    EXTRAS: 'Extras',
    EXPORT_MEMBERS: 'Export members',
    EMAIL: 'Email address',
    ENTER_A_PERCENTAGE: 'Enter an energy percentage',
    ENERGY_ALWAYS_100: '<b>{energy}%</b> (energy dedication always needs to add up to 100%)',
    EXPENSES_DISTRIBUTION: 'Expenses distribution',
    EXPENSES_DISTRIBUTION_TEXT: 'When this option is selected all expenses will be automatically distributed to the group savings account.',
    EXACT_LEGAL_REFERENCE: 'The exact legal entiry reference',
    FINANCES_RESULT: 'Total result ecosystem',
    FINANCES_BALANCE: 'Total balance ecosystem',
    FINANCE_EXTERNAL_REVENUE: 'Total external revenue ecosystem',
    FINANCE_TEMPLATES: 'Templates',
    FIRST_NAME: 'First name',
    FROM_MONTH: 'From month',
    FEE: 'Fee',
    FINANCE_SETTINGS: 'Finance settings',
    FINANCE_SETTINGS_TEXT: 'With the team privacy settings, you can give specific roles specific access to team finance data.',
    GROUP_RESULT: 'Group result',
    GROUP_CONNECTORS: 'Group connectors',
    GROUP_NAME: 'Group {name}',
    GROUP_SAFETY_ZONE: 'Group safety zone',
    GROUP_OPEN_INVOICES: 'Group open invoices',
    GROUP_OPEN_INVOICES_AMOUNT: 'Group open invoices amount:',
    GROUP_OPEN_EXPENSES: 'Group open expenses',
    GROUP_OPEN_EXPENSES_AMOUNT: 'Open expenses amount:',
    GROUP_ATTRIBUTIONS: 'Group attributions',
    GROUP_ATTRIBUTIONS_CHANGE_MESSAGE: 'Changing your group attributions may have a big impact on your finance.',
    GROUP_ALIASES: 'Group aliases',
    GROUP_ALIASES_TEXT: 'Aliases are used for mapping other names to {name}',
    INCOME: 'Income',
    INCOMING: 'Incoming',
    INCOME_DISTRIBUTION: 'Income distribution',
    INCOME_DISTRIBUTION_TEXT: 'When this option is selected all income will be automatically distributed to the group savings account.',
    IMPORT_INVOICES_CSV: 'Import invoices (.csv)',
    INVOICE_TYPE: 'Invoice type',
    JOIN_GROUP: 'Join group',
    LEAVE_TEAM: 'Leave team',
    LEAVE_GROUP: 'Leave group',
    LAST_NAME: 'Last name',
    LEGAL_ENTITY_REFERENCE: 'Legal entity reference',
    LEGAL_ENTITY_REFERENCE_TEXT: 'This reference determines cross legal entity reference cash flows.',
    MONTHLY: 'Month',
    MONTHS: 'months',
    MEMBER_COMPENSATIONS: 'Member compensations',
    MEMBERS: 'Members',
    MAKE_TRANSFER: 'Make transfer',
    NEW_BUDGET: 'New budget',
    NO_CLAIMED_DISTRIBUTED_INVOICES: 'No claimed or distributed invoices',
    NO_USEFULL_INFO: 'Workspace information: add relevant links or content for your group members. For example drive links, slack channel, etc.',
    NO: 'No',
    NO_PERIOD_FOUND: 'No period found',
    OPEN_INVOICES: 'Team open invoices',
    OPEN_EXPENSES: 'Team open expenses',
    OPEN_PERIOD: 'Open period',
    OPEN_INVOICES_AMOUNT: 'Open invoices amount:',
    OVERVIEW_TITLE: 'Overview',
    OUTGOING: 'Outgoing',
    OR: 'or',
    OH_SNAP: '<strong>Oh snap!</strong> Change a few things up and try submitting again.',
    PLACEHOLDER_ANYTHING_YOU_LIKE: 'Anything you like...',
    PROCEED_TO_ENERGY_DEDICATION: 'Proceed to energy dedication',
    PURPOSE: 'Purpose',
    PREVIOUS_BUDGET: 'Previous budget',
    PERSONAL_GROUP_FINANCE: 'Personal group finance',
    PERSONAL_GROUP_FINANCE_TEXT: `The default group privacy level on team is set on <b>{level}</b>. It's possible to overrule this setting by defining it beneath.`,
    PRIVACY_SETTINGS: 'Privacy settings',
    PRIVACY_SETTINGS_TEXT: 'With the team privacy settings, you can give specific roles specific access to team finance data.',
    RECEIVER: 'Receiver',
    RELATIONS: 'Relations',
    QUARTERLY: 'Quarter',
    RESULT: 'Result',
    ROLES: 'Roles',
    ROLE_MANAGEMENT: 'Role management',
    RECURRING_TRANSFER: 'Perform this transfer periodically',
    RECURS_EVERY: 'Recurs every...',
    RECURRING_TRANSFERS: 'Recurring transfers',
    RECURRING_COMPENSATIONS: 'Recurring compensations',
    REQUEST_FROM_OTHER_GROUPS: 'requests from other groups',
    REQUEST_TO_OTHER_GROUPS: 'requests to other groups',
    REMITTANCE_PERCENTAGE: 'Remittance percentage',
    REMITTANCE_PERCENTAGE_TEXT: 'Changing these remittance percentages may have a big impact on your finance.',
    SAVE: 'Save',
    SAVE_SETTINGS: 'Save settings',
    SAVE_TEAM_PROFILE: 'Save team profile',
    SAVE_ALIASES: 'Save aliases',
    SAVE_COLLABORATION_MODE: 'Save collaboration mode',
    SAVE_LEGAL_ENTITY: 'Save legal entity reference',
    SELECT_PERSON_GROUP: '--Select a person/group--',
    SELECT_GROUP: '--Select a group--',
    SELECT_RECEIVER: '-- Select a receiver --',
    SELECT_SENDER: 'Select a sender',
    RECOMMEND_DESCRIPTION: 'We recommend to add a description',
    SELECT_PERIOD: '-- Select period --',
    SELECT_MODE: '-- Select a mode --',
    SELECT_ROLE: '-- Select a role --',
    SENDER: 'Sender',
    SEARCH: 'Search',
    SETTINGS_TITLE: 'Settings',
    SEND_INVITE: 'Send invitation',
    SEND_TRANSFER_REQUEST: 'Send a transfer request',
    SET_UP_ENERGY_DEDICATION: 'Set up energy dedication',
    SUBJECT: 'Subject',
    SHOW_NOT_YET_DISTRIBUTED: 'Show not yet distributed',
    SHOW_NON_RECURRING: 'Show non-recurring',
    SHOW_ALL: 'Show all',
    TO_MONTH: 'To month',
    TOTAL_ENERGY_DEDICATION: 'Total energy dedication',
    TOTAL_EXPENSES: 'Total expenses',
    TOTAL_EXTRAS: 'Total extras',
    TOTALS: 'Totals',
    TEAM_PROFILE: 'Team profile',
    TEAM_NAME: 'Team name',
    TEAM_LEADER: 'Team leader',
    TEAMS_INCLUDED_IN_TOTALS: 'Teams included in totals',
    TEAM_ATTRIBUTIONS: 'Team attributions',
    TEAM_ATTRIBUTIONS_TEXT: 'Changing your team attributions may have a big impact on your finance.',
    TRANSFERS: 'Transfers',
    TRANSFER_GROUP_LEAD: 'Transfer group lead',
    TRANSFER_TEAM_LEAD: 'Transfer',
    TRANSFER_TEAM_LEAD_TITLE: 'Transfer Team leadership',
    TOTAL_INCOME: 'Total income',
    TOTAL_COMPENSATIONS: 'Total compensations',
    TOTAL_ATTRIBUTIONS: 'Total attributions',
    TOTAL_NON_RECURRING_EXPENSES: 'Total non-recurring expenses',
    TOTAL_OUTGOING_TRANSFERS: 'Total transfers (outgoing)',
    TOTAL_COSTS: 'Total costs',
    WORKSPACE: 'Workspace',
    YES: 'Yes',
    AVAILABLE_LINES: 'Show selected lines',
    CREATE: 'Create',
    SELECT_OPTION: 'Select an option',

    ECOSYSTEM_INSIGHT_VIEWS: 'Insight views',
    INSIGHTS: '.insights',
    ADD_VIEW: 'Add view',

    SET_UP_TEMPLATES: 'Setup default group template',
    FINANCE_SETTING_INHERETED: 'Finance settins inhereted from selected template',
    FINANCE_TEMPLATES_TEXT: 'Define finance templates which can be use to inheret settings when creating a new group',
    GROUP_TEMPLATE_PROPERTIES: 'Mandatory template properties',
    ADD_FINANCE_TEMPLATES: 'Add a new template',
    FINANCE_TEMPLATES_HEADER: 'New template',

    CREATE_GROUP_GROUPLEAD_DESCRIPTION: 'You can transfer group lead when you created a group',
    CREATE_GROUP_GROUPLEAD_LABEL: 'Group lead',
    CREATE_GROUP_PURPOSE_DESCRIPTION: 'Describe briefly what this group stands for',
    CREATE_GROUP_PURPOSE_PLACEHOLDER: 'Develop is responsible for all the copy and paste work',

    CREATE_GROUP_NAME_LABEL: 'Group name',
    CREATE_GROUP_NAME_DESCRIPTION: 'The group name',

    CREATE_GROUP_SUMMARY_TITLE: 'Summary',
    CREATE_GROUP_SUMMARY: 'Summary',
    CREATE_GROUP_MEMBERS_ADD: 'Members to be add',
    CREATE_GROUP_MEMBERS_ADD_AT_LEAST_ONE: 'Select at least one group member',
    CREATE_GROUP_NO_TEMPLATES: 'No templates found, make sure to confiure them before creating a activity.',
    CREATE_GROUP_CONFIGURE_TEMPLATES: 'Configure templates',
    CREATE_GROUP_SUMMARY_NO_CONNECTORS: `No connectors selected, this group will only exists as a directory group, financal activaties wihtin this group aren't possible.`,
    CREATE_GROUP_SUMMARY_NO_TEMPLATE: `The group hasn't a finance connector enabled or there is no template selected, so you can define your settings on the group after it's created.`,
    CREATE_GROUP_SUMMERY_APPLIED: 'Finance settings will be inhereted from {templateName}',
    SET_UP_GENERIC_PROPERTIES: 'Setup generic properties',
    SET_UP_MEMBERS: 'Add members',
    SET_UP_WORKSPACE: 'Add handy links or other important stuff',
    SET_UP_CONNECTORS: 'Enable connectors',

    SEND_REQUEST: 'Send request',
    REJECT_REQUEST: 'Reject request',
    ACCEPT_REQUEST: 'Accept request',
    WITHDRAW_REQUEST: 'Withdraw request',
    UPDATE_REQUEST: 'Update request',

    // Settings
    SETTINGS_TRANSLATE_GENERAL: 'General',
    SETTINGS_ALERT_MESSAGES: 'Alerts',
    SETTINGS_REGISTER_MESSAGES: 'Register',
    SETTINGS_MAP_MESSAGES: 'Map',
    SETTINGS_MODAL_MESSAGES: 'Modals',
    SETTINGS_GRID_MESSAGES: 'Grids',
    SETTINGS_ALERT_TIP: `Tip: search for 'pending', 'failed' or 'succes'.`,
    SETTINGS_TRANSLATE_LANGUAGE: 'Languages',
    SETTINGS_ADD_LANGUAGE: 'Add language',

    // Relations
    RELATIONS_TITLE: 'Contractual and fiscal relations',
    RELATIONS_DESCRIPTION:
        'These are the legal entities that are not part of your legal entity that you get transfers from. It is important to define these relations correctly as they have legal and fiscal consequenses.',

    // Insights
    INSIGHTS_INTRO: 'Select a view and a lens for insights',
    EXTERNAL_REVENUE: 'External Revenue',
    START_BALANCE: 'Start balance',
    BALANCE: 'Balance',
    NONRECURRING_EXPENSES: 'Non-recurring expenses',
    INCOMING_FROM: 'Incoming from',
    OUTGOING_TO: 'Outgoing to',
    HOVER_INSIGHTS: 'Hover over an entity',
    TEAM_INSIGHTS_PAYMENT_STATUSSES: 'Payment statusses',

    CO2_BALANCE: 'CO2 balance',
    CO2_MUTATION: 'CO2 Mutation',
    CO2_COMPENSATION: 'CO2 Compensation',
    CO2_ONEXPENSES: 'CO2 On expenses ',
    CO2_TRANSFERED_TO_REVENUE: 'CO2 Transfered to revenue',

    SAFETY_ZONE: 'Safety Zone',

    CASHFLOW_BALANCE: 'Cashflow balance',
    CASHFLOW_RESULT: 'Cashflow result',
    UNPAID_REVENUE: 'Unpaid revenue',
    PAID_REVENUE: 'Paid revenue',

    SHOW_UNPAID_INVOICES_ONLY: 'Show not fully paid invoices only',

    SUBSCRIPTION: '.subscription',
    GROUP_BASE_TEMPLATE: 'Financial base template',
    GROUP_BASE_TEMPLATE_DESCRIPTION: 'Add explantion here for stuff about financial template and how inheretance works',
    GROUP_ATTRIBUTIONS_INHERETED: 'This groups derives his attributions from the financial base template <strong>{templateName}</strong>',
    GROUP_ATTRIBUTIONS_EXPLANATION_CHANGE: 'Explanation what happens if changed',
    GROUP_BASE_TEMPLATE_UPDATE: 'Update template',

    CONTINUE_SAVING_ATTRIBUTIONS: 'Ga verder met opslaann',

    CREATE_TEMPLATE: 'Create template',
    EDIT_TEMPLATE: `Edit template '{name}'`,

    TEMPLATE_NAME_DESCRIPTION: 'The template name',
    TEMPLATE_NAME_LABEL: 'Template name',
    TEMPLATE_DESCRIPTION_LABEL: 'Template name',
    TEMPLATE_DESCRIPTION_DESCRIPTION: 'Describe briefly what this template is for',

    DELETED: 'Deleted',
    TRANSFER_PL_CONTENT: 'Autmoatic to zero p&l transfer',
    REMOVE_FROM_TEAM: 'Remove from team',

    EXPORT_INVOICES: 'Export invoices',
    LEGAL_ENTITIES: 'Legal entities',
    TEAMS: 'Teams',
    INVOICES: 'Invoices',

    INCLUDE_OWN_TEAMS: 'Include own teams',

    OPEN_TRANSFER_REQUEST: 'Open request in: ',

    DROPDOWN: {
        SELET_A_TEAM: '-- Select a team --',
        SELET_A_GROUP: '-- Select a group --',
        SELET_A_MEMBER: '-- Select a member --',
        SELET_A_ECOSYSTEM: '-- Select a ecosystem --',
    },

    // Map
    MAP: {
        SETUP: 'Map setup',
        POSITION_LAT: 'Position lat',
        POSITION_LNG: 'Position lng',
        RADIUS: 'Radius',
        TRANSFER: 'Transfer',
        OUTGOING: 'Outgoing',
        INCOMING: 'Incoming',
    },

    GRID: {
        AMOUNT_ALL: '(All)',
        AMOUNT: 'Amount',
        AMOUNT_LEFT: 'Amount left',
        AMOUNT_FULLY_DISTRIBUTED: 'Fully distributed',
        AMOUNT_NOT_YET_DISTRIBUTED: 'Not yet distributed',
        ACTIONS: 'Actions',
        BALANCE: 'Balance',
        COMPENSATION: 'Compensation',
        CUSTOMER: 'Customer',
        CREATED: 'Created',
        DATE: 'Date',
        DESCRIPTION: 'Description',
        DROP_DEAD_RATIO: 'Drop dead ratio',
        ENERGY_DEDICATION: 'Energy dedication',
        EMAIL: 'E-mail',
        EXTRAS: 'Extras',
        EXTRA_NON_RECURRING: 'Extra - non recurring expenses',
        EXPENSES: 'Expenses',
        EXPENSES_RECURRING: 'Expenses - Recurring expenses',
        END_BALANCE: 'End balance',
        EXTERNAL_REVENUE: 'External Revenue',
        ECOSYSTEM: 'Ecosystem',
        FIRST_NAME: 'First name',
        FILTER_BY: 'Filter by',
        FINANCIAL_COMPENSATION: 'Financial compensation',
        FINANCE_EMPLOYEE: 'Finance Employee',
        GROUP_NAME: 'Group name',
        GROUP_PURPOSE: 'Purpose',
        GROUP_MEMBERS: 'Members',
        GROUP_LEAD: 'Group lead',
        GROUPS: 'Groups',
        INVOICE: 'Invoice',
        INVOICE_DATE: 'Invoice date',
        IS_RECURRING: 'Is Recurring',
        INITIATOR: 'Initiator',
        LAST_NAME: 'Last name',
        LANGUAGE: 'Taal',
        MEMBER: 'Member',
        MEMBERS_OF: 'Member of',
        NO_TASKS: 'No tasks',
        NO_RECORDS: 'No records found',
        NAME: 'Name',
        OPEN_INVOICES: 'Open invoices (team)',
        OPEN_INVOICE_AMOUNT: 'Open invoice amount',
        OPEN_EXPENSES: 'Open expenses (team)',
        OPEN_EXPENSE_AMOUNT: 'Open expense amount',
        OPEN_TASKS: 'open tasks',
        PHONE: 'Phonenumber',
        PERIOD_START: 'Period start',
        PERIOD_END: 'Period end',
        RECEIVER: 'Receiver',
        RESULT: 'Result',
        REFERENCE: 'Reference',
        SLACK: 'Slack',
        SENDER: 'Sender',
        SITE_ADMIN: 'Site Admin',
        START_BALANCE: 'Start balance',
        SCHEDULE: 'Schedule',
        TEAM: 'Team',
        TOTAL_AMOUNT_PAID: 'Total amount paid',
        TOTAL_INVOICE_AMOUNT: 'Total invoice amount',
        TRANSFERS: 'Transfers',
        VALUE: 'Value',
        TRANSFERRED_AMOUNT: 'Transferred amount',
        CROSS_LEGAL_ENITIY_TRANSACTION_MATCH_ID: 'Match ID',
        CROSS_LEGAL_ENITIY_TRANSACTION_AMOUNT: 'Amount',
        CROSS_LEGAL_ENITIY_TRANSACTION_INVOICE: 'Invoice number',
        CROSS_LEGAL_ENITIY_TRANSACTION_FROM: 'From',
        CROSS_LEGAL_ENITIY_TRANSACTION_TO: 'To',
        CROSS_LEGAL_ENITIY_TRANSACTION_DATE_HANDLED: 'Date handled',
        CROSS_LEGAL_ENITIY_TRANSACTION_DATE_CREATED: 'Date created',
        CROSS_LEGAL_ENITIY_TRANSACTION_STATUS: 'Status',
        CROSS_LEGAL_ENITIY_TRANSACTION_SETTINGS: 'Settings',
        INCOME: 'Income',
        TOTAL_COSTS: 'Total costs',
        ATTRIBUTIONS: 'Attributions',
        COMPENSATIONS: 'Compensations',
        OPEN_INVOICES_GROUP: 'Open invoices (group)',
        OPEN_EXPENSES_GROUP: 'Open expenses (group)',
        EXTERNAL_IDENTIFIER: 'External identifier',
        PERIOD: 'Period',
        PERIOD_MATCHED: 'Period matched',
        INVOICE_AMOUNT: 'Invoice amount',
        AMOUNT_PAID: 'Amount paid',
        PAYMENT_DATE: 'Payment date',
        TYPE_OF_NODES: 'Type of nodes',
        NODES_IN_USE: 'Number of nodes in use',
        INFORMATION: 'Information',
        INVOICE_ID: 'Invoice ID',
        INVOICE_TYPE: 'Type',
        LEGAL_ENTITY_REF: 'Legal entity Reference',
        SOURCE: 'Source',
    },

    MODALS: {
        AMORTIZE_SETTINGS: 'There are already amortize settings for this invoice. You can adjust them below if you want.',
        AMORTIZE_FINISHED_PERIODS: 'Amortization finished in # periods',
        AMORTIZE_PER_PERIOD: 'Amortization amount per period',
        AMORTIZE_KEEP_IN_MIND: 'Keep the following in mind',
        AMORTIZE_1: 'For the next months there will be automatically created a distrubition of',
        AMORTIZE_2: `It's always possible to adjust this distribution in the invoice grid if you want to amortize more or less.`,
        AMORTIZE_3: `In this month, when setting up the amortization settings, no distribution will be filled in, you're free to do it yourself.`,
        AMORTIZE_4: 'We will never amortize more in the last period then whats left in the invoice.',
        AMORTIZE_INVOICES: 'Set invoice amortization',

        ENERGY_DEDICATION: 'Set up energy dedication',
        ENERGY_DEDICATION_TOTAL: 'Total energy dedication',
        ENERGY_DEDICATION_PERCENT: '(energy dedication always needs to add up to 100%)',
        ENERGY_DEDICATION_OVERRIDE: 'All energy dedications defined after this period will be overruled by this save action',
        ENERGY_DEDICATION_SAVE: 'Save energy dedications',
        FILL_BE_CHARGED_ON: 'will be charged on',

        LEGAL_ENTITY_SWITCH_TITLE: 'Change legal entity referces of selected invoices',
        LEGAL_ENTITY_SWITCH_LABEL: 'Legal Entity Reference:',
        LEGAL_ENTITY_SWITCH_DESCRIPTION: `The name of your legal entity, eg: 'Bright Ecology B.V.'`,
        LEGAL_ENTITY_SWITCH_BUTTON: `Update invoices`,
        LEGAL_ENTITY_SWITCH_MODAL_BUTTON: `Change refs`,

        LEDGER_ACCOUNT_MODAL_TITLE: 'Create or update ledger account',
        LEDGER_ACCOUNT_MODAL_NAME: 'Ledger account name',
        LEDGER_ACCOUNT_MODAL_DESCRIPTION: `A short description of this ledger account`,
        LEDGER_ACCOUNT_MODAL_NUMBER: `Ledger account number`,
        LEDGER_ACCOUNT_MODAL_BUTTON: `Create or update ledger account`,

        IMPORT_INVOICES: 'Import invoices',
        IMPORT_CSV_DELIMITER: 'CSV or tab-delimited text file',
        IMPORT_CSV_DELIMITER_TEXT: 'Imports contacts from a .csv or .txt file.',
        IMPORT_PASTE_FILE: 'Paste from file',
        IMPORT_PASTE_FILE_TEXT: 'Copy and paste directly from Excel.',
        IMPORT_TEXT: `The lines below are preloaded to be imported. If you want to continue the import, press the import button. Otherwise, press the cancel button.`,
        IMPORT_TABLE_CLIENT: 'Client',
        IMPORT_TABLE_DESCRIPTION: 'Description',
        IMPORT_TABLE_REFERENCE: 'Reference',
        IMPORT_TABLE_AMOUNT: 'Amount',
        IMPORT_TABLE_DATE: 'Date',
        IMPORT_TABLE_LEGAL: 'Legal entity reference',
        IMPORT_TABLE_GROUP: 'Group',
        IMPORT_TABLE_DISTRIBUTIONS: 'Distributions',
        IMPORT_NOTE:
            'Click on the input below to open up a filebrowser. Pick and choose your .csv file to load your participants into the system. You get a preview after uploading the file.',
        IMPORT_HEADER_NOTE: 'Make sure that the first line is a header!',
        IMPORT_NOTE_COPY:
            'Copy the content form a excel-file and paste it directly into this text area. You get a preview after processing the data.',
        IMPORT_BUTTON: 'Process pasted input',
        IMPORT_EXAMPLE_FILE: 'Download example file',
        IMPORT_START: 'Import',
        IMPORT_CANCEL: 'Cancel',

        ACOUNT_SEND_INVOICES: 'Send all invoices to an account',

        EDIT_ROLES: 'Edit Roles',
        EDIT_ROLES_OF: 'Edit roles of',
        UPDATE_ROLES: 'Update roles',

        SALES_LEDGER_ACCOUNT: 'Sales ledger account',
        PURCHASE_LEDGER_ACCOUNT: 'Purchase ledger account',
        INTELECTUAL_PROPERTY_LIMITATION: 'Intelectual property limitation',
        DEFINE_RELATIONSHIP: 'Define relationship',
        DEFINE_RELATIONSHIP_EXPLANATION: 'Define relationship for transfers from <b>{from}</b> to <b>{to}</b>',
        VIEW_RELATIONSHIP_SETTINGS: 'View settings',

        YOU_SCARED_ME: 'You scared me, cancel it!',
        DO_IT: 'Do it!',
        CREATE_RECURRING_TRANSFER: 'Create recurring transfer',
        UPGRADE_ACCOUNT_TO_FULL: 'Upgrade the account of {name} to a full account so it can login and do actions by themselfs',

        DELETE: 'Delete',
        DELETE_EXPLANATION: 'To delete this enitity type the {property} of the {entityName}',
        DELETE_ENTIITY: 'Delete {entityName}',

        COUPLE_LOOSE_TEMPLATE: 'Remove link',
        COUPLE_LOOSE_TEMPLATE_EXPLANATION: `When removing the link between a group and a template this group won't automatically update their attributions when changing the templates attributons`,
        CONTINUE_SAVING_ATTRIBUTIONS: 'Continue saving attributions',

        REMOVE_MEMBER: 'Move P&L before removing',
        CURRENT_PL: 'The P&L of {name} is at this moment {value}',
        REMOVE_MEMBER_PL_EXPLANATION: `There are financial activities in this period for this member. It isn't allowed to leave this team in this period.`,
        RESPONSIBLE_GROUP_PL: 'Which group is responsible for the P&L?',
        REMOVE_MEMBER_PL_ACTION:
            'When deleting this member the group will get or make a transfer so the P&L of {name} will be zero. Thereafter the member will be removed in the next period and marked as deleted.',

        CONFIRM_GROUP_ATTRIBUTIONS_DELETE_TITLE: 'Delete group attributions',
        CONFIRM_GROUP_ATTRIBUTIONS_DELETE: 'Are you sure you want to deleta ALL attributions pointed towards this group?',

        CREATE_ECOSYSTEM: 'Create new ecosytem',
        NEW_ECOSYSTEM_LABEL: 'Switch to another ecosystem',
        NEW_ECOSYSTEM_TITLE: 'Switch between ecosystems',

        TAX_RATE: 'Tax rate',

    },

    // Register
    REGISTER: {
        WELCOME_TEXT: '{fullname} has invited you to join Team {teamName}',
        TITLE: 'Create your account',
        FIRST_NAME: 'First name',
        INSERTION: 'Insertion',
        LAST_NAME: 'Last name',
        EMAIL: 'Email address',
        EMAIL_DESCRIPTION: `We\'ll never share your email with anyone else.`,
        PASSWORD: 'Password',
        PASSWORD_DESCRIPTION: `We\'ll never share your password with anyone else.`,
        REAPEAT_PASSWORD: 'Repeat password',
        REPEAT_PASSWORD_DESCRIPTION: `This will be magicaly be filled in now (it\'s a ToDo) :)`,
        BUTTON: 'Register',
    },

    // Directory
    GROUP: {
        LEAVE_GROUP: 'Leave group',
        LEAVE_QUESTION: 'Do you want to leave this group?',
        LEAVE_TITLE: 'Leave group {name}',

        JOIN_GROUP: 'Join group',
        JOIN_QUESTION: 'Do you want to Join this group?',
        JOIN_TITLE: 'Join group {name}',

        TRANSFER_LEAD_TITLE: 'Transfer group lead of {name}',
        TRANSFER_LEAD_DESCRIPTION: `After you transferred the group lead you can't switch it back`,
        TRANSFER_LEAD_LABEL: 'New group lead',
        TRANSFER_LEAD: 'Transfer group lead',
    },

    // Alert messages
    ALERT_MESSAGES: {
        ERROR: 'Oh snap! Something has gone wrong...',
        WARNING: `Better check that thingy, this isn't valid...`,
        SUCCESS: 'Woohoo! Your changes are saved.',

        GROUP_CONNECTORS: 'Saving group connector(s)...',
        SETTING_AMORTIZATION: 'Setting amortization...',
        ENERGY_DEDICATION_PENDING: 'Saving your energy dedication...',
        IMPORT_INVOICE_PENDING: 'Importing invoices...',
        DISTRIBUTING_INVOICES_PENDING: 'Distributing invoices...',
        DISTRIBUTING_INVOICES_FAILED: 'Distribution failed saving',
        DISTRIBUTING_INVOICES_SUCCESS: 'Distribution succesfully saved',
        SAVING_ATTRIBUTIONS_PENDING: 'Saving attributions...',
        TRANSFERRING_PENDING: 'Transferring...',
        TRANSFER_REQUEST_PENDING: 'Sending transfer request...',
        SAVE_ROLE_PENDING: 'Saving role...',
        DELETE_ROLE_PENDING: 'Deleting role...',
        REGISTRATION_PENDING: 'Please wait while we are processing your registration...',
        LOGIN_PENDING: 'Logging in...',
        SAVE_PROFILE_PENDING: 'Saving profile...',
        SAVE_COMMUNICATION_CHANNELS_PENDING: 'Upserting communication channels...',
        PASSWORD_RESET_PENDING: 'Send mail for password reset...',
        DEACTIVATE_ACCOUNT_PENDING: 'Deactivating account...',
        CREATE_TEAM_PENDING: 'Creating team...',
        SAVE_MAP_PENDING: 'Saving settings...',
        SAVE_GROUP_PENDING: 'Saving group...',
        CALCULATING_REVENUE_PENDING: 'Calculating revenue...',
        REMOVING_MEMBER_PENDING: 'Removing member...',
        REMOVING_MEMBER_PENDING_ECO: 'Removing member...',
        TRANSFER_LEADERSHIP_PENDING: 'Transferring leadership...',
        JOINING_GROUP_PENDING: 'Joining group...',
        LEAVING_GROUP_PENDING: 'Leaving group...',
        AMORTIZATION_FAILED: 'Failed to set amortization',
        GROUP_CONNECTORS_FAILED: 'Failed to save group connectors',
        IMPORT_INVOICE_FAILED: 'Invoices could not be imported!',
        SAVE_ROLE_FAILED: 'Failed to save role',
        DELETE_ROLE_FAILED: 'Failed to delete role',
        REGISTRATION_FAILED: 'Failed to create an account.',
        SAVE_PROFILE_FAILED: 'Failed to save profile',
        SAVE_COMMUNICATION_CHANNELS_FAILED: 'Failed to upsert communication channels',
        PASSWORD_RESET_FAILED: 'Password reset mail sending failed.',
        DEACTIVATE_ACCOUNT_FAILED: 'Deactivating account failed.',
        CREATE_TEAM_FAILED: 'Failed to create team',
        SAVE_MAP_FAILED: 'Failed to save settings',
        SAVE_GROUP_FAILED: 'Failed to update group',
        REMOVING_MEMBER_FAILED: 'Failed to remove member, are all conditions met?',
        REMOVING_MEMBER_FAILED_ECO: 'Failed to remove member',
        TRANSFER_LEADERSHIP_FAILED: 'Could not transfer group lead. Change a few things up and try submitting again.',
        JOINING_GROUP_FAILED: 'Could not join group.',
        LEAVING_GROUP_FAILED: 'Could not leave group.',
        AMORIZATION_SUCCESS: 'Amortization set!',
        GROUP_CONNECTORS_SUCCESS: 'Group connectors saved.',
        ENERGY_DEDICATION_SUCCESS: 'Energy dedications updated',
        IMPORT_INVOICE_SUCCESS: 'Invoices succesfully imported',
        TRANSFERRING_SUCCESS: 'The transfer has been completed.',
        SAVE_ROLE_SUCCESS: 'Member succesfully coupled to role.',
        DELETE_ROLE_SUCCESS: 'Role succesfully deleted for member.',
        REGISTRATION_SUCCESS: 'The registration has been completed',
        SAVE_PROFILE_SUCCESS: 'Profile saved',
        SAVE_COMMUNICATION_CHANNELS_SUCCESS: 'Communication channels upserted',
        PASSWORD_RESET_SUCCESS: 'Password reset mail sent',
        DEACTIVATE_ACCOUNT_SUCCES: 'Account succesfull deactivated',
        CREATE_TEAM_SUCCESS: 'Team created',
        SAVE_MAP_SUCCESS: 'Saved settings',
        SAVE_GROUP_SUCCESS: 'Group saved',
        REMOVING_MEMBER_SUCCESS: 'Member removed',
        REMOVING_MEMBER_SUCCESS_ECO: 'Member removed',
        TRANSFER_LEADERSHIP_SUCCESS: 'Succesfully transferred group leader',
        JOINING_GROUP_SUCCESS: 'Succesfully joined group',
        LEAVING_GROUP_SUCCESS: 'Succesfully left group',

        EXTERNAL_TRANSFERS_FAILED: 'Failed to retrieve cross team person transfers',
        FINANCE_PERSONS_FAILED: 'Failed to load person accounts',
        LOADING_GROUP_FAILED: 'Failed to load group',

        COMPENSATE_MEMBERS_PENDING: 'Compensating all members, please hold on...',
        COMPENSATE_MEMBERS_FAILED: 'Failed to do transfers',
        COMPENSATE_MEMBERS_SUCCCESS: 'Members compensated',
        SAVE_ALIASES_PENDING: 'Saving aliases...',
        SAVE_ALIASES_FAILED: 'Failed to set group aliases',
        SAVE_ALIASES_SUCCESS: 'Aliases saved!',
        SAVE_COLLABORATION_FAILED: 'Failed to set collaboration mode',
        SAVE_COLLABORATION_PENDING: 'Setting collaboration mode...',
        SAVE_COLLABORATION_SUCCESS: 'Collaboration mode saved!',
        SAVE_FINANCE_SETTINGS_PENDING: 'Saving finance settings...',
        SAVE_FINANCE_SETTINGS_FAILED: 'Failed to save finance settings',
        SAVE_FINANCE_SETTINGS_SUCCESS: 'Finance settings saved.',
        DELETE_GROUP_PENDING: 'Deleting group..',
        DELETE_GROUP_FAILED: 'Failed to delete group, are all conditions met?',
        DELETE_GROUP_SUCCESS: 'Group deleted',
        SAVING_WORKSPACE_PENDING: 'Saving workspace...',
        SAVING_WORKSPACE_FAILED: 'Failed to update workspace',
        SAVING_WORKSPACE_SUCCESS: 'Workspace saved.',
        DELETE_TRANSFER_PENDING: 'Deleting transfer..',
        DELETE_TRANSFER_FAILED: 'Failed to delete transfer',
        DELETE_TRANSFER_SUCCESS: 'Transfer deleted.',
        CALCULATING_RESULT_PENDING: 'Calculating results...',
        CHECK_IF_MEMBER_EXISTS: 'CHECK_IF_MEMBER_EXISTS',
        ADD_MEMBER_PENDING: 'Please wait while adding the member',
        ADD_MEMBER_FAILED: 'Failed to add member to team',
        ADD_MEMBER_SUCCESS: 'Succesfully added member to team',
        ADD_PERSON_TO_GROUPS_PENDING: 'Adding person to groups..',
        ADD_PERSON_TO_GROUPS_FAILED: 'Failed to add member to groups',
        LEAVE_TEAM_FINANCE_ERROR: `There are financial activities in this period for this member. You aren\'t allowed to leave this team in this period.`,
        LEAVE_TEAM_PENDING: 'Leaving team..',
        LEAVE_TEAM_FAILED: 'Failed to leave team',
        LEAVE_TEAM_SUCCESS: 'Succesfully left',
        INVITE_PENDING: 'Inviting..',
        INVITE_FAILED: 'Failed sending an invite to',
        INVITE_SUCCESS: 'Invite sucessfully sent.',
        DOWNGRADE_PENDING: 'Downgrading..',
        DOWNGRADE_FAILED: 'Failed downgrading account',
        DOWNGRADE_SUCCESS: 'Member sucessfully downgraded.',
        SENDING_INVITES_PENDING: 'Sending invites. Please wait while we send the invite(s) to the filled in e-mail(s).',
        SENDING_INVITES_FAILED: 'Something went wrong please try again',
        SENDING_INVITES_SUCCESS: 'Invite(s) sent',
        SAVE_WHITELIST_PENDING: 'Saving whitelist...',
        SAVE_WHITELIST_FAILED: 'Could not save whitelist.',
        SAVE_WHITELIST_SUCCESS: 'Whitelist saved.',
        RELOAD_INVOICES_PENDING: 'Reloading invoices..',
        LOAD_EXPENSES_FAILED: 'Failed to load expenses',
        DELETE_INVOICE_PENDING: 'Deleting invoices..',
        DELETE_INVOICE_FAILED: 'Deleting invoices failed.',
        DELETE_INVOICE_SUCCES: 'invoices deleted',
        UPDATE_RECURRENCES_EXPENSES_PENDING: 'Updating recurrences of expense(s)..',
        UPDATE_RECURRENCES_EXPENSES_FAILED: 'Failed updating expense(s)',
        UPDATE_RECURRENCES_EXPENSES_SUCCESS: 'Updated expense(s)',
        UPDATE_LEGAL_ENTITY_PENDING: 'Updating legal entity of invoice(s)..',
        UPDATE_LEGAL_ENTITY_FAILED: 'Failed updating invoice(s)',
        UPDATE_LEGAL_ENTITY_SUCCESS: 'Updated invoice(s)',
        CLOSE_PERIOD_PENDING: 'Sending to close period...',
        CLOSE_PERIOD_FAILED: 'Cannot close period due several issues. Please contact your contact person.',
        CLOSE_PERIOD_SUCCESS: 'The system is closing the period. This can take up to several minutes. Please be patient.',
        SAVE_TEAM_PENDING: 'Saving team..',
        SAVE_TEAM_FAILED: 'Failed to update team',
        SAVE_TEAM_SUCCESS: 'Team updated!',
        UPDATE_TRANSFER_PENDING: 'Updating transfer request..',
        UPDATE_TRANSFER_FAILED: 'Failed to post transfer request',
        UPDATE_TRANSFER_SUCCESS: 'Successfully sent transfer request',
        ACCEPT_TRANSFER_PENDING: 'Accepting transfer request...',
        ACCEPT_TRANSFER_FAILED: 'Failed to accept transfer request',
        ACCEPT_TRANSFER_SUCCESS: 'Successfully accepted transfer request',
        REJECT_TRANSFER_PENDING: 'Rejecting/withdrawing transfer request..',
        REJECT_TRANSFER_FAILED: 'Failed to reject/withdraw transfer request',
        REJECT_TRANSFER_SUCCESS: 'Successfully rejected/withdrawed transfer request',
        LOADING_DISTRIBUTIONS: 'Loading distributions..',
        LOADING_DISTRIBUTIONS_EMPTY: 'No distributions yet',
        LOADING_DISTRIBUTIONS_FAILED: 'Distribution not available',

        UPDATING_ROLES_PENDING: 'Saving role(s)',
        UPDATING_ROLES_FAILED: 'Failed to save role(s)',
        UPDATING_ROLES_SUCCEEDED: 'Successfully saved role(s)',

        CREATE_OR_UPDATE_LEDGER_PENDING: 'Creating/updating ledger account..',
        CREATE_OR_UPDATE_LEDGER_FAILED: 'Failed creating or updating ledger account',
        CREATE_OR_UPDATE_LEDGER_SUCCESS: 'Succesfully created/updated ledger account',

        FAILED_GETTING_RELATIONS: 'Failed to load relations',
        SAVING_RELATION_SETTINGS_FAILED: 'Failed to save relation settings',
        SAVING_RELATION_SETTINGS_SUCCESS: 'Relation settings saved',
        SAVING_RELATION_SETTINGS_PENDING: 'Saving relation settings..',
        RELATION_DELETED_SUCCESS: 'Relation deleted successfully',
        RELATION_DELETED_FAILED: 'Deleting relation failed',

        FAILED_CHECK_PERIOD_CLOSE: 'Failed to check if period can be closed.',
        CANNOT_CLOSE_PERIOD: `Period cannot be closed.`,
        CANNOT_CLOSE_OPEN_TEAMS: `These team have attributions to this team and aren't closed yet:`,
        CANNOT_CLOSE_OPEN_TRANSFER: `These transfers are still open:`,

        UPDATE_TEAM_PROFILE_FAILED: 'Failed to update team profile.',
        UPDATE_TEAM_PROFILE_SUCCESS: 'Succesfully updated team profile.',

        CREATE_RECURRING_TRANSFER_FAILED: 'Failed to create recurring transfer.',
        CREATE_RECURRING_TRANSFER_SUCCESS: 'Succesfully created recurring transfer.',
        STOP_RECURRING_TRANSFER_PENDING: 'Stopping recurring transfer...',
        STOP_RECURRING_TRANSFER_SUCCESS: 'Succesfully stopped recurring transfer.',
        STOP_RECURRING_TRANSFER_FAILED: 'Failed to stop reucrring transfer.',

        LOADING_TEMPLATES_FAILED: 'Failed to load templates',
        CREATE_GROUP_PENDING: 'Creatig group..',

        SAVING_TEMPLATE: 'Saving finance template',
        ERROR_SAVING_TEMPLATE: 'Saving finance template failed',
        DELETING_TEMPLATE: 'Deleting finance template',
        ERROR_DELETING_TEMPLATE: 'Deleting finance template failed',

        SAVING_VIEW: 'Saving finance view',
        ERROR_SAVING_VIEW: 'Saving finance view failed',
        DELETING_VIEW: 'Deleting finance view',
        ERROR_DELETING_VIEW: 'Deleting finance view failed',

        DELETE_TEAM_PENDING: 'Deleting team..',
        DELETE_TEAM_SUCCESS: 'Succesfully deleted team',
        DELETE_TEAM_FAILED: 'Failed to delete team',

        TRANSFER_TEAM_LEAD_PENDING: 'Transfering team lead..',
        TRANSFER_TEAM_LEAD_SUCCESS: 'Succesfully trasnfered team lead',
        TRANSFER_TEAM_LEAD_FAILED: 'Failed to transfer team lead',

        PROPERTY_DOENST_EXIST: `Property doesn't excists`,
        VALUE_DOESNT_MATCH: `Text doesn't match`,

        OTHER_TEAM_PERIOD_CLOSED_WARNING: `Period of other team is closed, transfer can't be made in this period`,

        FAILED_TO_DELETE_ATTRIBUTION_SOURCES: 'Failed to delete attribution sources',
        CREATING_ECOSYSTEM: 'Creating ecosystem',
        CREATING_ECOSYSTEM_FAILED: 'Failed creating ecosystem',

        INSIGHTS_NO_ACCESS: `You're not a member of this entity and therefore have no access to see detailed info.`,
    },

    SIDE_ACTIONS: {
        CREATE_ECOSYSTEM: 'Create ecosystem',
        CREATE_ECOSYSTEM_ECOSYSTEM_LABEL: 'Ecosystem name',
        CREATE_ECOSYSTEM_ECOSYSTEM_DESCRIPTION: 'The ecosystem name',
        CREATE_ECOSYSTEM_TEAM_LABEL: 'Team name',
        CREATE_ECOSYSTEM_TEAM_DESCRIPTION: 'The name of your first team',
        CREATE_ECOSYSTEM_GROUP_LABEL: 'Activity name',
        CREATE_ECOSYSTEM_GROUP_DESCRIPTION: `The first activity created will automaticcly be 'Activity One'`,
    },
};
