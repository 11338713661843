import { Component, Prop } from 'vue-property-decorator';
import BaseModal from './BaseModal';
import { Member } from '@/models/Member';
import { personService, teamHelper, teamService, roleHelper, loginHelper } from '@/main';
import Vue from 'vue';
import EnergyDedication from '@/models/EnergyDedication';
import { Person } from '@/models/Person';
import { periodModule } from '@/store/modules/period';

@Component
export default class EnergyDedicationModalComponent extends BaseModal {
    @Prop({ default: () => new Member() }) public member: Member;

    public energyGroups: EnergyDedication[] = [];
    public energySpent: number = 0;

    public totalSpent: string;
    public energyDedication: string;

    public percentageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];

    private _member: Person = new Person();

    public async mounted() {
        if (this.member && this.member.memberId > 0) {
            await this.setMember(this.member);
        }

        this.isLoading = false;
    }

    public async setMember(member: Member) {
        this._member = new Person(member);
        if (typeof this._member.groupMemberships === 'undefined' || this._member.groupMemberships.length === 0) {
            this._member = await teamService.getMemberById(this._member.personId, periodModule.selectedPeriod);
        }

        const energy = await personService.getEnergyDedication(this._member.personId, teamHelper.currentTeamId, periodModule.selectedPeriod);
        this.energyGroups.length = 0;
        for (const groupShip of this._member.groupMemberships) {
            const energyofGroup = energy.find((x) => x.groupKey === groupShip.key);
            this.energyGroups.push({
                groupKey: groupShip.key,
                groupId: groupShip.id,
                amount: energyofGroup ? parseInt(energyofGroup.amount.toString()) : 0,
                initialAmount: energyofGroup ? parseInt(energyofGroup.amount.toString()) : 0,
            });
        }

        this.updateEnergy();
    }

    public onHide() {
        this._member = null;
    }

    public handleInput(e) {
        console.log(e);
        // this.input = this.input.replace(/[0-9]/g, '')
    }

    public handleChange(e) {
        console.log(e);
        // this.input = this.input.replace(/[0-9]/g, '')
    }

    public async setEnergyDedication() {
        this.showPending('ENERGY_DEDICATION_PENDING');

        let success = false;

        if (roleHelper.isSiteAdmin() && this._member.personId !== loginHelper.getUser().personId) {
            success = await this.updateEnergyDedicationForMember();
        } else {
            success = await this.updateEnergyDedication();
        }

        if (success) {
            this.clearAndShowSuccess('ENERGY_DEDICATION_SUCCESS');
            this.$emit('energy-dedications-updated');
            this.hide();
        }
    }

    public async updateEnergyDedication() {
        try {
            await teamService.updateEnergyDedicationsForMember(
                teamHelper.currentTeamId,
                this.energyGroups,
                periodModule.selectedPeriod,
                loginHelper.getUser().personId,
            );
        } catch (e) {
            this.clearAndShowError('Failed to update energy dedication', e);
            return false;
        }

        return true;
    }

    public async updateEnergyDedicationForMember() {
        try {
            await teamService.updateEnergyDedicationsForMember(
                teamHelper.currentTeamId,
                this.energyGroups,
                periodModule.selectedPeriod,
                this._member.personId,
            );
        } catch (e) {
            this.clearAndShowError('Failed to update energy dedication', e);
            return false;
        }

        return true;
    }

    public totalEnergySpentPercentage() {
        const amount = this.getTotalEnergyDedicationSpent();
        return Vue.filter('number-format')(100 - amount);
    }

    public getTotalEnergyDedicationSpentString() {
        return `${this.getTotalEnergyDedicationSpent()}%`;
    }

    public getTotalEnergyDedicationSpent(): number {
        return parseInt(this.totalSpent) + parseInt(this.energyDedication);
    }

    public updateEnergy(value?: number, groupId?: number) {
        this.energySpent = 0;

        if (value && groupId) {
            const fGroup = this.energyGroups.find((x) => x.groupId === groupId);
            fGroup.amount = value * 1;
        }

        for (const group of this.energyGroups) {
            if (!group.amount) {
                continue;
            }
            this.energySpent += isNaN(group.amount) ? parseInt(group.amount.toString()) : group.amount;
        }

        return this.energySpent;
    }
}
