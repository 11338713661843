import DistributedInvoice from '../models/DistributedInvoice';
import { AccountType } from '@/models/AccountType';
import { Distribution } from '@/models/Interfaces';

class Functions {
    public getCommunicationChannel(channels: any, channelName: string) {
        if (channels && channels.length) {
            const filteredChannel = channels.filter((channel: any) => channel.channelName === channelName);

            if (filteredChannel && filteredChannel.length) {
                return filteredChannel[0].value;
            }
        }

        return 'N/A';
    }

    public groupBy(arr, prop) {
        const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
        arr.forEach((obj) => map.get(obj[prop]).push(obj));
        return Array.from(map.values());
    }

    public amountLeft(item: DistributedInvoice, isNegative: boolean = false) {
        let amountLeft = parseInt((item.invoice.amount * 100).toFixed());
        if (item && item.distributions) {
            for (const distribution of item.distributions) {
                if (distribution) {
                    if (isNegative) {
                        amountLeft += parseInt((distribution.amount * 100 * -1).toFixed());
                    } else {
                        amountLeft -= parseInt((distribution.amount * 100).toFixed());
                    }
                }
            }
        }
        return amountLeft / 100;
    }

    public setCookie(name, value, days): void {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = `${name}=${value || ''}${expires}; path=/`;
    }

    public getCookie(name): any {
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    public eraseCookie(name): void {
        document.cookie = `${name}=; Max-Age=-99999999;`;
    }

    public getPersonDistributions(personId: number, distributions: Distribution[]) {
        if (typeof personId === 'undefined' || personId === null) {
            console.error('Given personId is undefined or null. Please give a valid personId');
        }

        return distributions.filter((distribution: Distribution) => {
            if (distribution.account) {
                return distribution.account.relatedEntityId === personId && distribution.account.accountType === AccountType.Person;
            }
        });
    }
}

export const functions = new Functions();
