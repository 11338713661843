import RangeItem from './RangeItem';

export default class Range<T> {
    public items: RangeItem<T>[] = [];

    constructor(obj?: Partial<Range<T>>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
