import moment from 'moment';
import PageRender from '@/models/PageRender';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { filtersModule } from '@/store/modules/filters';
import { periodModule } from '@/store/modules/period';
import PeriodRange from '@/models/PeriodRange';

@Component
export default class GridPeriodRangeFilterComponent extends PageRender {
    @Prop({ type: Boolean, default: false }) public hideRefresh: boolean;
    public disabledDates: any = { to: null, from: null };
    public fromFilterMonth: string = null;
    public toFilterMonth: string = null;

    @Watch('toFilterMonth')
    public async OnToFilterMonthChange(val, oldVal) {
        if (val != null && oldVal != null) {
            filtersModule.setEndMonth(this.toFilterMonth);
            this.onInput();
        }
    }

    @Watch('fromFilterMonth')
    public async OnFromFilterMonthChange(val, oldVal) {
        if (val != null && oldVal != null) {
            filtersModule.setStartMonth(this.fromFilterMonth);

            const startDate = moment(this.fromFilterMonth, 'YYYY-MM');
            const endDate = moment(this.toFilterMonth, 'YYYY-MM');
            if (startDate.isAfter(endDate)) {
                this.toFilterMonth = this.fromFilterMonth;
                filtersModule.setEndMonth(this.fromFilterMonth);
            }
            this.onInput();
        }
    }

    public mounted() {
        this.disabledDates.from = moment(periodModule.periods[0].startDate, 'YYYY-MM-DD')
            .add('month', 1)
            .toDate();
        this.disabledDates.to = moment(periodModule.periods[periodModule.periods.length - 1].startDate, 'YYYY-MM-DD').toDate();

        if (!this.$store.getters.hasMonths) {
            this.fromFilterMonth = this.toFilterMonth = periodModule.selectedPeriod;
            filtersModule.setStartMonth(this.fromFilterMonth);
            filtersModule.setEndMonth(this.toFilterMonth);
        } else {
            this.fromFilterMonth = this.$store.getters.startMonth;
            this.toFilterMonth = this.$store.getters.endMonth;
        }

        this.reload();
    }

    public notAfterOrBeforeDate(date: Date) {
        return date >= this.disabledDates.from || date < this.disabledDates.to;
    }

    public reload() {
        const period: PeriodRange = { toFilterMonth: this.toFilterMonth, fromFilterMonth: this.fromFilterMonth };
        this.$emit('filter-change', period);
    }

    public onInput() {
        const period: PeriodRange = { toFilterMonth: this.toFilterMonth, fromFilterMonth: this.fromFilterMonth };
        this.$emit('filter-input', period);
    }
}
