import { financeService } from '@/main';
import DistributedInvoice from '@/models/DistributedInvoice';
import to from 'await-to-js';
import { Component, Prop } from 'vue-property-decorator';
import BaseModal from './BaseModal';

@Component
export default class ChangeLegalEntityReferenceModal extends BaseModal {
    @Prop({ default: () => [] }) public invoices: DistributedInvoice[];

    public legalEntityReference: string = '';

    public async updateLegalEntity() {
        const self = this;
        const list = [];

        this.invoices.forEach((invoice: DistributedInvoice) => {
            if (invoice) {
                list.push({ InvoiceId: invoice.invoice.invoiceId, LegalEntityReference: this.legalEntityReference });
            }
        });

        this.showPending('UPDATE_LEGAL_ENTITY_PENDING');
        const [err] = await to(financeService.setInvoicesLegalEntityReference(list));
        if (err) {
            return self.clearAndShowError('UPDATE_LEGAL_ENTITY_FAILED', null);
        }
        self.clearAndShowSuccess('UPDATE_LEGAL_ENTITY_SUCCESS');
        this.$emit('invoices-updated');
        this.hide();
    }
}
