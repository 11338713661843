import { AxiosHelper } from '../helpers/axiosHelper';
import { BaseService } from './baseService';
import Vue from 'vue';
import to from 'await-to-js';
import MemberResults from '@/models/Finance/Api/MemberResults';
import Range from '@/models/Finance/Api/Range';
import EcosystemResults from '@/models/Finance/Api/TeamResults';
import TeamResultItem from '@/models/Finance/Api/TeamResultItem';

export class ResultService extends BaseService {
    private endpoint = `${Vue.$env().financeEndpoint}`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getEcosystemResults(starPeriod: string, endPeriod: string, teamIds: number[] = []): Promise<EcosystemResults> {
        const config = {
            headers: {
                'x-startPeriod': starPeriod,
                'x-endPeriod': endPeriod,
            },
        };

        let teamQueryStringParam = '?';
        if (teamIds && teamIds.length > 0) {
            teamIds.forEach((x) => {
                teamQueryStringParam += `${!teamQueryStringParam.endsWith('?') ? '&' : ''}team_id=${x}`;
            });
        }

        const [err, response] = await to(this.get(`${this.endpoint}results/ecosystem${teamQueryStringParam}`, null, config));

        if (err) {
            this.clearAndShowWarning(`Failed to load ecosystem results from: ${starPeriod} to ${endPeriod}`);
            return new EcosystemResults();
        }
        return new EcosystemResults(response.data);
    }

    public async getGroupResults(teamId: number, starPeriod: string, endPeriod: string): Promise<Range<TeamResultItem>> {
        const config = {
            headers: {
                'x-startPeriod': starPeriod,
                'x-endPeriod': endPeriod,
            },
        };
        const [err, response] = await to(this.get(`${this.endpoint}results/team?teamId=:teamId`, [['teamId', teamId]], config));

        if (err) {
            this.clearAndShowWarning(`Failed to load group results for team: ${teamId} from: ${starPeriod} to ${endPeriod}`);
            return new Range<TeamResultItem>();
        }

        return new Range<TeamResultItem>(response.data);
    }

    public async getMemberResults(teamId: number, starPeriod: string, endPeriod: string): Promise<Range<MemberResults>> {
        const config = {
            headers: {
                'x-startPeriod': starPeriod,
                'x-endPeriod': endPeriod,
            },
        };
        const [err, response] = await to(this.get(`${this.endpoint}results/members?teamId=:teamId`, [['teamId', teamId]], config));

        if (err) {
            this.clearAndShowWarning(`Failed to load member results for team: ${teamId} from: ${starPeriod} to ${endPeriod}`);
            return new Range<MemberResults>();
        }

        return new Range<MemberResults>(response.data);
    }

    public async getMemberResultsNew(teamId: number, starPeriod: string, endPeriod: string): Promise<Range<MemberResults>> {
        const config = {
            headers: {
                'x-startPeriod': starPeriod,
                'x-endPeriod': endPeriod,
            },
        };
        const [err, response] = await to(this.get(`${this.endpoint}results/members-new?teamId=:teamId`, [['teamId', teamId]], config));

        if (err) {
            this.clearAndShowWarning(`Failed to load member results for team: ${teamId} from: ${starPeriod} to ${endPeriod}`);
            return new Range<MemberResults>();
        }

        return new Range<MemberResults>(response.data);
    }
}
