import to from 'await-to-js';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import store from '../index';
// import AttributionPercentages from '@/models/Finance/AttributionPercentages';
import {  attributionService, settingService } from '@/main';
// import GroupFinanceSettings from '@/models/Finance/GroupFinanceSettings';
import TeamFinanceSettings from '@/models/Finance/TeamFinanceSettings';
import GroupFinanceSettings from '@/models/Finance/GroupFinanceSettings';
// import { AttributionType } from '@/models/AttributionType';
// import { min } from 'moment';

@Module
class SettingsModule extends VuexModule {
    public failed: boolean = false;
    // tslint:disable: variable-name
    // private teamAttributionPercentages: AttributionPercentages[] = [];
    // private groupAttributionPercentages: AttributionPercentages[] = [];
    private groupFinanceSettings: GroupFinanceSettings[] = [];
    private teamFinanceSettings: TeamFinanceSettings[] = [];

    public get findTeamFinanceSettings() {
        return (teamId: number) => {
            return this.teamFinanceSettings[teamId];
        };
    }

    public get findGroupFinanceSettings() {
        return (groupId: number) => {
            return this.groupFinanceSettings[groupId];
        };
    }

    // public get findAttributionPercentages() {
    //     return (groupId: number) => {
    //         return this.teamAttributionPercentages.find((c: AttributionPercentages) => {
    //             return c.groupId === groupId;
    //         });
    //     };
    // }

    @Action
    public async fetchGroupSettingsIfNeeded(groupId: number) {
        if (
            this.groupFinanceSettings.length === 0 ||
            typeof this.groupFinanceSettings[groupId] === 'undefined' ||
            this.groupFinanceSettings[groupId] === null
        ) {
            return this.fetchGroupSettings(groupId);
        }
    }

    @Action
    public async fetchTeamSettingsIfNeeded(teamId: number) {
        if (
            this.teamFinanceSettings.length === 0 ||
            typeof this.teamFinanceSettings[teamId] === 'undefined' ||
            this.teamFinanceSettings[teamId] === null
        ) {
            return this.fetchTeamSettings(teamId);
        }
    }

    @Action
    public async fetchGroupSettings(groupId: number) {
        const [err, response] = await to(attributionService.getAttributionSetting(groupId));
        if (err) {
            this.failGroupSettings(groupId);
        }

        const items = response && response.data ? response.data : new GroupFinanceSettings();
        this.setGroupSettings({ items, groupId });
    }

    @Action
    public async fetchTeamSettings(teamId: number) {
        const [err, response] = await to(settingService.getTeamSettings(teamId));
        if (err) {
            this.failTeamSettings(teamId);
        }

        const items = response && response.data ? response.data : new TeamFinanceSettings();
        this.setTeamSettings({ items, teamId });
    }

    // @Action
    // public async fetch(teamId: number) {
    //     const [err, response] = await to(attributionService.getAttributionSetting(teamId));
    //     if (err) {
    //         this.fail(teamId);
    //     }

    //     const items = response && response.data ? response.data : [];
    //     this.set({ items, groupId: teamId });
    // }

    // @Action
    // public async fetchTeamAttributions(payload) {
    //     const [err, response] = await to(attributionService.getAttributions(AttributionType.TeamAttribution, payload.teamId, payload.period));
    //     if (err) {
    //         this.fail(payload.teamId);
    //     }

    //     const items = response && response.data ? response.data : [];
    //     this.set({ items, teamId: payload.teamId });
    // }

    // @Action
    // public async fetchTeamAttributionsIfNeeded(teamId: number) {
    //     if (this.teamAttributionPercentages.length === 0 || this.teamAttributionPercentages[teamId] === null) {
    //         return this.fetchTeamAttributions(teamId);
    //     }
    // }

    @Mutation
    private failTeamSettings(teamId) {
        this.teamFinanceSettings.splice(teamId, 1);
        this.failed = true;
    }

    @Mutation
    private failGroupSettings(groupId) {
        this.groupFinanceSettings.splice(groupId, 1);
        this.failed = true;
    }

    // @Mutation
    // private set(payload) {
    //     this.groupAttributionPercentages[payload.groupId] = payload.items;
    //     this.failed = false;
    // }

    @Mutation
    private setTeamSettings(payload) {
        this.teamFinanceSettings[payload.teamId] = payload.items;
        this.failed = false;
    }

    @Mutation
    private setGroupSettings(payload) {
        this.groupFinanceSettings[payload.groupId] = payload.items;
        this.failed = false;
    }
}

export const settingsModule = new SettingsModule({ store, name: 'settings' });
