import Period from './Period';
import TeamRevenue from './TeamRevenue';
import Group from './Group';
import { Person } from './Person';

export default class Team {
    public teamId: number = 0;
    public teamKey: string = '';
    public name: string = '';
    public periods: Period[] = [];
    public groups: Group[] = [];
    public revenue: TeamRevenue[] = [];
    public legalEntityReference: string = '';
    public logoUrl?: string;
    public teamLeadId: number = 0;
    public teamLead: Person = null;

    constructor(init?: Partial<Team>) {
        Object.assign(this, init);
    }
}
