import { BFormCheckbox } from 'bootstrap-vue';
import Item from './item.vue';
// @ts-ignore
import toast from './toast.vue';
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import Notification from './models/Notification';
import { INotificationsService } from './models/Interface/INotifcationService';

@Component({
    components: {
        BFormCheckbox,
        toast,
        Item,
    },
})
export default class NotificationsComponent extends Vue {
    @Prop() public title: string;
    @Prop() public duration: number;
    @Prop() public hideTimeout: number;
    @Prop() public notificationService: INotificationsService;

    public $refs!: {
        toaster;
    };

    public canDisturb: boolean = false;
    public showToast: boolean = false;
    public visible: boolean = false;
    public lock: boolean = false;
    public isLoading: boolean = true;
    public toastNotification: any = {};
    public lastUpdated: Date = null;
    public lastUpdatedText: string = 'never';
    public notifications: Notification[] = [];

    public hasUnreadNotifications() {
        return this.notifications.filter((x) => !x.handled).length > 0;
    }

    public toast(item) {
        if (this.showToast) {
            return;
        }
        this.showToast = true;
        this.toastNotification = item;
        this.$refs.toaster.show();
        this.hideTimeout = setTimeout(() => {
            this.showToast = false;
            this.$refs.toaster.hide();
        }, this.duration);
    }

    public show() {
        if (!this.visible && !this.lock) {
            this.lock = true;
            document.addEventListener('click', this.mouseClick);
            setTimeout(() => {
                this.visible = true;
                this.lastUpdatedText = this.getLastUpdatedText();
            });
        }
    }

    public hide() {
        document.removeEventListener('click', this.mouseClick);
        this.visible = false;
        this.$nextTick(() => {
            this.lock = false;
        });
    }

    public mouseClick(e) {
        const outside = !this.$el.contains(e.target);
        if (outside && this.visible) {
            this.hide();
        }
    }

    public async fetch() {
        this.lastUpdated = new Date();
        this.lastUpdatedText = this.getLastUpdatedText();
        if (this.notificationService) {
            const notifications = await this.notificationService.getNotifications();
            this.addNotifcations(notifications);
            this.$emit('notifications-fetched');
        } else {
            console.error('There is not fetch action specified');
        }
    }

    public addNotifcations(notifications: any[]) {
        if (!notifications || notifications.length === 0) {
            this.isLoading = false;
            return;
        }

        this.notifications = notifications.map((x) => new Notification(x));
        this.isLoading = false;
    }

    public readAll() {
        this.notifications.forEach(async (not: Notification) => {
            this.notificationService.markAsHandled(not);
            not.handled = true;
        });
    }

    public getLastUpdatedText() {
        if (this.lastUpdated != null) {
            const diff = new Date().getTime() - this.lastUpdated.getTime();
            const seconds = Math.ceil(diff / 1000);
            const minutes = Math.ceil(seconds / 60);
            const hours = Math.ceil(minutes / 60);

            if (seconds < 60) {
                return `a few seconds ago`;
            }
            if (minutes < 60) {
                return `${minutes} minutes ago`;
            }
            return `${hours} hours ago`;
        }
        return 'never';
    }

    public async deleteNotification(notification: Notification) {
        await this.notificationService.deleteNotification(notification);
        this.notifications = this.notifications.filter((x) => x.id !== notification.id);
        this.show();
    }
}
