import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import GraphData from '@/models/Graph/GraphData';
import { eventBus } from '@/main';
// @ts-ignore
import { SVG, Svg } from '@svgdotjs/svg.js';
// @ts-ignore
import '@svgdotjs/svg.draggable.js';
// @ts-ignore
import svgPanZoom from 'svg-pan-zoom';

@Component
export default class SVGMapComponent extends Vue {
    // tslint:disable: restrict-plus-operands
    @Prop({ default: () => new GraphData() }) public graphData: GraphData;
    @Prop({ default: () => false }) public editMode: boolean;

    public show: boolean = false;
    public showTotals: boolean = false;
    public incomingEdges: any[] = [];
    public outgoingEdges: any[] = [];
    public target: string = 'graph';
    public content: string = '';

    private canvas: Svg = null;

    public mounted() {
        this.canvas = SVG()
            .addTo('#graph')
            .size(this.calculateGraphWidth(), this.calculateGraphHeight())
            .attr('id', 'svg-container')
            .addClass('svg-container');

        this.$nextTick(() => {
            this.doDraw();
        });

        eventBus.$on('redraw', this.doDraw);
    }

    public doDraw() {
        for (let i = 0; i < this.graphData.vertices.length; i++) {
            const vertex = this.graphData.vertices[i];

            if (this.graphData.vertexPaths[vertex.id] == null) {
                const group = this.canvas.group();
                const vertexCircle = this.canvas.circle(vertex.radius).fill('#0f0');
                vertexCircle.attr({ cx: vertex.x, cy: vertex.y, opacity: '0.2' });
                vertexCircle.attr('class', 'cursor-pointer');

                if (vertex.icon) {
                    const img = this.canvas.image(vertex.icon);
                    img.size(vertex.radius, vertex.radius);
                    img.attr({ cx: vertex.x, cy: vertex.y });
                    vertexCircle.attr({fill: img});
                }

                const text = this.canvas.text(vertex.name);
                const textBbox = text.bbox();
                text.move(vertex.x - textBbox.width / 2, vertex.y - textBbox.height - vertex.radius / 2);

                group.add(vertexCircle);
                group.add(text);

                group.draggable();

                group.on('dragmove.namespace' + i, () => {
                    vertex.x = vertexCircle.attr('cx');
                    vertex.y = vertexCircle.attr('cy');

                    for (let j = 0; j < this.graphData.edges.length; j++) {
                        const edge = this.graphData.edges[j];
                        this.drawEdge(edge);
                    }
                });

                vertexCircle.on('mouseover', () => {
                    this.hideEdges(vertex);
                    this.showVertexTotals();
                });

                vertexCircle.on('mouseout', () => {
                    this.showAllEdges();
                    this.showTotals = false;
                });

                if (vertex.onclick) {
                    vertexCircle.on('click', vertex.onclick);
                }

                this.graphData.vertexPaths[vertex.id] = vertexCircle;
            } else {
                const vertexPath = this.graphData.vertexPaths[vertex.id];
                vertexPath.radius(vertex.radius / 2);
            }
        }

        for (let i = 0; i < this.graphData.edges.length; i++) {
            const edge = this.graphData.edges[i];
            this.drawEdge(edge);
        }

        svgPanZoom('#svg-container', {
            panEnabled: true,
            controlIconsEnabled: true,
        });
    }

    public drawEdge(edge) {
        const startx = edge.v1.x;
        const starty = edge.v1.y - edge.v1.radius / 3;

        const endx = edge.v2.x;
        const endy = edge.v2.y + edge.v2.radius / 3;

        const mpx = (startx + endx) * 0.5;
        const mpy = (starty + endy) * 0.5;

        // angle of perpendicular to line:
        const theta = Math.atan2(endy - starty, endx - startx) - Math.PI / 2;

        // distance of control point from mid-point of line:
        const offset = 30; // random(20, 50);

        // location of control point:
        const c1x = mpx + offset * Math.cos(theta);
        const c1y = mpy + offset * Math.sin(theta);

        if (this.graphData.edgePaths[edge.edge] == null) {
            const path = this.canvas.path(`M${startx} ${starty} Q${c1x} ${c1y} ${endx} ${endy}`);
            path.stroke({ color: 'rgba(0,0,0,0.3)', width: 5, linecap: 'round' })
                .attr({ fill: 'transparent' })
                .attr('id', 'edge-' + edge.edge)
                .attr('class', 'cursor-pointer');

            path.on('mouseover', () => {
                this.target = 'edge-' + edge.edge;
                this.content = `${edge.v2.name} to ${edge.v1.name} | <strong>${edge.weight}</strong>`;
                this.$nextTick(() => {
                    this.show = true;
                });
                path.stroke({ color: `rgba(0,128,0,0.3)` });
            });

            path.on('mouseout', () => {
                this.show = false;
                path.stroke({ color: 'rgba(0,0,0,0.3)' });
            });

            this.graphData.edgePaths[edge.edge] = path;
        } else {
            this.graphData.edgePaths[edge.edge].plot(`M${startx} ${starty} Q${c1x} ${c1y} ${endx} ${endy}`);
        }
    }

    public hideEdges(vertex) {
        this.outgoingEdges = [];
        this.incomingEdges = [];
        for (let i = 0; i < this.graphData.edges.length; i++) {
            const edge = this.graphData.edges[i];
            if (edge.v1 !== vertex && edge.v2 !== vertex) {
                this.hideEdge(edge);
            } else {
                this.colorEdge(edge, edge.v1 === vertex);
                if (edge.v1 === vertex) {
                    this.incomingEdges.push(`${edge.v2.name} to ${edge.v1.name} | <strong>${edge.weight}</strong>`);
                } else {
                    this.outgoingEdges.push(`${edge.v2.name} to ${edge.v1.name} | <strong>${edge.weight}</strong>`);
                }
            }
        }
    }

    public colorEdge(edge, isPositive: boolean = true) {
        const path = this.graphData.edgePaths[edge.edge];
        path.stroke({ color: `rgba(${isPositive ? '0' : 255},${isPositive ? '128' : 0},0,0.3)` });
    }

    public hideEdge(edge) {
        const path = this.graphData.edgePaths[edge.edge];
        path.stroke({ color: 'rgba(0,0,0,0)' });
    }

    public showVertexTotals() {
        this.showTotals = true;
    }

    public showAllEdges() {
        for (let i = 0; i < this.graphData.edges.length; i++) {
            const edge = this.graphData.edges[i];
            const path = this.graphData.edgePaths[edge.edge];
            path.stroke({ color: 'rgba(0,0,0,0.3)' });
        }
    }

    public updateSettings(vertex) {
        this.$emit('settingsUpdated', vertex);
    }

    private calculateGraphHeight() {
        return window.innerHeight - 200;
    }

    private calculateGraphWidth() {
        return window.innerWidth - 350;
    }
}
