import { ledgerService } from '@/main';
import Ledger from '@/models/Finance/Ledger';
import { Mutex } from 'async-mutex';
import to from 'await-to-js';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import store from '../index';

@Module
class LedgerModule extends VuexModule {
    private _failed: boolean = false;
    private _ledgers: Ledger[] = [];
    private _mutex = new Mutex();

    public get failed() {
        return this._failed;
    }

    public get ledgers() {
        return this._ledgers;
    }

    @Action
    public async fetchLedgersIfNeeded() {
        // tslint:disable-next-line: no-floating-promises
        this._mutex.acquire().then(async () => {
            if (typeof this.ledgers === 'undefined' || this.ledgers.length === 0) {
                await this.fetch();
                this._mutex.release();
                return;
            }
        });
    }

    @Action
    public async fetch() {
        const [err, response] = await to(ledgerService.getLedgers());
        if (err) {
            return this.FAIL();
        }

        this.SET_LEDGERS(response.data);
    }

    @Mutation
    private SET_LEDGERS(payload: Ledger[]) {
        this._ledgers = payload;
    }

    @Mutation
    private FAIL() {
        this._failed = true;
    }
}

export const ledgerModule = new LedgerModule({ store, name: 'ledgers' });
