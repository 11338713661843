import { UserRoles } from '../models/UserRoles';
import { loginHelper, loginService } from '@/main';
import Group from '@/models/Group';
import { PrivacyLevel } from '@/models/PrivacyLevel';

/**
 * Helper function to assist in checking user roles.
 */
export class RoleHelper {
    /**
     * Checks if the user possess admin rights in a group.
     */
    public isAccountant(): boolean {
        return loginService.isInRole(UserRoles.Accountant);
    }

    /**
     * Check if the user possess full 'superuser' rights.
     */
    public isSiteAdmin(): boolean {
        return loginService.isInRole(UserRoles.SiteAdmin);
    }

    /**
     * Check if user possess admin rights in a team.
     */
    public isFinanceEmployee(): boolean {
        return loginService.isInRole(UserRoles.FinanceEmployee);
    }

    /**
     * Checks if the user possess admin rights in a group.
     */
    public isMember(): boolean {
        return loginService.isInRole(UserRoles.Member);
    }

    public isGroupLead(group: Group): boolean {
        if (!group.groupLead) {
            return false;
        }
        const user = loginHelper.getUser();
        return group.groupLead.memberId === user.personId;
    }

    public checkAccess(level: PrivacyLevel, group?: Group) {
        if (this.isAccountant()) {
            return true;
        }

        if (level === PrivacyLevel.SiteAdmin && this.isSiteAdmin()) {
            return true;
        }

        if (level === PrivacyLevel.FinanceEmployee && (this.isSiteAdmin() || this.isFinanceEmployee())) {
            return true;
        }

        if (level === PrivacyLevel.GroupLead && (this.isSiteAdmin() || this.isFinanceEmployee() || (group && this.isGroupLead(group)))) {
            return true;
        }

        if (level === PrivacyLevel.Member) {
            return true;
        }

        return false;
    }
}
