import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import Register from '../../models/Register';
import { teamService } from '@/main';
import { Membership } from '../../models/Interfaces';

@Component
export default class RegisterComponent extends Vue {
    public model: Register = new Register();

    @Prop() public email: string = undefined;
    @Prop() public isInvite: boolean;
    @Prop() public needsAccount: boolean;
    @Prop() public team: string;
    @Prop() public ecosystem: string;
    @Prop() public hijackPost: boolean;

    public getEmail() {
        return this.email;
    }
    public getEmailInputDisabled() {
        return this.email !== undefined && this.isInvite;
    }

    public created() {
        if (this.email !== undefined) {
            this.model.person.emailAddress = this.getEmail();
        }
    }

    public onSubmit(evt: any) {
        evt.preventDefault();
        this.register();
    }

    public register(): void {
        const self = this;

        self.model.emailAddress = this.getEmail();
        self.model.person.emailAddress = this.getEmail();
        self.model.needsAccount = this.needsAccount;
        self.model.person.teamMemberships.push({ key: this.team } as Membership);

        if (this.hijackPost) {
            self.$emit('hijackPostEvent', self.model);
            return;
        }

        teamService
            .acceptInvite(1, '', self.model)
            .then((response: any) => {
                self.$emit('registerOnSuccessEvent', response);
            })
            .catch((error) => {
                self.$emit('registerOnFailEvent', error);
            });
    }
}
