import { AttributionInterface } from './Interfaces';
import Group from './Group';
import { AttributionType } from './AttributionType';
import Team from './Team';

export default class Attribution implements AttributionInterface {
    public attributionId: number;
    public group: number;
    public groups: Group[] = [];
    public groupModel: Group;
    public percentage: any;
    public attributionType: AttributionType;
    public targetGroup: number;
    public targetTeam: number;
    public targetGroupModel: Group;
    public targetTeamModel: Team = null;
    public targetAccount: number;
    public team: number;
    public name: string;

    constructor(percentage: any, name: string = '') {
        this.percentage = percentage;
        this.name = name;
    }
}
