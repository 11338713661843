import _Vue from 'vue';
import { events } from './sideActionsEvents';

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export default function SideActionsPlugin(vue: typeof _Vue, _?: any): void {
    vue.component('side-actions', require('./sideActionsComponent.vue').default);

    vue.prototype.$sideActions = {
        push(component: string, sideOptions: any, finishedHandler: () => void) {
            events.$emit('pushed', { component, sideOptions, finishedHandler });
        },
    };
}
