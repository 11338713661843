import { CellScore } from './CellScore';
import MapConfig from './MapConfig';

export default class CellColor {
    public static score(score: CellScore) {
        const map = {
            [CellScore.Bad]: { light: MapConfig.lightRed, dark: MapConfig.red },
            [CellScore.BelowAvarage]: { light: MapConfig.lightOrange, dark: MapConfig.orange },
            [CellScore.AboveAvarage]: { light: MapConfig.lightGreen, dark: MapConfig.green },
            [CellScore.Ambigious]: { light: MapConfig.lightPurple, dark: MapConfig.purple },
            [CellScore.Unknown]: { light: MapConfig.lightBlue, dark: MapConfig.blue },
        }

        return map[score];
    }
}
