import Vue from 'vue';
import { periodFilterHelper, teamHelper, $router, ecosystemHelper, roleHelper } from '@/main';
import { functions } from '@/helpers/functions';
import moment from 'moment';
import DistributedInvoice from './DistributedInvoice';
import { translateModule } from '@/store/modules/translate';
import { periodModule } from '@/store/modules/period';
import { teamsModule } from '@/store/modules/teams';
import { InvoiceType } from './InvoiceType';

export default class PageRender extends Vue {
    public section = 'directory';
    public isLoading: boolean = true;
    public editMode: boolean = false;

    public checkPeriodParam() {
        if (this.$route && !this.$route.params.period) {
            const params = this.$route.params;
            params.period = periodModule.selectedPeriod;
            return $router.push({ name: this.$route.name, params });
        } else if (this.$route.params.period) {
            if (!periodFilterHelper.isValidPeriod(this.$route.params.period)) {
                const params = this.$route.params;
                params.period = null;
                return $router.push({ name: this.$route.name, params });
            }
        }
    }

    // tslint:disable-next-line: variable-name
    public rowRender(_h, tr, _row, item) {
        const invoice = new DistributedInvoice(item.dataItem);

        // tslint:disable-next-line: no-string-literal
        tr.data['class'] += ` ${invoice.invoice.invoiceAmortization && invoice.invoice.invoiceAmortization.amortizationAmount ? 'text-success' : ''}`;

        if (teamsModule.current && (roleHelper.isFinanceEmployee() || roleHelper.isSiteAdmin()) && invoice.invoice.legalEntityReferenceOrigin) {
            // tslint:disable-next-line: no-string-literal
            tr.data['class'] += ` ${
                invoice.invoice && invoice.invoice.legalEntityReferenceOrigin !== teamsModule.current.legalEntityReference
                    ? 'legal-entity-difference'
                    : ''
            }`;
        }

        return tr;
    }

    public renderInvoicePopoverDistributionGrid(h, _, row: any) {
        const props = { invoice: row.dataItem.invoice, showDescription: true, grid: 'distribution' };
        return h(Vue.component('grid-popover-element'), { props });
    }

    /** Render functions */
    public formatInvoiceAmountValue(h, _, row) {
        const props = { invoice: row.dataItem };
        return h(Vue.component('distribution-tree-amount'), { props });
    }

    public renderInvoiceDate(h, _, row) {
        return h('td', [moment(row.dataItem.invoicedate, 'YYYY-MM-DD').format('DD-MM-YYYY')]);
    }

    public formatImportedInvoiceAmountValue(h, _, row) {
        const props = { value: row.dataItem.amount };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderAmountLeft(h, _, row) {
        const amount = functions.amountLeft(row.dataItem);
        const textClass = amount === 0 ? 'text-success' : amount < 0 && row.dataItem.invoice.amount >= 0 ? 'text-danger font-weight-bold' : '';

        Vue.set(row.dataItem, 'amountLeft', amount);

        const props = { value: amount, classNames: `${row.className} bg-white ${textClass}` };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderEnergyDedication(h, _, row) {
        const props = {
            value: row.dataItem.energyDedication,
            showPercentage: true,
        };

        return h(Vue.component('grid-percentage'), { props });
    }

    public renderMemberBy(h, _, row): any {
        const entity = row.dataItem ? row.dataItem.sender : null;
        const team = row.dataItem ? row.dataItem.senderTeam : null;

        if (!team) {
            return h('td', entity.name);
        }

        return h('td', [`${team.name} - ${entity.name}`]);
    }

    public renderMemberFor(h, _, row): any {
        const entity = row.dataItem ? row.dataItem.receiver : null;
        const team = row.dataItem ? row.dataItem.receiverTeam : null;

        if (!team) {
            return h('td', entity.name);
        }

        return h('td', [`${team.name} - ${entity.name}`]);
    }

    public renderMember(h, member) {
        return h(Vue.component('member-name-native'), { props: { member } });
    }

    public renderTeamMemberships(h, _, row): any {
        return h(Vue.component('memberships-component'), {
            props: { memberships: row.dataItem.teamMemberships, isTeam: true, disableOnClick: true },
        });
    }

    public renderMemberships(h, _, row): any {
        return h(Vue.component('memberships-component'), { props: { memberships: row.dataItem.groupMemberships, disableOnClick: true } });
    }

    public renderGroups(h, _, row): any {
        return h(Vue.component('memberships-component'), { props: { memberships: row.dataItem.groups, disableOnClick: true } });
    }

    public renderMembers(h, _, row): any {
        const props = { members: row.dataItem.members };
        return h(Vue.component('members-component'), { props });
    }

    public renderTransferAmount(h, _, row) {
        const props = { value: row.dataItem.amount };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderGroupFinanceLink(h, _, row): any {
        const name = row.dataItem.group ? row.dataItem.group.name : '';
        const groupKey = row.dataItem.group ? row.dataItem.group.groupKey : '';
        const groupId = row.dataItem.group ? row.dataItem.group.groupId : '';
        const routeUrl = $router.resolve({
            name: 'team-finances-group',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                groupKey,
                groupId,
                teamId: teamHelper.currentTeam.id.toString(),
                teamKey: teamHelper.currentTeam.key,
                tab: 'overview',
                period: periodModule.selectedPeriod,
            },
        }).href;

        const props = {
            text: name,
            url: routeUrl,
            entity: row.dataItem.group,
        };

        return h(Vue.component('grid-router-link'), { props });
    }

    public renderInvoiceLink(h, _, row): any {
        const isExpense = row.dataItem.invoiceType === InvoiceType.Expenses;

        const routeUrl = $router.resolve({
            name: 'team-finances',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                teamId: teamHelper.currentTeam.id.toString(),
                teamKey: teamHelper.currentTeam.key,
                tab: isExpense ? 'expenses' : 'invoices',
                period: periodModule.selectedPeriod,
            },
            query: {
                invoiceId: row.dataItem.invoiceId,
            },
        }).href;

        const props = {
            text: row.dataItem.invoiceId,
            url: routeUrl,
        };

        return h(Vue.component('grid-router-link'), { props });
    }

    public renderGroupInsightsLink(h, _, row): any {
        const name = row.dataItem.group ? row.dataItem.group.name : '';
        const groupKey = row.dataItem.group ? row.dataItem.group.groupKey : '';
        const groupId = row.dataItem.group ? row.dataItem.group.groupId : '';
        const routeUrl = $router.resolve({
            name: 'team-finances-group',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                groupKey,
                groupId,
                teamId: teamHelper.currentTeam.id.toString(),
                teamKey: teamHelper.currentTeam.key,
                tab: 'analytics',
                period: periodModule.selectedPeriod,
            },
        }).href;

        const props = {
            text: name,
            url: routeUrl,
        };

        return h(Vue.component('grid-router-link'), { props });
    }

    public renderRecurring(h, _, row) {
        return h('td', { class: 'bg-white k-grid-content-sticky' }, [row.dataItem.invoice.isRecurring ? 'Yes' : 'No']);
    }

    public renderBoolean(h, _, row) {
        return h('td', [row.dataItem.invoice.isRecurring ? 'Yes' : 'No']);
    }

    /** Notifications */
    public showValidationErrors(errors): void {
        const errorsHtml = errors
            .map((item) => {
                return `<li>${item}</li>`;
            })
            .join('');

        // First clean all previous errors
        this.clearNotifications();
        this.$notify({
            title: 'The form is invalid, please correct the following issues:',
            text: `<ul>${errorsHtml}</ul>`,
            type: 'error',
            duration: -1,
        });
    }

    public showFailedResponse(error, consoleError = null): void {
        if (consoleError != null) {
            console.error(consoleError);
        }

        this.showError(error);
    }

    public showPending(translateKey): void {
        this.$notify({
            title: translateModule.inEditMode ? translateKey : translateModule.value(translateKey, 'ALERT_MESSAGES'),
            text: `
                <div class="d-flex justify-content-center">
                    <img src="/dist/images/bright-loading-spinner-white.gif" alt="Loading..." style="height: 50px;"/>
                </div>
            `,
            type: 'loading',
            duration: -1,
        });
    }

    public showError(translateKey): void {
        if (this.$appInsights) {
            this.$appInsights.trackException({
                exception: translateModule.inEditMode ? new Error(translateKey) : new Error(translateModule.value(translateKey, 'ALERT_MESSAGES')),
            });
        }

        this.$notify({
            title: translateModule.value('ERROR', 'ALERT_MESSAGES'),
            text: translateModule.inEditMode ? translateKey : translateModule.value(translateKey, 'ALERT_MESSAGES'),
            type: 'error',
            duration: -1,
        });
    }

    public showWarning(translateKey): void {
        if (this.$appInsights) {
            this.$appInsights.trackTrace({
                message: translateModule.inEditMode ? translateKey : translateModule.value(translateKey, 'ALERT_MESSAGES'),
            });
        }
        this.$notify({
            title: translateModule.value('WARNING', 'ALERT_MESSAGES'),
            text: translateModule.inEditMode ? translateKey : translateModule.value(translateKey, 'ALERT_MESSAGES'),
            type: 'error',
            duration: -1,
        });
    }

    public clearAndShowWarning(translateKey) {
        if (!translateModule.inEditMode) {
            this.clearNotifications();
        }

        Vue.nextTick(() => {
            this.showWarning(translateKey);
        });
    }

    public showSuccess(translateKey): void {
        let duration = 2000;
        if (translateModule.inEditMode) {
            duration = -1;
        }
        this.$notify({
            title: translateModule.inEditMode ? translateKey : translateModule.value(translateKey, 'ALERT_MESSAGES'),
            type: 'success',
            duration,
        });
    }

    public clearNotifications(): void {
        this.$notify({
            clean: true,
        });
    }

    public clearAndShowSuccess(translateKey): void {
        if (!translateModule.inEditMode) {
            this.clearNotifications();
        }

        Vue.nextTick(() => {
            this.showSuccess(translateKey);
        });
    }

    public clearAndShowError(translateKey, consoleError: any = null): void {
        if (consoleError !== null) {
            console.error(consoleError);
        }

        if (!translateModule.inEditMode) {
            this.clearNotifications();
        }
        this.showError(translateKey);
    }

    /** Page functions */
    public getCommunicationChannel(channels: any, channelName: string) {
        if (channels && channels.length) {
            const filteredChannel = channels.filter((channel: any) => {
                return channel.channelName === channelName;
            });

            if (filteredChannel && filteredChannel.length) {
                return filteredChannel[0].value;
            }
        }

        return 'N/A';
    }

    public calculateHeight() {
        return { height: `${window.innerHeight - 305}px` };
    }

    public calculateInsightsGridsHeight() {
        return { height: `${window.innerHeight - 315}px` };
    }

    public calculateInvoicesGridHeight(items = []) {
        if (items && items.length > 0) {
            return { height: `${items.length * 40 + 110}px` };
        }
        return { height: `${window.innerHeight - 110}px` };
    }

    public filterPropsOnText(invoice: DistributedInvoice, text: string) {
        if (invoice.invoice.invoiceId && invoice.invoice.invoiceId.toString().indexOf(text) > -1) {
            return true;
        }

        if (invoice.invoice.reference && invoice.invoice.reference.toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return true;
        }

        if (invoice.invoice.description && invoice.invoice.description.toLowerCase().toString().indexOf(text.toLowerCase()) > -1) {
            return true;
        }

        if (invoice.invoice.name && invoice.invoice.name.toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return true;
        }

        if (invoice.invoice.originalAmount && invoice.invoice.originalAmount.toString().indexOf(text) > -1) {
            return true;
        }

        if (invoice.invoice.amount && invoice.invoice.amount.toString().indexOf(text) > -1) {
            return true;
        }

        if (invoice.invoice.source && invoice.invoice.source.toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return true;
        }

        if (invoice.invoice.invoiceType && invoice.invoice.invoiceType.toLowerCase().toString().indexOf(text.toLowerCase()) > -1) {
            return true;
        }

        if (invoice.distributions) {
            for (let i = 0; i < invoice.distributions.length; i++) {
                const line = invoice.distributions[i];
                if (line.amount > 0 && line.account && line.account.name && line.account.name.toLowerCase().indexOf(text.toLowerCase()) > -1) {
                    return true;
                }

                if (line.account && line.account.accountType && line.account.accountType.toLowerCase().indexOf(text.toLowerCase()) > -1) {
                    return true;
                }

                if (line.amount && line.amount > 0 && line.amount.toString().indexOf(text) > -1) {
                    return true;
                }
            }
        }

        return false;
    }

    public formatPeriod(h, _, row) {
        const period = periodModule.allPeriods.find((x) => x.periodId === row.dataItem.period);
        if (period) {
            return h('td', moment(period.startDate, 'YYYY-MM-DD').format(`MMMM 'YY`));
        }
        return h('td', translateModule.value('NO_PERIOD_FOUND'));
    }

    public formatExpensesValue(h, _, row) {
        const props = { value: row.dataItem.expenses };
        return h(Vue.component('number-formatter'), { props });
    }

    public formatExtrasValue(h, _, row) {
        const props = { value: row.dataItem.extras };
        return h(Vue.component('number-formatter'), { props });
    }

    public formatResultValue(h, _, row) {
        const props = { value: row.dataItem.result };
        return h(Vue.component('number-formatter'), { props });
    }

    public formatBalanceValue(h, _, row) {
        const props = { value: row.dataItem.balance };
        return h(Vue.component('number-formatter'), { props });
    }

    public getStartBalanceValue(item) {
        return item.balance.round(2) - item.result.round(2);
    }

    public formatStartBalanceValue(h, _, row) {
        const props = {
            value: row.dataItem.balance.round(2) - row.dataItem.result.round(2),
        };
        return h(Vue.component('number-formatter'), { props });
    }

    public edit() {
        this.editMode = true;
    }

    public cancelEdit() {
        this.editMode = false;
    }

    protected translate(h, _, props) {
        return h(Vue.component('prd-translate-text'), {
            props: {
                translateKey: props.title,
                translateGroup: 'GRID',
                column: true,
                inline: true,
            },
        });
    }
}
