import BaseGroupPage from './BaseGroupPage';
import { Component } from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';
import { loginHelper, groupHelper } from '@/main';

@Component({
    components: {
        VueEditor,
    },
})
export default class GroupDetailWorkspace extends BaseGroupPage {
    public customControls: any = [
        [{ header: [false, 1, 2, 3] }],
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
    ];

    public isInGroup: boolean = false;

    public async mounted() {
        await this.initSettingsGroup();
        this.isInGroup = this.group.members.filter((member) => member.memberId === loginHelper.getUser().personId).length > 0;
        this.isLoading = false;
    }

    public async saveWorkspace() {
        this.showPending('SAVING_WORKSPACE_PENDING');
        const group = await groupHelper.createOrUpdateGroup(this.group);
        if (!group || group.groupId === 0) {
            return this.clearAndShowError('SAVING_WORKSPACE_FAILED', null);
        }

        this.editMode = false;
        this.clearAndShowSuccess('SAVING_WORKSPACE_SUCCESS');
    }
}
