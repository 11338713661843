import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseModal from './BaseModal';
import DistributedInvoice from '@/models/DistributedInvoice';
import to from 'await-to-js';
import { financeService } from '@/main';
import Vue from 'vue';

@Component
export default class AmortizeModalComponent extends BaseModal {
    @Prop({ default: () => false }) public isGroupScope: boolean;
    @Prop({ default: () => false }) public isExpense: boolean;
    @Prop({ default: () => [] }) public optionsList: any[];
    @Prop({ default: () => new DistributedInvoice() }) public invoice: DistributedInvoice;

    public account: any = null;
    public amount: any = null;
    public numberOfPeriods: number = 24;

    public amountIsDirty = false;
    public numberOfPeriodsIsDirty = false;

    public mounted() {
        this.amount = this.invoice.invoice.amount / this.numberOfPeriods;

        if (this.invoice.invoice.invoiceAmortization && this.invoice.invoice.invoiceAmortization.account) {
            this.account = this.invoice.invoice.invoiceAmortization.account.accountId;
            this.amount = this.invoice.invoice.invoiceAmortization.amortizationAmount;
        }

        if (this.$refs.modal) {
            this.$refs.modal.$on('hidden.bs.BModal', this.onHide);
        }
    }

    public onHide() {
        this.account = null;
    }

    public async setInvoiceAsAmortized() {
        this.showPending('SETTING_AMORTIZATION');
        const [err] = await to(financeService.setInvoiceAmortization(this.invoice.invoice.invoiceId, this.account, this.amount));
        if (err) {
            return this.clearAndShowError('AMORTIZATION_FAILED', err);
        }

        this.invoice.selected = false;

        this.clearAndShowSuccess('AMORIZATION_SUCCESS');
        this.$emit('amortization-set', this.invoice);
        this.hide();
    }

    public getAccountName() {
        const account = this.optionsList.find((option) => option.value === this.account);
        return account ? account.text : 'select a person/group first';
    }

    public getAmortizationAmountFormat() {
        if (this.amount) {
            return Vue.filter('number-format')(this.amount);
        }

        return '0';
    }

    @Watch('numberOfPeriods')
    public OnNumberChange(val) {
        if (val && this.numberOfPeriodsIsDirty && !this.amountIsDirty) {
            this.amount = this.invoice.invoice.amount / this.numberOfPeriods;
        }
    }

    @Watch('amount')
    public OnAmountChange(val) {
        if (val && this.amountIsDirty && !this.numberOfPeriodsIsDirty) {
            this.numberOfPeriods = Math.ceil(this.invoice.invoice.amount / this.amount);
        }
    }
}
