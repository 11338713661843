import { Component } from 'vue-property-decorator';
import NavMenuComponent from '@/components/navmenu/navmenu.vue';
import { loginHelper, teamHelper, meService, $router, ecosystemHelper } from './main';
import { Person } from './models/Person';
import Team from './models/Team';
import to from 'await-to-js';
import PageRender from './models/PageRender';
import { translateModule } from './store/modules/translate';
import { profileModule } from './store/modules/profile';
import { periodModule } from './store/modules/period';

@Component({
    components: {
        MenuComponent: NavMenuComponent,
    },
})
export default class AppComponent extends PageRender {
    public isLoading: boolean = true;
    public teams: Team[] = [];
    public user: Person = null;

    public async beforeMount() {
        await profileModule.init();
        await this.initApp();
        this.isLoading = false;
    }

    public showContent() {
        return profileModule.profileLoaded; // && loginHelper.profileLoaded() && periodFilterHelper.periods.length > 0;
    }

    public showTopBar() {
        return profileModule.profileLoaded; // loginService.user !== null || typeof loginService.user !== 'undefined'; // && loginHelper.profileLoaded();
    }

    public showNav() {
        return profileModule.profileLoaded; // loginService.user !== null || typeof loginService.user !== 'undefined'; // && loginHelper.profileLoaded() && periodFilterHelper.periods.length > 0;
    }

    public get loggedIn() {
        return profileModule.isLoggedIn;
    }

    public get lang() {
        return translateModule.activeLanguage;
    }

    public get loadingLanguage() {
        return translateModule.isLoading;
    }

    private async initApp() {
        translateModule.detectLanguage();
        await translateModule.fetch();

        if (profileModule.isLoggedIn) {
            const [err, response] = await to(meService.profile());
            if (err) {
                await $router.push({ name: 'login' });
                this.isLoading = false;
                return loginHelper.logOut();
            }
            profileModule.setBrightProfile(response);

            if (this.$appInsights) {
                this.$appInsights.trackEvent({ name: 'user-login-existing-token' });
            }

            await ecosystemHelper.setup();
            await teamHelper.setup();
            await periodModule.init();
        }
    }
}
