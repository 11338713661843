import { render, staticRenderFns } from "./move-to-account-modal.vue?vue&type=template&id=028597d4&"
import script from "./move-to-account-modal.ts?vue&type=script&lang=js&"
export * from "./move-to-account-modal.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports