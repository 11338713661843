import { AxiosPromise } from 'axios';
import Group from '../models/Group';
import { AxiosHelper } from '../helpers/axiosHelper';
import { BaseService } from './baseService';
import { Person } from '../models/Person';
import Vue from 'vue';
import to from 'await-to-js';
import EnergyDedication from '@/models/EnergyDedication';

export class PersonService extends BaseService {
    private endpoint = `${Vue.$env().directoryEndpoint}persons`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public getPostModel(): AxiosPromise {
        return this.get(`${this.endpoint}/new`);
    }

    public createNewPerson(model: any) {
        return this.post<Group>(this.endpoint, model);
    }

    public async getPerson(id: number, period: string): Promise<Person> {
        const config = {
            headers: {
                'x-period': period,
            },
        };
        const [err, response] = await to(this.get<Person>(`${this.endpoint}/:id`, [['id', id]], config));
        if (err) {
            this.showFailedResponse('Failed to load person');
            return new Person();
        }

        return new Person(response.data);
    }

    public async getPersonByEmailaddress(emailaddress: string, period: string, showError: boolean = true): Promise<Person> {
        const config = {
            headers: {
                'x-period': period,
            },
        };
        const [err, response] = await to(this.get<Person>(`${this.endpoint}?searchText=:emailaddress`, [['emailaddress', emailaddress]], config));

        if (err) {
            if (showError) {
                this.showFailedResponse('Failed to load person');
            }
            return new Person();
        }

        return new Person(response.data);
    }

    public invitePerson(model: any, period: string) {
        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.post(`${this.endpoint}/:personId/invite`, model, [['personId', model.personId]], config);
    }

    public downgrageAccount(personId: number): Promise<any> {
        return this.post(`${this.endpoint}/:personId/disable`, null, [['personId', personId]]);
    }

    public async getEnergyDedication(personId: number, team: number, period: string) {
        const [err, response] = await to(
            this.get<EnergyDedication[]>(`${this.endpoint}/:personId/energy-dedication?period=:period&teamId=:team`, [
                ['personId', personId],
                ['period', period],
                ['team', team],
            ]),
        );

        if (err) {
            this.showFailedResponse('Failed to load energy dedication');
            return [];
        }

        return response.data;
    }
}
