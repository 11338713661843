import TeamResultItem from './TeamResultItem';

export default class TeamResults {
    public balance: number = 0;
    public result: number = 0;
    public extras: number = 0;
    public expenses: number = 0;
    public externalRevenue: number = 0;

    public teamResults: TeamResultItem[] = [];

    constructor(obj?: Partial<TeamResults>) {
        if (obj) {
            Object.assign(this, obj);

            this.teamResults = [];

            for (const result of obj.teamResults) {
                this.teamResults.push(new TeamResultItem(result));
            }
        }
    }
}
