import { Component } from 'vue-property-decorator';
import { resultService, teamHelper } from '@/main';
import FinanceTeam from '../../../../models/FinanceTeam';
import BaseFinancePage from './BaseFinancePage';
import Vue from 'vue';
import { orderBy } from '@progress/kendo-data-query';
import { periodModule } from '@/store/modules/period';

@Component
export default class TeamFinanceGroupOverview extends BaseFinancePage {
    public groupOverviewData: any[] = [];
    public teamFinance: FinanceTeam;
    public isLoaded: boolean = false;
    public groupResultColumns: any[] = [
        { field: 'group.name', title: 'GROUP_NAME', cell: this.renderGroupFinanceLink },
        { cell: this.formatResultValue, title: 'RESULT' },
        { cell: this.formatBalanceValue, title: 'BALANCE' },
        { cell: this.formatOpenInvoicesValue, title: 'OPEN_INVOICES' },
        { cell: this.formatOpenExpensesValue, title: 'OPEN_EXPENSES' },
    ];

    public async mounted() {
        const period = periodModule.selectedPeriod;
        const results = await resultService.getGroupResults(teamHelper.getTeamId(), period, period);
        this.groupOverviewData = orderBy(results.items[0].item.groupResults, [{ field: 'group.name', dir: 'asc' }]);
        this.isLoaded = true;
    }

    public formatOpenInvoicesValue(h, _, row) {
        const props = { value: row.dataItem.openInvoiceAmount };
        return h(Vue.component('number-formatter'), { props });
    }

    public formatOpenExpensesValue(h, _, row) {
        const props = { value: row.dataItem.openExpenseAmount };
        return h(Vue.component('number-formatter'), { props });
    }
}
