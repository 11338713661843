import { LoginService } from '../services/loginService';
import { ecosystemHelper, meService } from '@/main';
import { profileModule } from '@/store/modules/profile';

export class LoginHelper {
    private loginService: LoginService;

    constructor(loginService: LoginService) {
        this.loginService = loginService;
    }

    public async logOut() {
        await this.loginService.logout();
    }

    public isLoggedIn() {
        return profileModule.isLoggedIn;
    }

    public getUser() {
        return profileModule.brightProfile;
    }

    public async reloadProfile() {
        return await meService.profile(ecosystemHelper.currentEcosystem.id);
    }
}
