import Component from 'vue-class-component';
import BaseGroupPage from './BaseGroupPage';
import { InvoiceType } from '../../../../models/InvoiceType';
import { Watch } from 'vue-property-decorator';
import { roleHelper } from '@/main';

@Component
export default class GroupDetailIncome extends BaseGroupPage {
    public invoiceType: InvoiceType = InvoiceType.Income;

    @Watch('onlyNotYetDistributed')
    public onFailureFilterChange() {
        this.filterInvoices();
    }

    @Watch('textSearch')
    public onTextFilterChange(val, oldVal) {
        if ((val && val.length > 2) || (oldVal && oldVal.length > val.length)) {
            this.filterInvoices();
        }
    }

    public async mounted() {
        const self = this;

        await this.initInvoicesOrExpensesGroup(InvoiceType.Income);
        await this.initAttributions();
        await self.calculateRevenue();

        self.isLoaded = true;
    }

    public async openModalMultiDistribution() {
        const modal = this.$refs.multiIncomeDistributionModal;
        modal.show();
    }

    get hideCalculatedIncome() {
        return !roleHelper.checkAccess(this.groupFinanceSettings.privacyLevel, this.group);
    }
}
