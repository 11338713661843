import { FinanceTotalInterface, FinanceAccount } from './Interfaces';
import Group from './Group';
import { financeService, meService, financeAccountService, groupHelper, memberHelper, compensationService, teamHelper, transferService } from '@/main';
import { InvoiceType } from './InvoiceType';
import FinanceMe from './FinanceMe';
import FinanceGroup from './FinanceGroup';
import FinanceTeam from './FinanceTeam';
import { AccountType } from './AccountType';
import { Member } from './Member';
import { periodModule } from '@/store/modules/period';

export default class FinanceTotal implements FinanceTotalInterface {
    public team: string;
    public teamId: number;

    constructor(team: number) {
        this.teamId = team;
        this.team = teamHelper.getTeam(team).key;
    }

    public async getMeFinance(personId: number, accountId: number, period: string, previousPeriod: string) {
        let expenses: any;
        let previousExpenses: any;
        let invoices: any;
        let previousInvoices: any;
        let transfers: any;
        let personTransfers: any;
        let personPrevTransfers: any;
        let groups: any;
        let energyDedications: any;
        let finance: any;

        groups = await groupHelper.getGroups();

        const [
            expensesResult,
            previousExpensesResult,
            invoicesResult,
            previousInvoiceResult,
            transfersResult,
            personTransfersResult,
            personPrevTransfersResult,
            energyResult,
            balance,
        ] = await Promise.all([
            financeService.getInvoices(this.teamId, null, InvoiceType.Expenses, personId, period),
            financeService.getInvoices(this.teamId, null, InvoiceType.Expenses, personId, previousPeriod),
            financeService.getInvoices(this.teamId, null, InvoiceType.Income, personId, period),
            financeService.getInvoices(this.teamId, null, InvoiceType.Income, personId, previousPeriod),
            transferService.getTransfers(this.teamId, null, personId, period),
            transferService.getCrossTeamPersonTransfers(this.teamId, period, personId),
            transferService.getCrossTeamPersonTransfers(this.teamId, previousPeriod, personId),
            meService.getEnergyDedications(this.teamId, period),
            financeAccountService.getBalance(accountId, period, this.teamId, AccountType.Person, previousPeriod),
        ]);

        expenses = expensesResult;
        previousExpenses = previousExpensesResult;
        invoices = invoicesResult;
        previousInvoices = previousInvoiceResult;
        transfers = transfersResult;
        energyDedications = energyResult.data;
        personTransfers = personTransfersResult;
        personPrevTransfers = personPrevTransfersResult;

        groups.forEach((group: Group) => {
            const energy = energyDedications.find((item) => {
                return item.groupKey.toLowerCase() === group.groupKey;
            });
            group.energyDedication = energy ? energy.amount : 0;
        });

        finance = new FinanceMe(personId, invoices, previousInvoices, expenses, previousExpenses, balance, personTransfers, personPrevTransfers);
        finance.loadCompensations(groups, transfers);
        finance.transfers = transfers;

        return finance;
    }

    public async getGroupFinance(group: Group, period: string) {
        let transfers: any;
        let compensations: any;
        let financeAccount: any;
        let finance: any;

        financeAccount = group.account;

        const [transfersResult, compensationsResult] = await Promise.all([
            transferService.getTransfers(this.teamId, group.groupId, null, period),
            compensationService.getCompensations(this.teamId, group.groupId, periodModule.selectedPeriod),
        ]);

        transfers = transfersResult;
        compensations = compensationsResult.data;

        finance = new FinanceGroup(this.teamId, group, [], [], transfers, compensations.memberCompensations, [], null);
        finance.account = financeAccount;
        return finance;
    }

    public async getTeamFinance(period) {
        let expenses: any;
        const invoices: any = [];
        let members: any;
        let energyDedications: any;
        let groups: any;
        let finance: any;

        expenses = await financeService.getInvoices(this.teamId, null, InvoiceType.Expenses, null, period);
        groups = await groupHelper.getGroups();
        members = await memberHelper.getMembers();
        energyDedications = []; // (await teamService.getEnergyDedication(this.team)).data;

        const groupsSavingsAccountIds = (await financeAccountService.getAccounts(AccountType.GroupSavings, this.teamId, periodModule.selectedPeriod))
            .data;
        groups.map((item: Group) => {
            return (item.account = groupsSavingsAccountIds.filter((account) => {
                return account.relatedEntityId === item.groupId;
            })[0] as FinanceAccount);
        });

        const groupsIcomeAccountIds = (await financeAccountService.getAccounts(AccountType.GroupIncome, this.teamId, periodModule.selectedPeriod))
            .data;
        groups.map((item: Group) => {
            return (item.accountIncome = groupsIcomeAccountIds.filter((account) => {
                return account.relatedEntityId === item.groupId;
            })[0] as FinanceAccount);
        });

        const memberAccountIds = (await financeAccountService.getAccounts(AccountType.Person, this.teamId, periodModule.selectedPeriod)).data;
        members.map((item: Member) => {
            return (item.account = memberAccountIds.find((account) => {
                return account.relatedEntityId === item.memberId;
            }) as FinanceAccount);
        });

        for (let i = 0; i < groups.length; i++) {
            if (groups[i].account && groups[i].account.accountId) {
                groups[i].balance = await financeAccountService.getBalance(
                    groups[i].account.accountId,
                    period,
                    this.teamId,
                    groups[i].account.AccountType,
                );
            }
        }

        for (let i = 0; i < members.length; i++) {
            if (members[i].account && members[i].account.accountId) {
                members[i].balance = await financeAccountService.getBalance(
                    members[i].account.accountId,
                    period,
                    this.teamId,
                    members[i].account.AccountType,
                );
            }
        }

        finance = new FinanceTeam(invoices, expenses, members, groups, energyDedications);
        // finance.transfers = transfers;
        return finance;
    }
}
