import { Invoice } from './Invoice';
import { Distribution, DistributedInvoiceInterface } from './Interfaces';
import { AccountType } from './AccountType';

export default class DistributedInvoice implements DistributedInvoiceInterface {
    public invoice: Invoice;
    public distributions: Distribution[] = [];
    public amountDistributed: number;
    public selected: boolean = false;
    public amountLeft: number;
    public amountLeftFilter: number;

    constructor(item?: any) {
        if (item != null) {
            Object.assign(this, item);
        }
    }

    public getPersonDistributions = function(personId: number): Distribution[] {
        if (typeof personId === 'undefined' || personId === null) {
            console.error('Given personId is undefined or null. Please give a valid personId');
        }

        return this.distributions.filter((distribution: Distribution) => {
            if (distribution.account) {
                return distribution.account.relatedEntityId === personId && distribution.account.accountType === AccountType.Person;
            }
        });
    };

    public getDistributionsByType = function(type: AccountType): Distribution[] {
        if (typeof type === 'undefined' || type === null) {
            console.error('Given type is undefined or null. Please give a valid type');
        }

        return this.distributions.filter((distribution: Distribution) => {
            if (distribution.account) {
                return distribution.account.accountType === type;
            }
        });
    };
    public getGroupSavings = function(groupId: number): Distribution[] {
        if (typeof groupId === 'undefined' || groupId === null) {
            console.error('Given groupId is undefined or null. Please give a valid groupId');
        }

        return this.distributions.filter((distribution: Distribution) => {
            if (distribution.account) {
                return distribution.account.relatedEntityId === groupId && distribution.account.accountType === AccountType.GroupSavings;
            }
        });
    };

    public hasDistributions(entityId: number, type: AccountType) {
        if (this.distributions.length === 0) {
            return false;
        }

        const distributions = this.distributions.filter((distribution: Distribution) => {
            if (distribution.account) {
                return distribution.account.relatedEntityId === entityId && distribution.account.accountType === type;
            }
        });

        if (distributions.length < 1) {
            return false;
        }

        return true;
    }
}
