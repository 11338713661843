import { RegisterInteface } from './Interfaces';
import { Person } from './Person';

export default class Register implements RegisterInteface {
    public person: Person = new Person();
    public emailAddress: string;
    public password: string;
    public passwordRepeat: string;
    public needsAccount: boolean;
    public inviteCode: string;
    public period: string;
    public teamId: number;
}
