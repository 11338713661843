import { Component } from 'vue-property-decorator';
import { ecosystemHelper, teamService } from '@/main';
import { ecosystemService } from '@/main';
import { Member } from '@/models/Member';
import BaseEcosystemDirectory from './BaseEcosystemDirectory';
import { orderBy } from '@progress/kendo-data-query';
import Vue from 'vue';
import to from 'await-to-js';
import { periodModule } from '@/store/modules/period';

@Component
export default class MembersComponent extends BaseEcosystemDirectory {
    public allMembers: Member[] = [];
    public isLoading: boolean = true;

    public memberColumns: any[] = [
        { cell: this.renderMemberWithIcon, title: 'NAME', field: 'firstName' },
        { cell: this.renderTeamMemberships, title: 'MEMBERS_OF', field: 'firstName' },
        { cell: this.renderMemberships, title: 'MEMBERS_OF', field: 'firstName' },
        { title: 'EMAIL', field: 'emailAddress' },
        { title: 'PHONE', field: 'phoneNumber' },
        { title: 'ACTIONS', cell: this.renderActions, width: 120 },
    ];

    public updateGridData(dataItems) {
        this.members = orderBy(dataItems, [{ field: 'firstName', dir: 'asc' }]);
        this.refreshGrid++;
    }

    public async created() {
        const self = this;

        this.teams = await teamService.getTeams();
        const members = await ecosystemService.getMembers();
        const membersDataSorted = orderBy(members, [{ field: 'firstName', dir: 'asc' }]);
        self.allMembers = self.members = membersDataSorted;
        self.isLoading = false;
    }

    public renderMemberWithIcon(h, _, row) {
        return this.renderMember(h, row.dataItem);
    }

    public renderFullname(h, _, row) {
        const member = row.dataItem;
        const name = `${member.firstName} ${member.insertion ? member.insertion : ''} ${member.lastName}`;
        return h('td', [name]);
    }
    public async removeFromEcosystem(item) {
        this.showPending('REMOVING_MEMBER_PENDING_ECO');
        const [err] = await to(ecosystemService.removeMember(item.personId, ecosystemHelper.currentEcosystem.id, periodModule.selectedPeriod));
        if (err) {
            return this.clearAndShowError(`REMOVING_MEMBER_FAILED_ECO`, err);
        }

        this.clearAndShowSuccess(`REMOVING_MEMBER_SUCCESS_ECO`);
        await this.refreshMembersGrid();
    }

    public async refreshMembersGrid() {

        const members = await ecosystemService.getMembers();
        const membersDataSorted = orderBy(members, [{ field: 'firstName', dir: 'asc' }]);
        this.allMembers = this.members = membersDataSorted;

        this.refreshGrid++;
    }

    public renderActions(h, _, row) {
        const member = row.dataItem;

        if (!member.teamMemberships.length) {
            const actions = [{ title: 'Remove account', function: this.removeFromEcosystem }];
            const props = { actions, item: row.dataItem };
            return h(Vue.component('grid-actions'), { props });
        }

        return h('td');
    }
}
