import { Component, Prop } from 'vue-property-decorator';
import PageRender from '../../models/PageRender';

@Component
export default class SideActionBase<T> extends PageRender {
    @Prop() public options: T;

    public cancel(): void {
        this.$emit('cancel');
    }

    public finished(param?: any): void {
        this.$emit('finished', param);
    }
}
