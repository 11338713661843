import { Component, Prop } from 'vue-property-decorator';
import BaseModal from './BaseModal';
// import DistributedInvoice from '@/models/DistributedInvoice';
// import { InvoiceType } from '@/models/InvoiceType';
import {
    periodFilterHelper,
    transferService,
    teamHelper,
    teamService,
    groupHelper,
    attributionService,
    financeAccountService,
    personService,
    loginHelper,
    roleHelper,
} from '@/main';
// import Group from '@/models/Group';
// import moment from 'moment';
import Transfer from '@/models/Transfer';
import { FinanceAccount } from '@/models/Interfaces';
import to from 'await-to-js';
import Group from '@/models/Group';
import { Person } from '@/models/Person';
import { AttributionType } from '@/models/AttributionType';
import { AccountType } from '@/models/AccountType';
import TransferRequest from '@/models/Finance/TransferRequest';
import { InvoiceType } from '@/models/InvoiceType';
import Team from '@/models/Team';
import { Member } from '@/models/Member';
import { periodModule } from '@/store/modules/period';
import { profileModule } from '@/store/modules/profile';

@Component
export default class TransferRequestModalComponent extends BaseModal {
    @Prop({ default: () => 'Create a transfer' }) public title: string;
    @Prop({ default: () => new Group() }) public group: Group;
    @Prop({ default: () => [] }) public receivingAccounts: FinanceAccount[];
    @Prop({ default: () => null }) public transferRequest: TransferRequest;
    @Prop({ type: Boolean }) public readonly: boolean;

    public applyMissingAttributions: boolean = false;
    public transfer: Transfer = new Transfer({
        includeMissingAttributions: this.applyMissingAttributions,
        invoiceType: this.applyMissingAttributions ? InvoiceType.Income : InvoiceType.InternalIncome,
    });
    public editMode: boolean = false;
    public externalAmountIsDirty: boolean = false;
    public ecoAmountIsDirty: boolean = false;
    public editIsLoading: boolean = false;

    public receivingTeams: Team[] = [];
    public teams: Team[] = [];
    public memberAccounts: FinanceAccount[] = [];
    public groupAccounts: FinanceAccount[] = [];
    public team: any = null;
    public selectedTeam: any = null;
    public selectedGroup: any = null;
    public selectedMember: any = null;

    public selectedRecipients: any = [];
    public groupLeadName: string = '';
    public isExpense: boolean = false;
    public recurring: boolean = null;
    public acceptTitle: string = '';

    public attributionsPercentage: number = 0;

    public currentUserId = profileModule.brightProfile.personId;

    get isRequestReceiver(): boolean {
        return this.transferRequest && this.transferRequest.receiverGroup === this.group.groupId;
    }

    get isRequestSender(): boolean {
        return this.transferRequest && this.transferRequest.senderGroup === this.group.groupId;
    }

    get isRecipient(): boolean {
        return this.transferRequest && this.transferRequest.recipients.find((x) => x.personId === loginHelper.getUser().personId);
    }

    get hasReceiverRights(): boolean {
        let group = null;
        const team = this.teams.find((x) => x.teamId === this.transferRequest.transfer.senderTeam.teamId);
        if (team) {
            group = team.groups.find((x) => x.groupId === this.transferRequest.receiverGroup);
        }

        if (roleHelper.isAccountant() || roleHelper.isSiteAdmin() || roleHelper.isFinanceEmployee() || (group && roleHelper.isGroupLead(group))) {
            return true;
        }

        if (this.isRecipient) {
            return true;
        }

        return false;
    }

    get hasSenderRights(): boolean {
        const team = this.teams.find((x) => x.teamId === this.transferRequest.transfer.receiverTeam.teamId);
        const group = team.groups.find((x) => x.groupId === this.transferRequest.receiverGroup);

        if (roleHelper.isAccountant() || roleHelper.isSiteAdmin() || roleHelper.isFinanceEmployee() || roleHelper.isGroupLead(group)) {
            return true;
        }

        if (this.isRecipient) {
            return true;
        }

        return false;
    }

    public isTransferValid: any = {
        amount: true,
        sender: true,
        receiver: true,
        reason: true,
        internalAmount: true,
        team: true,
    };

    private receipientsOptions: any[] = [];

    public async mounted() {
        this.teams = await teamService.getTeams();
        this.transfer.group = this.group.groupId;
        this.transfer.receiver = await financeAccountService.getGroupAccount(
            teamHelper.currentTeam.id,
            this.group.groupId,
            AccountType.GroupIncome,
            periodModule.selectedPeriod,
        );

        this.transfer.period = periodFilterHelper.getSelectedPeriodObject();

        if (this.transferRequest && this.transferRequest.transferRequestId) {
            await this.initRequest();
        }

        this.attributionsPercentage = 0;
        const groupAttributions = await this.getGroupAttributions(teamHelper.getTeamId(), this.group.groupId);
        groupAttributions.forEach((attr) => {
            this.attributionsPercentage += attr.percentage;
        });

        this.isLoading = false;
    }

    public async initRequest() {
        this.isLoading = true;
        if (this.isRequestSender) {
            this.selectedTeam = this.transferRequest.transfer.senderTeam.teamId;
            await this.teamChanged();
            this.selectedGroup = this.transferRequest.receiverGroup;
            await this.groupChanged();
        } else {
            this.selectedTeam = this.transferRequest.transfer.receiverTeam.teamId;
            await this.teamChanged();
            this.selectedGroup = this.transferRequest.senderGroup;
            await this.groupChanged();
        }

        this.transfer = Object.assign(this.transfer, this.transferRequest.transfer);

        this.selectedRecipients = this.transferRequest.recipients.map((x) => x.personId);

        if (!this.applyMissingAttributions) {
            this.ecoAmountIsDirty = true;
            this.amountChange({ target: { value: this.transfer.amount } });
            this.transfer.internalAmount = this.calculateTariff(this.transfer.amount, true);
            this.ecoAmountIsDirty = false;
        }

        const team = this.teams.find((x) => x.teamId === this.selectedTeam);
        const group = team.groups.find((x) => x.groupId === this.selectedGroup);
        const person = await personService.getPerson(this.transferRequest.initiator, periodModule.selectedPeriod);

        if (!this.isRequestSender) {
            this.acceptTitle = `Accept transfer request from ${team.name} - ${group.name} (${person.getFullName()})`;
        } else {
            this.acceptTitle = `Transfer request details`;
        }
        this.isLoading = false;
    }

    public optionListTeams() {
        const list = this.teams.map((team: Team) => {
            return { value: team.teamId, text: team.name };
        });

        return list;
    }

    public optionListGroups() {
        return this.groupAccounts
            .filter((x) => x.relatedEntityId !== this.group.groupId)
            .map((group: FinanceAccount) => {
                return { value: group.relatedEntityId, text: group.name };
            });
    }

    public async teamChanged() {
        const team = this.teams.find((x) => x.teamId === this.selectedTeam);
        // this.memberAccounts = await financeAccountService.getTeamAccounts(AccountType.Person, team.key, periodModule.selectedPeriod);
        this.groupAccounts = await financeAccountService.getTeamAccounts(AccountType.GroupSavings, team.teamId, periodModule.selectedPeriod);
        this.selectedGroup = this.selectedMember = null;
        this.selectedRecipients = [];
    }

    public getGroupLeadName() {
        const team = this.teams.find((x) => x.teamId === this.selectedTeam);
        const group = team.groups.find((x) => x.groupId === this.selectedGroup);
        if (group) {
            return group.groupLead.name;
        }
    }

    public amountChange(a) {
        let val = a.target.value;
        // this only works cuz output of prd-numeric-input is ,
        if (isNaN(val)) {
            val = parseFloat(val.replace(',', '.').toString());
        }
        if (this.ecoAmountIsDirty) {
            this.transfer.amount = this.calculateTariff(val, false);
        }

        if (this.externalAmountIsDirty) {
            this.transfer.internalAmount = this.calculateTariff(val);
        }
    }

    public calculateTariff(amount: number, isExternal: boolean = true) {
        let result = 0;

        if (isExternal) {
            result = amount * (1 - this.attributionsPercentage / 100);
        } else {
            result = amount / (1 - this.attributionsPercentage / 100);
        }
        return parseFloat(result.toFixed(2));
    }

    public async groupChanged() {
        this.receipientsOptions = [];
        this.selectedRecipients = [];
        const team = this.teams.find((x) => x.teamId === this.selectedTeam);
        const group = team.groups.find((x) => x.groupId === this.selectedGroup);
        if (group) {
            const apiGroup = await groupHelper.getGroup(this.isRequestReceiver ? this.group.groupId : this.selectedGroup);
            const list = apiGroup.members.map((member: Member) => {
                if (apiGroup.groupLead && member.memberId === apiGroup.groupLead.memberId) {
                    return;
                }

                let parts = [member.firstName, member.insertion, member.lastName];

                parts = parts.filter((value) => {
                    return !!value;
                });

                return { value: member.memberId, text: parts.join(' ') };
            });
            this.receipientsOptions.push(...list.filter((x) => x && x.value));

            if (apiGroup.groupLead) {
                this.selectedRecipients.push(apiGroup.groupLead.memberId);
                this.groupLeadName = new Person(apiGroup.groupLead).getFullName();
            } else {
                this.groupLeadName = '';
            }

            if (this.transferRequest) {
                const settings = await this.getAttributionsSettings(!this.isRequestSender ? this.group.groupId : this.selectedGroup);
                this.applyMissingAttributions = settings.applyMissingAttributionsOnTransfer;

                this.attributionsPercentage = 0;
                const groupAttributions = await this.getGroupAttributions(
                    this.isRequestSender ? teamHelper.getTeamId() : this.selectedTeam,
                    this.isRequestSender ? this.group.groupId : this.selectedGroup,
                );
                groupAttributions.forEach((attr) => {
                    this.attributionsPercentage += attr.percentage;
                });

                this.transfer.includeMissingAttributions = this.applyMissingAttributions;
                this.transfer.invoiceType = this.applyMissingAttributions ? InvoiceType.Income : InvoiceType.InternalIncome;
            } else {
                const settings = await this.getAttributionsSettings(this.selectedGroup);
                this.applyMissingAttributions = settings.applyMissingAttributionsOnTransfer;

                this.transfer.includeMissingAttributions = this.applyMissingAttributions;
                this.transfer.invoiceType = this.applyMissingAttributions ? InvoiceType.Income : InvoiceType.InternalIncome;
            }

            this.transfer.sender = this.groupAccounts.find((x) => x.relatedEntityId === this.selectedGroup);
        }
    }

    public async getGroupAttributions(teamId: number, groupId: number) {
        const [err, response] = await to(
            attributionService.getAttributions(AttributionType.GroupAttribution, teamId, periodModule.selectedPeriod, groupId),
        );
        if (err) {
            this.showFailedResponse('Failed to load group attributions', null);
        }

        return response.data;
    }

    public async memberChanged() {
        // do stuff
    }

    public optionListMembers() {
        return this.memberAccounts.map((member: FinanceAccount) => {
            return { value: member.relatedEntityId, text: member.name };
        });
    }

    public async onHide() {
        this.transfer = new Transfer({
            includeMissingAttributions: this.applyMissingAttributions,
            invoiceType: this.applyMissingAttributions ? InvoiceType.Income : InvoiceType.InternalIncome,
        });

        this.selectedTeam = this.selectedMember = this.selectedGroup = null;
        this.selectedRecipients = [];

        this.attributionsPercentage = 0;
        const groupAttributions = await this.getGroupAttributions(teamHelper.getTeamId(), this.group.groupId);
        groupAttributions.forEach((attr) => {
            this.attributionsPercentage += attr.percentage;
        });
    }

    public async sendRequest() {
        if (!this.validateTransferRequest()) {
            return;
        }

        if (this.selectedRecipients.length === 0) {
            this.showValidationErrors(['Select at least one recipient']);
            return;
        }

        this.showPending('TRANSFER_REQUEST_PENDING');

        if (!this.transfer.receiver || this.transfer.receiver.accountId === 0) {
            this.transfer.receiver = await financeAccountService.getGroupAccount(
                teamHelper.currentTeam.id,
                this.group.groupId,
                AccountType.GroupIncome,
                periodModule.selectedPeriod,
            );
        }

        let amount = this.transfer.internalAmount;
        if (this.applyMissingAttributions) {
            amount = this.transfer.amount;
        }

        if (!this.transfer.group) {
            this.transfer.group = this.group.groupId;
        }

        this.transfer.senderTeam = this.teams.find((x) => x.teamId === this.selectedTeam);
        this.transfer.receiverTeam = this.teams.find((x) => x.teamId === teamHelper.getTeamId());

        await transferService.createTransferRequest({ transfer: this.transfer, recipients: this.selectedRecipients }, amount, teamHelper.getTeamId());
        this.$emit('reload-transfer-requests');
        this.hide();
    }

    public async updateRequest() {
        if (!this.validateTransferRequest()) {
            return;
        }

        let amount = this.transfer.internalAmount;
        if (this.applyMissingAttributions) {
            amount = this.transfer.amount;
        }

        const model = {
            transfer: this.transferRequest.transfer,
            senderGroup: this.transferRequest.senderGroup,
            receiverGroup: this.transferRequest.receiverGroup,
            initiator: this.transferRequest.initiator,
            transferRequestId: this.transferRequest.transferRequestId,
            recipients: this.transferRequest.recipients.map((x) => x.personId),
        };

        model.transfer.amount = amount;

        const success = await transferService.updateTransferRequest(model, teamHelper.getTeamId());
        if (success) {
            this.$emit('reload-transfer-requests');
        }
    }

    public async acceptRequest(transferRequestId?: number) {
        const trId = transferRequestId ? transferRequestId : this.transferRequest.transferRequestId;
        const success = await transferService.acceptTransferRequest(trId);
        if (success) {
            this.$emit('transfer-request-status-changed', { transferRequestId: trId, accepted: true });
        }
    }

    public async withdrawRequest(transferRequestId?: number) {
        const trId = transferRequestId ? transferRequestId : this.transferRequest.transferRequestId;
        const success = await transferService.withdrawRequest(trId);
        if (success) {
            this.$emit('transfer-request-status-changed', { transferRequestId: trId, accepted: false });
        }
    }

    public async rejectRequest(transferRequestId?: number) {
        const trId = transferRequestId ? transferRequestId : this.transferRequest.transferRequestId;
        const success = await transferService.rejectTransferRequest(trId);
        if (success) {
            this.$emit('transfer-request-status-changed', { transferRequestId: trId, accepted: false });
        }
    }

    public validateTransferRequest(): boolean {
        const errors = [];
        // const minLengthReason = 10;
        const transfer = this.transfer;

        this.isTransferValid.amount = true;
        this.isTransferValid.sender = true;
        this.isTransferValid.receiver = true;
        this.isTransferValid.reason = true;
        this.isTransferValid.internalAmount = true;
        this.isTransferValid.team = true;

        if (transfer.receiverTeam == null) {
            this.isTransferValid.team = false;
            errors.push('Select a team.');
        }

        if (transfer.senderId == null) {
            this.isTransferValid.sender = false;
            errors.push('Select a sender.');
        }

        if (transfer.amount == null) {
            this.isTransferValid.amount = false;
            errors.push('Fill in a valid amount');
        }

        if (transfer.internalAmount == null) {
            this.isTransferValid.internalAmount = false;
            errors.push('Fill in a valid amount');
        }

        if (errors.length > 0) {
            this.showValidationErrors(errors);
            return false;
        }

        return true;
    }

    public async getAttributionsSettings(groupId: number) {
        const [err, response] = await to(attributionService.getAttributionSetting(groupId));
        if (err) {
            this.showFailedResponse('Failed to load attribution settings', null);
        }
        return response.data;
    }
}
