import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class GridAmountLeftFilterComponent extends Vue {
    public value: string = '';

    public change(ev) {
        if (!this.value) {
            this.reset(ev);
            return;
        }

        this.$emit('change', { operator: this.value, field: 'amountLeftFilter', value: 0, syntheticEvent: ev });
    }

    public reset(ev) {
        this.$emit('change', { operator: '', field: '', value: '', syntheticEvent: ev });
    }
}
