import Component from 'vue-class-component';
import Vue from 'vue';
import { ecosystemHelper, navigationHelper, roleHelper, teamHelper } from '@/main';
import { periodModule } from '@/store/modules/period';

@Component
export default class TopBarBreadcrumb extends Vue {
    public getCurrentTeamName() {
        const team = teamHelper.currentTeam;
        return team ? team.name : '';
    }

    public getGroupKey() {
        return this.$route.params.groupKey;
    }

    public getRouteObject() {
        const tab =
            this.$route.meta.section === 'directory' ? 'groups' : this.$route.meta.section === 'finances' ? 'group-overview' : 'group-results';

        return {
            name: navigationHelper.scope + '-' + navigationHelper.section,
            params: {
                ecosystemId: this.$route.params.ecosystemId,
                ecosystemKey: this.$route.params.ecosystemKey,
                teamId: this.$route.params.teamId,
                teamKey: this.$route.params.teamKey,
                tab,
                period: this.$route.params.period,
            },
        };
    }

    public toggleNavMenu() {
        navigationHelper.toggleNavigation();
    }

    public me() {
        return this.$router.push({
            name: 'me-directory',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                teamId: teamHelper.currentTeam.id.toString(),
                teamKey: teamHelper.currentTeam.key,
                tab: 'groups',
                period: periodModule.selectedPeriod,
            },
        });
    }

    public team() {
        return this.$router.push({
            name: 'team-directory',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                teamId: teamHelper.currentTeam.id.toString(),
                teamKey: teamHelper.currentTeam.key,
                tab: 'groups',
                period: periodModule.selectedPeriod,
            },
        });
    }

    public ecology() {
        return this.$router.push({
            name: 'ecosystem-overview',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                period: periodModule.selectedPeriod,
            },
        });
    }

    public settings() {
        return this.$router.push({ name: 'settings-base' });
    }

    public get variant() {
        if (this.$route.meta.scope === 'ecosystem') {
            return 'success-light';
        }
        if (this.$route.path.indexOf('/team/') > -1) {
            return 'warning-light';
        }
        if (this.$route.meta.scope === 'settings') {
            return 'dark-light';
        }
        return 'primary-light';
    }

    public get scope() {
        if (this.$route.meta.scope === 'ecosystem') {
            return 'ECOLOGY';
        }
        if (this.$route.path.indexOf('/team/') > -1) {
            return 'TEAM';
        }
        if (this.$route.meta.scope === 'settings') {
            return 'SETTINGS';
        }
        return 'ME';
    }

    public get breadcrumb() {
        return this.$route.meta && this.$route.meta.breadcrumb ? this.$route.meta.breadcrumb : {};
    }

    public get breadcrumbItems() {
        const items = [];
        if (this.breadcrumb && this.breadcrumb.items) {
            this.breadcrumb.items.forEach((routeName) => {
                const resolved = this.$router.resolve({ name: routeName, params: this.$route.params });
                if (resolved && resolved.route.meta && resolved.route.meta.breadcrumb) {
                    if (routeName === 'team-insights' && !roleHelper.isSiteAdmin()) {
                        return;
                    }

                    items.push({
                        text: resolved.route.meta.breadcrumb.text,
                        route: resolved.route,
                        path: resolved.href,
                    });
                }
            });
        }

        return items;
    }

    public get splitHref() {
        if (!this.breadcrumb.split) {
            return '';
        }

        const route = this.$router.resolve({
            name: this.breadcrumb.split,
            params: {
                ecosystemId: this.$route.params.ecosystemId,
                ecosystemKey: this.$route.params.ecosystemKey,
                period: this.$route.params.period,
                teamId: this.$route.params.teamId,
            },
        });
        return route.href;
    }

    public goTo(resolvedRoute) {
        return this.$router.push(resolvedRoute);
    }
}
