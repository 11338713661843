import Group from '../models/Group';
import { Member } from '../models/Member';
import { AxiosHelper } from '../helpers/axiosHelper';
import { BaseService } from './baseService';
import Vue from 'vue';
import { loginHelper } from '@/main';

export class GroupService extends BaseService {
    private groupEndpoint = `${Vue.$env().directoryEndpoint}` + 'groups';
    private teamEndpoint = `${Vue.$env().directoryEndpoint}` + 'teams';

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public createGroup(model: any, period: string) {
        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.post<Group>(`${this.groupEndpoint}`, model, null, config);
    }

    public getGroups(teamId: number, period?: string) {
        if (!period) {
            return this.get<Group[]>(`${this.teamEndpoint}/:teamId/groups`, [['teamId', teamId]]);
        }

        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.get<Group[]>(`${this.teamEndpoint}/:teamId/groups`, [['teamId', teamId]], config);
    }

    public getGroup(groupId: number, period: string) {
        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.get<Group>(`${this.groupEndpoint}/:group`, [['group', groupId]], config);
    }

    public getMembers(group: number, teamId: number, period?: string) {
        if (!period) {
            return this.get<Member[]>(`${this.groupEndpoint}/:group/members?teamId=:teamId`, [['group', group], ['teamId', teamId]]);
        }

        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.get<Member[]>(`${this.groupEndpoint}/:group/members`, [['group', group]], config);
    }

    public enroll(group: number, teamId: number, period: string) {
        return this.addMember(loginHelper.getUser().personId, group, teamId, period);
    }

    public unenroll(group: number, teamId: number, period: string) {
        return this.kickMember(group, loginHelper.getUser().personId, teamId, period);
    }

    public getEnergyDedication(teamId: number, group: number, period: string) {
        return this.get(`${this.groupEndpoint}/:group/energy-dedication?teamId=:teamId&period=:period`, [
            ['group', group],
            ['teamId', teamId],
            ['period', period],
        ]);
    }

    public updateEnergyDedication(teamId: number, group: number, energy: number, period: string) {
        return this.put(
            `${this.groupEndpoint}/:group/energy-dedication?teamId=:teamId&period=:period`,
            energy,
            [['group', group], ['teamId', teamId], ['period', period]],
            { headers: { 'Content-Type': 'application/json' } },
        );
    }

    public updateEnergyDedicationForMember(teamId: number, group: number, energy: number, period: string, memberId: number) {
        return this.put(
            `${this.groupEndpoint}/:group/members/:memberId/energy-dedication?teamId=:teamId&period=:period`,
            energy,
            [['group', group], ['teamId', teamId], ['period', period], ['memberId', memberId]],
            { headers: { 'Content-Type': 'application/json' } },
        );
    }

    public transferLeadership(teamId: number, group: number, model: any, period: string) {
        return this.post(`${this.groupEndpoint}/:group/leadership/transfer?teamId=:teamId&period=:period`, model, [
            ['group', group],
            ['teamId', teamId],
            ['period', period],
        ]);
    }

    public addMember(memberId: number, group: number, teamId: number, period: string) {
        return this.post(
            `${this.groupEndpoint}/:group/members?teamId=:teamId&period=:period`,
            [{ PersonId: memberId }],
            [['group', group], ['teamId', teamId], ['period', period]],
        );
    }

    public addMembers(memberIds: number[], group: number, teamId: number, period) {
        const list = memberIds.map((x) => {
            return { PersonId: x };
        });

        if (!period) {
            return this.post(`${this.groupEndpoint}/:group/members?teamId=:teamId`, list, [['group', group], ['teamId', teamId]]);
        }

        const config = {
            headers: {
                'x-period': period,
            },
        };
        return this.post(`${this.groupEndpoint}/:group/members?teamId=:teamId`, list, [['group', group], ['teamId', teamId]], config);
    }

    public deleteGroup(groupId: number, teamId: number, period: string) {
        return this.delete(`${this.groupEndpoint}/:group?teamId=:teamId&period=:period`, [
            ['group', groupId],
            ['teamId', teamId],
            ['period', period],
        ]);
    }

    public kickMember(group: number, memberId: number, teamId: number, period: string) {
        return this.delete(`${this.groupEndpoint}/:group/members/:memberId?teamId=:teamId&period=:period`, [
            ['group', group],
            ['teamId', teamId],
            ['period', period],
            ['memberId', memberId],
        ]);
    }
}
