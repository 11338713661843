import { InvoiceInterface, Distribution } from './Interfaces';
import { InvoiceType } from './InvoiceType';
import Amortization from './Amortization';
import { InvoiceSource } from './InvoiceSource';

export class Invoice implements InvoiceInterface {
    public name: string = '';
    public invoiceId: number = 0;
    public description: string = '';
    public customer: string = '';
    public invoiceDate: Date = new Date();
    public date: Date = new Date();
    public amount: number = 0;
    public reference: string;
    public source: InvoiceSource = InvoiceSource.Manual;
    public group: any = 0;
    public period: any;
    public originalAmount: number;
    public parent: number;
    public isRecurring: boolean;

    public invoiceType: InvoiceType = InvoiceType.Income;
    public distributions: Distribution[] = [];
    public invoiceAmortization: Amortization;
    public legalEntityReferenceOrigin: string;

    /**
     *
     */
    constructor(init?: Partial<Invoice>) {
        if (init) {
            Object.assign(this, init);
        }
    }
}
