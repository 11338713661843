import Vue from 'vue';
import IRecurringTransfer from '@/interfaces/IRecurringTransfer';
import { transferService } from '@/main';
import PageRender from '@/models/PageRender';
import to from 'await-to-js';
import { Component, Prop } from 'vue-property-decorator';
import TransferModalComponent from '../modals/transfer-modal';
import { TransferType } from '@/models/TransferType';
import { AccountType } from '@/models/AccountType';
import moment from 'moment';
import { periodModule } from '@/store/modules/period';

@Component
export default class RecurringTransfersGrid extends PageRender {
    @Prop() public teamId: number;
    @Prop() public groupId: number;
    @Prop() public personId: number;
    @Prop() public period: string;
    @Prop({ default: true }) public showTitle: boolean;
    @Prop({ default: false }) public compensations: boolean;
    @Prop({ default: false }) public showIfEmpty: boolean;

    public recurringTransfersLoading: boolean = true;
    public transfers: IRecurringTransfer[] = [];
    public selectedTransfer: IRecurringTransfer = null;
    public columns = [
        { title: 'AMOUNT', field: 'amount', width: 100 },
        { title: 'SENDER', field: 'senderAccount.name', cell: this.renderSender, width: 250 },
        { title: 'RECEIVER', field: 'receiverAccount.name', cell: this.renderReceiver, width: 250 },
        { title: 'DESCRIPTION', field: 'description' },
        { title: 'INITIATOR', field: 'initiator.name', width: 250 },
        { title: 'SCHEDULE', field: 'schedule', width: 100 },
        { title: 'CREATED', cell: this.renderCreated, width: 150 },
        { title: 'ACTIONS', cell: this.renderActions, width: 100 },
    ];

    public $refs!: {
        transferModal: TransferModalComponent;
    };

    public async mounted() {
        await this.refreshData();
    }

    public async refreshData() {
        this.recurringTransfersLoading = true;
        this.transfers = await this.getTransfers();
        this.recurringTransfersLoading = false;
    }

    public get teams() {
        return this.selectedTransfer ? [this.selectedTransfer.senderTeam, this.selectedTransfer.receiverTeam] : [];
    }

    public get transferModalType() {
        return TransferType.GroupToGroup;
    }

    private async getTransfers() {
        const [err, response] = await to(transferService.getReucrringTransfers(this.period, this.teamId, this.groupId));

        if (err) {
            this.clearAndShowError('Failed to load transfers.', null);
            return [];
        }
        return this.filterTransfers(response.data);
    }

    private filterTransfers(transfers: IRecurringTransfer[]) {
        return transfers.filter((transfer: IRecurringTransfer) => {
            if (this.compensations) {
                return transfer.receiverAccount.accountType === AccountType.Person;
            } else if (!this.compensations && this.groupId && !this.personId) {
                return transfer.senderAccount.accountType === AccountType.GroupSavings;
            } else if (!this.compensations && !this.groupId && !this.personId) {
                return transfer.senderAccount.accountType === AccountType.Person && transfer.receiverAccount.accountType === AccountType.Person;
            }
        });
    }

    private renderSender(h, _, row: { dataItem: IRecurringTransfer }) {
        const transfer = row.dataItem;
        const senders = [transfer.senderTeam ? transfer.senderTeam.name : '', transfer.senderGroup ? transfer.senderGroup.name : ''].filter(
            (v) => !!v,
        );

        if (transfer.receiverAccount.accountType === AccountType.Person) {
            senders.push(transfer.receiverAccount.name);
        }
        return h('td', senders.join(' - '));
    }

    private renderReceiver(h, _, row) {
        const transfer: IRecurringTransfer = row.dataItem;
        const receivers = [transfer.receiverTeam ? transfer.receiverTeam.name : '', transfer.receiverGroup ? transfer.receiverGroup.name : ''].filter(
            (v) => !!v,
        );
        if (transfer.receiverAccount.accountType === AccountType.Person) {
            receivers.push(transfer.receiverAccount.name);
        }
        return h('td', receivers.join(' - '));
    }

    private renderCreated(h, _, row) {
        const transfer: IRecurringTransfer = row.dataItem;
        return h('td', moment(transfer.dateCreated).format('DD-MM-YYYY'));
    }

    private renderActions(h, _, row) {
        const actions = [{ title: 'view', function: this.viewRecurringTransfer }, { title: 'stop', function: this.stopRecurringTransfer }];
        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    private async stopRecurringTransfer(transfer: IRecurringTransfer) {
        let period = this.period;

        if (!period) {
            period = periodModule.selectedPeriod;
        }

        this.showPending('STOP_RECURRING_TRANSFER_PENDING');
        const [err] = await to(transferService.stopRecurringTransfer(transfer.recurringTransferId, period));
        if (err) {
            this.clearAndShowError('STOP_RECURRING_TRANSFER_FAILED', null);
        } else {
            this.clearAndShowSuccess('STOP_RECURRING_TRANSFER_SUCCESS');
            await this.refreshData();
        }
    }

    private viewRecurringTransfer(transfer: IRecurringTransfer) {
        this.selectedTransfer = transfer;
        this.$nextTick(async () => {
            this.$refs.transferModal.editMode = true;
            await this.$refs.transferModal.initRecurringTransfer(transfer);
        });
    }
}
