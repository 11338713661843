import { AxiosHelper } from '../helpers/axiosHelper';
import { BaseService } from './baseService';
import Vue from 'vue';

export class CompensationService extends BaseService {
    private endpoint = `${Vue.$env().financeEndpoint}`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getCompensations(team: number, group: number, period: string) {
        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.get(
            `${this.endpoint}compensations?teamId=:team&groupId=:group`,
            [['team', team], ['group', group]],
            config,
        );
    }
}
