import { Component, Prop } from 'vue-property-decorator';
import Connector from '@/models/Connectors/Connector';
import PageRender from '@/models/PageRender';
import Group from '@/models/Group';
import { groupHelper } from '@/main';

@Component
export default class ConnectorsGroupComponent extends PageRender {
    @Prop({ default: () => [] }) public connectors: Connector[];
    @Prop({ default: () => new Group() }) public group: Group;

    public mounted() {
        for (const connector of this.connectors) {
            connector.selected = this.group.connectors.filter((x) => x.connectorId === connector.connectorId).length > 0;
        }

        this.isLoading = false;
    }

    public async saveConnectors() {
        this.showPending('GROUP_CONNECTORS');

        this.group.connectors = this.connectors.filter((x) => x.selected);

        const group = await groupHelper.createOrUpdateGroup(this.group);
        if (!group || group.groupId === 0) {
            return this.showFailedResponse('GROUP_CONNECTORS_FAILED');
        }

        this.clearAndShowSuccess('GROUP_CONNECTORS_SUCCESS');
    }
}
