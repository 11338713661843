import { Component } from 'vue-property-decorator';
import { teamService } from '@/main';
import InviteResponse from '../../../models/InviteResponse';
import SelectItem from '../../../models/SelectItem';
import Register from '../../../models/Register';
import { Person } from '../../../models/Person';
import { Membership } from '../../../models/Interfaces';
import PageRender from '@/models/PageRender';
import { periodModule } from '@/store/modules/period';
import to from 'await-to-js';
import Policies from '@/models/Policy/Policies';
import PolicyHelper from '@/helpers/PolicyHelper';

@Component
export default class InvitedComponent extends PageRender {
    public key: string = '';
    public team: string = '';
    public teamId: number = 0;
    public teamOptions: SelectItem[] = [];
    public selectedTeams: string[] = [];
    public invite: InviteResponse = new InviteResponse();
    public model: Register = new Register();
    public email: string = '';
    public needsAccount: boolean = true;
    public showPolicy: boolean = false;
    private passwordPolicyHelper = new PolicyHelper(Policies.Password);

    public get policies() {
        return this.passwordPolicyHelper.policies;
    }

    public async created() {
        this.key = this.$route.params.key;
        this.teamId = parseInt(this.$route.params.teamId);
        const period = this.$route.params.period;
        const ecosystem = this.$route.params.ecosystemId;

        const [err, inviteResponse] = await to(teamService.getInvite(this.key, this.teamId, period, ecosystem));
        if (!err) {
            this.invite = inviteResponse.data;
            this.invite.sender = new Person(inviteResponse.data.sender);
            this.email = this.invite.invite.email;

            if (inviteResponse.data.receiver) {
                this.model.person = this.invite.receiver = new Person(inviteResponse.data.receiver);
            }
        }

        this.isLoading = false;
    }

    public getInvitePersonFullname() {
        return this.invite.sender.getFullName();
    }

    public getTeamName() {
        return this.invite.team.name;
    }

    public registerPost(model: Register) {
        const self = this;

        model.period = periodModule.selectedPeriod;

        self.showPending('REGISTRATION_PENDING');

        teamService
            .acceptInvite(this.teamId, self.key, model)
            .then((response: any) => {
                if (response) {
                    self.clearAndShowSuccess('REGISTRATION_SUCCESS');

                    setTimeout(() => {
                        return self.$router.push({
                            name: 'login',
                        });
                    }, 3000);
                }
            })
            .catch((error) => {
                self.clearAndShowError('REGISTRATION_FAILED', error);
            });
    }

    public onSubmit(evt: any) {
        evt.preventDefault();
        this.register();
    }

    public register(): void {
        const self = this;
        const valid = this.passwordPolicyHelper.validate(self.model.password);
        if (!valid) {
            return this.clearAndShowWarning(`Make sure your password meets the requirements.`);
        }

        if (self.model.password !== self.model.passwordRepeat) {
            return this.clearAndShowWarning(`Passwords aren't equal`);
        }

        this.clearNotifications();
        self.model.emailAddress = self.email;
        self.model.person.emailAddress = self.email;
        self.model.needsAccount = self.needsAccount;
        self.model.person.teamMemberships.length = 0;
        self.model.person.teamMemberships.push({ key: this.team } as Membership);

        self.registerPost(self.model);
    }
}
