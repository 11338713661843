import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import store from '../index';
@Module
class FiltersModule extends VuexModule {
    // tslint:disable: variable-name
    private _startMonth: string = null;
    private _endMonth: string = null;

    public get startMonth() {
        return this._startMonth;
    }

    public get endMonth() {
        return this._endMonth;
    }

    public get hasMonths() {
        return this.endMonth != null && this.startMonth != null;
    }

    @Action
    public setStartMonth(startMonth: string) {
        this.SET_STARTMONTH(startMonth);
    }

    @Action
    public setEndMonth(endMonth: string) {
        this.SET_ENDMONTH(endMonth);
    }

    @Mutation
    private SET_STARTMONTH(startMonth) {
        this._startMonth = startMonth;
    }
    @Mutation
    private SET_ENDMONTH(endMonth) {
        this._endMonth = endMonth;
    }
}

export const filtersModule = new FiltersModule({ store, name: 'filters' });
