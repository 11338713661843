import { Component } from 'vue-property-decorator';
import PageRender from '../../../models/PageRender';
import {
    groupHelper,
    roleHelper,
    memberHelper,
    connectorService,
    templateService,
    teamHelper,
    groupService,
    teamService,
    ecosystemHelper,
} from '@/main';
import moment from 'moment';
import Group from '@/models/Group';
import { VueEditor } from 'vue2-editor';
import { periodModule } from '@/store/modules/period';
import Connector from '@/models/Connectors/Connector';
import { Person } from '@/models/Person';
import { Member } from '@/models/Member';
import { profileModule } from '@/store/modules/profile';
import { required, minLength } from 'vuelidate/lib/validators';
import to from 'await-to-js';
import Team from '@/models/Team';
import Period from '@/models/Period';

@Component({
    components: {
        VueEditor,
    },
    validations: {
        createGroupForm: {
            name: { required },
            purpose: { required },
            readMe: { minLength: minLength(20) },
        },
        selectMemberIds: { required, minLength: minLength(1) },
        selectedGroupLead: { required },
    },
} as any)
export default class TeamDirectoryAddGroupComponent extends PageRender {
    public connectors: Connector[] = [];
    public selectedConnectors: any[] = [];
    public allMembers: Person[] = [];
    public groups: Group[] = [];
    public selectedTemplateId: number = 0;
    public createGroupForm: Group = new Group();
    public selectedGroupLead: Person = null;
    public selectMemberIds: number[] = [];
    public submitted: boolean = false;
    public stepIndex: number = 0;
    public energySpent: number = 0;
    public selectedPeriod: Period = null;
    public templates: any[] = [];
    public teams: Team[] = [];

    public async mounted() {
        if (!roleHelper.isSiteAdmin() && !roleHelper.isFinanceEmployee()) {
            await this.$router.push({ name: 'not-found' });
        }

        this.allMembers = await memberHelper.getMembers(true);
        const me = this.allMembers.find((x) => x.personId === profileModule.brightProfile.personId);
        this.connectors = await connectorService.getConnectors();
        this.groups = await groupHelper.getGroups();
        this.selectedGroupLead = me ? me : null;
        if (this.selectedGroupLead) {
            this.selectMemberIds.push(this.selectedGroupLead.personId);
        }
        this.selectedPeriod = this.periods.find((periodOption: Period) => {
            return periodOption.periodId === periodModule.selectedPeriodObject.periodId;
        });

        await this.loadTemplates();
        this.teams = await teamService.getTeams();
        this.isLoading = false;
    }

    public get periods() {
        return periodModule.periods.map((period: Period) => {
            const option: any = { ...period };
            option.name = moment(period.startDate).format(`MMMM 'YY`);
            return option;
        });
    }

    public get members() {
        const groupLeadId = this.selectedGroupLead ? this.selectedGroupLead.personId : null;
        let list = [];
        if (this.allMembers && this.allMembers.length > 0) {
            list = this.allMembers.map((member) => {
                return {
                    text: `${member.getFullName()}${member.personId === groupLeadId ? ' (group lead)' : ''}`,
                    value: member.personId,
                };
            });
        }
        return list.sort((x, y) => x.value === groupLeadId ? -1 : y === groupLeadId ? 1 : 0);
    }

    public get memberNames() {
        return this.allMembers
            .filter((x) => this.selectMemberIds.indexOf(x.personId) > -1)
            .map((x) => x.getFullName())
            .join(', ');
    }

    public get memberOptions() {
        return this.allMembers.filter((x) => x.externalIdentifier);
    }

    public get templateName() {
        return this.templates.find((x) => x.financeGroupTemplateId === this.selectedTemplateId).name;
    }

    public get connectorNames() {
        return this.connectors
            .filter((x) => this.selectMemberIds.indexOf(x.connectorId) > -1)
            .map((x) => x.name)
            .join(', ');
    }

    public get teamFinanceSettingsRoute() {
        const params = this.$route.params;
        const route = {
            name: 'team-finances',
            params: {
                teamId: params.teamId,
                teamKey: params.teamKey,
                period: params.period,
                tab: 'settings',
            },
        };
        return route;
    }

    public get hasFinanceConnector() {
        return this.selectedConnectors.filter((x) => x === 1).length > 0;
    }

    public get connectorOptions() {
        return this.connectors.map((x) => {
            return { value: x.connectorId, text: x.name };
        });
    }

    public valid(index: number = this.stepIndex) {
        this.clearNotifications();
        if (index === 0) {
            return !(this as any).$v.createGroupForm.$invalid && !(this as any).$v.selectedGroupLead.$invalid;
        } else if (index === 1) {
            return !(this as any).$v.selectMemberIds.$invalid;
        } else if (index === 2) {
            return !(this as any).$v.createGroupForm.$invalid;
        } else if (index === 3) {
            // no validation needed, connector is selected or not.
        } else if (index === 4) {
            // no validation, default no template selected
        } else if (index === 5) {
            // validate finance settings when editable
        } else if (index === 6) {
            // validate attributions settings when editable
        } else if (index === 7) {
            return !(this as any).$v.createGroupForm.$invalid || !(this as any).$v.selectMemberIds.$invalid;
        }

        return true;
    }

    public moveToTab(newTabIndex, prevTabIndex, bvEvent) {
        if (newTabIndex > this.stepIndex + 1 || (!this.valid(prevTabIndex) && newTabIndex > prevTabIndex)) {
            this.submitted = true;
            return bvEvent.preventDefault();
        }

        this.submitted = false;
    }

    public async next() {
        this.submitted = true;
        if (this.valid()) {
            this.stepIndex++;

            if (this.stepIndex === 4) {
                await this.loadTemplates();
            }

            if (this.stepIndex === 6) {
                this.teams = await teamService.getTeams();
            }
            this.submitted = false;
        }
    }

    public back() {
        this.stepIndex--;
    }

    public async finalize() {
        if (this.valid()) {
            await this.addGroup();
        }
    }

    public updateMemberList(groupLead: Person) {
        this.selectMemberIds = [groupLead.personId];
    }

    public selectTemplate(template) {
        this.selectedTemplateId = template.financeGroupTemplateId;
    }

    public async addGroup() {
        const periodString = moment(this.selectedPeriod.startDate, 'YYYY-MM-DD').format('YYYY-MM');
        this.showPending('CREATE_GROUP_PENDING');
        this.createGroupForm.connectors = this.selectedConnectors;
        this.createGroupForm.groupLead = new Member({ memberId: this.selectedGroupLead.personId });
        this.createGroupForm.templateId = this.selectedTemplateId > 0 ? this.selectedTemplateId : null;

        const newGroup = await groupHelper.createOrUpdateGroup(this.createGroupForm, periodString);
        if (!newGroup.groupId) {
            return;
        }

        const groups = await teamService.getGroups(teamHelper.currentTeam.id, periodString);
        const group = groups.data.find((x) => x.name === this.createGroupForm.name);
        if (this.selectMemberIds.length > 1 && group) {
            this.clearNotifications();
            this.showPending('Adding selected members to the group...');
            const [err] = await to(groupService.addMembers(this.selectMemberIds, group.groupId, teamHelper.currentTeam.id, periodString));

            if (err) {
                return this.clearAndShowError('Failed adding members');
            }
        }

        this.clearNotifications();
        await this.$router.push({
            name: 'team-directory-group',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                teamId: teamHelper.currentTeam.id.toString(),
                teamKey: teamHelper.currentTeam.key,
                groupId: group.groupId.toString(),
                groupKey: group.groupKey,
                tab: 'members',
                period: periodModule.selectedPeriod,
            },
        });
    }

    private async loadTemplates() {
        this.templates = await templateService.getTemplates(teamHelper.currentTeam.id);
    }
}
