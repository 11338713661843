import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import store from '../index';

@Module
class NotificationModule extends VuexModule {
    private _visible: boolean = false;

    public get visible() {
        return this._visible;
    }

    @Action
    public toggleVisibility() {
        this.SET_VISIBILITY(!this.visible);
    }

    @Mutation
    private SET_VISIBILITY(payload: boolean) {
        this._visible = payload;
    }
}

export const notificationModule = new NotificationModule({ store, name: 'notification' });
