import Vue from 'vue';
import PaymentStatus from '@/models/Insights/PaymentStatus';
import { periodModule } from '@/store/modules/period';
import { translateModule } from '@/store/modules/translate';
import moment from 'moment';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BaseInsightsPage from './BaseInsightsPage';

@Component
export default class TeamInsightPaymentDetail extends BaseInsightsPage {
    @Prop() public dataItem: { payments: PaymentStatus[] };

    public get payments(): PaymentStatus[] {
        return this.dataItem.payments;
    }

    public paymentStatussesColumns: any[] = [
        { field: 'invoiceId', title: 'INVOICE', cell: this.renderInvoiceLink },
        { field: 'invoiceReference', title: 'REFERENCE' },
        { field: 'invoiceDate', cell: this.renderInvoiceDate, title: 'DATE' },
        { field: 'externalIdentifier', title: 'EXTERNAL_IDENTIFIER' },
        { field: 'period', cell: this.formatPeriod, title: 'PERIOD' },
        { field: 'periodMatched', cell: this.formatPeriodMatched, title: 'PERIOD_MATCHED' },
        { field: 'invoiceAmount', cell: this.formatInvoicesAmountValue, title: 'INVOICE_AMOUNT' },
        { field: 'amountPaid', cell: this.formatAmountPaidValue, title: 'AMOUNT_PAID' },
        { field: 'paymentDate', cell: this.renderPaymentDate, title: 'PAYMENT_DATE' },
    ];

    public renderInvoiceDate(h, _, row) {
        return h('td', [moment(row.dataItem.invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY')]);
    }

    public renderPaymentDate(h, _, row) {
        return h('td', [moment(row.dataItem.paymentDate, 'YYYY-MM-DD').format('DD-MM-YYYY')]);
    }

    public formatInvoicesAmountValue(h, _, row) {
        const props = { value: row.dataItem.invoiceAmount };
        return h(Vue.component('number-formatter'), { props });
    }

    public formatAmountPaidValue(h, _, row) {
        const props = { value: row.dataItem.amountPaid };
        return h(Vue.component('number-formatter'), { props });
    }

    public formatExternalRevenue(h, _, row) {
        const props = { value: row.dataItem.externalRevenue };
        return h(Vue.component('number-formatter'), { props });
    }

    public formatPeriodMatched(h, _, row) {
        if (!row.dataItem.periodMatched) {
            return h('td');
        }

        const period = periodModule.allPeriods.find((x) => x.periodId === row.dataItem.periodMatched);
        if (period) {
            return h('td', moment(period.startDate, 'YYYY-MM-DD').format(`MMMM 'YY`));
        }
        return h('td', translateModule.value('NO_PERIOD_FOUND'));
    }
}
