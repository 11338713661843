import { ecosystemService, teamService } from '@/main';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import to from 'await-to-js';
import { Component } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

@Component({
    validations: {
        ecosystem: {
            ecosystemName: { required },
            teamName: { required },
            // groupName: { required },
        },
    },
} as any)
export default class TemplateActionComponent extends SideActionBase<any> {
    public isEdit: boolean = false;
    public isLoading: boolean = true;
    public submitted: boolean = false;

    public ecosystem: any = {
        ecosystemName: '',
        teamName: '',
        groupName: 'Activity one',
    };

    public async mounted() {
        this.isLoading = false;
    }

    public async createEcosystem() {
        this.submitted = true;

        if ((this as any).$v.ecosystem.$invalid) {
            return;
        }

        this.showPending('CREATING_ECOSYSTEM');

        const createdEcosystem = await ecosystemService.createEcosytem(this.ecosystem.ecosystemName);
        if (createdEcosystem && createdEcosystem.ecosystemId > 0) {
            await to(
                teamService.createTeam(
                    {
                        name: this.ecosystem.teamName,
                        ecosystem: createdEcosystem.ecosystemKey,
                        legalEntityReference: this.ecosystem.teamName,
                    },
                    createdEcosystem.ecosystemId,
                ),
            );

            this.clearNotifications();
            return this.finished(createdEcosystem);
        }

        this.clearAndShowError('CREATING_ECOSYSTEM_FAILED', null);
    }
}
