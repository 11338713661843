import Ledger from '@/models/Finance/Ledger';
import Vue from 'vue';
import { BaseService } from './baseService';

export default class LedgerService extends BaseService {
    private endpoint = `${Vue.$env().financeEndpoint}ledgers`;

    public getLedgers() {
        return this.get(this.endpoint);
    }

    public postLedgers(ledger: Ledger) {
        return this.post(this.endpoint, ledger);
    }

    public updateLedgers(ledger: Ledger) {
        return this.put(`${this.endpoint}/${ledger.ledgerId}`, ledger);
    }
}
