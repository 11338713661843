import { Component } from 'vue-property-decorator';
import { ecosystemHelper, meService, teamHelper } from '@/main';
import to from 'await-to-js';
import PageRender from '@/models/PageRender';
import { Route } from 'vue-router';
import { periodModule } from '@/store/modules/period';
import { Person } from '@/models/Person';

@Component
export default class MembersComponent extends PageRender {
    public isLoaded: boolean = false;
    public members: Person[] = [];

    public memberColumns: any[] = [
        { cell: this.renderMemberName, title: 'NAME', field: 'firstName' },
        { cell: this.renderMemberships, title: 'MEMBERS_OF', field: 'firstName', width: 200 },
        { title: 'EMAIL', field: 'emailAddress' },
        { title: 'PHONE', field: 'phoneNumber' },
    ];

    public async mounted() {
        await this.checkPeriodParam();
        const self = this;

        const [errMembers, responseMembers] = await to(meService.getMembers(teamHelper.currentTeamId, periodModule.selectedPeriod));
        if (errMembers) {
            self.showFailedResponse('Could not get members.', errMembers);
        }

        this.members = responseMembers.data.map((x) => new Person(x));
        this.isLoaded = true;
    }

    public renderFullname(h, _, row) {
        const member = row.dataItem;
        const name = `${member.firstName} ${member.insertion ? member.insertion : ''} ${member.lastName}`;
        return h('td', [name]);
    }

    public renderMemberName(h, _, row): any {
        return this.renderMember(h, row.dataItem);
    }

    public exploreAllMembers(): Promise<Route> {
        return this.$router.push({
            name: 'team-directory',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                team: teamHelper.teamParam,
            },
        });
    }

    public fullname(member: any): string {
        return `${member.firstName} ${member.insertion ? member.insertion : ''} ${member.lastName}`;
    }
}
