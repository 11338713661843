import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { meService, periodFilterHelper, teamHelper, loginHelper, ecosystemHelper } from '@/main';
import to from 'await-to-js';
import PageRender from '@/models/PageRender';
import Group from '@/models/Group';
import { Route } from 'vue-router';
import EnergyDedicationModalComponent from '@/components/modals/energy-dedication-modal';
import { Member } from '@/models/Member';
import { periodModule } from '@/store/modules/period';

@Component
export default class GroupsComponent extends PageRender {
    public isLoaded: boolean = false;
    public groups: Group[] = [];
    public $refs!: {
        energyDedicationEdit: EnergyDedicationModalComponent;
    };

    public groupUsedInModal: Group = {} as Group;
    public totalEnergySpent: number = 0;
    public reloadKey: number = 0;

    public groupsColumns: any[] = [
        { cell: this.renderRouterLink, title: 'GROUP_NAME', width: 300 },
        { cell: this.renderEnergyDedication, title: 'ENERGY_DEDICATION', width: 175 },
        { field: 'purpose', title: 'GROUP_PURPOSE' },
        { cell: this.renderMembers, title: 'GROUP_MEMBERS', width: 125 },
        { cell: this.renderGroupLead, title: 'GROUP_LEAD', width: 300 },
        { cell: this.renderActions, title: 'ACTIONS', width: 125 },
    ];

    public async mounted() {
        await this.checkPeriodParam();
        const self = this;
        const [err, response] = await to(meService.getGroups(teamHelper.currentTeamId, periodModule.selectedPeriod));
        if (err) {
            self.showFailedResponse('Could not get groups.', err);
        }
        const groups = response.data;

        const [errEnergy, responseEnergy] = await to(
            meService.getEnergyDedications(teamHelper.currentTeamId, periodModule.selectedPeriod),
        );
        if (errEnergy) {
            self.showFailedResponse('Could not get energy dedication.', errEnergy);
        }

        responseEnergy.data.forEach((energy) => {
            const group = groups.find((item: any) => {
                return item.groupKey === energy.groupKey.toLowerCase();
            });
            if (group) {
                group.energyDedication = energy.amount;
                self.totalEnergySpent += energy.amount;
            }
        });

        self.groups = groups;
        this.isLoaded = true;
    }

    public async reloadEnergyDedications() {
        const [errEnergy, responseEnergy] = await to(
            meService.getEnergyDedications(teamHelper.currentTeamId, periodModule.selectedPeriod),
        );
        if (errEnergy) {
            this.showFailedResponse('Could not get energy dedication.', errEnergy);
        }

        this.groups.forEach((x) => (x.energyDedication = 0));

        responseEnergy.data.forEach((energy) => {
            const group = this.groups.find((item: any) => {
                return item.groupKey === energy.groupKey.toLowerCase();
            });
            if (group) {
                group.energyDedication = energy.amount;
            }
        });

        this.reloadKey++;
    }

    public renderFullname(h, _, row) {
        const member = row.dataItem;
        const name = `${member.firstName} ${member.insertion ? member.insertion : ''} ${member.lastName}`;
        return h('td', [name]);
    }

    public renderEnergyDedication(h, _, row) {
        const props = {
            value: row.dataItem.energyDedication,
            showPercentage: true,
            action: async () => {
                await this.openEditEnergyEdicationModal();
            },
        };
        return h(Vue.component('grid-percentage'), { props });
    }

    public renderGroupLead(h, _, row): any {
        return this.renderMember(h, row.dataItem.groupLead);
    }

    public renderMemberName(h, _, row): any {
        return this.renderMember(h, row.dataItem);
    }

    public renderActions(h, _, row: any): any {
        const actions = [
            { title: 'Overview', function: this.exploreAllGroups },
            {
                title: 'Edit energy dedication',
                function: this.openEditEnergyEdicationModal,
                disabled: periodFilterHelper.isCurrentPeriodClosed(),
            },
        ];
        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    public renderRouterLink(h, _, row) {
        const routeUrl = this.$router.resolve({
            name: 'team-directory-group',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                groupKey: row.dataItem.groupKey,
                groupId: row.dataItem.groupId,
                teamId: teamHelper.currentTeam.id.toString(),
                teamKey: teamHelper.currentTeam.key,
                tab: 'workspace',
                period: periodModule.selectedPeriod,
            },
        }).href;

        const props = {
            text: row.dataItem.name,
            url: routeUrl,
        };

        return h(Vue.component('grid-router-link'), { props });
    }

    public renderPercentage(item: any): any {
        const self = this;
        return {
            template: Vue.component('grid-percentage-component'),
            templateArgs: (Object as any).assign(
                {},
                {
                    value: item.energyDedication,
                    async action() {
                        await self.openEditEnergyEdicationModal();
                    },
                },
                {},
            ),
        };
    }

    public exploreAllGroups(): Promise<Route> {
        return this.$router.push({
            name: 'team-directory',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                team: teamHelper.teamParam,
            },
        });
    }

    public async openEditEnergyEdicationModal() {
        const self = this;
        const modal = self.$refs.energyDedicationEdit;
        const memberObj = new Member(loginHelper.getUser());
        await modal.setMember(memberObj);
        modal.show();
    }
}
