import { Component } from 'vue-property-decorator';
import to from 'await-to-js';
import { mapService, $router, groupService, teamService, eventBus, ecosystemHelper } from '@/main';
import GraphData from '@/models/Graph/GraphData';
import IMapSetting from '@/models/Map/IMapSettings';
import Group from '@/models/Group';
import Team from '@/models/Team';
import { Person } from '@/models/Person';
import { periodModule } from '@/store/modules/period';
import BaseEcologyDirectory from './directory/components/BaseEcosystemDirectory';

@Component
export default class EcosystemOverviewComponent extends BaseEcologyDirectory {
    public graph = new GraphData();
    public teamId: number = 0;
    public team: Team = new Team();
    public groupId: number = null;
    public group: Group = new Group();
    public isTeamScope: boolean = false;

    private mapSettings: IMapSetting[] = [];

    public created() {
        this.isTeamScope = typeof this.$route.params.teamId !== 'undefined';
        this.teamId = parseInt(this.$route.params.teamId);
        this.groupId = parseInt(this.$route.params.groupId);
    }

    public async mounted() {
        if (!isNaN(this.groupId)) {
            const [err, response] = await to(groupService.getGroup(this.groupId, periodModule.selectedPeriod));
            if (err) {
                this.showFailedResponse('LOADING_GROUP_FAILED', err);
            }

            this.group = response.data;
        }

        this.teams = await teamService.getTeams();
        if (!isNaN(this.teamId) && this.teamId > 0) {
            this.team = this.teams.find((x) => x.teamId === this.teamId);
            this.mapSettings = await mapService.getSettings(this.team.teamId);
        } else {
            this.mapSettings = await mapService.getSettings();
        }

        if (this.group && this.group.groupId > 0) {
            await this.handleGroupScope();
        } else if (this.team && this.team.teamId > 0) {
            await this.handleTeamScope();
        } else {
            await this.handleEcosystemScope();
        }

        this.isLoading = false;
    }

    public async handleGroupScope() {
        for (const [index, member] of this.group.members.entries()) {
            let settings = null; // this.mapSettings.find((x) => x.member === member.groupId);

            if (!settings) {
                const rows = Math.ceil(Math.sqrt(this.group.members.length));
                const x = 0 + (index % rows) * 100;
                const y = 0 + Math.floor(index / rows) * 100;

                settings = { x, y, radius: 40, team: null, group: this.group.groupId, member: member.memberId };
            }

            this.graph.vertices.push({
                id: member.memberId,
                name: new Person(member).getFullName(),
                x: settings.x,
                y: settings.y,
                radius: settings.radius,
                // onclick: () => this.goToGroup(this.team.key, group.groupKey),
            });
        }
    }

    public async handleTeamScope() {
        for (const [index, group] of this.team.groups.entries()) {
            let settings = this.mapSettings.find((x) => x.group === group.groupId);
            if (!settings) {
                settings = { x: 20 * index, y: 20, radius: 20, team: null, group: group.groupId };
            }

            this.graph.vertices.push({
                id: group.groupId,
                name: group.name,
                x: settings.x,
                y: settings.y,
                radius: settings.radius,
                onclick: () => this.goToGroup(this.team, group),
            });
        }
    }

    public async handleEcosystemScope() {
        for (const [index, team] of this.teams.entries()) {
            let settings = this.mapSettings.find((x) => x.team === team.teamId);
            if (!settings) {
                settings = { x: 20 * index, y: 20, radius: 20, team: team.teamId, group: null };
            }

            this.graph.vertices.push({
                id: team.teamId,
                key: team.teamKey,
                name: team.name,
                icon: team.logoUrl,
                x: settings.x,
                y: settings.y,
                radius: settings.radius,
                onclick: () => this.goToTeam(team),
            });
        }
    }

    public async goUp() {
        if (this.group && this.group.groupId > 0) {
            return this.goToTeam(this.team);
        }

        await $router.push({
            name: 'ecosystem-overview',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                period: periodModule.selectedPeriod,
            },
        });
    }

    public async goToTeam(team: Team) {
        if (this.editMode) {
            return;
        }

        await $router.push({
            name: 'ecosystem-overview-team',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                teamId: team.teamId.toString(),
                teamKey: team.teamKey,
                period: periodModule.selectedPeriod,
            },
        });
    }

    public async goToGroup(team: Team, group: Group) {
        if (this.editMode) {
            return;
        }

        await $router.push({
            name: 'ecosystem-overview-group',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                teamId: team.teamId.toString(),
                teamKey: team.teamKey,
                period: periodModule.selectedPeriod,
                groupId: group.groupId.toString(),
                groupKey: group.groupKey,
            },
        });
    }

    public getEcosystemName() {
        return ecosystemHelper.currentEcosystem.name;
    }

    public updateSettings(vertex) {
        const settings = this.mapSettings.find((x) => x.team === vertex.id);
        settings.radius = vertex.radius;
        eventBus.$emit('redraw');
    }

    public async saveMapSettings() {
        this.showPending('SAVE_MAP_PENDING');
        this.graph.vertices.forEach((vertex: any) => {
            let setting = this.mapSettings.find((s: IMapSetting) => {
                return (s.team === vertex.id && !this.team.teamId) || (s.group === vertex.id && s.team === this.team.teamId);
            });

            if (!setting) {
                setting = { x: parseInt(vertex.x), y: parseInt(vertex.y), radius: vertex.radius, group: null, team: null };

                if (this.isTeamScope) {
                    setting.team = this.team.teamId;
                    setting.group = vertex.id;
                } else {
                    setting.team = vertex.id;
                }
                this.mapSettings.push(setting);
            } else {
                setting.x = parseInt(vertex.x);
                setting.y = parseInt(vertex.y);
                setting.radius = vertex.radius;
            }
        });

        const [err] = await to(mapService.saveSettings(this.mapSettings));
        if (err) {
            this.clearAndShowError('SAVE_MAP_FAILED', err);
        }

        this.clearAndShowSuccess('SAVE_MAP_SUCCESS');
        this.editMode = false;
    }
}
