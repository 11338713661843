import { Component } from 'vue-property-decorator';
import { $router } from '@/main';
import jQuery from 'jquery';
import PageRender from '@/models/PageRender';

@Component
export default class EcosystemSettings extends PageRender {
    public tab: string = null;
    public currentTabComponent: string = '';
    public tabs: string[] = [
        'ledger-accounts',
        'cross-legal-transactions',
        'relations',
        'insight-views',
    ];

    public async mounted() {
        await this.checkPeriodParam();
        await this.changeTab(this.$route.params.tab);
    }

    public async changeTab(tab: string): Promise<void> {
        if (this.tabs.indexOf(tab) < 0) {
            tab = this.tabs[0];
        }
        if (tab !== this.$route.params.tab) {
            const params = this.$route.params;
            params.tab = tab;

            await $router.push({ name: 'ecosystem-settings', params });
        } else {
            this.doActiveClass(tab);
            this.currentTabComponent = 'ecosystem-settings-' + tab;
        }
    }

    public doActiveClass(tab: string) {
        this.$nextTick(() => {
            jQuery('.' + tab).addClass('active');
        });
    }
}
