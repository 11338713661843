import PageRender from '../../../../models/PageRender';
import Component from 'vue-class-component';
import Group from '../../../../models/Group';
import {
    financeAccountService,
    teamHelper,
    attributionService,
    groupService,
    periodFilterHelper,
    financeService,
    connectorService,
    financeGroupService,
} from '@/main';
import { AccountType } from '../../../../models/AccountType';
import { FinanceAccount, Distribution } from '../../../../models/Interfaces';
import to from 'await-to-js';
import Attribution from '../../../../models/Attribution';
import { AttributionType } from '../../../../models/AttributionType';
import DistributedInvoice from '@/models/DistributedInvoice';
import { Member } from '@/models/Member';
import { InvoiceType } from '@/models/InvoiceType';
import Vue from 'vue';
import { functions } from '@/helpers/functions';
import MoveToAccountModalComponent from '@/components/modals/move-to-account-modal';
import AmortizeModalComponent from '@/components/modals/amortize-modal';
import moment from 'moment';
import { enMessages } from '@/language/en';
import { BModal } from 'bootstrap-vue';
import SaveAttributionsModalComponent from '@/components/modals/save-attributions-modal';
import CreateFinanceGroupModalComponent from '@/components/modals/create-finance-group-modal';
import Connector from '@/models/Connectors/Connector';
import { Prop } from 'vue-property-decorator';
import TransferModalComponent from '@/components/modals/transfer-modal';
import TransferRequestModalComponent from '@/components/modals/transfer-request-modal';
import AttributionPercentages from '@/models/Finance/AttributionPercentages';
import TeamSettings from '@/models/TeamSettings';
import { settingsModule } from '@/store/modules/settings';
import EnergyDedicationModalComponent from '@/components/modals/energy-dedication-modal';
import Team from '@/models/Team';
import { periodModule } from '@/store/modules/period';
import RecurringTransfersGrid from '@/components/transfer/recurring-transfers-grid';

@Component
export default class BaseGroupPage extends PageRender {
    @Prop({ default: () => new Group() }) public group: Group;

    public isLoaded: boolean = false;
    public disableGrid: boolean = false;

    public groups: Group[] = null;
    public members: Member[] = [];
    public groupKey: string = '';
    public team: string = '';
    public teamId: number;
    public teams: Team[] = null;
    public invoices: DistributedInvoice[] = [];
    public allInvoices: DistributedInvoice[] = [];
    public skip: number = 0;
    public take: number = 50;
    public personIncomes: any[] = [];

    public onlyRecurring: boolean = false;
    public onlyNotYetDistributed: boolean = false;
    public isReloadingGrid: boolean = false;
    public textSearch: string = '';

    public financePersonAccounts: FinanceAccount[] = null;
    public financeGroupAccounts: FinanceAccount[] = null;
    public externalFinanceGroupAccounts: FinanceAccount[] = null;
    public sendingAccounts: FinanceAccount[] = null; // current group saving account + current group member accounts
    public receivingAccounts: FinanceAccount[] = null; // all group incoome acounts + current group member accounts
    public externalReceivingAccounts: FinanceAccount[] = null; // all group incoome acounts + current group member accounts
    public energyDedications: any[] = null;
    public receivingAccount: number = null;

    public applyMissingAttributions: boolean = false;
    public isBuitenBeschouwingGroup: boolean = false;

    public teamAttributions: Attribution[] = [];
    public groupRemittances: Attribution[] = [];
    public groupAttributions: Attribution[] = [];

    public attributionPercentages: AttributionPercentages = new AttributionPercentages();

    public groupFinanceSettings: any = {
        applyMissingAttributionsOnTransfer: false,
        autoDistributeIncomeToSavings: false,
        autoDistributeExpensesToSavings: false,
    };

    public teamFinanceSettings: TeamSettings = new TeamSettings();

    public selectedField: string = 'selected';
    public editID: number = null;

    public reloadInvoicesGrid: number = 0;
    public invoicesReloading: boolean = false;

    public connectors: Connector[] = [];

    public invoiceColumns: any[] = [
        { field: 'selected', width: 60, locked: true, filterable: false, className: 'bg-white' },

        {
            cell: this.renderInvoicePopoverDistributionGrid,
            title: 'INVOICE',
            locked: true,
            width: 200,
            editable: false,
            field: 'invoice.name',
            headerCell: this.translate,
        },
        {
            cell: this.formatInvoiceAmountValueForGroup,
            title: 'AMOUNT',
            locked: true,
            width: 200,
            editable: false,
            field: 'invoice.amount',
            headerCell: this.translate,
        },
        {
            cell: this.renderAmountLeft,
            title: 'AMOUNT_LEFT',
            locked: true,
            width: 200,
            editable: false,
            headerCell: this.translate,
        },
    ];

    public transferColumns: any[] = [
        { title: 'Amount', cell: this.renderTransferAmount, width: 100 },
        { title: 'Receiver', cell: this.renderMemberFor, width: 250 },
        { title: 'Description', field: 'reason' },
        { title: 'Initiator', cell: this.renderMemberInitiator, width: 250 },
        { title: 'Date', width: 140, cell: this.formatField('timestamp', (x) => moment(x, 'YYYY-MM-DD').format('DD-MM-YYYY')) },
        { title: 'Actions', width: 100, cell: this.renderActions },
    ];

    public personIncomesColumns: any[] = [
        { title: 'Member', field: 'member', cell: this.renderMemberRevenue},
        { title: 'Internal Income', field: 'internal', cell: this.formatNumber('internal') },
        { title: 'External Income', field: 'external', cell: this.formatNumber('external') },
        { title: 'Calculated internal revenue', cell: this.formatNumber('totalAmount') },
    ];

    public $refs!: {
        multiIncomeDistributionModal: MoveToAccountModalComponent;
        amortizeModal: AmortizeModalComponent;
        saveAttributionsModal: SaveAttributionsModalComponent;
        createFinanceGroupModal: CreateFinanceGroupModalComponent;
        transferModal: BModal;
        transferExternalModal: BModal;
        newTransferModal: TransferModalComponent;
        transferRequestModal: TransferRequestModalComponent;
        joinGroup: BModal;
        leaveGroup: BModal;
        transferGroupLead: BModal;
        addMemberModal: BModal;
        deleteConfirm: BModal;
        kickConfirm: BModal;
        energyDedicationEdit: EnergyDedicationModalComponent;
        recurringTransfers: RecurringTransfersGrid;
        templateEditWarning: BModal;
        confirmRemoveAttributions: BModal;
    };

    public async created() {
        this.groupKey = this.$route.params.groupKey;
        this.teamId = teamHelper.currentTeam.id;
    }

    public async initSettingsGroup() {
        if (this.section === 'directory') {
            this.connectors = await connectorService.getConnectors();
        }

        if (this.section === 'finances') {
            const groups = await financeGroupService.getGroups(teamHelper.currentTeam.id, periodModule.selectedPeriod);
            const foundGroup = groups.find((x) => x.groupId === this.group.groupId);
            if (!foundGroup) {
                this.showFailedResponse(`failed to load collaboration mode for ${this.group.name}`);
            }
            this.group.collaborationMode = foundGroup ? foundGroup.collaborationMode : null;
        }
    }

    public async initInvoicesOrExpensesGroup(invoiceType: InvoiceType) {
        const [groupAccount, financeGroupAccounts] = await Promise.all([
            this.getGroupAccount(this.teamId, this.group.groupId),
            this.getFinanceGroupAccounts(this.teamId),
            this.loadGroupFinanceSettings(),
        ]);
        this.group.account = groupAccount;
        this.financeGroupAccounts = financeGroupAccounts;

        const financeAccounts = (await financeAccountService.getAccounts(
            AccountType.Person,
            teamHelper.currentTeam.id,
            periodModule.selectedPeriod,
            parseInt(this.$route.params.groupId),
        )).data;

        let invoices = await this.loadInvoices(invoiceType);

        this.members = this.group.members;

        if (!this.isBuitenBeschouwingGroup && invoiceType === InvoiceType.Income) {
            this.members.forEach((member: Member) => {
                const account = financeAccounts.find((a: { relatedEntityId: number }) => {
                    return a.relatedEntityId === member.memberId;
                });
                if (account) {
                    const columnObject = {
                        width: 125,
                        field: `member_${member.memberId}`,
                        title: `${member.firstName} ${member.lastName}`,
                        locked: false,
                        cell: this.renderDistribution,
                        filterable: false,
                    };

                    this.invoiceColumns.push(columnObject);
                } else {
                    console.log(
                        `One or more members doesn't have a financial account yet, this can take up to several minutes (that's why she/he doesn't show up in the grid). <br/><br/> If this keeps popping up contact the helpdesk.`,
                    );
                }

                member.account = account;
            });
        }

        invoices = this.createMissingDistributions(invoices, invoiceType);

        const groupColumn = {
            width: 175,
            field: `group_${this.group.groupId}`,
            title: this.group.name,
            locked: false,
            cell: this.renderDistribution,
            filterable: false,
        };

        this.invoiceColumns.push(groupColumn);

        this.allInvoices = this.invoices = invoices;
    }

    public async initInsightsGroup() {
        // do split this
    }

    public async initAmbtionsGroup() {
        // do implement this
    }

    public async initAttributions() {
        const [groupAttributions, groupRemittances, teamAttributions] = await Promise.all([
            this.getGroupAttributions(teamHelper.currentTeamId, this.group.groupId),
            this.getGroupRemittances(teamHelper.currentTeamId, this.group.groupId),
            this.getTeamAttributions(teamHelper.currentTeamId),
        ]);
        this.attributionPercentages.teamAttributionsPercentage = this.attributionPercentages.groupRemittancePercentage = this.attributionPercentages.groupAttributionsPercentage = 0;

        groupRemittances.forEach((attr) => {
            this.attributionPercentages.groupRemittancePercentage += attr.percentage;
        });

        // only do this when check box in settings is checked
        groupAttributions.forEach((attr) => {
            this.attributionPercentages.groupAttributionsPercentage += attr.percentage;
        });

        teamAttributions.forEach((attr) => {
            this.attributionPercentages.teamAttributionsPercentage += attr.percentage;
        });
    }

    public async loadInvoices(type: InvoiceType, period?: string) {
        const invoices = await financeService.getInvoices(
            teamHelper.getTeamId(),
            this.group.groupId,
            type,
            null,
            period ? period : periodModule.selectedPeriod,
        );

        // remove distributions without account
        invoices.forEach((invoice) => {
            invoice.distributions = invoice.distributions.filter((dist) => dist.account && dist.account.accountId);
        });

        return invoices;
    }

    public async reloadGrid(invoiceType: InvoiceType) {
        this.invoicesReloading = true;
        const invoices = await this.loadInvoices(invoiceType);
        this.allInvoices = this.invoices = this.createMissingDistributions(invoices, invoiceType);
        this.filterInvoices();
        this.invoicesReloading = false;
        this.reloadInvoicesGrid++;
    }

    public createMissingDistributions(invoices: DistributedInvoice[], invoiceType: InvoiceType = InvoiceType.Income) {
        const self = this;
        invoices.forEach((item: DistributedInvoice) => {
            item.invoice.date = moment(item.invoice.invoiceDate, 'YYYY-MM-DD').toDate();

            const groupDistribution = item.distributions.filter((distribution) => {
                if (distribution.account && self.group.account) {
                    return distribution.account.accountId === self.group.account.accountId;
                }
            });

            if (item.invoice.invoiceType === InvoiceType.Attribution && groupDistribution && groupDistribution.length > 0) {
                item.distributions = groupDistribution;
            }

            if (invoiceType === InvoiceType.Income) {
                for (let i = 0; i < self.members.length; i++) {
                    const distribution = item.distributions.filter((d) => {
                        if (d.account && self.members[i].account) {
                            return d.account.accountId === self.members[i].account.accountId;
                        }
                    });

                    if (distribution && distribution.length === 0) {
                        item.distributions.push({ amount: 0, account: self.members[i].account });
                    }

                    item.selected = false;
                }
            }

            item.amountLeft = functions.amountLeft(item);
            item.amountLeftFilter = item.amountLeft;

            if (groupDistribution && groupDistribution.length === 0) {
                item.distributions.push({ amount: 0, account: self.group.account });
            }
        });

        return invoices;
    }

    public async loadTeamInvoices(type: InvoiceType) {
        const invoices = await financeService.getInvoices(teamHelper.getTeamId(), null, type, null, periodModule.selectedPeriod);

        return invoices;
    }

    public onHeaderSelectionChange(event: { event: { target: { checked: any } } }) {
        const checked = event.event.target.checked;
        Vue.set(
            this,
            'invoices',
            this.invoices.map((item) => {
                return { ...item, selected: checked };
            }),
        );
    }

    public onSelectionChange(event: { dataItem: object }) {
        Vue.set(event.dataItem, 'inEdit', false);
        Vue.set(event.dataItem, this.selectedField, !event.dataItem[this.selectedField]);
    }

    public calculateGridHeight() {
        if (this.invoices.length < 25) {
            return { height: `${120 + this.invoices.length * 40}px` };
        }

        return { height: `${window.innerHeight - 150}px` };
    }

    public getOpenInvoiceAmount() {
        let amountNotDistributed = 0;
        this.invoices.forEach((invoice: DistributedInvoice) => {
            let distributedAmount = 0;
            invoice.distributions.forEach((distribution: Distribution) => {
                if (distribution.amount && !isNaN(distribution.amount)) {
                    distributedAmount += parseFloat(distribution.amount.toString());
                }
            });

            amountNotDistributed += invoice.invoice.amount - distributedAmount;
        });

        return Vue.filter('number-format')(amountNotDistributed);
    }

    public getDistributedInvoiceAmount() {
        let amountDistributed = 0;
        this.invoices.forEach((invoice: DistributedInvoice) => {
            let distributedAmount = 0;
            invoice.distributions.forEach((distribution: Distribution) => {
                if (distribution.amount && !isNaN(distribution.amount)) {
                    distributedAmount += parseFloat(distribution.amount.toString());
                }
            });

            amountDistributed += distributedAmount;
        });

        return Vue.filter('number-format')(amountDistributed);
    }

    public getSelectedInvoices() {
        return this.invoices.filter((item) => item.selected === true);
    }

    get selectedInvoice() {
        // only 1 invoice returned
        return this.invoices.find((item) => item.selected === true);
    }

    public getData() {
        return this.invoices.slice(this.skip, this.take + this.skip);
    }

    public getTotal() {
        return this.invoices ? this.invoices.length : 0;
    }

    public pageChangeHandler(event: { page: { skip: number; take: number } }) {
        this.skip = event.page.skip;
        this.take = event.page.take;
    }

    public areAllSelected() {
        return this.invoices.findIndex((item) => item.selected === false) === -1;
    }

    public openAmortizeModal() {
        this.$refs.amortizeModal.show();
    }

    public optionListReceivers(): any[] {
        const list = this.members.map((sender) => {
            if (sender && typeof sender.account !== 'undefined') {
                return { value: sender.account.accountId, text: sender.account.name };
            }
        });

        list.push({ value: null, text: '-------------------------------------' });

        this.financeGroupAccounts.forEach((account) => {
            list.push({ value: account.accountId, text: `${account.name} Income` });
        });

        list.unshift({ value: null, text: '-------------------------------------' });

        list.unshift({ value: this.group.account.accountId, text: `${this.group.name} Savings` });

        return list.filter((option) => option != null);
    }

    public rowClick(e: { dataItem: any }) {
        if (periodFilterHelper.isCurrentPeriodClosed()) {
            return;
        }
        const invoice = this.invoices.find((item) => item.invoice.invoiceId === this.editID);
        this.invoices.forEach((expense) => (expense[this.selectedField] = false));
        if (invoice) {
            Vue.set(invoice, 'inEdit', false);
        }
        this.editID = e.dataItem.invoice.invoiceId;
        Vue.set(e.dataItem, 'inEdit', true);
    }

    public async getEnergyDedications() {
        const [err, response] = await to(groupService.getEnergyDedication(this.teamId, this.group.groupId, periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse('Failed to load group energy dedications', null);
        }
        return (this.energyDedications = response.data);
    }

    public async getGroupAccount(team: number, groupId: number) {
        const self = this;
        const [err, response] = await to(financeAccountService.getGroupAccount(team, groupId, AccountType.GroupSavings, periodModule.selectedPeriod));
        if (err) {
            self.showFailedResponse('Failed to load group account', null);
        }
        return response;
    }

    public async getFinanceGroupAccounts(team: number) {
        const self = this;
        const [err, response] = await to(financeAccountService.getAccounts(AccountType.GroupIncome, team, periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse('Failed to load group accounts', null);
        }

        if (team === teamHelper.currentTeam.id) {
            this.group.accountIncome = response.data.find((item: FinanceAccount) => {
                return item.relatedEntityId === self.group.groupId;
            });
        }

        return response.data.filter((item: FinanceAccount) => {
            return item.relatedEntityId !== self.group.groupId;
        });
    }

    public async getTeamAttributions(teamId: number) {
        const [err, response] = await to(attributionService.getAttributions(AttributionType.TeamAttribution, teamId, periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse('Failed to load team attributions', null);
        }

        this.attributionPercentages.teamAttributionsPercentage = 0;
        response.data.forEach((attr) => {
            this.attributionPercentages.teamAttributionsPercentage += attr.percentage;
        });

        return response.data;
    }

    public async getGroupAttributions(teamId: number, groupId: number) {
        const [err, response] = await to(
            attributionService.getAttributions(AttributionType.GroupAttribution, teamId, periodModule.selectedPeriod, groupId),
        );
        if (err) {
            this.showFailedResponse('Failed to load group attributions', null);
        }

        this.attributionPercentages.groupAttributionsPercentage = 0;

        response.data.forEach((attr) => {
            this.attributionPercentages.groupAttributionsPercentage += attr.percentage;
        });

        return response.data;
    }

    public async loadTeamFinanceSettings() {
        const currentTeamId = teamHelper.getTeamId(teamHelper.currentTeamId);
        await settingsModule.fetchTeamSettingsIfNeeded(currentTeamId);
        return (this.teamFinanceSettings = settingsModule.findTeamFinanceSettings(currentTeamId));
    }

    public async loadGroupFinanceSettings() {
        const groupId = parseInt(this.$route.params.groupId);
        await settingsModule.fetchGroupSettingsIfNeeded(groupId);
        return (this.groupFinanceSettings = settingsModule.findGroupFinanceSettings(groupId));
    }

    public async getGroupRemittances(teamId: number, groupId: number) {
        const [err, response] = await to(
            attributionService.getAttributions(AttributionType.GroupRemittance, teamId, periodModule.selectedPeriod, groupId),
        );
        if (err) {
            this.showFailedResponse('Failed to load group remittances', null);
        }

        this.attributionPercentages.groupRemittancePercentage = 0;

        response.data.forEach((attr) => {
            this.attributionPercentages.groupRemittancePercentage += attr.percentage;
        });

        return response.data;
    }

    public calculateRevenueGridHeight() {
        if (this.members.length < 25) {
            return { height: `${120 + this.members.length * 41}px` };
        }
    }

    public renderDistribution(
        h: (
            arg0: import('vue').VueConstructor<Vue>,
            arg1: {
                props: { value: any; prop: any; isNegative: boolean; dataItem: any };
                on: { saved: (a: any) => void; changed: (a: any) => void };
            },
        ) => any,
        _: any,
        row: { columnIndex: string | number; dataItem: { distributions: any[]; invoice: { amount: number } } },
        listeners: { saved: (arg0: any) => void; changed: (arg0: any) => void },
    ) {
        const column = this.invoiceColumns[row.columnIndex];
        const split = column.field.split('_');
        let accountType = AccountType.Person;
        if (split[0].indexOf('group') > -1) {
            accountType = AccountType.GroupSavings;
        }

        const distribution = row.dataItem.distributions.find(
            (d: { account: { relatedEntityId: number; accountType: string } }) =>
                d.account && d.account.relatedEntityId && d.account.relatedEntityId === parseInt(split[1]) && d.account.accountType === accountType,
        );

        const isNegative = row.dataItem.invoice.amount < 0;
        const props = {
            value: distribution.amount,
            prop: column.field,
            isNegative,
            dataItem: row.dataItem,
        };

        return h(Vue.component('grid-numeric-editor'), {
            props,
            on: {
                saved: (a: any) => {
                    listeners.saved(a);
                },
                changed: (a: any) => {
                    listeners.changed(a);
                },
            },
        });
    }

    public async updateDistribution(a: { dataItem: any; prop: string; value: any }) {
        const distributedInvoice = a.dataItem;
        const split = a.prop.split('_');
        let accountType = AccountType.Person;
        if (split[0].indexOf('group') > -1) {
            accountType = AccountType.GroupSavings;
        }

        const distribution = distributedInvoice.distributions.find(
            (d: { account: { relatedEntityId: number; accountType: string } }) =>
                d.account && d.account.relatedEntityId && d.account.relatedEntityId === parseInt(split[1]) && d.account.accountType === accountType,
        );
        distribution.amount = a.value;
    }

    public async removeAndSaveCurrentDistributions(invoice: DistributedInvoice) {
        invoice.distributions.forEach((x) => (x.amount = 0));
        await this.saveRow({ dataItem: invoice, value: 0, prop: '_' });
        this.reloadInvoicesGrid++;
    }

    public async saveRow(a: { dataItem: any; prop: string; value: any }) {
        if (this.disableGrid) {
            return;
        }

        const self = this;
        const distributedInvoice = a.dataItem;
        const split = a.prop.split('_');
        let accountType = AccountType.Person;
        if (split[0].indexOf('group') > -1) {
            accountType = AccountType.GroupSavings;
        }

        const distribution = distributedInvoice.distributions.find(
            (d: { account: { relatedEntityId: number; accountType: string } }) =>
                d.account && d.account.relatedEntityId && d.account.relatedEntityId === parseInt(split[1]) && d.account.accountType === accountType,
        );
        if (distribution) {
            distribution.amount = a.value;
        }
        const amount = functions.amountLeft(distributedInvoice, distributedInvoice.invoice.amount < 0);
        if ((amount >= 0 && distributedInvoice.invoice.amount > 0) || (distributedInvoice.invoice.amount < 0 && amount <= 0)) {
            self.showPending('DISTRIBUTING_INVOICES_PENDING');
            this.disableGrid = true;
            const [err] = await to(financeService.saveDistribution(distributedInvoice, teamHelper.getTeamId(), this.group.groupId));
            if (err) {
                self.clearAndShowError('DISTRIBUTING_INVOICES_FAILED', err);
            } else {
                self.clearAndShowSuccess('DISTRIBUTING_INVOICES_SUCCESS');

                Vue.set(distributedInvoice, 'inEdit', false);
                Vue.set(distributedInvoice, 'amountLeftFilter', distributedInvoice.amountLeft);

                this.editID = null;

                await this.reloadGrid(InvoiceType.Income);
                await this.calculateRevenue();
            }

            this.disableGrid = false;
        } else {
            return self.showError(enMessages.notAllowedToDistribute);
        }
    }

    public async calculateRevenue() {
        const self = this;
        self.personIncomes = [];

        self.invoices.forEach((invoice) => {
            invoice.distributions.forEach((distribution) => {
                if (distribution.account) {
                    const member = this.members.find((x) => x.account && x.account.accountId === distribution.account.accountId);
                    if (!member) {
                        self.members.push(
                            new Member({
                                firstName: distribution.account.name,
                                memberId: distribution.account.relatedEntityId,
                                account: distribution.account,
                            }),
                        );
                    }
                }
            });
        });

        for (let i = 0; i < self.members.length; i++) {
            const invoices = self.invoices.filter((invoice: DistributedInvoice) => {
                return (
                    invoice.distributions.filter((item) => {
                        if (self.members[i] && self.members[i].account && item.account) {
                            return (
                                item.account.accountId === self.members[i].account.accountId || item.account.relatedEntityId === self.group.groupId
                            );
                        }
                    }).length > 0
                );
            });

            const internalInvoices = invoices.filter(
                (invoice: DistributedInvoice) =>
                    invoice.invoice.invoiceType === InvoiceType.InternalIncome || invoice.invoice.invoiceType === InvoiceType.Attribution,
            );
            const externalInvoices = invoices.filter((invoice: DistributedInvoice) => invoice.invoice.invoiceType === InvoiceType.Income);
            let internalAmount = 0;
            let externalAmount = 0;

            internalInvoices.forEach((invoice) => {
                invoice.distributions.forEach((item) => {
                    if (item.account && item.account.accountId === self.members[i].account.accountId) {
                        internalAmount += item.amount;
                    }
                });
            });

            externalInvoices.forEach((invoice) => {
                invoice.distributions.forEach((item) => {
                    if (item.account && item.account.accountId === self.members[i].account.accountId) {
                        externalAmount += item.amount;
                    }
                });
            });

            self.personIncomes.push({
                member: self.members[i],
                internal: internalAmount,
                external: externalAmount,
                totalAmount: internalAmount + externalAmount * (1 - self.attributionPercentages.groupAttributionsPercentage / 100),
                totalExternalAmount: internalAmount / (1 - self.attributionPercentages.groupAttributionsPercentage / 100) + externalAmount,
            });
        }
    }

    public renderMemberRevenue(h: (arg0: import('vue').VueConstructor<Vue>, arg1: { props: { member: any } }) => any, _: any, row: any) {
        const props = { member: row.dataItem.member };
        return h(Vue.component('member-name-native'), { props });
    }

    public filterInvoices() {
        this.invoices = [];
        this.isReloadingGrid = true;

        let invoices = this.allInvoices;

        if (this.textSearch) {
            invoices = invoices.filter((invoice) => this.filterPropsOnText(invoice, this.textSearch));
        }

        if (this.onlyNotYetDistributed) {
            invoices = invoices.filter((invoice) => invoice.amountLeftFilter !== 0);
        }

        if (this.onlyRecurring) {
            invoices = invoices.filter((invoice) => !invoice.invoice.isRecurring);
        }

        if (this.textSearch || this.onlyNotYetDistributed || this.onlyRecurring) {
            // reset to page 1
            this.skip = 0;
        }

        this.invoices = invoices;
        this.isReloadingGrid = false;
        this.reloadInvoicesGrid++;
    }

    public formatInvoiceAmountValueForGroup(h, _, row) {
        const props = { invoice: row.dataItem, group: this.group };
        return h(Vue.component('distribution-tree-amount'), { props });
    }

    public formatNumber(field) {
        return ((h, _, row) => {
            const props = { value: row.dataItem[field] };
            return h(Vue.component('number-formatter'), { props });
        });
    }

    public formatField<T>(field, format: (field: T) => string, defaultValue: T = null) {
        return ((h, _, row) => {
            const value = row.dataItem[field];
            return h('td', format(value ? value : defaultValue));
        });
    }

    // tslint:disable-next-line: variable-name
    public renderActions(_h: any, _: any, _row: any) {
        // this is a stub
    }

    // tslint:disable-next-line: variable-name
    public renderMemberInitiator(_h: any, _: any, _row: any) {
        // this is a stub
    }
}
