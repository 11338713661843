import { groupService, teamService, insightService } from '@/main';
import Attribution from '@/models/Attribution';
import InsightView from '@/models/Insights/InsightView';
import { Member } from '@/models/Member';
import { Person } from '@/models/Person';
import Team from '@/models/Team';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { periodModule } from '@/store/modules/period';
import to from 'await-to-js';
import { Component } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

@Component({
    validations: {
        view: {
            name: { required },
        },
    },
} as any)
export default class TemplateActionComponent extends SideActionBase<any> {
    public isEdit: boolean = false;
    public isLoading: boolean = true;
    public submitted: boolean = false;

    public view: InsightView = new InsightView();

    public teams: Team[] = [];
    public members: Member[] = [];
    public copingLoading: boolean = false;
    public importGroupAttributionGroupId?: number = null;

    public selectedTeams: any[] = [];
    public selectedRoles: any[] = [];
    public selectedMembers: any[] = [];

    public roleOptions: any[] = [
        { text: 'Accountant', value: 'BE_Accountant' },
        { text: 'Site Admin', value: 'BE_SiteAdmin' },
        { text: 'Member', value: 'BE_Member' },
    ];

    public async mounted() {
        this.isEdit = this.options.view && this.options.view.insightViewId && this.options.view.insightViewId > 0;

        this.teams = await teamService.getTeams();
        const members = [];
        for (const team of this.teams) {
            const apiMembers = await teamService.getMembers(team.teamId);

            for (const member of apiMembers) {
                const fMember = members.find((x) => x.id === member.personId);
                if (fMember) {
                    fMember.groupMemberships.push(...member.groupMemberships);
                } else {
                    this.members.push(new Member(member));
                }
            }
        }

        if (this.isEdit) {
            Object.assign(this.view, this.options.view);
        }

        if (this.view.teams.length > 0) {
            this.selectedTeams = this.view.teams.filter((x) => x.teamId).map((x) => x.teamId);
        }
        if (this.view.persons.length > 0) {
            this.selectedMembers = this.view.persons.map((x) => x.personId);
        }

        if (this.view.teams.length > 0) {
            this.selectedRoles = this.view.roles.map((x) => x.role);
        }

        this.isLoading = false;
    }

    public async teamChange(attribution: Attribution) {
        attribution.targetGroup = null;
        attribution.groups = await this.getTeamGroups(attribution.targetTeamModel);
    }

    public async getTeamGroups(team: Team) {
        const [err, response] = await to(groupService.getGroups(team.teamId, periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse(`Failed to load groups for team ${team.teamKey}`, null);
        }

        return response.data;
    }

    public memberOptions() {
        return this.members
            .map((member) => {
                return { text: member.getFullName(), value: member.memberId };
            })
            .filter((item, index, array) => {
                return array.findIndex((x) => x.value === item.value) === index;
            });
    }

    public teamOptions() {
        return this.teams.map((team) => {
            return { text: team.name, value: team.teamId };
        });
    }

    public async createTemplate(e) {
        this.submitted = true;

        if ((this as any).$v.view.$invalid) {
            return;
        }

        this.showPending('SAVING_VIEW');

        this.view.persons = this.selectedMembers.map((x) => {
            return { personId: x } as Person;
        });

        this.view.roles = this.selectedRoles.map((x) => {
            return { role: x };
        });

        if (this.selectedTeams && this.selectedTeams.length) {
            this.view.teams = this.selectedTeams.map((x) => {
                return { teamId: x, teamKey: this.teams.find((y) => y.teamId === x).teamKey } as Team;
            });
        }

        if (this.isEdit) {
            const [errU] = await to(insightService.updateView(this.view));
            if (errU) {
                return this.clearAndShowError('ERROR_SAVING_VIEW', errU);
            }

            this.clearNotifications();
            this.finished(e);
            return;
        }

        const [err] = await to(insightService.createView(this.view));
        if (err) {
            return this.clearAndShowError('ERROR_SAVING_VIEW', err);
        }

        this.clearNotifications();
        this.finished(e);
    }

    public async deleteView(e) {
        this.showPending('DELETING_VIEW');
        const [err] = await to(insightService.deleteView(this.view));
        if (err) {
            return this.clearAndShowError('ERROR_DELETING_VIEW', err);
        }

        this.clearNotifications();
        this.finished(e);
    }
}
