import { loginHelper } from '@/main';
import { profileModule } from '@/store/modules/profile';
import { Membership } from '../models/Interfaces';

export class EcosystemHelper {
    public _currentEcosystem: Membership = null;
    public setupIsDone: boolean = false;

    public async setup() {
        let ecosystemId = null;
        const urlSegments = window.location.pathname.split('/');

        if (typeof urlSegments === 'object' && urlSegments.length > 1) {
            ecosystemId = parseInt(urlSegments[1].split('-')[0]);
        }

        this.setEcosystemByEcosystemId(ecosystemId);
        this.setupIsDone = true;
    }

    public getEcosystem(id?: number) {
        if (!id && this._currentEcosystem) {
            id = this._currentEcosystem.id;
        }

        const user = profileModule.brightProfile;
        let ecosystem = user.ecosystemMemberships.find((x) => x.id === id);
        if (ecosystem) {
            return ecosystem;
        }

        ecosystem = user.ecosystemMemberships.find((x) => x.id === user.primaryEcosystem);
        if (ecosystem) {
            return ecosystem;
        }

        return user.ecosystemMemberships[0];
    }

    public setEcosystemByEcosystemId(id: number) {
        this._currentEcosystem = this.getEcosystem(id);
    }

    // public getEcosystemId(key?: string): any {
    //     const ecosystem = this.getEcosystem(key);
    //     return ecosystem.id;
    // }

    public getEcosystemName(id: number): any {
        const user = loginHelper.getUser();
        const team = user.ecosystemMemberships.find((membership) => {
            return membership.id === id;
        });

        if (team) {
            return team.name;
        }
        return user.ecosystemMemberships[0].name;
    }

    get currentEcosystem(): Membership {
        return this._currentEcosystem;
    }

    get ecosystemParam(): string {
        return `${this._currentEcosystem.id}-${this._currentEcosystem.key}`;
    }
}
