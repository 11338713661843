import { Component } from 'vue-property-decorator';
import { resultService, teamHelper, roleHelper, ecosystemHelper } from '@/main';
import FinanceTeam from '../../../../models/FinanceTeam';
import Vue from 'vue';
import BaseInsightsPage from './BaseInsightsPage';
import GroupResults from '@/models/Finance/Api/GroupResults';
import RangeItem from '@/models/Finance/Api/RangeItem';
import { orderBy } from '@progress/kendo-data-query';
import { periodModule } from '@/store/modules/period';

@Component
export default class TeamFinanceGroupOverviewNew extends BaseInsightsPage {
    public teamId: number = null;
    public reloadingResults: boolean = false;

    public groupOverviewData: any[] = [];
    public tempGroupOverviewData: any[] = [];
    public teamFinance: FinanceTeam;
    public isLoaded: boolean = false;
    public reload: number = 0;

    public groupResultColumns: any[] = [
        { field: 'group.groupName', title: 'GROUP_NAME', cell: this.renderGroupInsightsLink },
        { cell: this.formatStartBalanceValue, title: 'START_BALANCE', footerCell: this.renderFooterCellStartBalance },
        { field: 'result', cell: this.formatResultValue, title: 'RESULT', footerCell: this.renderFooterCellSum },
        { field: 'balance', cell: this.formatBalanceValue, title: 'END_BALANCE', footerCell: this.renderFooterCellSum },
        { field: 'expenses', cell: this.formatExpensesValue, title: 'EXPENSES' },
        { field: 'extras', cell: this.formatExtrasValue, title: 'EXTRAS' },
        { field: 'openInvoiceAmount', cell: this.formatOpenInvoicesValue, title: 'OPEN_INVOICES', footerCell: this.renderFooterCellSum },
        { field: 'openExpenseAmount', cell: this.formatOpenExpensesValue, title: 'OPEN_EXPENSES', footerCell: this.renderFooterCellSum },
        { field: 'externalRevenue', cell: this.formatExternalRevenue, title: 'EXTERNAL_REVENUE', footerCell: this.renderFooterCellSum },
    ];

    public async mounted() {
        if (!roleHelper.isSiteAdmin() && !roleHelper.isFinanceEmployee()) {
            await this.$router.push({
                name: 'team-finances',
                params: {
                    ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                    ecosystemKey: ecosystemHelper.currentEcosystem.key,
                    teamId: teamHelper.currentTeam.id.toString(),
                    teamKey: teamHelper.currentTeam.key,
                    tab: 'group-overview',
                    period: periodModule.selectedPeriod,
                },
            });
        }

        this.teamId = teamHelper.currentTeamId;
        this.isLoaded = true;
    }

    public async recalculateResultsIncome(dates) {
        this.reloadingResults = true;
        this.showPending('CALCULATING_RESULT_PENDING');

        this.groupOverviewData = [];
        this.tempGroupOverviewData = [];

        const results = await resultService.getGroupResults(teamHelper.getTeamId(), dates.fromFilterMonth, dates.toFilterMonth);
        this.calculateTotals(results.items);
        this.groupOverviewData = orderBy(this.tempGroupOverviewData, [{ field: 'group.name', dir: 'asc' }]);
        this.clearNotifications();
        this.reloadingResults = false;
        this.reload++;
    }

    public calculateTotals(results: RangeItem<GroupResults>[]) {
        for (const item of results) {
            for (const groupResult of item.item.groupResults) {
                const group = this.tempGroupOverviewData.find((x) => x.group.groupId === groupResult.group.groupId);
                if (group) {
                    group.result += groupResult.result;
                    group.expenses += groupResult.expenses;
                    group.extras += groupResult.extras;
                    group.balance = groupResult.balance;
                    group.externalRevenue += groupResult.externalRevenue;
                    group.openInvoiceAmount = groupResult.openInvoiceAmount;
                    group.openExpenseAmount = groupResult.openExpenseAmount;
                } else {
                    this.tempGroupOverviewData.push(groupResult);
                }
            }
        }
    }

    public formatOpenInvoicesValue(h, _, row) {
        const props = { value: row.dataItem.openInvoiceAmount };
        return h(Vue.component('number-formatter'), { props });
    }

    public formatOpenExpensesValue(h, _, row) {
        const props = { value: row.dataItem.openExpenseAmount };
        return h(Vue.component('number-formatter'), { props });
    }

    public formatExternalRevenue(h, _, row) {
        const props = { value: row.dataItem.externalRevenue };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderFooterCellSum(h, _, row) {
        const total = this.groupOverviewData.reduce((acc: number, current) => acc + (current[row.field] as number), 0);
        const props = { value: total };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderFooterCellStartBalance(h, _) {
        const total = this.groupOverviewData.reduce((acc: number, current) => acc + this.getStartBalanceValue(current), 0);
        const props = { value: total };
        return h(Vue.component('number-formatter'), { props });
    }
}
