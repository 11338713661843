import { render, staticRenderFns } from "./sideActionsComponent.vue?vue&type=template&id=6a4c2e08&"
import script from "./sideActionsComponent.ts?vue&type=script&lang=js&"
export * from "./sideActionsComponent.ts?vue&type=script&lang=js&"
import style0 from "./sideActionsComponent.vue?vue&type=style&index=0&id=6a4c2e08&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports