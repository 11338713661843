import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

@Component
export default class SpinnerComponent extends Vue {
    @Prop({ default: 50 }) public height: number| string;
    @Prop({ type: String, default: 'blue' }) public color: string;
    @Prop() public center: boolean;
    public src: string = '';
    public spinnerHeight: string = '50px';
    public spinnerColor: string = 'blue';

    public mounted() {
        if (this.height) {
            if (typeof(this.height) === 'number') {
                this.spinnerHeight = `${this.height}px`;
            } else {
                this.spinnerHeight = this.height;
            }
        }

        if (this.color) {
            this.spinnerColor = this.color;
        }

        this.src = `/img/bright-loading-spinner-${this.spinnerColor}.gif`;
    }
}
