import { SVG, Svg } from '@svgdotjs/svg.js';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class PrdCanvasComponent extends Vue {
    public canvas: Svg = null;

    public mounted() {
        this.canvas = SVG().addTo(this.getContainer());
        this.fitContainer();

        this.$emit('loaded', this.canvas);
        window.addEventListener('resize', this.onResized);
    }

    public beforeDestroy() {
        window.removeEventListener('resize', this.onResized);
    }

    private onResized() {
        this.fitContainer();
        if (this.canvas) {
            this.$emit('resized', this.canvas);
        }
    }

    private fitContainer() {
        const container: HTMLElement = this.getContainer();
        const rect = container.getBoundingClientRect();
        const height = rect.height;
        const width = rect.width;

        if (this.canvas) {
            this.canvas.size(width, height);
        }
    }

    private getContainer(): HTMLElement {
        return this.$el as HTMLElement;
    }
}
