import GroupResultItem from '@/models/Finance/Api/GroupResultItem';

export default class InsightGroupTotals {
    public id: number;
    public name: string;
    public totals: GroupResultItem[] = [];

    constructor(obj?: Partial<InsightGroupTotals>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
