import { Component, Prop } from 'vue-property-decorator';
import BaseModal from './BaseModal';
import { teamHelper, attributionService } from '@/main';
import Attribution from '@/models/Attribution';
import moment from 'moment';
import { AttributionType } from '@/models/AttributionType';
import Group from '@/models/Group';
import { periodModule } from '@/store/modules/period';

@Component
export default class SaveAttributionsModalComponent extends BaseModal {
    @Prop({ default: () => new Group() }) public group: Group;

    @Prop({ default: () => [] }) public groupAttributions: Attribution[];
    @Prop({ default: () => [] }) public resourceAttributions: Attribution[];
    @Prop({ default: () => [] }) public servicesAttributions: Attribution[];
    // tslint:disable-next-line: no-empty
    @Prop({ default: () => {} }) public groupFinanceSettings: any;

    public isLoaded: boolean = false;
    public period: number = 9999;
    public isTeamScope: boolean = false;

    public async mounted() {
        this.period = periodModule.periods[0].periodId + 1;
        this.isTeamScope = !this.group || this.group.groupId === 0;
        this.isLoaded = true;
    }

    public onHide() {
        this.period = periodModule.periods[0].periodId + 1;
    }

    public optionsList() {
        const periods = periodModule.periods;

        const list = periods.map((period) => {
            return {
                text: moment(period.startDate).format(`MMMM 'YY`),
                value: period.periodId,
                apiFormat: moment(period.startDate).format(`YYYY-MM`),
            };
        });

        list.unshift({
            text: `Next period (${moment()
                .add(1, 'months')
                .format(`MMMM 'YY`)})`,
            value: periodModule.periods[0].periodId + 1,
            apiFormat: `${moment()
                .add(1, 'months')
                .format(`YYYY-MM`)}`,
        });

        return list;
    }
    public async saveSettings() {
        let postResourcesModel = [];
        let postGroupModel = [];
        let postModel = [];
        const errors = [];
        const periodString = this.optionsList().find((x) => x.value === this.period).apiFormat;

        this.showPending('SAVING_ATTRIBUTIONS_PENDING');

        if (!this.isTeamScope) {
            postResourcesModel = this.resourceAttributions.map((item) => {
                return {
                    attributionId: item.attributionId,
                    attributionType: AttributionType.GroupAttribution,
                    percentage: !isNaN(item.percentage) ? parseFloat(item.percentage) : item.percentage,
                    team: teamHelper.getTeamId(),
                    group: this.group.groupId,
                    targetGroup: item.targetGroupModel.groupId,
                    targetTeam: item.targetTeamModel.teamId,
                };
            });

            try {
                await attributionService.saveAttributions(
                    postResourcesModel,
                    AttributionType.GroupAttribution,
                    teamHelper.currentTeam.id,
                    periodString,
                    this.group.groupId,
                );
            } catch (e) {
                console.error(e);
                errors.push('Could not save group attributions.');
            }
        }

        if (!this.isTeamScope) {
            postGroupModel = this.groupAttributions.map((item) => {
                return {
                    attributionId: item.attributionId,
                    attributionType: AttributionType.GroupRemittance,
                    percentage: !isNaN(item.percentage) ? parseFloat(item.percentage) : item.percentage,
                    team: teamHelper.currentTeam.id,
                    group: this.group.groupId,
                    name: item.name,
                    targetGroup: this.group.groupId,
                };
            });

            try {
                await attributionService.saveAttributions(
                    postGroupModel,
                    AttributionType.GroupRemittance,
                    teamHelper.currentTeam.id,
                    periodString,
                    this.group.groupId,
                );
            } catch (e) {
                console.error(e);
                errors.push('Could not save remmittance.');
            }
        }

        if (this.isTeamScope) {
            postModel = this.servicesAttributions.map((item) => {
                return {
                    attributionId: item.attributionId,
                    attributionType: AttributionType.TeamAttribution,
                    percentage: !isNaN(item.percentage) ? parseFloat(item.percentage) : item.percentage,
                    team: teamHelper.currentTeam.id,
                    targetGroup: item.targetGroupModel.groupId,
                    targetTeam: item.targetTeamModel.teamId,
                };
            });

            try {
                await attributionService.saveAttributions(postModel, AttributionType.TeamAttribution, teamHelper.getTeamId(), periodString);
            } catch (e) {
                console.error(e);
                errors.push('Could not save service attributions.');
            }
        }

        if (errors.length === 0) {
            this.$emit('settings-saved');
            this.clearAndShowSuccess('SUCCESS');
            this.hide();
        } else {
            this.clearAndShowError(errors.join('<br/>'), null);
        }
    }
}
